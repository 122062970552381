import SVGIcon from '../SVGIcon';
import React from 'react';

export default function LeichtBewoelktIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#F59E18">
				<path d="M19.148 16c0 2.928-2.388 5.317-5.317 5.317S8.514 18.928 8.514 16s2.388-5.317 5.317-5.317 5.317 2.389 5.317 5.317zm-4.319-6.789h-1.963V2.176h1.963zm-1.963 13.578h1.963v7.035h-1.963zM11.296 9.62l-1.702.981-3.517-6.085 1.701-.982zm5.087 12.744l1.702-.982 3.517 6.086-1.701.981zM8.449 11.73l-.982 1.702-6.085-3.518.981-1.701zm10.781 8.491l.981-1.702 6.086 3.518-.982 1.701zM7.042 14.986v1.963H.008v-1.963zm13.578 1.963v-1.963h7.035v1.963zM7.451 18.519l.982 1.702-6.086 3.517-.982-1.701zm12.744-5.087l-.982-1.702 6.086-3.517.982 1.701zM9.561 21.366l1.702.981-3.517 6.086-1.702-.981zm8.491-10.781l-1.701-.981 3.517-6.086 1.701.981z" />
			</g>
			<path
				fill="#FFF"
				d="M12.201 28.087a3.822 3.822 0 0 1-3.822-3.822 3.805 3.805 0 0 1 3.822-3.802c.3 0 .58.04.88.1l.56.14.16-.56c.74-2.742 3.242-4.663 6.083-4.663s5.343 1.921 6.083 4.663l.16.56.56-.14c.28-.06.58-.1.88-.1 2.101 0 3.822 1.701 3.822 3.802s-1.701 3.822-3.822 3.822H12.201z"
			/>
			<path
				fill="#4F4E4E"
				d="M19.885 16.08a5.699 5.699 0 0 1 5.503 4.222l.3 1.121 1.141-.26c.24-.06.5-.08.74-.08 1.781 0 3.222 1.441 3.222 3.202a3.22 3.22 0 0 1-3.222 3.222H12.201a3.22 3.22 0 0 1-3.222-3.222c0-1.761 1.441-3.202 3.222-3.202.24 0 .5.02.74.08l1.141.26.3-1.121a5.7 5.7 0 0 1 5.503-4.222m0-1.2c-3.182 0-5.863 2.161-6.664 5.103-.32-.08-.66-.12-1.021-.12-2.441 0-4.423 1.981-4.423 4.403a4.424 4.424 0 0 0 4.423 4.422h15.369a4.424 4.424 0 0 0 4.423-4.422c0-2.441-1.981-4.403-4.423-4.403-.36 0-.7.04-1.021.12-.799-2.942-3.481-5.103-6.663-5.103z"
			/>
			<path fill="#4F4E4E" d="M15.183 21.964a.497.497 0 0 1-.191-.038l-1.941-.8a.5.5 0 0 1 .381-.925l1.941.8a.5.5 0 0 1-.19.963z" />
		</SVGIcon>
	);
}
