import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementEmbed.scss';
import { subtypesAMP } from './EmbedSubtypes';

interface IProps {
	data: any;
}

/**
 * Embeddings for amp.
 */
export default class ContentElementEmbed extends React.Component<IProps> {
	static defaultProps = {
		data: {
			element_type: 'embed',
			element_subtype: 'facebook-video',
			content: subtypesAMP['facebook-video'].contentElement.defaultProps.data.content,
		},
	};

	render() {
		return (
			<div className={classNames(styles.embeddedElement)}>
				{React.createElement(subtypesAMP[this.props.data.element_subtype].contentElement, this.props)}
			</div>
		);
	}
}
