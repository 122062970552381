import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterTrockenSonnigIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.458 13.523c0 2.209-1.802 4.01-4.01 4.01s-4.01-1.802-4.01-4.01 1.802-4.01 4.01-4.01 4.01 1.801 4.01 4.01zM11.2 8.402H9.719V3.096H11.2zM9.719 18.645H11.2v5.306H9.719zM8.535 8.711l-1.284.74-2.653-4.59 1.283-.741zM6.387 10.303l-.74 1.283-4.59-2.653.74-1.283zm8.132 6.404l.741-1.283 4.59 2.653-.74 1.283zM5.326 12.758v1.481H.02v-1.481zm10.242 1.481v-1.481h5.307v1.481zM5.635 15.424l.74 1.283-4.59 2.653-.741-1.283zm9.613-3.838l-.741-1.283 4.591-2.653.74 1.283zM7.227 17.571l1.283.74-2.653 4.591-1.284-.741zm6.404-8.132l-1.283-.74 2.653-4.591 1.283.74z"
			/>
			<path
				fill="#FFF"
				d="M21.541 15.744c-1.11 0-2.022-.912-2.022-2.022s.912-2.022 2.022-2.022c.16 0 .308.012.469.049l.296.074.074-.284a3.338 3.338 0 0 1 3.218-2.466 3.326 3.326 0 0 1 3.218 2.466l.074.284.296-.074a1.95 1.95 0 0 1 .469-.049c1.11 0 2.022.912 2.022 2.022s-.912 2.022-2.022 2.022h-8.114z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.598 9.369c1.369 0 2.565.925 2.922 2.244l.16.58.58-.136c.136-.025.259-.049.395-.049a1.71 1.71 0 0 1 1.714 1.714 1.71 1.71 0 0 1-1.714 1.714h-8.113a1.71 1.71 0 0 1-1.714-1.714 1.71 1.71 0 0 1 2.109-1.665l.58.136.16-.58a3.031 3.031 0 0 1 2.921-2.244m0-.616a3.63 3.63 0 0 0-3.514 2.7 2.336 2.336 0 0 0-2.873 2.268 2.336 2.336 0 0 0 2.33 2.33h8.113a2.336 2.336 0 0 0 2.33-2.33 2.336 2.336 0 0 0-2.873-2.268 3.626 3.626 0 0 0-3.513-2.7z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.267 12.55a.296.296 0 0 1-.116-.023l-1.208-.493a.308.308 0 1 1 .233-.571l1.208.493a.308.308 0 0 1-.117.594z"
			/>
			<path
				fill="#C9C8C8"
				d="M9.175 21.679a3.513 3.513 0 0 1-3.507-3.507 3.513 3.513 0 0 1 3.507-3.507c.272 0 .543.037.815.099l.469.111.123-.457a5.764 5.764 0 1 1 11.14-.025l.124.457.469-.111c.272-.062.543-.099.815-.099a3.513 3.513 0 0 1 3.507 3.507 3.513 3.513 0 0 1-3.507 3.507H9.175v.025z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.152 10.613a5.288 5.288 0 0 1 5.101 3.903l.247.926.926-.222c.235-.049.457-.086.692-.086a3.017 3.017 0 0 1 3.013 3.013 3.018 3.018 0 0 1-3.013 3.013H9.162a3.017 3.017 0 0 1-3.013-3.013 3.018 3.018 0 0 1 3.013-3.013c.235 0 .469.025.692.086l.926.222.247-.926a5.34 5.34 0 0 1 5.125-3.903m0-.988a6.261 6.261 0 0 0-6.051 4.631 4 4 0 0 0-4.927 3.89 4 4 0 0 0 4.001 4.001H23.13a4 4 0 0 0 4.001-4.001 4 4 0 0 0-4.927-3.89 6.261 6.261 0 0 0-6.052-4.631z"
			/>
			<g>
				<path
					fill="#4F4E4E"
					d="M11.842 15.973a.439.439 0 0 1-.172-.036l-1.593-.692a.433.433 0 0 1 .345-.793l1.593.692a.433.433 0 0 1-.173.829z"
				/>
			</g>
			<g>
				<path
					fill="#A03416"
					d="M20.023 19.678c-.09-.09-.182-.09-.273-.09l-3.836 1.006 2.648-5.025v-.274c0-.092-.09-.184-.182-.184l-2.102-.641c-.184-.09-.275 0-.365.184l-2.193 5.939-.639 1.734-.184.457c0 .092 0 .184.092.273.092.092.182.092.274.092l3.381-.914h.09l-2.373 6.029v.275c0 .092.09.092.182.182l.549.184h.092c.092 0 .182-.092.273-.184l3.379-6.395.914-1.734.365-.641c0-.09-.092-.18-.092-.273z"
				/>
			</g>
		</SVGIcon>
	);
}
