import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterTrockenBedecktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#C9C8C8"
				d="M2.745 15.443a2.375 2.375 0 0 1-2.373-2.375 2.36 2.36 0 0 1 2.373-2.358c.185 0 .36.024.546.061l.348.088.1-.349a3.919 3.919 0 0 1 3.776-2.894 3.92 3.92 0 0 1 3.776 2.894l.1.349.347-.088a2.65 2.65 0 0 1 .547-.061 2.369 2.369 0 0 1 2.373 2.358 2.374 2.374 0 0 1-2.373 2.375h-9.54z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.516 7.987a3.537 3.537 0 0 1 3.416 2.623l.186.695.709-.161a1.94 1.94 0 0 1 .459-.051c1.105 0 1.999.895 1.999 1.989 0 1.105-.894 2-1.999 2H2.745c-1.106 0-2-.895-2-2 0-1.093.894-1.989 2-1.989.15 0 .311.014.46.051l.709.161.186-.696a3.537 3.537 0 0 1 3.416-2.622m0-.744a4.292 4.292 0 0 0-4.137 3.166A2.744 2.744 0 0 0 0 13.067a2.746 2.746 0 0 0 2.745 2.746h9.541a2.746 2.746 0 0 0 2.745-2.746 2.735 2.735 0 0 0-2.745-2.731c-.224 0-.435.024-.634.073a4.292 4.292 0 0 0-4.136-3.166z"
			/>
			<path
				fill="#FFF"
				d="M21.487 12.916a2.045 2.045 0 0 1-2.037-2.036 2.047 2.047 0 0 1 2.508-1.985l.298.075.074-.287a3.36 3.36 0 0 1 3.24-2.481 3.347 3.347 0 0 1 3.24 2.481l.075.287.298-.075a2.047 2.047 0 0 1 2.507 1.985 2.044 2.044 0 0 1-2.036 2.036h-8.167z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.57 6.499c1.378 0 2.581.932 2.942 2.26l.162.583.583-.135c.136-.026.261-.051.398-.051.956 0 1.726.769 1.726 1.726a1.72 1.72 0 0 1-1.726 1.726h-8.167a1.72 1.72 0 0 1-1.725-1.726c0-.956.77-1.726 1.725-1.726.136 0 .274.014.398.051l.582.135.163-.583a3.051 3.051 0 0 1 2.939-2.26m0-.621a3.655 3.655 0 0 0-3.537 2.719 2.746 2.746 0 0 0-.546-.063 2.353 2.353 0 0 0-2.346 2.346 2.353 2.353 0 0 0 2.346 2.348h8.167A2.353 2.353 0 0 0 32 10.88a2.353 2.353 0 0 0-2.892-2.283 3.657 3.657 0 0 0-3.538-2.719z"
			/>
			<path
				fill="#C9C8C8"
				d="M9.111 18.935a3.54 3.54 0 0 1-3.534-3.534 3.54 3.54 0 0 1 3.534-3.533c.275 0 .547.039.822.098l.472.114.124-.459A5.802 5.802 0 0 1 16.14 7.29a5.807 5.807 0 0 1 5.611 4.305l.125.459.472-.112c.274-.061.548-.1.821-.1a3.54 3.54 0 0 1 3.533 3.533 3.539 3.539 0 0 1-3.533 3.534H9.111v.026z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.139 7.787a5.329 5.329 0 0 1 5.137 3.932l.249.932.932-.224a3.39 3.39 0 0 1 .697-.086 3.04 3.04 0 0 1 3.035 3.035 3.04 3.04 0 0 1-3.035 3.035H9.099a3.04 3.04 0 0 1-3.037-3.035 3.04 3.04 0 0 1 3.037-3.035c.235 0 .473.024.696.086l.933.224.249-.932a5.379 5.379 0 0 1 5.162-3.932m0-.994a6.305 6.305 0 0 0-6.095 4.664 4.03 4.03 0 0 0-4.963 3.918 4.031 4.031 0 0 0 4.03 4.032h14.057a4.032 4.032 0 0 0 0-8.062c-.323 0-.634.037-.931.112a6.31 6.31 0 0 0-6.098-4.664z"
			/>
			<path
				fill="#C9C8C8"
				d="M20.027 16.881c-.091-.09-.184-.09-.274-.09l-3.933 1.005 2.651-5.031v-.273c0-.092-.09-.183-.182-.183l-2.103-.734c-.184-.09-.276 0-.366.185l-2.196 5.946-.64 1.737-.184.55c0 .09 0 .183.091.273.092.092.183.092.275.092l3.384-.915h.091l-2.377 6.036v.275c0 .092.09.092.183.185l.549.183h.09c.092 0 .184-.092.276-.183l3.476-6.496.915-1.737.366-.642c.001.092.001-.09-.092-.183z"
			/>
			<path
				fill="#A03416"
				d="M20.027 16.881c-.091-.09-.184-.09-.274-.09l-3.933 1.005 2.651-5.031v-.273c0-.092-.09-.183-.182-.183l-2.103-.734c-.184-.09-.276 0-.366.185l-2.196 5.946-.64 1.737-.184.55c0 .09 0 .183.091.273.092.092.183.092.275.092l3.384-.915h.091l-2.377 6.036v.275c0 .092.09.092.183.185l.549.183h.09c.092 0 .184-.092.276-.183l3.476-6.496.915-1.737.366-.642c.001.092.001-.09-.092-.183z"
			/>
		</SVGIcon>
	);
}
