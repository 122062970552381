import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NebelGefrierendIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#0971B8">
				<path d="M4.959 14.974h-1v-4.261l.5-.351.5.351v3.797z" />
				<path d="M4.846 12.932l-.703-.703 1.306-1.305.598.104.103.596z" />
				<path d="M4.074 12.932l.701-.703-1.306-1.305-.596.104-.105.596zm.631 2.472l-.246-.43-.244-.429.402-.237 3.289-1.894.551.254-.061.605-3.289 1.903z" />
				<path d="M6.424 14.289l.254-.963 1.779.482.211.569-.465.388z" />
				<path d="M6.037 13.615l.955.263.483-1.779-.393-.475-.57.211zM4.215 15.404l.244-.43.246-.429.402.228 3.289 1.902.061.604-.551.254-3.289-1.892z" />
				<path d="M6.037 16.333l.955-.263.483 1.78-.393.473-.57-.21z" />
				<path d="M6.424 15.657l.254.965 1.779-.482.211-.57-.465-.385zM3.959 14.974h1v4.26l-.5.352-.5-.352v-3.795z" />
				<path d="M4.074 17.017l.701.702-1.306 1.305-.596-.104-.105-.597z" />
				<path d="M4.846 17.017l-.703.702 1.306 1.305.598-.104.103-.597z" />
				<g>
					<path d="M4.215 14.545l.244.429.246.43-.402.237-3.289 1.892-.553-.254.062-.604 3.287-1.902z" />
					<path d="M2.496 15.657l-.256.965-1.779-.482-.209-.57.463-.385z" />
					<path d="M2.881 16.333l-.955-.263-.483 1.78.395.473.57-.21z" />
				</g>
				<g>
					<path d="M4.705 14.545l-.246.429-.244.43-.405-.228-3.287-1.903-.062-.605.553-.254 3.289 1.894z" />
					<path d="M2.881 13.615l-.955.263-.483-1.779.395-.475.57.211z" />
					<path d="M2.496 14.289l-.256-.963-1.779.482-.209.569.463.388z" />
				</g>
			</g>
			<path
				fill="#0971B8"
				d="M13.35 27.403c0-.183-.184-1.829-.275-2.011-.092-.55-.092-1.372-.092-1.922 0-.458.184-.821.457-1.278.275-.366.73-.732 1.465-1.007.639-.273 1.461-.549 2.65-.822.73-.184 1.189-.365 1.555-.641.365-.274.547-.457.641-.823.09-.273.09-.548 0-.914-.094-.273-.184-.548-.275-.821h1.371c.365.182.457.363.641.639.184.366.365.732.274 1.19 0 .457-.184.821-.639 1.188-.457.366-1.191.732-2.287 1.098-.822.183-1.371.457-1.828.641-.457.181-.731.457-.914.639-.184.275-.184.551-.184.823.092.366.184.732.367 1.279.639 1.738.731 1.92 1.096 2.744"
			/>
			<path
				fill="#0971B8"
				d="M4.113 27.403c.275-.457.551-.824.732-1.189.273-.364.457-.73.639-1.006.184-.273.367-.549.549-.822.551-.822.916-1.554 1.373-2.193.457-.55.914-1.007 1.463-1.465a7.562 7.562 0 0 1 2.102-1.005 17.298 17.298 0 0 1 3.109-.73 9.746 9.746 0 0 0 1.096-.275c.275-.091.459-.183.549-.274.092-.091.184-.274.184-.364.092-.366 0-.914 0-.914h1.371c.184.273.275.548.365.729 0 .184 0 .366-.182.549-.092.184-.365.366-.732.55-.365.182-1.006.364-1.826.548-1.008.183-1.83.458-2.471.64-.639.275-1.189.457-1.555.823-.365.366-.731.732-.914 1.19-.273.547-.549 1.097-.822 2.011-.639 2.011-.549 2.561-.732 3.2"
			/>
			<path
				fill="#969696"
				d="M32 6.871c-8.02 0-12.098-.327-16.039-.642C12.037 5.915 7.978 5.591 0 5.591v-.994c8.019 0 12.096.326 16.039.642 3.924.314 7.98.638 15.961.638v.994zm0 3.657c-8.02 0-12.098-.326-16.039-.642C12.037 9.572 7.978 9.248 0 9.248v-.994c8.019 0 12.096.327 16.039.643 3.924.313 7.98.637 15.961.637v.994zm0 3.748c-8.102 0-12.19-.313-16.143-.616a218.507 218.507 0 0 0-5.826-.39l.051-.993c2.213.114 4.062.256 5.85.393 4.117.316 8.006.612 16.068.612v.994zm0 3.657c-8.102 0-12.19-.312-16.145-.615-1.781-.136-3.625-.277-5.824-.391l.051-.992c2.213.114 4.061.255 5.85.393 4.117.315 8.003.612 16.068.612v.993z"
			/>
		</SVGIcon>
	);
}
