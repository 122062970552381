import React, { Component } from 'react';
import { IconMap } from '../BRIcon/index';
import moment from 'moment';
import classNames from 'classnames';
import Slider, { CustomArrowProps } from 'react-slick';
import SvgNext from '../ContentElementImage/icon_b3_next.svg';
import SvgPrevious from '../ContentElementImage/icon_b3_previous.svg';
import { WeatherSliderProps } from './IWeather';
import styles from './WeatherSlider.scss';

const PrevArrowComponent = (props: CustomArrowProps) => {
	const { className, style, onClick } = props;
	return <SvgPrevious className={className} style={style} onClick={onClick} />;
};

const NextArrowComponent = (props: CustomArrowProps) => {
	const { className, style, onClick } = props;
	return <SvgNext className={className} style={style} onClick={onClick} />;
};

export default class WeatherSlider extends Component<WeatherSliderProps> {
	render() {
		let sliderSettings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToScroll: 1,
			nextArrow: <NextArrowComponent />,
			prevArrow: <PrevArrowComponent />,
		};
		const { days } = this.props;

		return (
			<Slider {...sliderSettings} className={classNames(styles.weatherSlider)}>
				{days.map((day, index) => (
					<div className={classNames(styles.weatherSliderDay)} key={index}>
						<div className={classNames(styles.weatherSliderDayString)}>
							{moment(day.date).calendar(undefined, {
								sameDay: '[Heute] - dddd, DD. MMMM',
								nextDay: '[Morgen] - dddd, DD. MMMM',
								nextWeek: 'dddd, DD. MMMM',
							})}
						</div>
						{day.forecast.map((itemData, i) => {
							let Icon = IconMap.get(itemData.weathersituation.style);
							if (!Icon) Icon = IconMap.get('bedeckt');

							return (
								<div className={classNames(styles.weatherSliderItem, 'rows', 'flexcenter')} key={i}>
									<div className={classNames(styles.sliderItemTime)}>{itemData.formattedhour}</div>
									<div className={classNames(styles.sliderItemIcon)}>{Icon && <Icon />}</div>
									<div className={classNames(styles.sliderItemTemperature)}>{itemData.temperature.value}°</div>
								</div>
							);
						})}
					</div>
				))}
			</Slider>
		);
	}
}
