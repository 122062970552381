import moment from 'moment';

class PlaylistItem {
	constructor(item) {
		Object.assign(this, item);
		this.start = moment(this.timestamp);
		this.duration = moment.duration(this.duration);
		this.end = moment(this.timestamp).add(this.duration);

		if (this.end.isBefore(moment())) {
			this.elapsed = this.duration;
		} else if (this.start.isAfter(moment())) {
			this.elapsed = moment.duration();
		} else {
			this.elapsed = moment.duration(moment().diff(this.timestamp));
		}

		this.remaining = moment.duration(this.duration).subtract(this.elapsed);
	}

	format() {
		let result = Object.assign({}, this);
		result.start = this.start.toISOString();
		result.duration = this.duration.hours() + ':' + this.duration.minutes() + ':' + this.duration.seconds();
		result.end = this.end.toISOString();
		result.elapsed = this.elapsed.hours() + ':' + this.elapsed.minutes() + ':' + this.elapsed.seconds();
		result.remaining = this.remaining.hours() + ':' + this.remaining.minutes() + ':' + this.remaining.seconds();
		return result;
	}
}

export default class Playlist {
	constructor(list) {
		this.list = list;
	}

	get first() {
		if (this._list.length > 0) {
			return this._list[0].format();
		}
		return { title: '', type: 'none' };
	}

	get current() {
		let current = null;
		this._list.forEach(item => {
			if (moment().isAfter(item.start) && moment().isBefore(item.end)) {
				current = !current || item.start.isBefore(current.timestamp) ? current : item;
			}
		});
		return current ? current.format() : this.first;
	}

	get list() {
		return this._list.map(item => {
			return item.format();
		});
	}

	set list(list = []) {
		// if (!list || list.length === 0) return;

		this._list = list.map(item => {
			return new PlaylistItem(item);
		});
	}
}
