import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GraupelschauerHeiterIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="4.439" cy="23.146" r="1.396" />
			<path
				fill="#4F4E4E"
				d="M4.439 24.781c-.9 0-1.635-.733-1.635-1.634 0-.901.734-1.636 1.635-1.636a1.634 1.634 0 1 1 0 3.27zm0-2.794a1.158 1.158 0 1 0 .001 2.317 1.158 1.158 0 0 0-.001-2.317z"
			/>
			<circle fill="#FFF" cx="6.65" cy="30.359" r="1.396" />
			<path
				fill="#4F4E4E"
				d="M6.65 31.994c-.9 0-1.635-.733-1.635-1.635 0-.9.734-1.634 1.635-1.634a1.636 1.636 0 0 1 0 3.269zm0-2.793a1.159 1.159 0 1 0 .001 2.317 1.159 1.159 0 0 0-.001-2.317z"
			/>
			<path fill="#FFF" d="M13.398 26.521a1.395 1.395 0 1 1 0 2.79 1.395 1.395 0 0 1 0-2.79z" />
			<path
				fill="#4F4E4E"
				d="M13.398 29.55a1.635 1.635 0 0 1 0-3.268 1.635 1.635 0 0 1 0 3.268zm0-2.791a1.157 1.157 0 1 0-.001 2.315 1.157 1.157 0 0 0 .001-2.315z"
			/>
			<circle fill="#FFF" cx="20.26" cy="30.359" r="1.396" />
			<path
				fill="#4F4E4E"
				d="M20.26 31.994a1.635 1.635 0 0 1 0-3.269c.902 0 1.637.733 1.637 1.634a1.639 1.639 0 0 1-1.637 1.635zm0-2.793a1.158 1.158 0 1 0 .005 2.317 1.158 1.158 0 0 0-.005-2.317z"
			/>
			<path fill="#FFF" d="M25.963 25.821a1.397 1.397 0 0 1 0 2.793 1.395 1.395 0 0 1-1.396-1.396 1.395 1.395 0 0 1 1.396-1.397z" />
			<path
				fill="#4F4E4E"
				d="M25.963 28.852a1.637 1.637 0 0 1-1.637-1.633c0-.9.734-1.635 1.637-1.635a1.635 1.635 0 0 1 0 3.268zm0-2.792c-.641 0-1.16.52-1.16 1.158a1.159 1.159 0 0 0 2.316 0c0-.638-.517-1.158-1.156-1.158z"
			/>
			<g fill="#F59E18">
				<path d="M18.422 10.805a5.166 5.166 0 0 1-1.83 7.057c-2.445 1.439-5.615.616-7.055-1.831a5.164 5.164 0 0 1 1.83-7.055 5.152 5.152 0 0 1 7.055 1.829zm-6.944-3.55l-1.642.964L6.379 2.34l1.642-.963zM8.725 9.333l-.94 1.655-5.937-3.37.939-1.656zM7.383 12.495l.017 1.903-6.814.053-.016-1.905zm13.181 1.797l-.015-1.904 6.814-.052.016 1.903zM7.809 15.907l.964 1.639-5.879 3.457-.964-1.64zm12.31-5.032l-.965-1.641 5.877-3.457.965 1.641zM18.035 8.146l-1.656-.94 3.355-5.931 1.659.941zm-3.189-1.328l-1.905.017-.05-6.814 1.902-.015z" />
			</g>
			<g>
				<path
					fill="#CAC9C8"
					d="M12.234 23.845c-2.094 0-3.723-1.629-3.723-3.722 0-2.095 1.629-3.725 3.723-3.725.232 0 .58 0 .814.116l.582.118.115-.583c.697-2.674 3.143-4.536 5.934-4.536 2.793 0 5.234 1.862 5.934 4.536l.115.583.582-.118c.233-.116.582-.116.815-.116 2.094 0 3.723 1.63 3.723 3.725 0 2.093-1.629 3.722-3.723 3.722H12.234z"
				/>
				<path
					fill="#4F4E4E"
					d="M19.68 12.212c2.443 0 4.654 1.629 5.352 4.071l.348 1.049 1.049-.235c.233 0 .465-.116.697-.116a3.128 3.128 0 0 1 3.141 3.142 3.127 3.127 0 0 1-3.141 3.14H12.234a3.13 3.13 0 0 1-3.143-3.14 3.13 3.13 0 0 1 3.143-3.142c.232 0 .465 0 .697.116l1.049.235.348-1.049c.697-2.327 2.791-4.071 5.352-4.071m0-1.165c-3.141 0-5.699 2.095-6.514 5.003-.352-.116-.699-.116-.932-.116a4.295 4.295 0 0 0-4.305 4.306 4.294 4.294 0 0 0 4.305 4.303h14.891a4.293 4.293 0 0 0 4.305-4.303 4.295 4.295 0 0 0-4.305-4.306c-.35 0-.697 0-.93.116-.816-2.908-3.375-5.003-6.515-5.003z"
				/>
				<path
					fill="#4F4E4E"
					d="M15.143 17.932a.49.49 0 0 1-.186-.037l-1.977-.813a.486.486 0 0 1 .369-.898l1.977.814a.486.486 0 0 1-.183.934z"
				/>
			</g>
		</SVGIcon>
	);
}
