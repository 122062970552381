import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
// @ts-ignore
import trackEvent from '../../commons/tracking/trackers';
import * as config from '../../../config.webapp';
import SvgPlayerPlay from './icon_b3_player_play.svg';
import SvgPlayerPause from './icon_b3_player_pause.svg';
import SvgPlayerBusy from './icon_b3_player_busy.svg';
import styles from './RadioPlayer.scss';
import SpectrumDataFetcher from './SpectrumDataFetcher';

const IS_SPECTRUM_ANALYZER_DATA_FAKED = config.IS_SPECTRUM_ANALYZER_DATA_FAKED;
const STREAM_URL = config.SERVICE.radiostream;

enum PlayerStatus {
	STOPPED = 'stopped',
	STARTING = 'starting',
	PLAYING = 'playing',
}

interface IState {
	playerStatus: PlayerStatus;
	playerAlreadyUsed: boolean;
}

interface IProps {
	handlePlayerUpdate: (a: number[]) => void;
}

/**
 * Component for playing the webradio stream.
 */
export default class RadioPlayer extends React.Component<IProps, IState> {
	static propTypes = {
		handlePlayerUpdate: PropTypes.func,
	};

	state = {
		playerStatus: PlayerStatus.STOPPED,
		playerAlreadyUsed: false,
	};

	playerRef: React.RefObject<HTMLAudioElement> = React.createRef();
	requestAnimationFrameId?: number;
	spectrumDataFetcher?: SpectrumDataFetcher;

	/**
	 * Performs a spectrum analysis and delivers the data to the 'handlePlayerUpdate'
	 * method. If the spectrum analysis is disabled through config, faked data is
	 * delivered.
	 */
	updateSpectrumData = () => {
		let data = SpectrumDataFetcher.prevFakeData;
		if (this.spectrumDataFetcher) {
			data = this.spectrumDataFetcher.fetch(this.state.playerStatus === PlayerStatus.PLAYING);
		}

		if (this.props.handlePlayerUpdate) {
			this.props.handlePlayerUpdate(data);
		}

		this.requestAnimationFrameId = window.requestAnimationFrame(this.updateSpectrumData);
	};

	/**
	 * Starts or stops the player depending on the current state. This is
	 * called when the play or pause button is clicked.
	 */
	onPlayPause = () => {
		switch (this.state.playerStatus) {
			case PlayerStatus.STOPPED:
				this.play();
				break;
			case PlayerStatus.STARTING:
			case PlayerStatus.PLAYING:
				this.pause();
				break;
		}

		this.setState({
			playerAlreadyUsed: true,
		});
	};

	/**
	 * Sets the player state to starting so that the player renders and can be started in the
	 * componentDidUpdate method. The corresponding tracking event is send.
	 */
	play = () => {
		trackEvent('RadioPlayer', this.state.playerAlreadyUsed ? 'play' : 'firstPlay');
		this.setState({
			playerStatus: PlayerStatus.STARTING,
		});

		var event = document.createEvent('CustomEvent');
		event.initCustomEvent('pausePlayers', false, false, { sender: this });
		window.dispatchEvent(event);
	};

	/**
	 * Pauses the player, cancels the spectrum analyser and ends the stream download.
	 * The corresponding tracking event is send.
	 */
	pause = () => {
		trackEvent('RadioPlayer', 'pause');
		const player = this.playerRef.current;
		if (player) {
			player.pause();
			player.src = '';
		}
		this.cancelSpectrumAnalyser();
		this.setState({
			playerStatus: PlayerStatus.STOPPED,
		});
	};

	/**
	 * This sets the players state to 'playing' when the stream is loaded and starting to play.
	 */
	onPlaying = () => {
		this.setState({
			playerStatus: PlayerStatus.PLAYING,
		});
	};

	/**
	 * Pauses the player when other media players send the 'pausePlayers' event.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onPause = (event: any) => {
		if (event.detail.sender !== this && this.state.playerStatus !== PlayerStatus.STOPPED) {
			this.pause();
		}
	};

	/**
	 * Checks if the current browser is Apple's Safari.
	 */
	isSafari = () => {
		return navigator ? navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1 : false;
	};

	/**
	 * Initialises a spectrum analyser (fake or real one) to supply data for the spectrum analyser
	 * on the frontend.
	 */
	setupSpectrumAnalyser = () => {
		if (!IS_SPECTRUM_ANALYZER_DATA_FAKED && !this.isSafari() && AudioContext && this.playerRef.current) {
			const audioContext = new AudioContext();
			const analyser = audioContext.createAnalyser();
			const source = audioContext.createMediaElementSource(this.playerRef.current);
			source.connect(analyser);
			analyser.connect(audioContext.destination);

			this.spectrumDataFetcher = new SpectrumDataFetcher(analyser);
		} else {
			this.spectrumDataFetcher = new SpectrumDataFetcher();
		}
		if (window) {
			this.requestAnimationFrameId = window.requestAnimationFrame(this.updateSpectrumData);
		}
	};

	/**
	 * Stops the spectrum analyser.
	 */
	cancelSpectrumAnalyser = () => {
		if (this.requestAnimationFrameId) {
			window.cancelAnimationFrame(this.requestAnimationFrameId);
			this.requestAnimationFrameId = undefined;
		}
	};

	componentDidMount() {
		window.addEventListener('pausePlayers', this.onPause);
		this.setupSpectrumAnalyser();
	}

	componentDidUpdate(prevProps: IProps, prevState: IState) {
		const player = this.playerRef.current;
		if (prevState.playerStatus !== this.state.playerStatus && this.state.playerStatus === PlayerStatus.STARTING && player) {
			this.setupSpectrumAnalyser();
			player.play();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('pausePlayers', this.onPause);
		this.pause();
	}

	render = () => {
		let buttonClass = classNames(styles.button);
		let ButtonComponent = SvgPlayerPlay;

		if (this.state.playerStatus === PlayerStatus.STARTING) {
			buttonClass = classNames(styles.rotobutton);
			ButtonComponent = SvgPlayerBusy;
		} else if (this.state.playerStatus === PlayerStatus.PLAYING) {
			ButtonComponent = SvgPlayerPause;
		}

		return (
			<div className={classNames(styles.radioPlayer)}>
				{this.state.playerStatus !== PlayerStatus.STOPPED && (
					<audio
						ref={this.playerRef}
						title="Bayern 3 Stream"
						crossOrigin="anonymous"
						onPlaying={this.onPlaying}
						onError={this.pause}
						src={STREAM_URL}
					/>
				)}
				<button
					title={this.state.playerStatus === PlayerStatus.PLAYING ? 'Radio Player stoppen' : 'Radio Player starten'}
					onClick={this.onPlayPause}
					className={buttonClass}
				>
					<ButtonComponent className={classNames(styles.currentColorButton)} />
				</button>
			</div>
		);
	};
}
