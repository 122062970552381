import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GraupelschauerIcon(props) {
	return (
		<SVGIcon {...props}>
			<ellipse fill="#FFF" cx="3.287" cy="21.506" rx="1.096" ry="1.058" />
			<path
				fill="#4F4E4E"
				d="M3.287 22.746c-.709 0-1.281-.557-1.281-1.238 0-.682.572-1.237 1.281-1.237s1.285.556 1.285 1.237c0 .682-.576 1.238-1.285 1.238zm0-2.114c-.502 0-.91.392-.91.876 0 .483.408.878.91.878.5 0 .91-.395.91-.878 0-.484-.41-.876-.91-.876z"
			/>
			<ellipse fill="#FFF" cx="5.025" cy="26.886" rx="1.096" ry="1.058" />
			<path
				fill="#4F4E4E"
				d="M5.025 28.125c-.707 0-1.283-.555-1.283-1.239 0-.682.576-1.235 1.283-1.235s1.281.554 1.281 1.235c.001.684-.574 1.239-1.281 1.239zm0-2.117c-.502 0-.91.394-.91.878 0 .485.408.878.91.878.5 0 .912-.393.912-.878 0-.484-.412-.878-.912-.878z"
			/>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zm9.986-1.27l-.757-1.286 4.609-2.709.757 1.285zm-1.633-2.14l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<ellipse fill="#FFF" cx="10.328" cy="25.122" rx="1.098" ry="1.058" />
			<path
				fill="#4F4E4E"
				d="M10.328 26.362c-.707 0-1.285-.556-1.285-1.238 0-.684.578-1.238 1.285-1.238.709 0 1.287.555 1.287 1.238 0 .683-.578 1.238-1.287 1.238zm0-2.118c-.5 0-.91.396-.91.88s.41.88.91.88c.502 0 .912-.396.912-.88s-.41-.88-.912-.88z"
			/>
			<ellipse fill="#FFF" cx="15.635" cy="26.886" rx="1.098" ry="1.058" />
			<path
				fill="#4F4E4E"
				d="M15.635 28.125c-.709 0-1.285-.555-1.285-1.239 0-.682.576-1.235 1.285-1.235.707 0 1.283.554 1.283 1.235 0 .684-.576 1.239-1.283 1.239zm0-2.117c-.502 0-.91.394-.91.878 0 .485.408.878.91.878s.908-.393.908-.878c0-.484-.406-.878-.908-.878z"
			/>
			<g>
				<ellipse fill="#FFF" cx="20.207" cy="24.595" rx="1.1" ry="1.059" />
				<path
					fill="#4F4E4E"
					d="M20.207 25.83c-.709 0-1.283-.555-1.283-1.237s.574-1.237 1.283-1.237c.707 0 1.283.556 1.283 1.237 0 .682-.576 1.237-1.283 1.237zm0-2.114c-.502 0-.91.394-.91.877 0 .485.408.881.91.881.5 0 .912-.396.912-.881 0-.483-.412-.877-.912-.877z"
				/>
			</g>
			<g>
				<path
					fill="#C9C8C8"
					d="M2.746 18.58c-1.303 0-2.373-1.018-2.373-2.285 0-1.259 1.057-2.276 2.373-2.276.185 0 .361.025.547.06l.346.081.1-.332c.459-1.64 2.012-2.792 3.775-2.792 1.764 0 3.316 1.152 3.779 2.792l.098.332.346-.081a2.82 2.82 0 0 1 .547-.06c1.305 0 2.373 1.018 2.373 2.276 0 1.256-1.057 2.285-2.373 2.285H2.746z"
				/>
				<path
					fill="#4F4E4E"
					d="M7.514 11.398c1.604 0 3.006 1.04 3.416 2.526l.186.67.711-.157a2.03 2.03 0 0 1 .457-.049c1.106 0 2 .863 2 1.919 0 1.065-.894 1.926-2 1.926H2.746c-1.106 0-2-.86-2-1.926 0-1.056.894-1.919 2-1.919.146 0 .31.014.461.049l.705.157.188-.67c.408-1.486 1.812-2.526 3.414-2.526m0-.72c-1.975 0-3.639 1.294-4.135 3.053a2.734 2.734 0 0 0-.633-.071C1.23 13.659 0 14.846 0 16.295c0 1.461 1.23 2.645 2.746 2.645h9.537c1.516 0 2.746-1.184 2.746-2.645s-1.231-2.636-2.746-2.636c-.225 0-.436.024-.633.071-.496-1.758-2.162-3.052-4.136-3.052z"
				/>
				<path
					fill="#FFF"
					d="M21.486 16.132c-1.098 0-2.01-.883-2.01-1.941 0-1.06.912-1.938 2.01-1.938.184 0 .277 0 .459.085l.275.092.09-.266c.367-1.411 1.74-2.383 3.293-2.383 1.557 0 2.836.972 3.293 2.383l.092.266.272-.092c.185 0 .277-.085.461-.085 1.096 0 2.01.878 2.01 1.938 0 1.058-.914 1.941-2.01 1.941h-8.235z"
				/>
				<path
					fill="#4F4E4E"
					d="M25.603 9.956c1.371 0 2.563.883 2.928 2.208l.182.525.547-.174c.092 0 .277-.086.369-.086.916 0 1.736.701 1.736 1.672s-.732 1.675-1.736 1.675h-8.143c-.912 0-1.736-.704-1.736-1.675s.732-1.672 1.736-1.672c.092 0 .277 0 .369.086l.547.174.184-.525c.365-1.325 1.646-2.208 3.017-2.208m0-.615c-1.738 0-3.109 1.143-3.566 2.644-.182 0-.367-.088-.551-.088-1.279 0-2.375 1.06-2.375 2.294 0 1.233 1.096 2.292 2.375 2.292h8.143c1.279 0 2.377-1.06 2.377-2.292 0-1.234-1.098-2.294-2.377-2.294-.184 0-.369 0-.549.088-.367-1.501-1.83-2.644-3.477-2.644z"
				/>
				<path
					fill="#4F4E4E"
					d="M23.227 13.081a.365.365 0 0 1-.131-.027l-1.189-.531a.291.291 0 0 1-.15-.396.313.313 0 0 1 .412-.148l1.187.529a.293.293 0 0 1 .152.397.31.31 0 0 1-.281.176z"
				/>
				<path
					fill="#CAC9C8"
					d="M9.141 21.948c-1.924 0-3.568-1.5-3.568-3.435 0-1.856 1.557-3.441 3.568-3.441.274 0 .547 0 .824.087l.455.09.094-.442c.641-2.469 3.018-4.142 5.578-4.142 2.562 0 4.938 1.673 5.578 4.142l.094.442.457-.09c.273-.087.545-.087.822-.087 1.92 0 3.566 1.499 3.566 3.441 0 1.85-1.555 3.435-3.566 3.435H9.141z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.092 11.192c2.377 0 4.482 1.588 5.121 3.792l.273.883.916-.178c.275-.09.457-.09.733-.09 1.644 0 3.017 1.323 3.017 2.914 0 1.583-1.373 2.908-3.017 2.908H9.141c-1.646 0-3.019-1.325-3.019-2.908 0-1.591 1.373-2.914 3.019-2.914.274 0 .459 0 .732.09l.914.178.275-.883c.549-2.204 2.653-3.792 5.03-3.792m0-.967c-2.928 0-5.398 1.939-6.127 4.495-.277-.09-.641-.09-.916-.09-2.195 0-4.023 1.766-4.023 3.884 0 2.111 1.828 3.876 4.023 3.876h14.086c2.193 0 4.025-1.765 4.025-3.876 0-2.118-1.832-3.884-4.025-3.884-.369 0-.641 0-.914.09-.735-2.556-3.203-4.495-6.129-4.495z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.791 16.373a.514.514 0 0 1-.154-.027l-1.65-.62a.417.417 0 0 1-.246-.543.438.438 0 0 1 .562-.237l1.648.617a.418.418 0 0 1 .248.544.436.436 0 0 1-.408.266z"
				/>
			</g>
		</SVGIcon>
	);
}
