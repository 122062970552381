import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PlayerFullscreenDisableGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="15.999" cy="15.999" r="15.999" />
			<path
				fill="#FFF"
				d="M13.879 9.152a.509.509 0 0 0-.508.508v3.657H9.569a.508.508 0 0 0 0 1.015h4.31c.28 0 .507-.226.507-.507V9.66a.508.508 0 0 0-.507-.508zm4.386 5.253h4.165a.507.507 0 0 0 0-1.016h-3.657V9.588a.508.508 0 0 0-1.017 0v4.31c0 .281.229.507.509.507zm4.165 3.264h-4.312a.506.506 0 0 0-.505.509v4.162a.508.508 0 0 0 1.015 0v-3.656h3.803a.507.507 0 1 0-.001-1.015zm-8.698-.073H9.568a.508.508 0 1 0 0 1.014h3.657v3.804c0 .28.226.507.507.507a.51.51 0 0 0 .509-.507v-4.311a.508.508 0 0 0-.509-.507z"
			/>
		</SVGIcon>
	);
}
