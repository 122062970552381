import SVGIcon from '../SVGIcon';
import React from 'react';

export default function ShareGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16.001" cy="15.999" r="16.001" />
			<path
				fill="#FFF"
				d="M20.48 20.474c-.698 0-1.311.326-1.724.824l-5.255-4.271c.161-.309.261-.654.261-1.028s-.1-.72-.261-1.028l5.255-4.271c.413.5 1.025.823 1.724.823a2.237 2.237 0 1 0-2.238-2.236c0 .211.037.41.092.604l-5.397 4.385a2.216 2.216 0 0 0-1.413-.515 2.238 2.238 0 0 0 0 4.476c.54 0 1.027-.199 1.413-.517l5.397 4.386a2.202 2.202 0 0 0-.092.604 2.238 2.238 0 1 0 2.238-2.236zm0-12.531c.739 0 1.342.602 1.342 1.344a1.345 1.345 0 0 1-2.688 0 1.347 1.347 0 0 1 1.346-1.344zm0 16.113a1.347 1.347 0 0 1-1.346-1.344c0-.74.604-1.344 1.346-1.344a1.345 1.345 0 0 1 0 2.688z"
			/>
		</SVGIcon>
	);
}
