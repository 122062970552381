/*eslint no-console:0*/

import React from 'react';
import classNames from 'classnames';
import styles from './Summary.scss';

export default class Summary extends React.Component {
	summaryEditorRef = React.createRef();

	onChange = text => {
		if (this.summaryEditorRef.current && this.summaryEditorRef.current.medium.origElements.textContent.trim() === '') {
			text = '';
		}
		this.props.onChange(text);
	};

	render() {
		let content = <div className={classNames(styles.summaryContent)} dangerouslySetInnerHTML={{ __html: this.props.text }} />;

		if (this.props.onChange) {
			if (IS_CMS) {
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;
				content = (
					<ReactMediumEditor
						text={this.props.text}
						onChange={this.onChange}
						className={classNames(styles.summaryContent)}
						options={{ placeholder: { text: 'Text für Kurzzusammenfassung' }, buttons: ['anchor'], anchor: { targetCheckbox: true } }}
						ref={this.summaryEditorRef}
					/>
				);
			}
		}

		if ((content && this.props.text.length > 0) || this.props.onChange) {
			return (
				<div className={classNames(styles.summary)} data-class="summary">
					<h3>Kurzzusammenfassung</h3>
					{content}
				</div>
			);
		} else {
			return null;
		}
	}
}
