import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WolkigIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#F59E18">
				<path d="M14.472 16c0 2.212-1.805 4.017-4.017 4.017S6.438 18.212 6.438 16s1.805-4.017 4.017-4.017 4.017 1.805 4.017 4.017zm-3.263-5.129H9.726V5.556h1.483zM9.726 21.129h1.483v5.315H9.726zM8.539 11.18l-1.285.741-2.658-4.598 1.286-.741zm3.844 9.628l1.286-.742 2.657 4.598-1.285.742zm-5.994-8.034l-.742 1.285-4.598-2.657.742-1.285zm8.145 6.415l.742-1.286 4.597 2.658-.741 1.285zM5.326 15.234v1.483H.011v-1.483zm10.259 1.483v-1.483h5.314v1.483zM5.635 17.903l.741 1.286-4.598 2.657-.741-1.285zm9.628-3.844l-.741-1.285 4.597-2.657.742 1.285zM7.229 20.054l1.286.742-2.658 4.598-1.285-.742zm6.415-8.145l-1.285-.742 2.657-4.598 1.285.742z" />
			</g>
			<path
				fill="#FFF"
				d="M21.536 18.195c-1.111 0-2.024-.913-2.024-2.024s.913-2.024 2.024-2.024c.16 0 .309.012.469.049l.296.074.074-.284c.395-1.456 1.715-2.468 3.221-2.468s2.838 1.012 3.221 2.468l.074.284.296-.074c.148-.037.309-.049.469-.049 1.111 0 2.024.913 2.024 2.024s-.913 2.024-2.024 2.024h-8.12z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.596 11.815c1.37 0 2.567.925 2.925 2.246l.16.58.58-.136c.136-.025.259-.049.395-.049.95 0 1.715.765 1.715 1.715s-.765 1.715-1.715 1.715h-8.12c-.95 0-1.715-.765-1.715-1.715a1.711 1.711 0 0 1 2.11-1.666l.58.136.16-.58a3.037 3.037 0 0 1 2.925-2.246m0-.617a3.632 3.632 0 0 0-3.517 2.702 2.338 2.338 0 0 0-2.875 2.27 2.338 2.338 0 0 0 2.332 2.332h8.12a2.338 2.338 0 0 0 2.332-2.332 2.338 2.338 0 0 0-2.875-2.27 3.634 3.634 0 0 0-3.517-2.702z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.263 14.999a.296.296 0 0 1-.116-.023l-1.209-.494a.308.308 0 1 1 .233-.571l1.209.494a.308.308 0 0 1-.117.594z"
			/>
			<path
				fill="#FFF"
				d="M9.23 24.177a3.518 3.518 0 0 1-3.513-3.513 3.518 3.518 0 0 1 3.513-3.513c.272 0 .544.037.816.099l.47.111.124-.458a5.773 5.773 0 1 1 11.158-.025l.124.458.47-.111a3.67 3.67 0 0 1 .816-.099 3.518 3.518 0 0 1 3.513 3.513 3.518 3.518 0 0 1-3.513 3.513H9.23v.025z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.219 13.093a5.298 5.298 0 0 1 5.109 3.909l.247.928.928-.223c.235-.05.458-.087.693-.087a3.022 3.022 0 0 1 3.018 3.018 3.022 3.022 0 0 1-3.018 3.018H9.217a3.022 3.022 0 0 1-3.018-3.018 3.022 3.022 0 0 1 3.018-3.018c.235 0 .47.025.693.087l.928.223.247-.928a5.349 5.349 0 0 1 5.134-3.909m0-.99a6.27 6.27 0 0 0-6.061 4.639 4.007 4.007 0 1 0-.928 7.905h13.978a4.007 4.007 0 1 0-.928-7.905 6.27 6.27 0 0 0-6.061-4.639z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.901 18.462a.435.435 0 0 1-.172-.036l-1.596-.693a.434.434 0 0 1 .345-.795l1.596.693a.434.434 0 0 1-.173.831z"
			/>
		</SVGIcon>
	);
}
