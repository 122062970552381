import React from 'react';

interface IState {
	hasError: boolean;
}

export default class ErrorBoundary extends React.Component<{}, IState> {
	constructor(props: {}) {
		super(props);
	}

	state = {
		hasError: false,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	static getDerivedStateFromError(error: any) {
		// eslint-disable-next-line no-console
		console.error(error);
		return { hasError: true };
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	componentDidCatch(error: any, info: any) {
		// eslint-disable-next-line no-console
		console.error('component did catch!');
		console.error(error);
		console.error(info);
	}

	render() {
		if (this.state.hasError) {
			return <h1>Something went wrong</h1>;
		}

		return this.props.children;
	}
}
