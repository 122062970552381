import * as config from '../../config.webapp';
import { createReducer } from './_defaults';
import ApiClient from '../../helpers/ApiClient';

const LOAD = 'br3/privacyPolicy/LOAD';
const LOAD_SUCCESS = 'br3/privacyPolicy/LOAD_SUCCESS';
const LOAD_FAIL = 'br3/privacyPolicy/LOAD_FAIL';

const initialState = {
	loaded: false,
};

const serviceUrl = config.SERVICE.privacyPolicy;

export default createReducer(initialState, LOAD, LOAD_SUCCESS, LOAD_FAIL);

export function isLoaded(globalState: any) {
	return globalState.privacyPolicy && globalState.privacyPolicy.loaded;
}

/**
 * Loads the privacy policy.
 */
export function load() {
	return {
		types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
		promise: (client: ApiClient) => client.get(`${serviceUrl}`, { headers: { Accept: 'application/json' } }),
	};
}
