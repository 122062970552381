import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterHagelBedecktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="2.197" cy="18.586" r="1.738" />
			<path
				fill="#4F4E4E"
				d="M2.197 20.572a1.986 1.986 0 1 1 1.985-1.986 1.988 1.988 0 0 1-1.985 1.986zm0-3.475c-.82 0-1.489.668-1.489 1.489 0 .82.669 1.488 1.489 1.488s1.489-.668 1.489-1.488a1.49 1.49 0 0 0-1.489-1.489z"
			/>
			<circle fill="#FFF" cx="3.844" cy="24.163" r="1.738" />
			<path
				fill="#4F4E4E"
				d="M3.844 26.149a1.986 1.986 0 0 1 0-3.971 1.986 1.986 0 0 1 0 3.971zm0-3.475a1.49 1.49 0 0 0 0 2.978c.82 0 1.488-.669 1.488-1.49a1.49 1.49 0 0 0-1.488-1.488z"
			/>
			<circle fill="#FFF" cx="9.787" cy="22.517" r="1.736" />
			<path
				fill="#4F4E4E"
				d="M9.787 24.502A1.988 1.988 0 0 1 7.8 22.517c0-1.094.892-1.985 1.987-1.985a1.986 1.986 0 0 1 0 3.97zm0-3.473a1.49 1.49 0 0 0-1.489 1.487 1.489 1.489 0 0 0 2.976 0 1.49 1.49 0 0 0-1.487-1.487z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.752 15.409a2.372 2.372 0 0 1-2.371-2.372 2.36 2.36 0 0 1 2.371-2.358c.187 0 .36.023.546.062l.35.087.099-.348c.459-1.701 2.012-2.892 3.774-2.892s3.314 1.191 3.774 2.892l.1.348.348-.087a2.368 2.368 0 0 1 2.917 2.296 2.37 2.37 0 0 1-2.372 2.372H2.752z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.521 7.959a3.535 3.535 0 0 1 3.414 2.619l.187.697.708-.162c.148-.037.31-.051.458-.051 1.106 0 2 .895 2 1.987a1.997 1.997 0 0 1-2 1.999H2.752a1.998 1.998 0 0 1-1.998-1.999c0-1.093.894-1.987 1.998-1.987.149 0 .31.014.459.051l.709.162.186-.697a3.538 3.538 0 0 1 3.415-2.619m0-.744a4.29 4.29 0 0 0-4.135 3.165 2.742 2.742 0 0 0-3.378 2.657 2.745 2.745 0 0 0 2.744 2.744h9.535a2.745 2.745 0 0 0 2.745-2.744 2.734 2.734 0 0 0-2.745-2.73c-.223 0-.433.024-.633.073a4.29 4.29 0 0 0-4.133-3.165z"
			/>
			<path
				fill="#FFF"
				d="M21.485 12.887a2.044 2.044 0 0 1-2.035-2.036 2.045 2.045 0 0 1 2.506-1.984l.297.074.075-.286a3.357 3.357 0 0 1 3.237-2.48 3.348 3.348 0 0 1 3.239 2.48l.075.286.296-.074c.148-.037.31-.051.471-.051 1.118 0 2.036.919 2.036 2.035a2.044 2.044 0 0 1-2.036 2.036h-8.161z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.565 6.472c1.378 0 2.581.932 2.941 2.258l.161.584.584-.137c.136-.024.261-.051.396-.051.956 0 1.726.769 1.726 1.725 0 .956-.77 1.725-1.726 1.725h-8.161a1.72 1.72 0 0 1-1.725-1.725c0-.955.769-1.725 1.725-1.725.136 0 .272.013.396.051l.584.137.161-.584a3.05 3.05 0 0 1 2.938-2.258m0-.62a3.652 3.652 0 0 0-3.535 2.717 2.352 2.352 0 0 0-2.891 2.282 2.352 2.352 0 0 0 2.346 2.346h8.161a2.352 2.352 0 0 0 2.346-2.346 2.352 2.352 0 0 0-2.891-2.282 3.651 3.651 0 0 0-3.536-2.717z"
			/>
			<path
				fill="#C9C8C8"
				d="M9.115 18.899a3.538 3.538 0 0 1-3.531-3.532 3.536 3.536 0 0 1 3.531-3.53c.273 0 .547.037.82.1l.473.112.123-.46a5.798 5.798 0 0 1 5.607-4.327c2.625 0 4.938 1.765 5.608 4.302l.125.46.473-.111c.273-.063.546-.1.819-.1a3.536 3.536 0 0 1 3.532 3.53 3.538 3.538 0 0 1-3.532 3.532H9.115v.024z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.139 7.759c2.4 0 4.515 1.616 5.136 3.929l.25.933.933-.224c.235-.05.46-.088.695-.088a3.039 3.039 0 0 1 3.035 3.034 3.04 3.04 0 0 1-3.035 3.034H9.103a3.037 3.037 0 0 1-3.033-3.034 3.037 3.037 0 0 1 3.033-3.034c.235 0 .473.025.696.088l.933.224.249-.933a5.372 5.372 0 0 1 5.158-3.929m0-.995a6.305 6.305 0 0 0-6.092 4.662 3.806 3.806 0 0 0-.932-.112 4.029 4.029 0 1 0 0 8.057h14.049c2.227 0 4.028-1.803 4.028-4.028s-1.802-4.029-4.028-4.029c-.323 0-.634.037-.931.112a6.303 6.303 0 0 0-6.094-4.662z"
			/>
			<g>
				<path
					fill="#C9C8C8"
					d="M20.026 16.848c-.091-.09-.184-.09-.274-.09l-3.932 1.005 2.651-5.027v-.275c0-.091-.092-.183-.183-.183l-2.103-.732c-.184-.091-.366 0-.366.184l-2.193 5.942-.64 1.736-.183.549c0 .091 0 .182.09.273.093.093.183.093.274.093l3.383-.915h.093l-2.379 6.035v.274c0 .091.092.091.184.183l.549.183h.092c.091 0 .183-.092.274-.183l3.473-6.492.915-1.736.365-.64c.001.091.001-.091-.09-.184z"
				/>
			</g>
			<g>
				<path
					fill="#A03416"
					d="M20.026 16.848c-.091-.09-.184-.09-.274-.09l-3.932 1.005 2.651-5.027v-.275c0-.091-.092-.183-.183-.183l-2.103-.732c-.184-.091-.366 0-.366.184l-2.193 5.942-.64 1.736-.183.549c0 .091 0 .182.09.273.093.093.183.093.274.093l3.383-.915h.093l-2.379 6.035v.274c0 .091.092.091.184.183l.549.183h.092c.091 0 .183-.092.274-.183l3.473-6.492.915-1.736.365-.64c.001.091.001-.091-.09-.184z"
				/>
			</g>
		</SVGIcon>
	);
}
