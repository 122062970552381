import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenschauerIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M4.209 24.445c.273-.496.273-1.292.273-1.888 0-.794.092-1.491.092-1.491s-.365.497-1.006.895c-.547.396-1.371.695-1.555 1.095-.365.693-.182 1.489.457 1.887.641.396 1.374.198 1.739-.498zm2.377 4.572c.275-.497.275-1.292.275-1.887 0-.797.092-1.492.092-1.492s-.367.497-1.006.896c-.551.397-1.373.694-1.555 1.091-.367.696-.184 1.491.457 1.89.547.396 1.374.197 1.737-.498zm5.213-1.49c.275-.498.275-1.293.275-1.89 0-.795.09-1.491.09-1.491s-.365.498-1.004.896c-.549.396-1.373.694-1.557 1.093-.365.694-.182 1.489.457 1.89.551.395 1.374.196 1.739-.498z"
			/>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zm9.986-1.27l-.757-1.286 4.609-2.709.757 1.285zm-1.633-2.14l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.752 18.777C1.448 18.777.38 17.63.38 16.2c0-1.416 1.056-2.563 2.372-2.563.186 0 .36.027.546.067l.348.094.099-.378c.46-1.848 2.012-3.143 3.776-3.143s3.316 1.295 3.776 3.143l.099.378.348-.094a2.4 2.4 0 0 1 .546-.067c1.304 0 2.372 1.147 2.372 2.563s-1.056 2.577-2.372 2.577H2.752z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.522 10.683c1.602 0 3.006 1.174 3.415 2.846l.186.756.708-.175c.149-.041.311-.054.46-.054 1.105 0 2 .971 2 2.158 0 1.201-.894 2.172-2 2.172H2.752c-1.105 0-2-.971-2-2.172 0-1.187.894-2.158 2-2.158.149 0 .31.013.46.054l.708.175.186-.756c.41-1.673 1.813-2.846 3.416-2.846m0-.81c-1.975 0-3.639 1.457-4.136 3.44a2.408 2.408 0 0 0-.633-.081c-1.515 0-2.745 1.335-2.745 2.968 0 1.646 1.23 2.981 2.745 2.981h9.539c1.515 0 2.745-1.336 2.745-2.981 0-1.646-1.23-2.968-2.745-2.968-.224 0-.435.027-.633.081-.498-1.983-2.163-3.44-4.137-3.44z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.603 14.649a.277.277 0 0 1-.118-.026l-1.205-.54c-.159-.071-.234-.268-.169-.44s.247-.254.405-.183l1.205.54c.159.071.234.268.169.44a.312.312 0 0 1-.287.209z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.487 16.035c-1.117 0-2.035-.998-2.035-2.212s.918-2.212 2.035-2.212c.161 0 .31.014.472.054l.298.081.074-.31c.397-1.592 1.725-2.698 3.239-2.698 1.514 0 2.854 1.106 3.239 2.698l.074.31.298-.081c.149-.04.31-.054.472-.054 1.117 0 2.035.998 2.035 2.212s-.918 2.212-2.035 2.212h-8.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.57 9.06c1.378 0 2.581 1.012 2.941 2.455l.161.634.583-.148c.136-.027.261-.054.397-.054.956 0 1.725.836 1.725 1.875s-.769 1.875-1.725 1.875h-8.166c-.956 0-1.725-.836-1.725-1.875s.769-1.875 1.725-1.875a1.3 1.3 0 0 1 .397.054l.583.148.161-.634c.362-1.443 1.566-2.455 2.943-2.455m0-.674c-1.7 0-3.127 1.255-3.537 2.954a2.548 2.548 0 0 0-.546-.067c-1.291 0-2.345 1.147-2.345 2.55 0 1.403 1.055 2.55 2.345 2.55h8.166c1.291 0 2.345-1.147 2.345-2.55 0-1.403-1.055-2.55-2.345-2.55-.186 0-.372.027-.546.067-.409-1.7-1.837-2.954-3.537-2.954z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.225 12.541a.287.287 0 0 1-.117-.025l-1.216-.54c-.159-.07-.235-.267-.17-.44.065-.173.246-.255.404-.185l1.216.54c.159.07.235.267.17.44a.31.31 0 0 1-.287.21z"
			/>
			<g>
				<path
					fill="#FFF"
					d="M9.114 22.572c-1.953 0-3.533-1.731-3.533-3.84 0-2.123 1.592-3.84 3.533-3.84.274 0 .547.041.821.108l.473.122.124-.5c.672-2.785 2.986-4.705 5.61-4.705s4.939 1.92 5.61 4.678l.124.5.473-.122c.274-.068.547-.108.821-.108 1.953 0 3.533 1.731 3.533 3.84 0 2.123-1.592 3.84-3.533 3.84H9.114v.027z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.143 10.458c2.401 0 4.516 1.758 5.138 4.272l.249 1.014.933-.243c.236-.054.46-.095.697-.095 1.679 0 3.035 1.487 3.035 3.299 0 1.825-1.368 3.299-3.035 3.299H9.102c-1.679 0-3.035-1.487-3.035-3.299 0-1.825 1.368-3.299 3.035-3.299.236 0 .473.027.697.095l.933.243.249-1.014c.646-2.514 2.761-4.272 5.162-4.272m0-1.081c-2.923 0-5.374 2.15-6.096 5.07a3.553 3.553 0 0 0-.933-.122c-2.227 0-4.031 1.96-4.031 4.381 0 2.42 1.804 4.38 4.031 4.38h14.057c2.227 0 4.031-1.96 4.031-4.38s-1.804-4.381-4.031-4.381c-.323 0-.634.041-.933.122-.721-2.921-3.172-5.07-6.095-5.07z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.801 16.326a.411.411 0 0 1-.173-.039l-1.605-.757c-.221-.104-.322-.383-.226-.623s.352-.349.573-.246l1.605.757c.22.104.322.383.226.623a.436.436 0 0 1-.4.285z"
				/>
			</g>
		</SVGIcon>
	);
}
