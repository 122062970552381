import ContentElementEmbedFacebookVideo from './ContentElementEmbedFacebookVideo';
import ContentElementEmbedTypeForm from './ContentElementEmbedTypeForm';
import ContentElementEmbedScript from './ContentElementEmbedScript';
import ContentElementEmbedFacebookVideoAMP from './ContentElementEmbedFacebookVideoAMP';
import ContentElementEmbedScriptAMPIA from './ContentElementEmbedScriptAMPIA';
import ContentElementEmbedFacebookVideoIA from './ContentElementEmbedFacebookVideoIA';

let editors = {
	'facebook-video': () => null,
	typeform: () => null,
	script: () => null,
};

if (IS_CMS) {
	editors['facebook-video'] = require('./EmbedEditorFacebookVideo').default;
	editors.typeform = require('./EmbedEditorTypeForm').default;
	editors.script = require('./EmbedEditorScript').default;
}

// subtypes
export const subtypes = {
	'facebook-video': {
		title: 'Facebook Video',
		editor: editors['facebook-video'],
		contentElement: ContentElementEmbedFacebookVideo,
	},
	typeform: {
		title: 'Typeform',
		editor: editors.typeform,
		contentElement: ContentElementEmbedTypeForm,
	},
	script: {
		title: 'Script',
		editor: editors.script,
		contentElement: ContentElementEmbedScript,
	},
};

// subtypes for amp
export const subtypesAMP = {
	'facebook-video': {
		title: 'Facebook Video',
		contentElement: ContentElementEmbedFacebookVideoAMP,
	},
	typeform: {
		title: 'Typeform',
		contentElement: ContentElementEmbedScriptAMPIA,
	},
	script: {
		title: 'Script',
		contentElement: ContentElementEmbedScriptAMPIA,
	},
};

// subtypes for instant articles
export const subtypesIA = {
	'facebook-video': {
		title: 'Facebook Video',
		contentElement: ContentElementEmbedFacebookVideoIA,
	},
	typeform: {
		title: 'Typeform',
		contentElement: ContentElementEmbedScriptAMPIA,
	},
	script: {
		title: 'Script',
		contentElement: ContentElementEmbedScriptAMPIA,
	},
};
