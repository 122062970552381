import classNames from 'classnames';
import moment from 'moment';
import 'moment/locale/de';
import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Footer } from '../br3-components';
import ErrorBoundary from '../br3-components/components/ErrorBoundary/ErrorBoundary';
import { isLoaded as isNavigationLoaded, load as loadNavigation } from '../redux/modules/navigation';
import { isLoaded as isPlaylistLoaded, load as loadPlaylist } from '../redux/modules/playlist';
import { stopTransition } from '../redux/modules/transition';
import styles from './App.scss';
import routes from './routes';
import HeaderConnector from './views/HeaderConnector';

moment.locale('de');

const title = 'BAYERN 3';
const description = 'BAYERN 3';
const image = 'https://bayern3.de/favicon.png';

const meta = {
	title,
	description,
	meta: {
		name: {
			viewport: 'width=device-width, minimum-scale=1, initial-scale=1',
			'google-site-verification': ['BtTxh_ODGYgo4bZgScCkT0RVQTyjuRG8Ij_p_dm6NvE', 'cb2i9zSLWUIP2ac3waCFd7EpxJ5dZ6O3VpnfHWFRvTo'],
		},
		charSet: 'utf-8',
		property: {
			'og:site_name': title,
			'og:type': 'website',
			'og:image': image,
			'og:locale': 'de_DE',
			'og:title': title,
			'og:description': description,
			'fb:app_id': '211679079181036',
			'twitter:card': 'summary',
			'twitter:site': '@bayern3',
			'twitter:title': title,
			'twitter:description': description,
			'twitter:image': image,
		},
	},
};

class App extends Component {
	static displayName = 'Root Application';

	static fetchData(getState, dispatch) {
		const promises = [];
		if (!isNavigationLoaded(getState())) promises.push(dispatch(loadNavigation()));
		if (!isPlaylistLoaded(getState())) promises.push(dispatch(loadPlaylist()));
		return Promise.all(promises);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.location.pathname !== this.props.location.pathname) {
			if (window && window.scrollTo) {
				window.scrollTo(0, 0);
			}
		}
	}

	render() {
		const {
			location: { pathname },
			footer,
			playlist,
			transition: { navigating },
			navigation: { mainNavigation },
		} = this.props;

		const isNotAmpOrAndroidOrIa = !(pathname.endsWith('/amp') || pathname.endsWith('/android') || pathname.endsWith('/ia'));
		const isNotAndroidOrIa = !(pathname.endsWith('/ia') || pathname.endsWith('/android'));

		return (
			<>
				<div className={classNames(styles.contentRoot)}>
					<DocumentMeta {...meta} />

					{isNotAmpOrAndroidOrIa && <HeaderConnector loadPlaylist={loadPlaylist} playlist={playlist} navigation={mainNavigation} />}

					<div className={classNames([styles.content, navigating && styles.transitionRunning])}>
						<ErrorBoundary>{routes}</ErrorBoundary>
					</div>

					{isNotAndroidOrIa && <Footer data={footer} />}
				</div>
			</>
		);
	}
}

const ConnectedComponent = hot(
	withRouter(
		connect(
			state => ({
				navigation: state.navigation,
				playlist: state.playlist,
				transition: state.transition,
			}),
			{ stopTransition, loadNavigation, loadPlaylist }
		)(App)
	)
);
ConnectedComponent.fetchData = App.fetchData;

export default ConnectedComponent;
