import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeStarkBedecktIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M12.808 22.629l-.184-.733-1.279.458.365-1.371-.731-.182-.364 1.371-.916-1.006-.548.548 1.005.915-1.279.366.182.731 1.28-.366-.274 1.28.639.183.366-1.28.915.915.548-.55-1.005-.913zm10.879 1.279l-.183-.639-1.279.366.365-1.281-.732-.182-.366 1.28-.914-.915-.548.458L21.036 24l-1.28.366.183.641 1.28-.367-.367 1.371.733.184.366-1.372.913.914.55-.457-1.007-1.006zM7.1 22.117l-.36-1.228-2.307.657.583-2.332-1.228-.31-.583 2.332-1.737-1.662-.881.918 1.725 1.662-2.308.657.36 1.229 2.308-.658-.583 2.332 1.24.31.571-2.332 1.736 1.663.881-.918-1.724-1.663z"
			/>
			<path
				fill="#FFF"
				d="M2.758 15.362a2.37 2.37 0 0 1-2.371-2.371 2.36 2.36 0 0 1 2.371-2.359c.186 0 .36.025.546.062l.348.087.099-.348c.46-1.701 2.011-2.893 3.774-2.893s3.315 1.192 3.774 2.893l.099.348.348-.087c.174-.037.36-.062.546-.062 1.304 0 2.371 1.055 2.371 2.359s-1.055 2.371-2.371 2.371H2.758z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.525 7.913a3.534 3.534 0 0 1 3.414 2.62l.186.695.708-.161c.149-.037.31-.05.459-.05 1.105 0 1.999.894 1.999 1.986a1.998 1.998 0 0 1-1.999 1.999H2.758a1.998 1.998 0 0 1-1.999-1.999c0-1.093.894-1.986 1.999-1.986.149 0 .31.012.459.05l.708.161.186-.695a3.536 3.536 0 0 1 3.414-2.62m0-.745a4.29 4.29 0 0 0-4.134 3.166 2.742 2.742 0 0 0-3.377 2.657 2.745 2.745 0 0 0 2.744 2.744h9.535a2.745 2.745 0 0 0 2.744-2.744 2.734 2.734 0 0 0-2.744-2.731c-.223 0-.435.025-.633.074a4.292 4.292 0 0 0-4.135-3.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.608 11.563a.307.307 0 0 1-.118-.024l-1.204-.497a.311.311 0 0 1 .236-.574l1.204.497a.311.311 0 0 1-.118.598z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.488 12.839c-1.116 0-2.034-.918-2.034-2.034s.918-2.035 2.034-2.035c.161 0 .31.012.471.05l.298.074.074-.285a3.357 3.357 0 0 1 3.238-2.481 3.348 3.348 0 0 1 3.238 2.481l.074.285.299-.074c.149-.037.31-.05.471-.05 1.116 0 2.034.918 2.034 2.035s-.918 2.034-2.034 2.034h-8.163z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.57 6.425c1.377 0 2.58.93 2.94 2.258l.161.583.583-.136c.136-.025.26-.05.397-.05.955 0 1.724.769 1.724 1.724s-.769 1.724-1.724 1.724h-8.163c-.955 0-1.724-.769-1.724-1.724s.769-1.724 1.724-1.724c.136 0 .273.012.397.05l.583.136.161-.583a3.054 3.054 0 0 1 2.941-2.258m0-.62a3.65 3.65 0 0 0-3.535 2.717 2.35 2.35 0 0 0-2.891 2.283 2.35 2.35 0 0 0 2.345 2.345h8.163a2.35 2.35 0 0 0 2.345-2.345 2.35 2.35 0 0 0-2.891-2.283 3.653 3.653 0 0 0-3.536-2.717z"
			/>
			<path fill="#4F4E4E" d="M23.225 9.626a.32.32 0 0 1-.117-.023l-1.216-.496a.31.31 0 1 1 .234-.574l1.216.496a.31.31 0 0 1-.117.597z" />
			<path
				fill="#C9C8C8"
				d="M9.118 18.853a3.538 3.538 0 0 1-3.532-3.532 3.538 3.538 0 0 1 3.532-3.532c.274 0 .547.037.821.099l.473.112.124-.46a5.802 5.802 0 1 1 11.216-.024l.124.46.473-.112c.274-.062.547-.099.821-.099a3.538 3.538 0 0 1 3.532 3.532 3.538 3.538 0 0 1-3.532 3.532H9.118v.024z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.144 7.711c2.4 0 4.514 1.617 5.136 3.929l.249.933.933-.224c.236-.05.46-.087.696-.087a3.038 3.038 0 0 1 3.034 3.034 3.038 3.038 0 0 1-3.034 3.034H9.106a3.038 3.038 0 0 1-3.034-3.034 3.038 3.038 0 0 1 3.034-3.034c.236 0 .473.025.696.087l.933.224.249-.933a5.376 5.376 0 0 1 5.16-3.929m0-.995a6.304 6.304 0 0 0-6.093 4.663 4.028 4.028 0 0 0-4.962 3.917 4.028 4.028 0 0 0 4.029 4.029H23.17a4.028 4.028 0 0 0 4.029-4.029 4.028 4.028 0 0 0-4.962-3.917 6.304 6.304 0 0 0-6.093-4.663z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.804 13.108a.44.44 0 0 1-.173-.036l-1.604-.696a.435.435 0 1 1 .347-.799l1.604.696a.435.435 0 0 1-.174.835z"
			/>
			<g>
				<path
					fill="#0971B8"
					d="M19.79 18.422l-.36-1.228-2.307.657.583-2.332-1.228-.31-.583 2.332-1.737-1.662-.881.918 1.725 1.662-2.308.658.36 1.228 2.307-.658-.583 2.333 1.241.31.571-2.332 1.736 1.662.881-.918-1.724-1.662z"
				/>
			</g>
		</SVGIcon>
	);
}
