import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoaded as isServiceLoaded, load as loadService } from '../../redux/modules/weather';
import { bindActionCreators } from 'redux';
import DocumentMeta from 'react-document-meta';
import Weather from '../../br3-components/components/Weather/Weather';

class WeatherViewConnector extends Component {
	static fetchData(getState, dispatch) {
		if (!isServiceLoaded(getState())) {
			return dispatch(loadService());
		}
	}

	checkLocalZipcode = () => {
		let zipcode = null;
		if (window && window.localStorage) {
			zipcode = window.localStorage.getItem('location:zipcode');
			if (zipcode) {
				this.props.loadService(parseInt(zipcode, 10));
			}
		}
	};

	updateWeatherByZipcode = zipcode => {
		if (window && window.localStorage) {
			window.localStorage.setItem('location:zipcode', zipcode);
		}
		this.props.loadService(zipcode);
	};

	componentDidMount() {
		this.checkLocalZipcode();
	}

	render() {
		let view = this.props.location.pathname.endsWith('/wetter/android') && 'android';
		let atiDoctype = 'Wetter';
		let metaSection = 'Bayern-Wetter';
		let metaTitle = 'Bayern 3 - Wetter';
		let metaDescription = 'Die Wetteraussichten in Bayern für die nächste Woche.';
		let meta = {
			title: metaTitle,
			description: metaDescription,
			meta: {
				property: {
					'og:title': metaTitle,
					'og:description': metaDescription,
					'twitter:site': '@bayern3',
					'twitter:creator': '@bayern3',
					'twitter:title': metaTitle,
					'twitter:description': metaDescription,
					'ati:section': metaSection,
					'ati:doctype': atiDoctype,
				},
			},
		};

		return (
			<div>
				<Weather data={this.props.data} updateWeatherByZipcode={this.updateWeatherByZipcode} loading={this.props.loading} view={view} />
				<DocumentMeta {...meta} extend />
			</div>
		);
	}
}

/**
 * Component to load data for the weather page.
 */
export default connect(
	state => ({
		data: state.weather.data,
		error: state.weather.error,
		loading: state.weather.loading,
	}),
	dispatch =>
		bindActionCreators(
			{
				loadService,
			},
			dispatch
		)
)(WeatherViewConnector);
