import SVGIcon from '../SVGIcon';
import React from 'react';

export default function StarkBewoelktIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#F59E18">
				<path d="M14.331 16c0 2.193-1.788 3.981-3.981 3.981S6.368 18.193 6.368 16s1.788-3.981 3.981-3.981 3.982 1.788 3.982 3.981zm-3.234-5.084h-1.47V5.649h1.47zm-1.47 10.168h1.47v5.267h-1.47zm-1.176-9.861l-1.274.735-2.634-4.557 1.274-.735zm3.809 9.542l1.274-.735 2.634 4.557-1.274.735zm-5.941-7.962l-.735 1.274-4.557-2.634.735-1.274zm8.073 6.357l.735-1.273 4.557 2.633-.735 1.274zM5.266 15.24v1.47H-.002v-1.47zm10.167 1.47v-1.47H20.7v1.47zM5.572 17.887l.735 1.273-4.557 2.634-.735-1.274zm9.543-3.81l-.735-1.274 4.557-2.634.735 1.274zM7.152 20.018l1.274.735-2.634 4.557-1.274-.735zm6.358-8.073l-1.274-.735 2.634-4.557 1.274.735z" />
			</g>
			<path
				fill="#C9C8C8"
				d="M2.81 20.692a2.338 2.338 0 0 1-2.338-2.338 2.327 2.327 0 0 1 2.338-2.326c.184 0 .355.024.539.061l.343.086.098-.343c.453-1.677 1.983-2.852 3.721-2.852s3.268 1.175 3.721 2.852l.098.343.343-.086c.171-.037.355-.061.539-.061a2.335 2.335 0 0 1 2.338 2.326 2.338 2.338 0 0 1-2.338 2.338H2.81z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.51 13.348a3.486 3.486 0 0 1 3.366 2.583l.184.686.698-.159c.147-.037.306-.049.453-.049 1.089 0 1.971.881 1.971 1.958a1.97 1.97 0 0 1-1.971 1.971h-9.4A1.97 1.97 0 0 1 .84 18.367c0-1.077.881-1.958 1.971-1.958.147 0 .306.012.453.049l.698.159.184-.686a3.482 3.482 0 0 1 3.364-2.583m0-.734a4.23 4.23 0 0 0-4.076 3.121 2.704 2.704 0 0 0-3.329 2.62A2.706 2.706 0 0 0 2.81 21.06h9.4a2.706 2.706 0 0 0 2.705-2.705 2.696 2.696 0 0 0-2.705-2.693c-.22 0-.428.024-.624.073a4.23 4.23 0 0 0-4.076-3.121z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.633 16.947a.325.325 0 0 1-.117-.023l-1.187-.49a.306.306 0 1 1 .234-.566l1.187.49a.306.306 0 0 1-.117.589z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.643 18.165c-1.101 0-2.006-.905-2.006-2.006s.905-2.006 2.006-2.006c.159 0 .306.012.465.049l.294.073.073-.281c.391-1.443 1.7-2.446 3.192-2.446s2.813 1.003 3.192 2.446l.073.281.294-.073c.147-.037.306-.049.465-.049 1.101 0 2.006.905 2.006 2.006s-.905 2.006-2.006 2.006h-8.048z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.667 11.842c1.358 0 2.544.917 2.898 2.226l.159.575.575-.134c.134-.024.257-.049.391-.049.942 0 1.7.758 1.7 1.7s-.758 1.7-1.7 1.7h-8.047c-.942 0-1.7-.758-1.7-1.7a1.696 1.696 0 0 1 2.091-1.651l.575.134.159-.575a3.01 3.01 0 0 1 2.899-2.226m0-.611a3.6 3.6 0 0 0-3.486 2.678 2.317 2.317 0 0 0-2.849 2.25 2.317 2.317 0 0 0 2.311 2.311h8.047a2.317 2.317 0 0 0 2.311-2.311 2.317 2.317 0 0 0-2.849-2.25 3.598 3.598 0 0 0-3.485-2.678z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.355 14.997a.292.292 0 0 1-.115-.023l-1.198-.489a.307.307 0 0 1-.168-.399.307.307 0 0 1 .399-.168l1.198.489a.307.307 0 0 1-.116.59z"
			/>
			<g>
				<path
					fill="#FFF"
					d="M9.255 24.165a3.487 3.487 0 0 1-3.482-3.482 3.487 3.487 0 0 1 3.482-3.482c.27 0 .539.037.809.098l.466.11.123-.454a5.721 5.721 0 1 1 11.058-.024l.123.454.466-.11c.27-.061.539-.098.809-.098a3.487 3.487 0 0 1 3.482 3.482 3.487 3.487 0 0 1-3.482 3.482H9.255v.024z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.181 13.18a5.25 5.25 0 0 1 5.063 3.874l.245.919.919-.221c.233-.049.454-.086.687-.086a2.995 2.995 0 0 1 2.991 2.991 2.995 2.995 0 0 1-2.991 2.991H9.242a2.995 2.995 0 0 1-2.991-2.991 2.995 2.995 0 0 1 2.991-2.991c.233 0 .466.025.687.086l.919.221.245-.919a5.301 5.301 0 0 1 5.088-3.874m0-.981a6.215 6.215 0 0 0-6.007 4.597 3.972 3.972 0 1 0-.919 7.834h13.854a3.972 3.972 0 1 0-.919-7.834 6.218 6.218 0 0 0-6.009-4.597z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.903 18.501a.417.417 0 0 1-.171-.036l-1.582-.687a.43.43 0 0 1 .341-.788l1.582.687a.43.43 0 0 1-.17.824z"
				/>
			</g>
		</SVGIcon>
	);
}
