import React from 'react';
import classNames from 'classnames';
import update from 'immutability-helper';
import styles from './ContentElementHtmlText.scss';

/**
 * Component to display plain html content in the webapp and to edit the content in the cms.
 */
export default class ContentElementHtmlText extends React.Component {
	static defaultProps = {
		data: {
			content: {
				text: '',
			},
			element_type: 'text',
			element_subtype: 'html',
		},
	};

	onChange = text => {
		if (this.props.onChange) {
			this.props.onChange(
				update(this.props.data, {
					content: {
						text: {
							$set: text,
						},
					},
				})
			);
		}
	};

	render() {
		let content = null;
		if (this.props.onChange) {
			if (IS_CMS) {
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;

				content = (
					<div className={classNames(styles.text)}>
						<ReactMediumEditor
							text={this.props.data.content.text}
							onChange={this.onChange}
							options={{
								toolbar: { buttons: ['bold', 'italic', 'anchor', 'h3', 'h4', 'quote', 'unorderedlist'] },
								placeholder: { text: 'Text eingeben...' },
								anchor: { targetCheckbox: true },
							}}
						/>
					</div>
				);
			}
		} else {
			content = <div className={classNames(styles.text)} dangerouslySetInnerHTML={{ __html: this.props.data.content.text }} />;
		}

		return content;
	}
}
