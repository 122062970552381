import { createStore, applyMiddleware, compose } from 'redux';
import createApiMiddleware from './middleware/apiMiddleware';
import trackerMiddleware from './middleware/trackerMiddleware';
import reducer from './modules/reducer';
import { routerMiddleware } from 'connected-react-router';

export default function create(client, routerHistory, data) {
	const store = compose(
		applyMiddleware(...[trackerMiddleware, routerMiddleware(routerHistory), createApiMiddleware(client)]),
		process.env.BUILD_TARGET === 'client' && window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : compose
	)(createStore)(reducer(routerHistory), data);

	store.client = client;

	return store;
}
