import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeschauerIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zm9.986-1.27l-.757-1.286 4.609-2.709.757 1.285zm-1.633-2.14l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<path
				fill="#0971B8"
				d="M4.947 21.895l-.183-.733-1.28.367.276-1.279-.641-.184-.365 1.372-1.006-1.004-.457.546 1.006.915-1.371.365.273.73 1.281-.365-.365 1.279.729.184.275-1.279 1.006.914.549-.547-1.006-.916zM9.607 26.555l-.181-.733-1.28.368.274-1.28-.639-.183-.367 1.373-1.004-1.006-.457.547 1.006.914-1.371.367.273.73 1.28-.365-.366 1.278.731.185.275-1.281 1.004.916.549-.549-1.006-.914zM16.006 24.545l-.184-.73-1.279.455.275-1.37-.732-.183-.274 1.371-1.005-1.006-.457.549.914.914-1.28.365.184.731 1.369-.366-.363 1.28.73.183.274-1.279 1.006.914.457-.551-.914-.912z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.762 18.409a2.37 2.37 0 0 1-2.37-2.37 2.36 2.36 0 0 1 2.37-2.358c.186 0 .36.025.546.062l.347.087.099-.347c.459-1.7 2.01-2.892 3.773-2.892s3.313 1.191 3.773 2.892l.099.347.347-.087a2.367 2.367 0 0 1 2.916 2.296 2.37 2.37 0 0 1-2.37 2.37h-9.53z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.527 10.963a3.535 3.535 0 0 1 3.413 2.618l.186.695.707-.161c.149-.037.31-.05.459-.05 1.104 0 1.998.894 1.998 1.986a1.997 1.997 0 0 1-1.998 1.998h-9.53a1.997 1.997 0 0 1-1.998-1.998c0-1.092.894-1.986 1.998-1.986.149 0 .31.012.459.05l.707.161.186-.695a3.535 3.535 0 0 1 3.413-2.618m0-.744a4.29 4.29 0 0 0-4.133 3.165A2.741 2.741 0 0 0 .018 16.04a2.744 2.744 0 0 0 2.743 2.743h9.531a2.744 2.744 0 0 0 2.743-2.743 2.733 2.733 0 0 0-2.743-2.73 2.64 2.64 0 0 0-.633.074 4.287 4.287 0 0 0-4.132-3.165z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.611 14.612a.307.307 0 0 1-.118-.024l-1.204-.496a.311.311 0 0 1 .236-.574l1.204.496a.311.311 0 0 1-.118.598z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.485 15.888c-1.116 0-2.034-.918-2.034-2.034s.918-2.034 2.034-2.034c.161 0 .31.012.471.05l.298.074.074-.285c.397-1.463 1.724-2.48 3.236-2.48s2.852 1.017 3.236 2.48l.074.285.298-.074c.149-.037.31-.05.471-.05 1.116 0 2.034.918 2.034 2.034s-.918 2.034-2.034 2.034h-8.158z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.564 9.477c1.376 0 2.579.93 2.939 2.257l.161.583.583-.136c.136-.025.26-.05.397-.05.955 0 1.724.769 1.724 1.724s-.769 1.724-1.724 1.724h-8.159c-.955 0-1.724-.769-1.724-1.724s.769-1.724 1.724-1.724c.136 0 .273.012.397.05l.583.136.161-.583a3.05 3.05 0 0 1 2.938-2.257m0-.62a3.649 3.649 0 0 0-3.534 2.716 2.35 2.35 0 0 0-2.89 2.282 2.35 2.35 0 0 0 2.344 2.344h8.159a2.35 2.35 0 0 0 2.344-2.344 2.35 2.35 0 0 0-2.89-2.282 3.647 3.647 0 0 0-3.533-2.716z"
			/>
			<path fill="#4F4E4E" d="M23.221 12.676a.32.32 0 0 1-.117-.023l-1.215-.496a.31.31 0 1 1 .234-.574l1.215.496a.31.31 0 0 1-.117.597z" />
			<g>
				<path
					fill="#FFF"
					d="M9.118 21.898a3.535 3.535 0 0 1-3.53-3.53 3.535 3.535 0 0 1 3.53-3.53c.273 0 .547.037.82.099l.472.112.124-.46a5.8 5.8 0 1 1 11.212-.025l.124.46.472-.112c.273-.062.547-.099.82-.099a3.535 3.535 0 0 1 3.53 3.53 3.535 3.535 0 0 1-3.53 3.53H9.118v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.141 10.761a5.324 5.324 0 0 1 5.134 3.928l.249.932.932-.224c.236-.05.46-.087.696-.087a3.037 3.037 0 0 1 3.033 3.033 3.037 3.037 0 0 1-3.033 3.033H9.105a3.037 3.037 0 0 1-3.033-3.033 3.037 3.037 0 0 1 3.033-3.033c.236 0 .472.025.696.087l.932.224.249-.932a5.375 5.375 0 0 1 5.159-3.928m0-.995a6.302 6.302 0 0 0-6.091 4.661 4.026 4.026 0 0 0-4.959 3.915 4.026 4.026 0 0 0 4.027 4.027h14.046a4.026 4.026 0 0 0 4.027-4.027 4.026 4.026 0 0 0-4.959-3.915 6.304 6.304 0 0 0-6.091-4.661z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.802 16.155a.44.44 0 0 1-.173-.036l-1.603-.696a.435.435 0 0 1 .346-.798l1.604.696a.436.436 0 0 1-.174.834z"
				/>
			</g>
		</SVGIcon>
	);
}
