import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NewsVideoIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#3D3D3C">
				<path d="M16 .799C24.381.799 31.201 7.618 31.201 16c0 8.382-6.819 15.201-15.201 15.201C7.618 31.201.799 24.382.799 16 .799 7.619 7.618.799 16 .799M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0z" />
				<path d="M23.776 11.657a.4.4 0 0 0-.424.052l-2.918 2.387v-2.078a.4.4 0 0 0-.4-.4H8.405a.4.4 0 0 0-.4.4v8.001a.4.4 0 0 0 .4.4h11.629a.4.4 0 0 0 .4-.4v-2.078l2.918 2.387a.397.397 0 0 0 .424.053.4.4 0 0 0 .229-.361v-8.001a.404.404 0 0 0-.229-.362zm-4.142 7.963H8.805v-7.202h10.83v7.202zm3.571-.443l-2.621-2.145v-2.025l2.621-2.144v6.314z" />
			</g>
		</SVGIcon>
	);
}
