import ContentElementHtmlTextAMP from '../components/ContentElementHtmlText/ContentElementHtmlTextAMP';
import ContentElementMediaPlayerAMP from '../components/ContentElementMediaPlayer/ContentElementMediaPlayerAMP';
import ContentElementImageAMP from '../components/ContentElementImage/ContentElementImageAMP';
import ContentElementEmbedAMP from '../components/ContentElementEmbed/ContentElementEmbedAMP';

export default {
	text: ContentElementHtmlTextAMP,
	media: ContentElementMediaPlayerAMP,
	gallery: ContentElementImageAMP,
	embed: ContentElementEmbedAMP,
};
