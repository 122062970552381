export default {
	draft: 'Entwurf',
	review: 'Abgenommen',
	published: 'Veröffentlicht',
	depublished: 'Archiviert',
	banner: {
		draft: 'inaktiv',
		published: 'aktiv',
	},
	enterLocation: 'Ort, PLZ, oder Region eingeben',
	locationNotFound: 'Ort konnte nicht gefunden werden - kontrolliere bitte deine Eingabe',
};
