import { combineReducers } from 'redux';

import article from './article';
import dashboard from './dashboard';
import teaserBoard from './teaserBoard';
import navigation from './navigation';
import traffic from './traffic';
import weather from './weather';
import privacyPolicy from './privacyPolicy';
import playlist from './playlist';
import news from './news';
import transition from './transition';
import { connectRouter } from 'connected-react-router';

export default history =>
	combineReducers({
		router: connectRouter(history),
		article,
		dashboard,
		teaserBoard,
		navigation,
		traffic,
		weather,
		privacyPolicy,
		playlist,
		news,
		transition,
	});
