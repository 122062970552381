import TagManager, { TagManagerArgs } from 'react-gtm-module';
import numeral from 'numeral';
import { shouldTrack, parseLocation, getOS, convertIdStringToNumber } from './utils';
import * as config from '../../../config.webapp';

const PAGE_TRACKING_EVENTS = {
	ContentElementImage: [],
	ContentElementMediaPlayer: ['firstPlay'],
	Navigation: ['open'],
	PlayerHeaderDropdown: ['open'],
	RadioPlayer: ['firstPlay'],
	Router: [],
};
const RICH_MEDIA_TRACKING_EVENTS = {
	ContentElementMediaPlayer: ['firstPlay', 'play', 'pause', 'move', 'buffering', 'bufferingend', 'stop'],
	Router: [],
};

type DataLayerContent = {
	event?: string;
};
type GoogleTagManagerArgs = TagManagerArgs & { gtmSrc: string };
/**
 * Tracker for Google Tag Manager.
 */
class GoogleTagManager {
	lastLocation?: string;
	loading = false;

	/**
	 * Constructs the tracker.
	 * @param gtmId id for Google Tag Manager
	 */
	constructor(private gtmId: string, private gtmSrc: string) {
		config.isClient && this.init();
	}

	/**
	 * Inits the datalayer and loads GTM.
	 */
	private init = () => {
		TagManager.initialize({ gtmId: this.gtmId, gtmSrc: this.gtmSrc } as GoogleTagManagerArgs);
	};

	private updateDataLayer = (dataLayer: Partial<DataLayerContent>) => {
		TagManager.dataLayer({ dataLayer });
	};

	trackEvent(component: string, event: string, id: string, info: any) {
		if (shouldTrack(PAGE_TRACKING_EVENTS, component, event)) {
			this.doPageTracking(component, event, info);
		}
		if (shouldTrack(RICH_MEDIA_TRACKING_EVENTS, component, event)) {
			this.doRichMediaTracking(component, event, info);
		}

		if (component === 'Router') {
			this.lastLocation = window.location.href;
		}
	}

	/**
	 * Performs the page tracking.
	 * @param {string} component
	 * @param {string} event
	 * @param {string} id
	 * @param {object} info
	 */
	doPageTracking(component: string, event: string, info: any) {
		const pageVars = this.buildPageVariables(component);
		const customVars = this.buildCustomVariables(component, info);

		this.updateDataLayer({ event, ...pageVars, ...customVars });
	}

	buildPageVariables(component: string) {
		const result = this.buildDefaultPageVariables();
		switch (component) {
			case 'Navigation':
				result['name'] = 'bayern3-navigation';
				break;
			case 'PlayerHeaderDropdown':
				result['name'] = 'bayern3-playliste';
				break;
		}

		return result;
	}

	buildDefaultPageVariables() {
		const location = parseLocation();
		const sectionElement = document.querySelector('meta[property="ati:section"]');
		const section = sectionElement ? sectionElement.getAttribute('content') : null;

		const result: any = {
			level2: 3,
			chapter1: encodeURIComponent(section ? section.replace('Bayern3 - ', '') : 'ohne Bereich'),
		};
		if (location) {
			result.name = encodeURIComponent(location);
		}

		return result;
	}

	buildCustomVariables(component: string, info: any) {
		const result = this.buildDefaultCustomVariables();
		switch (component) {
			case 'RadioPlayer':
				result['6'] = encodeURIComponent('[Audio-Livestream-Start]');
				result['7'] = encodeURIComponent('[bayern3-audio-livestream-100]');
				result['8'] = encodeURIComponent('[Livestream - Bayern3]');
				delete result['9'];
				delete result['10'];
				delete result['12'];
				delete result['15'];
				break;
			case 'Navigation':
				result['6'] = encodeURIComponent('[Navigation]');
				result['7'] = encodeURIComponent('[bayern3-bereichsnavigation-100]');
				result['8'] = encodeURIComponent('[Navigationsseite]');
				delete result['9'];
				delete result['10'];
				delete result['12'];
				delete result['15'];
				break;
			case 'PlayerHeaderDropdown':
				result['6'] = encodeURIComponent('[Playlist]');
				result['7'] = encodeURIComponent('[bayern3-playlist]');
				result['8'] = encodeURIComponent('[Playlist]');
				delete result['9'];
				delete result['10'];
				delete result['12'];
				delete result['15'];
				break;
			case 'ContentElementImage':
				result['6'] = encodeURIComponent('[Bildergalerie]');
				result['8'] = encodeURIComponent(`[${info.title ? info.title : 'Bildergalerie'}]`);
				result['12'] = encodeURIComponent(`[Galerie: ${info.id ? info.id : 'unbekannt'}]`);
				break;
			case 'ContentElementMediaPlayer':
				if (info.subtype.indexOf('video') !== -1) {
					result['6'] = encodeURIComponent('[Video-Start]');
				}
				if (info.subtype.indexOf('audio') !== -1) {
					result['6'] = encodeURIComponent('[Audio-Start]');
				}
				if (info.title) {
					result['8'] = encodeURIComponent(`[${info.title}]`);
				} else {
					delete result['8'];
				}
				if (info.subtype.indexOf('youtube') !== -1) {
					result['12'] = encodeURIComponent(`[Youtube: ${info.id ? info.id : 'unbekannt'}]`);
				} else {
					result['12'] = encodeURIComponent(`[BR-AV: ${info.id ? info.id : 'unbekannt'}]`);
				}
				break;
		}
		return result;
	}

	buildDefaultCustomVariables() {
		const doctypeElement = document.querySelector('meta[property="ati:doctype"]');
		const doctype = doctypeElement ? escape(doctypeElement.getAttribute('content') || '') : '';

		const tagsElement = document.querySelector('meta[property="ati:tags"]');
		const tags = tagsElement ? tagsElement.getAttribute('content') : null;

		const publishedElement = document.querySelector('meta[property="ati:published"]');
		const published = publishedElement ? new Date(publishedElement.getAttribute('content') || '') : null;

		const headlineElement = document.querySelector('meta[property="ati:headline"]');
		const headline = headlineElement ? headlineElement.getAttribute('content') : null;

		const previousLocation = this.getPreviousLocation();
		const location = parseLocation();
		const title = this.getTitle();
		const os = getOS();

		const result = {
			'1': encodeURIComponent('[Radio]'),
			'2': encodeURIComponent('[Bayern 3]'),
			'6': encodeURIComponent(`[${doctype}]`),
			'7': encodeURIComponent(`[${location}]`),
			'14': encodeURIComponent('[Web]'),
			'16': encodeURIComponent(os ? `[${os}]` : '[unbekannt]'),
		};
		if (title) {
			result['8'] = encodeURIComponent(`[${title}]`);
		}
		if (tags) {
			result['9'] = encodeURIComponent(`[|${tags}|]`);
		}
		if (published) {
			result['10'] = `${numeral(published.getFullYear()).format('0000')}${numeral(published.getMonth() + 1).format('00')}${numeral(
				published.getDate()
			).format('00')}`;
		}
		if (document.URL) {
			result['11'] = encodeURIComponent(`[${document.URL}]`);
		}
		result['12'] = result['7'];
		if (previousLocation) {
			result['13'] = encodeURIComponent(`[${previousLocation}]`);
		}
		if (headline) {
			result['15'] = encodeURIComponent(`[${headline}]`);
		}

		return result;
	}

	/**
	 * Performs the rich media tracking.
	 * @param {string} component
	 * @param {string} event
	 * @param {object} info
	 */
	doRichMediaTracking(component: string, event: string, info: any) {
		if (component !== 'Router') {
			let mediaType = null;
			if (info.subtype.indexOf('video') !== -1) {
				mediaType = 'video';
			}
			if (info.subtype.indexOf('audio') !== -1) {
				mediaType = 'audio';
			}

			const location = parseLocation();
			let mediaEvent = event;
			let isBuffering = false;
			switch (event) {
				case 'firstPlay':
					mediaEvent = 'play';
					break;
				case 'buffering':
					mediaEvent = 'info';
					isBuffering = true;
					break;
				case 'bufferingend':
					mediaEvent = 'info';
					isBuffering = false;
			}

			const mediaInfo: any = {
				mediaType: mediaType,
				playerId: convertIdStringToNumber(info.id),
				mediaLevel2: 3,
				mediaLabel: encodeURIComponent(location),
				mediaTheme1: encodeURIComponent('Bayern 3'),
				refreshDuration: { '0': 5, '1': 12, '12': 15, '18': 30 },
				duration: info.duration,
				broadcastMode: encodeURIComponent('clip'),
				isEmbedded: info.subtype.indexOf('youtube') !== -1,
			};
			if (mediaInfo.isEmbedded) {
				mediaInfo.webdomain = encodeURIComponent(info.source);
			}
			const actionInfo = { event, action: mediaEvent, ...mediaInfo };
			if (mediaEvent === 'info') {
				actionInfo.isBuffering = isBuffering;
			}

			this.updateDataLayer(actionInfo);
		}
	}

	/**
	 * Returns the dedicated ati title or if not available the document.title.
	 */
	getTitle() {
		const titleElement = document.querySelector('meta[property="ati:title"]');
		const title = titleElement ? titleElement.getAttribute('content') : null;
		return title ? title : document.title;
	}

	/**
	 * Returns the previous location if accessible.
	 */
	getPreviousLocation() {
		return this.lastLocation ? this.lastLocation : window.document.referrer !== window.location.href ? window.document.referrer : null;
	}
}

export default GoogleTagManager;
