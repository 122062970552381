import { LOCATION_CHANGE } from 'connected-react-router';
import trackEvent from '../../br3-components/commons/tracking/trackers';
import idx from 'idx';

export default () => next => action => {
	if (action.type === LOCATION_CHANGE && typeof idx(action, _ => _.payload.location.pathname) !== 'undefined') {
		setTimeout(() => {
			trackEvent('Router', 'location', action.payload.location.pathname);
			// TODO: this timeout allows react to render the meta tags required by tracking first, this is obviously not a nice design
		}, 2000);
	}
	return next(action);
};
