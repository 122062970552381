import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NextTransparentIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle opacity=".8" fill="#FFF" cx="16" cy="16" r="16" />
			<path
				fill="#3C3C3B"
				d="M13.141 22.271a.547.547 0 0 1-.389-.937L18.086 16l-5.334-5.334a.544.544 0 0 1 0-.775.545.545 0 0 1 .774 0l5.723 5.723a.546.546 0 0 1 0 .775l-5.723 5.722a.54.54 0 0 1-.385.16z"
			/>
		</SVGIcon>
	);
}
