import React from 'react';
import classNames from 'classnames';
import HTML5Player from './HTML5Player';
import styles from './HTML5Player.scss';

export default class HTML5AudioPlayer extends HTML5Player {
	render = () => {
		return (
			<audio className={classNames(styles.html5audioplayer)} ref={this.playerRef} autoPlay={true}>
				<source src={this.props.sources.standard} type="audio/mp3" />;
			</audio>
		);
	};
}
