import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterTrockenSonnigNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M12.787 13.065a6.968 6.968 0 0 1-5.777 3.049c-3.869 0-6.999-3.13-6.999-6.999s3.13-6.999 6.999-6.999c.403 0 .806.04 1.196.107a6.056 6.056 0 0 0-2.418 4.85 6.075 6.075 0 0 0 6.072 6.072c.323-.013.632-.04.927-.08zm3.95-10.317l-.04 2.525 2.458-.591-1.639 1.908 2.257 1.128-2.499.403 1.008 2.311-2.163-1.29-.712 2.418-.833-2.378-2.109 1.398.9-2.365-2.512-.282 2.203-1.236-1.733-1.827 2.472.47-.161-2.512 1.598 1.948z"
			/>
			<path
				fill="#FFF"
				d="M20.627 15.607c-1.207 0-2.2-.993-2.2-2.2s.993-2.2 2.2-2.2c.174 0 .335.013.51.054l.322.08.08-.308c.429-1.583 1.865-2.683 3.501-2.683s3.085 1.1 3.501 2.683l.08.308.322-.08c.161-.04.335-.054.51-.054 1.207 0 2.2.993 2.2 2.2s-.993 2.2-2.2 2.2h-8.826z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.04 8.673a3.3 3.3 0 0 1 3.179 2.441l.174.63.63-.147c.147-.027.282-.054.429-.054 1.033 0 1.865.832 1.865 1.865s-.832 1.864-1.865 1.864h-8.826c-1.033 0-1.865-.832-1.865-1.864s.832-1.865 1.865-1.865c.148 0 .295.013.429.054l.63.147.174-.63a3.305 3.305 0 0 1 3.181-2.441m0-.671a3.947 3.947 0 0 0-3.823 2.938 2.541 2.541 0 0 0-3.125 2.468 2.541 2.541 0 0 0 2.535 2.535h8.826a2.541 2.541 0 0 0 2.535-2.535 2.541 2.541 0 0 0-3.125-2.468 3.947 3.947 0 0 0-3.823-2.938z"
			/>
			<path
				fill="#4F4E4E"
				d="M22.505 12.133a.338.338 0 0 1-.127-.025l-1.315-.537a.336.336 0 0 1 .253-.621l1.315.537a.336.336 0 0 1-.126.646z"
			/>
			<path
				fill="#C9C8C8"
				d="M7.168 22.071a3.824 3.824 0 0 1-3.818-3.818 3.824 3.824 0 0 1 3.818-3.818c.296 0 .592.04.887.108l.511.121.134-.498c.726-2.769 3.227-4.678 6.063-4.678s5.337 1.909 6.063 4.651l.134.497.511-.121c.296-.067.592-.108.887-.108a3.824 3.824 0 0 1 3.818 3.818 3.824 3.824 0 0 1-3.818 3.818H7.168v.028z"
			/>
			<path
				fill="#4F4E4E"
				d="M14.763 10.026a5.756 5.756 0 0 1 5.552 4.248l.269 1.008 1.008-.242c.255-.054.497-.094.753-.094a3.284 3.284 0 0 1 3.28 3.28 3.284 3.284 0 0 1-3.28 3.28H7.154a3.284 3.284 0 0 1-3.28-3.28 3.284 3.284 0 0 1 3.28-3.28c.255 0 .511.027.753.094l1.008.242.269-1.008a5.812 5.812 0 0 1 5.579-4.248m0-1.076a6.814 6.814 0 0 0-6.587 5.041 4.355 4.355 0 0 0-5.364 4.235 4.355 4.355 0 0 0 4.356 4.356h15.191a4.355 4.355 0 0 0 4.356-4.356 4.355 4.355 0 0 0-5.364-4.235 6.817 6.817 0 0 0-6.588-5.041z"
			/>
			<path fill="#4F4E4E" d="M10.071 15.86a.479.479 0 0 1-.187-.039l-1.734-.753a.47.47 0 0 1 .375-.863l1.734.753a.47.47 0 0 1-.188.902z" />
			<path
				fill="#C9C8C8"
				d="M18.908 19.889c-.101-.099-.2-.099-.3-.099l-4.196 1.099 2.896-5.497v-.3c0-.1-.098-.2-.198-.2l-2.301-.699c-.198-.099-.299 0-.397.198l-2.398 6.497-.702 1.899-.199.499c0 .1 0 .2.1.3.099.1.199.1.3.1l3.698-1h.099l-2.598 6.498v.299c0 .1.1.1.199.198l.6.201h.099c.1 0 .2-.099.3-.201l3.698-6.995 1-1.897.401-.7c-.001.001-.001-.098-.101-.2z"
			/>
			<path
				fill="#A03416"
				d="M18.908 19.889c-.101-.099-.2-.099-.3-.099l-4.196 1.099 2.896-5.497v-.3c0-.1-.098-.2-.198-.2l-2.301-.699c-.198-.099-.299 0-.397.198l-2.398 6.497-.702 1.899-.199.499c0 .1 0 .2.1.3.099.1.199.1.3.1l3.698-1h.099l-2.598 6.498v.299c0 .1.1.1.199.198l.6.201h.099c.1 0 .2-.099.3-.201l3.698-6.995 1-1.897.401-.7c-.001.001-.001-.098-.101-.2z"
			/>
		</SVGIcon>
	);
}
