import SVGIcon from '../SVGIcon';
import React from 'react';

export default function ShareWhiteIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="16.001" cy="15.999" r="16.001" />
			<path
				fill="#3C3C3B"
				d="M20.479 20.474a2.23 2.23 0 0 0-1.723.824l-5.255-4.271c.16-.309.26-.654.26-1.028s-.1-.72-.26-1.029l5.255-4.269c.412.5 1.024.823 1.723.823a2.237 2.237 0 1 0-2.237-2.236c0 .211.037.41.092.604l-5.398 4.385a2.219 2.219 0 0 0-1.412-.515 2.238 2.238 0 0 0 0 4.476 2.21 2.21 0 0 0 1.412-.517l5.398 4.386a2.202 2.202 0 0 0-.092.604 2.237 2.237 0 1 0 4.473 0 2.237 2.237 0 0 0-2.236-2.237zm0-12.531c.74 0 1.343.602 1.343 1.344a1.345 1.345 0 0 1-2.689 0 1.347 1.347 0 0 1 1.346-1.344zm0 16.113a1.347 1.347 0 0 1-1.346-1.344c0-.74.605-1.344 1.346-1.344a1.345 1.345 0 0 1 0 2.688z"
			/>
		</SVGIcon>
	);
}
