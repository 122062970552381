/**
 * Loads javascript from an url.
 * @param {string} name script name
 * @param {string} url url to the script
 */
export function loadScript(name, url) {
	return new Promise((resolve, reject) => {
		if (document.querySelector(`#${name}`)) {
			resolve();
		} else {
			const element = document.createElement('script');
			element.setAttribute('type', 'text/javascript');
			element.setAttribute('id', name);
			element.setAttribute('src', url);
			element.onload = resolve;
			element.onerror = reject;
			document.body.appendChild(element);
		}
	});
}

/**
 * Checks if possibleEvents contains the event for the given component.
 * @param {string} component
 * @param {string} event
 * @param {object} possibleEvents
 */
export function shouldTrack(possibleEvents, component, event) {
	for (const c in possibleEvents) {
		if (c === component) {
			return !possibleEvents[c] || possibleEvents[c].length == 0 || possibleEvents[c].indexOf(event) !== -1;
		}
	}
	return false;
}

/**
 * Parses the current url and gives back the page name.
 */
export function parseLocation() {
	if (document.location.pathname === '/') {
		return 'startseite_bayern3';
	} else {
		let location = document.location.pathname;
		location = location.replace('/r/', '');
		location = location.replace('/', '');
		location = location.replace('&', '-');
		return location.toLowerCase();
	}
}

/**
 * Determines which os was used to open the website.
 */
export function getOS() {
	let os = null;
	if (typeof window !== 'undefined') {
		const userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'];

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'MacOS';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'iOS';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'Windows';
		} else if (/Android/.test(userAgent)) {
			os = 'Android';
		} else if (!os && /Linux/.test(platform)) {
			os = 'Linux';
		}
	}

	return os;
}

/**
 * Sums up all the characters of one string to a number.
 * @param {string} sId ID as string
 */
export function convertIdStringToNumber(sId) {
	let result = 0;
	if (sId) {
		for (let i = 0; i < sId.length; i++) {
			result += sId.charCodeAt(i);
		}
	}
	return result;
}
