import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WolkenlosNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M20.631 17.516a11.147 11.147 0 0 1-9.241 4.878C5.201 22.394.194 17.387.194 11.198S5.201.002 11.39.002c.645 0 1.289.064 1.913.172a9.687 9.687 0 0 0-3.868 7.758c0 5.351 4.341 9.714 9.714 9.714a15.866 15.866 0 0 0 1.482-.13zm6.318-16.504l-.064 4.04 3.932-.945-2.621 3.051 3.61 1.805-3.997.645 1.612 3.696-3.46-2.063-1.139 3.868-1.333-3.803-3.374 2.235 1.44-3.783-4.018-.451 3.524-1.977-2.772-2.923 3.954.753-.258-4.019 2.557 3.116zm-6.011 23.554v2.212l2.054-.632-1.424 1.739 1.897.949-2.055.315.79 1.897-1.738-1.106-.632 2.054-.633-2.054-1.896 1.264.791-2.055-2.055-.157 1.897-1.107-1.566-1.58 2.072.473v-2.053l1.248 1.58z"
			/>
		</SVGIcon>
	);
}
