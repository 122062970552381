import React from 'react';
import { DropTarget, DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import TeaserBoardTile from './TeaserBoardTile.js';

const tileTarget = {
	drop(props, monitor) {
		props.onChange(monitor.getItem());
	},

	hover() {},
};

function collectTarget(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
	};
}

const tileSource = {
	beginDrag(props) {
		return props.teaser;
	},
};

function collectSource(connect, monitor) {
	return {
		connectDragSource: connect.dragSource(),
		connectDragPreview: connect.dragPreview(),
		isDragging: monitor.isDragging(),
	};
}

class TeaserBoardDropTargetTile extends React.Component {
	componentDidMount() {
		this.props.connectDragPreview(getEmptyImage(), {
			captureDraggingState: true,
		});
	}

	render() {
		let { teaser } = this.props;
		let tileProperties = {
			title: teaser && teaser.attributes.content.title,
			headline: teaser && teaser.attributes.content.headline,
			image: teaser && teaser.attributes.content.teaser_image.content,
			link: teaser && '/' + teaser.id,
		};
		return this.props.connectDropTarget(
			this.props.connectDragSource(
				<div className={this.props.className}>
					<TeaserBoardTile {...tileProperties} dropping={this.props.isOver} />
				</div>
			)
		);
	}
}

export default DropTarget('article', tileTarget, collectTarget)(
	DragSource('article', tileSource, collectSource)(TeaserBoardDropTargetTile)
);
