const START_NAVIGATING = 'br3/router/START_NAVIGATING';
const STOP_NAVIGATING = 'br3/router/STOP_NAVIGATING';

const initialState = {
	navigating: false,
};

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case START_NAVIGATING:
			return {
				...state,
				navigating: true,
			};

		case STOP_NAVIGATING:
			return {
				...state,
				navigating: false,
			};
		default:
			return state;
	}
}

export function startTransition() {
	return {
		type: START_NAVIGATING,
	};
}

export function stopTransition() {
	return {
		type: STOP_NAVIGATING,
	};
}
