import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Traffic.scss';
import moment from 'moment';
import ContentElementImage from '../ContentElementImage/ContentElementImage';

import SvgBaustelle from './baustelle.svg';
import SvgCar from './car.svg';
import SvgEngstelle from './engstelle.svg';
import SvgGefahrenstelle from './gefahrenstelle.svg';
import SvgInfo from './info.svg';
import SvgKeins from './keins.svg';
import SvgSchleudergefahr from './schleudergefahr.svg';
import SvgSchneeglaette from './schneeglaette.svg';
import SvgSicht from './sicht.svg';
import SvgStau from './stau.svg';
import SvgUnfall from './unfall.svg';
import SvgVerbotFahrzeuge from './verbot_fahrzeuge.svg';
import SvgVorsicht from './vorsicht.svg';
import SvgWartezeit from './wartezeit.svg';

export default class Traffic extends Component {
	static propTypes = {
		data: PropTypes.object,
	};

	render() {
		let categorySection;
		if (this.props.data) {
			const { bmtKategorien } = this.props.data;

			categorySection = bmtKategorien.map((kategorie, kIndex) => {
				const meldungen = kategorie.meldungen.map((meldung, mIndex) => {
					const { absatz, headline, meldungsZeit, visualisierung } = meldung;

					let IconComponent;

					switch (visualisierung) {
						case 'baustelle':
							IconComponent = SvgBaustelle;
							break;
						case 'car':
							IconComponent = SvgCar;
							break;
						case 'engstelle':
							IconComponent = SvgEngstelle;
							break;
						case 'gefahrenstelle':
							IconComponent = SvgGefahrenstelle;
							break;
						case 'info':
							IconComponent = SvgInfo;
							break;
						case 'keins':
							IconComponent = SvgKeins;
							break;
						case 'schleudergefahr':
							IconComponent = SvgSchleudergefahr;
							break;
						case 'schneeglaette':
							IconComponent = SvgSchneeglaette;
							break;
						case 'sicht':
							IconComponent = SvgSicht;
							break;
						case 'stau':
							IconComponent = SvgStau;
							break;
						case 'unfall':
							IconComponent = SvgUnfall;
							break;
						case 'verbot_fahrzeuge':
							IconComponent = SvgVerbotFahrzeuge;
							break;
						case 'vorsicht':
							IconComponent = SvgVorsicht;
							break;
						case 'wartezeit':
							IconComponent = SvgWartezeit;
							break;
						default:
							IconComponent = SvgKeins;
					}

					return (
						<div key={mIndex} className={styles.item}>
							<div className={styles.icon}>
								<IconComponent />
							</div>

							<div className={styles.message}>
								<div className={styles.headline}>
									<h4>{headline}</h4>
									<p className={styles.timestamp}>{meldungsZeit}</p>
								</div>
								<p>{absatz}</p>
							</div>
						</div>
					);
				});

				return (
					<div key={kIndex}>
						<h3>{kategorie.titel}</h3>
						{meldungen}
					</div>
				);
			});
		}

		return (
			<div className={styles.traffic}>
				<ContentElementImage
					data={{
						element_type: 'gallery',
						content: {
							images: [
								{
									element_type: 'image',
									content: {
										source: require('./traffic.jpg'),
										variants: {
											one_by_one: require('./traffic.jpg'),
											three_by_two: require('./traffic.jpg'),
											three_by_one: require('./traffic.jpg'),
										},
									},
									metadata: {
										title: '',
										tags: [],
										copyright: '',
									},
								},
							],
						},
					}}
					isTeaser={true}
				/>
				<div className="container">
					<div className={classNames(styles.headline)}>
						<time>{moment().format('DD.MM.YYYY')}</time>
						<h2>Verkehr, Gefahrenmeldungen und Unterrichtsausfälle</h2>
						<h1>Das BAYERN 3 Verkehrs-Update - die Lage in Bayern</h1>
					</div>
					{categorySection}
				</div>
			</div>
		);
	}
}
