import SvgBayern3Events from '../assets/img/icon_bayern3_events.svg';
import SvgBayern3Radio from '../assets/img/icon_bayern3_radio.svg';
import SvgBrDe from '../assets/img/icon_br.de.svg';
import SvgFacebook from '../assets/img/icon_facebook.svg';
import SvgInstagram from '../assets/img/icon_instagram.svg';
import SvgSpotify from '../assets/img/icon_spotify.svg';
import SvgTwitter from '../assets/img/icon_twitter.svg';
import SvgYoutube from '../assets/img/icon_youtube.svg';
import SvgTikTok from '../assets/img/icon_tiktok.svg';
import SvgSoundcloud from '../assets/img/icon_soundcloud.svg';
import SvgGooglemaps from '../assets/img/icon_googlemaps.svg';

const STORAGE_KEY = 'dataPrivacy';

/**
 * All 3rd party services.
 */
export enum Services {
	BR = 'br',
	BAYERN3_EVENTS = 'bayern3events',
	BAYERN3_RADIO = 'bayern3radio',
	ARD_AUDIOTHEK = 'ardaudiothek',
	ARD_MEDIATHEK = 'ardmediathek',
	MDR = 'mdr',
	FACEBOOK = 'facebook',
	YOUTUBE = 'youtube',
	INSTAGRAM = 'instagram',
	TWITTER = 'twitter',
	PINTEREST = 'pinterest',
	TYPEFORM = 'typeform',
	EXCO = 'exco',
	SPOTIFY = 'spotify',
	APPLE_PODCASTS = 'applepodcasts',
	CLEVERREACH = 'cleverreach',
	TIKTOK = 'tiktok',
	SOUNDCLOUD = 'soundcloud',
	GOOGLE_MAPS = 'googlemaps',
}

/**
 * Interface for privacy settings.
 */
export interface IPrivacySettings {
	br: boolean;
	bayern3events: boolean;
	bayern3radio: boolean;
	ardaudiothek: boolean;
	ardmediathek: boolean;
	mdr: boolean;
	facebook: boolean;
	youtube: boolean;
	instagram: boolean;
	twitter: boolean;
	pinterest: boolean;
	typeform: boolean;
	exco: boolean;
	spotify: boolean;
	applepodcasts: boolean;
	cleverreach: boolean;
	tiktok: boolean;
	soundcloud: boolean;
	googlemaps: boolean;
}

/**
 * Callback for listeners.
 */
export interface ICallbackFunction {
	(state: boolean): void;
}

/**
 * Metadata for a service.
 */
export interface IServicesMetadata {
	domains: string[]; // domains to fetch the content from
	content: string; // in words what content is fetched
	provider: string; // in words the provider (e.g. company) of the content
	icon?: any; // svg icon
}

export const servicesMetadata = new Map<Services, IServicesMetadata>();
servicesMetadata.set(Services.BAYERN3_EVENTS, {
	domains: ['bayern3.events'],
	content: 'Formulare',
	provider: 'den Bayerischen Rundfunk',
	icon: SvgBayern3Events,
});
servicesMetadata.set(Services.BAYERN3_RADIO, {
	domains: ['bayern3.radio'],
	content: 'Playlisten',
	provider: 'den Bayerischen Rundfunk',
	icon: SvgBayern3Radio,
});
servicesMetadata.set(Services.BR, {
	domains: ['br.de'],
	content: 'Videos und Audios',
	provider: 'den Bayerischen Rundfunk',
	icon: SvgBrDe,
});
servicesMetadata.set(Services.ARD_AUDIOTHEK, {
	domains: ['ardaudiothek.de'],
	content: 'Audios',
	provider: 'ARD Audiothek',
});
servicesMetadata.set(Services.ARD_MEDIATHEK, {
	domains: ['ardmediathek.de'],
	content: 'Videos',
	provider: 'ARD Mediathek',
});
servicesMetadata.set(Services.MDR, {
	domains: ['mdr.de'],
	content: 'Inhalte',
	provider: 'MDR',
});
servicesMetadata.set(Services.EXCO, {
	domains: ['ex.co', 'playbuzz.com'],
	content: 'Umfragen und Abstimmungen',
	provider: 'Playbuzz Ltd.',
});
servicesMetadata.set(Services.FACEBOOK, {
	domains: ['facebook.com'],
	content: 'Facebook-Posts und Videos',
	provider: 'Facebook',
	icon: SvgFacebook,
});
servicesMetadata.set(Services.INSTAGRAM, {
	domains: ['instagram.com'],
	content: 'Instagram Inhalte',
	provider: 'Instagram',
	icon: SvgInstagram,
});
servicesMetadata.set(Services.TWITTER, {
	domains: ['twitter.com'],
	content: 'Tweets',
	provider: 'Twitter',
	icon: SvgTwitter,
});
servicesMetadata.set(Services.PINTEREST, {
	domains: ['pinterest.com'],
	content: 'Pins und Boards',
	provider: 'Pinterest',
});
servicesMetadata.set(Services.TYPEFORM, {
	domains: ['typeform.com'],
	content: 'Umfragen',
	provider: 'TYPEFORM SL',
});
servicesMetadata.set(Services.YOUTUBE, {
	domains: ['youtube.com', 'youtu.be'],
	content: 'YouTube-Videos',
	provider: 'Google',
	icon: SvgYoutube,
});
servicesMetadata.set(Services.SPOTIFY, {
	domains: ['spotify.com'],
	content: 'Playlisten',
	provider: 'Spotify',
	icon: SvgSpotify,
});
servicesMetadata.set(Services.APPLE_PODCASTS, {
	domains: ['podcasts.apple.com'],
	content: 'Podcasts',
	provider: 'Apple',
});
servicesMetadata.set(Services.CLEVERREACH, {
	domains: ['cleverreach.com'],
	content: 'Formulare',
	provider: 'CleverReach',
});
servicesMetadata.set(Services.TIKTOK, {
	domains: ['tiktok.com'],
	content: 'TikTok-Videos',
	provider: 'TikTok',
	icon: SvgTikTok,
});
servicesMetadata.set(Services.SOUNDCLOUD, {
	domains: ['soundcloud.com'],
	content: 'Soundcloud-Audios und Soundcloud-Streams',
	provider: 'Soundcloud',
	icon: SvgSoundcloud,
});
servicesMetadata.set(Services.GOOGLE_MAPS, {
	domains: ['www.google.com/maps/embed'],
	content: 'Goople Maps',
	provider: 'Google',
	icon: SvgGooglemaps,
});

export const defaultPrivacySettings: IPrivacySettings = {
	br: true,
	bayern3events: true,
	bayern3radio: true,
	ardaudiothek: false,
	ardmediathek: false,
	mdr: false,
	facebook: false,
	youtube: false,
	instagram: false,
	twitter: false,
	pinterest: false,
	typeform: false,
	exco: false,
	spotify: false,
	applepodcasts: false,
	cleverreach: true,
	tiktok: false,
	soundcloud: false,
	googlemaps: false,
};

const listener = new Map<Services, ICallbackFunction[]>();
Object.values(Services).forEach((service: Services) => listener.set(service, new Array<ICallbackFunction>()));

function setData(key: string, value: any) {
	if (typeof localStorage !== 'undefined') {
		localStorage.setItem(key, JSON.stringify(value));
	}
}

function getData(key: string) {
	let result;
	if (typeof localStorage !== 'undefined') {
		result = localStorage.getItem(key);
	}
	return result && JSON.parse(result);
}

/**
 * Gets the current privacy settings.
 */
export const getSettings = (): IPrivacySettings => {
	return getData(STORAGE_KEY) || defaultPrivacySettings;
};

/**
 * Checks the data privacy storage if the given service has been activated.
 * @param service service string
 */
export const isServiceActive = (service: Services): boolean => {
	const services = getData(STORAGE_KEY) || defaultPrivacySettings;
	return services[service];
};

/**
 * Toggles a 3rd party service. The privacy storage will be refreshed and all listeners
 * will be called.
 * @param service service string
 */
export const toggleService = (service: Services) => {
	const services = getData(STORAGE_KEY) || defaultPrivacySettings;
	services[service] = !services[service];
	setData(STORAGE_KEY, services);

	// call listener
	const serviceListener = listener.get(service);
	if (serviceListener) {
		serviceListener.forEach(callback => callback(services[service]));
	}
};

/**
 * Register listener for state changes.
 * @param service service to listen for changes
 * @param callback callback function
 */
export const registerListener = (service: Services, callback: ICallbackFunction) => {
	const serviceListener = listener.get(service);
	if (serviceListener) {
		serviceListener.push(callback);
		listener.set(service, serviceListener);
	}
};

/**
 * Remove listener for state changes.
 * @param service service that has been subscribed
 * @param callback callback function
 */
export const removeListener = (service: Services, callback: ICallbackFunction) => {
	const serviceListener = listener.get(service);
	if (serviceListener) {
		const index = serviceListener.indexOf(callback);
		if (index > -1) {
			serviceListener.splice(index, 1);
			listener.set(service, serviceListener);
		}
	}
};

/**
 * Identifies which service is used in the given code.
 * @param embedCode code to analyse
 */
export const identifyService = (embedCode: string): Services | null => {
	let result = null;
	servicesMetadata.forEach((metadata, service) => {
		metadata.domains.forEach(domain => {
			if (embedCode.indexOf(domain) !== -1) {
				result = service;
			}
		});
	});
	return result;
};
