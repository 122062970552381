import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GraupelschauerHeiterNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M17.726 12.938a8.236 8.236 0 0 1-6.829 3.605 8.268 8.268 0 0 1-8.273-8.274 8.267 8.267 0 0 1 8.273-8.273c.475 0 .954.047 1.413.125a7.18 7.18 0 0 0 4.32 12.911 11.3 11.3 0 0 0 1.096-.094zM22.396.743l-.05 2.985 2.908-.699-1.936 2.254 2.666 1.335-2.953.476 1.189 2.731-2.555-1.523-.842 2.858-.985-2.811-2.491 1.652 1.062-2.795-2.969-.333 2.604-1.461-2.048-2.16 2.922.555-.19-2.97 1.89 2.301z"
			/>
			<path
				fill="#FFF"
				d="M4.701 23.188c0 .696-.579 1.392-1.391 1.392-.697 0-1.389-.582-1.389-1.392 0-.696.577-1.392 1.389-1.392.811.001 1.391.579 1.391 1.392z"
			/>
			<path
				fill="#4F4E4E"
				d="M3.31 24.816c-.787 0-1.629-.653-1.629-1.627 0-.786.654-1.629 1.629-1.629.945 0 1.63.684 1.63 1.629 0 .786-.655 1.627-1.63 1.627zm0-2.78c-.69 0-1.154.596-1.154 1.153 0 .688.596 1.153 1.154 1.153.689 0 1.154-.596 1.154-1.153 0-.679-.475-1.153-1.154-1.153z"
			/>
			<circle fill="#FFF" cx="5.628" cy="30.375" r="1.39" />
			<path
				fill="#4F4E4E"
				d="M5.628 32.004a1.63 1.63 0 0 1-1.629-1.629c0-.898.73-1.629 1.629-1.629a1.63 1.63 0 0 1 1.629 1.629 1.63 1.63 0 0 1-1.629 1.629zm0-2.782c-.635 0-1.153.518-1.153 1.153s.517 1.155 1.153 1.155 1.152-.52 1.152-1.155-.516-1.153-1.152-1.153z"
			/>
			<circle fill="#FFF" cx="12.351" cy="27.941" r="1.391" />
			<path
				fill="#4F4E4E"
				d="M12.351 29.569a1.63 1.63 0 0 1 0-3.258c.898 0 1.63.731 1.63 1.631 0 .896-.732 1.627-1.63 1.627zm0-2.781c-.635 0-1.154.518-1.154 1.155a1.154 1.154 0 0 0 2.308 0c0-.638-.519-1.155-1.154-1.155z"
			/>
			<circle fill="#FFF" cx="19.191" cy="30.375" r="1.39" />
			<path
				fill="#4F4E4E"
				d="M19.191 32.004a1.632 1.632 0 0 1-1.631-1.629c0-.898.732-1.629 1.631-1.629a1.63 1.63 0 0 1 1.628 1.629 1.63 1.63 0 0 1-1.628 1.629zm0-2.782c-.637 0-1.155.518-1.155 1.153s.517 1.155 1.155 1.155a1.154 1.154 0 1 0 0-2.308z"
			/>
			<circle fill="#FFF" cx="24.985" cy="27.245" r="1.391" />
			<path
				fill="#4F4E4E"
				d="M24.985 28.875a1.63 1.63 0 0 1 0-3.26c.899 0 1.63.733 1.63 1.631s-.731 1.629-1.63 1.629zm0-2.783a1.154 1.154 0 1 0 .004 2.308 1.154 1.154 0 0 0-.004-2.308z"
			/>
			<g>
				<path
					fill="#CAC9C8"
					d="M11.192 23.884c-2.088 0-3.709-1.623-3.709-3.711 0-2.086 1.621-3.709 3.709-3.709.231 0 .579 0 .811.118l.58.116.117-.58c.694-2.666 3.13-4.52 5.911-4.52 2.781 0 5.215 1.854 5.91 4.52l.118.58.579-.116c.23-.118.58-.118.811-.118 2.088 0 3.71 1.623 3.71 3.709 0 2.088-1.622 3.711-3.71 3.711H11.192z"
				/>
				<path
					fill="#4F4E4E"
					d="M18.611 12.177c2.55 0 4.752 1.739 5.332 4.058l.348 1.041 1.043-.231c.231 0 .465-.116.695-.116 1.74 0 3.131 1.39 3.131 3.129a3.118 3.118 0 0 1-3.131 3.131H11.076a3.117 3.117 0 0 1-3.129-3.131 3.115 3.115 0 0 1 3.129-3.129c.233 0 .464 0 .696.116l1.044.231.347-1.041c.811-2.319 3.012-4.058 5.448-4.058m0-1.16c-3.131 0-5.681 2.086-6.492 4.983-.348-.116-.696-.116-1.044-.116-2.317 0-4.289 1.97-4.289 4.289s1.972 4.289 4.289 4.289h14.953c2.319 0 4.29-1.97 4.29-4.289s-1.971-4.289-4.29-4.289c-.347 0-.695 0-1.044.116-.693-2.897-3.244-4.983-6.373-4.983z"
				/>
				<path
					fill="#4F4E4E"
					d="M14.09 17.88a.482.482 0 0 1-.162-.029l-1.969-.696a.486.486 0 0 1-.299-.619.487.487 0 0 1 .621-.296l1.971.696a.487.487 0 0 1-.162.944z"
				/>
			</g>
		</SVGIcon>
	);
}
