import React from 'react';
// @ts-ignore
import ToggleButton from 'react-toggle-button';

interface IProps {
	value: boolean;
	onToggle?: () => void;
}

/**
 * Button to toggle values.
 */
export default class B3ToggleButton extends React.Component<IProps> {
	render() {
		return (
			<ToggleButton
				value={this.props.value}
				onToggle={this.props.onToggle}
				activeLabel=""
				inactiveLabel=""
				colors={{ active: { base: '#a1bf36' } }}
				thumbStyle={{ width: 29, height: 29 }}
				thumbAnimateRange={[1, 21]}
				trackStyle={{ width: 51, height: 31 }}
			/>
		);
	}
}
