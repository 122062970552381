import SVGIcon from '../SVGIcon';
import React from 'react';

export default function BedecktIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#C9C8C8"
				d="M2.684 17.066a2.31 2.31 0 0 1-2.31-2.309 2.299 2.299 0 0 1 2.31-2.297c.181 0 .351.024.532.06l.339.085.097-.339c.447-1.657 1.959-2.817 3.676-2.817s3.229 1.161 3.676 2.817l.097.339.339-.085a2.58 2.58 0 0 1 .532-.06c1.27 0 2.31 1.028 2.31 2.297s-1.028 2.309-2.31 2.309H2.684z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.327 9.811a3.443 3.443 0 0 1 3.325 2.551l.181.677.689-.157c.145-.036.302-.048.447-.048 1.076 0 1.947.871 1.947 1.935a1.946 1.946 0 0 1-1.947 1.947H2.684a1.946 1.946 0 0 1-1.947-1.947c0-1.064.871-1.935 1.947-1.935.145 0 .302.012.447.048l.689.157.181-.677a3.445 3.445 0 0 1 3.326-2.551m0-.726A4.18 4.18 0 0 0 3.3 12.168a2.67 2.67 0 0 0-3.289 2.587 2.673 2.673 0 0 0 2.672 2.672h9.287a2.673 2.673 0 0 0 2.672-2.672 2.663 2.663 0 0 0-3.289-2.587 4.178 4.178 0 0 0-4.026-3.083z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.485 13.366a.295.295 0 0 1-.115-.023l-1.173-.484a.302.302 0 1 1 .231-.559l1.172.484a.302.302 0 0 1-.115.582z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.755 14.517c-1.087 0-1.981-.894-1.981-1.981s.894-1.981 1.981-1.981c.157 0 .302.012.459.048l.29.072.073-.278a3.27 3.27 0 0 1 3.153-2.416 3.26 3.26 0 0 1 3.153 2.416l.073.278.29-.072c.145-.036.302-.048.459-.048 1.087 0 1.981.894 1.981 1.981s-.894 1.981-1.981 1.981h-7.95z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.73 8.271c1.341 0 2.513.906 2.863 2.199l.157.568.568-.133c.133-.024.254-.048.387-.048.93 0 1.679.749 1.679 1.679s-.749 1.679-1.679 1.679h-7.95c-.93 0-1.679-.749-1.679-1.679a1.676 1.676 0 0 1 2.066-1.631l.568.133.157-.568a2.972 2.972 0 0 1 2.863-2.199m0-.604a3.556 3.556 0 0 0-3.443 2.646 2.289 2.289 0 0 0-2.815 2.224 2.29 2.29 0 0 0 2.283 2.283h7.95a2.289 2.289 0 0 0 2.283-2.283 2.29 2.29 0 0 0-2.815-2.224 3.555 3.555 0 0 0-3.443-2.646z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.447 11.388a.287.287 0 0 1-.114-.023l-1.184-.483a.303.303 0 0 1 .228-.56l1.184.483a.303.303 0 0 1-.114.583z"
			/>
			<path
				fill="#FFF"
				d="M9.273 20.539a3.445 3.445 0 0 1-3.44-3.44c0-1.902 1.55-3.44 3.44-3.44.266 0 .533.036.799.097l.46.109.121-.448c.654-2.495 2.907-4.215 5.462-4.215s4.808 1.72 5.462 4.191l.121.448.46-.109c.266-.061.533-.097.799-.097 1.902 0 3.44 1.55 3.44 3.44 0 1.902-1.55 3.44-3.44 3.44H9.273v.024z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.117 9.687a5.186 5.186 0 0 1 5.002 3.827l.242.908.908-.218c.23-.048.448-.085.678-.085a2.959 2.959 0 0 1 2.955 2.955 2.959 2.959 0 0 1-2.955 2.955H9.261a2.959 2.959 0 0 1-2.955-2.955 2.959 2.959 0 0 1 2.955-2.955c.23 0 .46.024.678.085l.908.218.242-.908a5.24 5.24 0 0 1 5.028-3.827m0-.969a6.142 6.142 0 0 0-5.935 4.542 3.923 3.923 0 0 0-4.832 3.815 3.923 3.923 0 0 0 3.924 3.924H22.96a3.923 3.923 0 0 0 3.924-3.924 3.923 3.923 0 0 0-4.832-3.815 6.142 6.142 0 0 0-5.935-4.542z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.889 14.944a.423.423 0 0 1-.169-.035l-1.562-.678a.424.424 0 1 1 .338-.778l1.562.678a.424.424 0 0 1-.169.813z"
			/>
			<g>
				<path
					fill="#C9C8C8"
					d="M9.619 23.97a2.31 2.31 0 0 1-2.31-2.309 2.299 2.299 0 0 1 2.31-2.297c.181 0 .351.024.532.06l.339.085.097-.339c.447-1.657 1.959-2.817 3.676-2.817s3.229 1.161 3.676 2.817l.097.339.339-.085a2.58 2.58 0 0 1 .532-.06c1.27 0 2.31 1.028 2.31 2.297a2.309 2.309 0 0 1-2.31 2.309H9.619z"
				/>
				<path
					fill="#4F4E4E"
					d="M14.262 16.715a3.443 3.443 0 0 1 3.325 2.551l.181.677.689-.157c.145-.036.302-.048.447-.048 1.076 0 1.947.871 1.947 1.935a1.946 1.946 0 0 1-1.947 1.947H9.619a1.946 1.946 0 0 1-1.947-1.947c0-1.064.871-1.935 1.947-1.935.145 0 .302.012.447.048l.689.157.181-.677a3.445 3.445 0 0 1 3.326-2.551m0-.725a4.18 4.18 0 0 0-4.027 3.083 2.67 2.67 0 0 0-3.289 2.587 2.673 2.673 0 0 0 2.672 2.672h9.287a2.673 2.673 0 0 0 2.672-2.672 2.663 2.663 0 0 0-3.289-2.587 4.177 4.177 0 0 0-4.026-3.083z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.42 20.27a.295.295 0 0 1-.115-.023l-1.173-.484a.302.302 0 1 1 .231-.559l1.173.484a.302.302 0 0 1-.116.582z"
				/>
			</g>
		</SVGIcon>
	);
}
