import * as config from '../../config.webapp.ts';
import idx from 'idx';

const LOAD_NAVIGATION = 'br3/dashboard/LOAD_NAVIGATION';
const LOAD_NAVIGATION_SUCCESS = 'br3/dashboard/LOAD_NAVIGATION_SUCCESS';
const LOAD_NAVIGATION_FAIL = 'br3/dashboard/LOAD_NAVIGATION_FAIL';

const SAVE_NAVIGATION = 'br3/dashboard/SAVE_NAVIGATION';
const SAVE_NAVIGATION_SUCCESS = 'br3/dashboard/SAVE_NAVIGATION_SUCCESS';
const SAVE_NAVIGATION_FAIL = 'br3/dashboard/SAVE_NAVIGATION_FAIL';

const LOAD_BANNER = 'br3/dashboard/LOAD_BANNER';
const LOAD_BANNER_SUCCESS = 'br3/dashboard/LOAD_BANNER_SUCCESS';
const LOAD_BANNER_FAIL = 'br3/dashboard/LOAD_BANNER_FAIL';

const SAVE_BANNER = 'br3/dashboard/SAVE_BANNER';
const SAVE_BANNER_FAIL = 'br3/dashboard/SAVE_BANNER_FAIL';
const SAVE_BANNER_SUCCESS = 'br3/dashboard/SAVE_BANNER_SUCCESS';

const LOAD_ARTICLES = 'br3/dashboard/LOAD_ARTICLES';
const LOAD_ARTICLES_SUCCESS = 'br3/dashboard/LOAD_ARTICLES_SUCCESS';
const LOAD_ARTICLES_FAIL = 'br3/dashboard/LOAD_ARTICLES_FAIL';

const LOAD_ARTICLE_SUCCESS = 'br3/dashboard/LOAD_ARTICLE_SUCCESS';

const SEARCH = 'br3/dashboard/SEARCH';
const SEARCH_SUCCESS = 'br3/dashboard/SEARCH_SUCCESS';
const SEARCH_FAIL = 'br3/dashboard/SEARCH_FAIL';

const initialState = {
	articles: [],
	searchTerm: '',
	searchResults: [],
	articlesErrors: [],
	articlesLoaded: false,
	bannersLoaded: false,
	navigationLoaded: false,
	mainNavigation: [],
};

export default function dashboard(state = initialState, action = {}) {
	switch (action.type) {
		case LOAD_NAVIGATION_SUCCESS:
			const indexMainNavigation = (idx(action, _ => _.result.data) || []).findIndex(({ id }) => id === 'main');
			return {
				...state,
				mainNavigation: action.result.data[indexMainNavigation],
				navigationLoaded: true,
			};

		case LOAD_BANNER_SUCCESS:
			let newBanners = [];
			action.result.forEach(banners => {
				newBanners = newBanners.concat(banners.data);
			});
			return {
				...state,
				banners: newBanners,
				bannersLoaded: true,
			};

		case LOAD_ARTICLES:
			return {
				...state,
				articlesLoading: true,
			};

		case LOAD_ARTICLES_SUCCESS:
			const newArticles = state.articles.slice(0);
			for (let articlesOfOneStatus of action.result) {
				for (let article of articlesOfOneStatus.data) {
					const { id } = article;
					const i = newArticles.indexOf(article => article.id === id);
					if (i > -1) {
						newArticles[i] = article;
					} else {
						newArticles.push(article);
					}
				}
			}
			return {
				...state,
				articles: newArticles,
				articlesPagination: action.result.map(l => (l.links ? l.links.next : null)), // list of next-links for each status type
				articlesLoaded: true,
			};

		case LOAD_ARTICLE_SUCCESS:
			if (state.articles) {
				const { id } = action.result;
				const index = state.articles.indexOf(article => article.id === id);
				if (index > -1) {
					state.articles[index] = action.result;
				} else {
					state.articles.unshift(action.result);
				}
			}
			return {
				...state,
				articles: state.articles,
				articlesLoading: false,
			};

		case LOAD_ARTICLES_FAIL:
			return {
				...state,
				articlesErrors: state.articlesErrors.concat([action.error]),
				articlesLoading: false,
			};

		case SAVE_NAVIGATION_SUCCESS:
			return {
				...state,
				mainNavigation: action.result.data,
				navigationLoaded: true,
			};

		case SEARCH:
			return {
				...state,
				searchTerm: action.q,
			};

		case SEARCH_SUCCESS:
			return {
				...state,
				searchResults: action.result,
			};

		case SEARCH_FAIL:
			return {
				...state,
				searchTerm: '',
				searchResults: [],
			};

		default:
			return state;
	}
}

export function loadNavigation() {
	return {
		types: [LOAD_NAVIGATION, LOAD_NAVIGATION_SUCCESS, LOAD_NAVIGATION_FAIL],
		promise: client =>
			client.get(`${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.navigation}`, {
				headers: { Accept: 'application/json' },
			}),
	};
}

export function saveNavigation(nextNavigation, previousNavigation) {
	return {
		types: [SAVE_NAVIGATION, SAVE_NAVIGATION_SUCCESS, SAVE_NAVIGATION_FAIL],
		promise: client => {
			const endpoints = {
				teaserBoard: `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.teaserBoard}`,
				navigation: `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.navigation}`,
			};

			const filterFunction = item => /^\/r\/[A-Za-z0-9_-]+/.test(item);
			const mapFunction = item => item.link;

			const nextNavigationItemLinks = nextNavigation.attributes.content.items.map(mapFunction).filter(filterFunction);
			const previousNavigationItemLinks = previousNavigation.attributes.content.items.map(mapFunction).filter(filterFunction);

			const navigationIntersection = previousNavigationItemLinks.filter(value => nextNavigationItemLinks.includes(value));
			const toCreate = nextNavigationItemLinks.filter(x => !previousNavigationItemLinks.includes(x));
			const toDelete = previousNavigationItemLinks.filter(x => !navigationIntersection.includes(x));

			let saveNavigationChanges = republish => {
				let promises = [];

				if (toCreate.length > 0) {
					const newBoardId = toCreate[0].slice(3);

					// const newBoardSlug = toCreate[0];

					// this seem to have never properly worked...
					// const title = result(find(nextNavigation.attributes.content.items, 'link', newBoardSlug), 'title');

					// console.log({ title });

					const newBoardFetchOptions = {
						method: !republish ? 'post' : 'put',
						endpoint: !republish ? endpoints.teaserBoard : `${endpoints.teaserBoard}/${newBoardId}`,
						data: {
							data: {
								id: newBoardId,
								type: 'board',
								attributes: {
									content: {
										title: newBoardId,
									},
									metadata: {
										board_type: 0,
										doc: {
											status: 'published',
										},
									},
								},
								relationships: {
									pinned_articles: { data: [] },
								},
							},
						},
					};

					const { method, endpoint, data } = newBoardFetchOptions;

					promises.push(client[method](endpoint, { data }));
				}

				// oldNav without intersection = to delete
				toDelete.forEach(item => {
					// mark Teaserboard as depublished
					promises.push(
						client.put(`${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.teaserBoard}/${item.slice(3)}`, {
							data: {
								data: {
									id: item.slice(3),
									type: 'board',
									attributes: {
										metadata: {
											board_type: 0,
											doc: {
												status: 'depublished',
											},
										},
									},
								},
							},
						})
					);
				});

				const nextNavigationFetchOptions = {
					method: !nextNavigation.createdAt ? 'post' : 'put',
					endpoint: !nextNavigation.createdAt ? endpoints.navigation : `${endpoints.navigation}/${nextNavigation.id}`,
					data: { data: nextNavigation },
				};

				const { method, endpoint, data } = nextNavigationFetchOptions;

				promises.push(client[method](endpoint, { data }));

				return Promise.all(promises).then(values => {
					return values.pop();
				});
			};

			// navigation without intersection = to create
			let promise;
			if (toCreate.length > 0) {
				// check if teaserboard exists
				promise = client.get(`${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.teaserBoard}/${toCreate[0].slice(3)}?status=all`).then(
					() => {
						// teaserboard exists => republish
						return saveNavigationChanges(true, nextNavigation);
					},
					() => {
						// teaserboard does not exist => create
						return saveNavigationChanges(false, nextNavigation);
					}
				);
			} else {
				// always resolve promise, no new teaserboards
				promise = saveNavigationChanges(false, nextNavigation);
			}
			return promise;
		},
	};
}

export function loadArticles(params, pagination) {
	return {
		types: [LOAD_ARTICLES, LOAD_ARTICLES_SUCCESS, LOAD_ARTICLES_FAIL],
		promise: client =>
			Promise.all(
				['draft', 'review', 'published', 'depublished'].map((status, statusIndex) => {
					if (pagination) {
						if (pagination[statusIndex]) {
							return client.get(pagination[statusIndex], { headers: { Accept: 'application/json' } });
						} else {
							return new Promise(resolve => resolve({ data: [] }));
						}
					}
					return client.get(`${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.article}?status=${status}&limit=25`, {
						headers: { Accept: 'application/json' },
					});
				})
			),
	};
}

export function searchArticle(q, status) {
	if (status) {
		status = '&status=' + status.join(',');
	} else {
		status = '';
	}
	return {
		q,
		types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
		promise: client =>
			client.get(`${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.search}?q=${q}${status}`, {
				headers: { Accept: 'application/json' },
			}),
	};
}

export function refreshArticle(e) {
	return {
		type: LOAD_ARTICLE_SUCCESS,
		result: e,
	};
}

export function loadBanners() {
	return {
		types: [LOAD_BANNER, LOAD_BANNER_SUCCESS, LOAD_BANNER_FAIL],
		promise: client =>
			Promise.all(
				['published', 'draft'].map(status =>
					client.get(`${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.banner}?status=${status}`, {
						headers: { Accept: 'application/json' },
					})
				)
			),
	};
}

export function saveBanner(banner) {
	const types = [SAVE_BANNER, SAVE_BANNER_SUCCESS, SAVE_BANNER_FAIL];
	const endpoint = `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.banner}`;

	if (banner.createdAt) {
		return {
			types,
			promise: client => client.put(`${endpoint}/${banner.id}`, { data: { data: banner } }),
		};
	} else {
		return {
			types,
			promise: client => client.post(`${endpoint}`, { data: { data: banner } }),
		};
	}
}
