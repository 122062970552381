import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenStarkBedecktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M7.573 25.562c.255-.432.255-1.177.294-1.786.039-.785.059-1.393.059-1.393s-.334.432-.981.844c-.51.334-1.334.589-1.589 1.04a1.293 1.293 0 0 0 .471 1.766 1.282 1.282 0 0 0 1.746-.471zm4.528-2.503c.255-.432.255-1.177.294-1.786.039-.785.059-1.393.059-1.393s-.334.432-.981.844c-.51.334-1.334.589-1.589 1.04a1.293 1.293 0 0 0 .471 1.766 1.281 1.281 0 0 0 1.746-.471zm4.227 2.503c.255-.432.255-1.177.294-1.786.039-.785.059-1.393.059-1.393s-.334.432-.981.844c-.51.334-1.334.589-1.589 1.04a1.293 1.293 0 0 0 .471 1.766 1.282 1.282 0 0 0 1.746-.471zM3.952 21.861c.421-.713.421-1.943.486-2.947.065-1.295.097-2.3.097-2.3s-.551.713-1.619 1.393c-.842.551-2.202.972-2.623 1.717a2.135 2.135 0 0 0 .777 2.915c1.004.582 2.299.226 2.882-.778z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.755 15.356a2.371 2.371 0 0 1-2.371-2.371 2.36 2.36 0 0 1 2.371-2.358c.186 0 .36.025.546.062l.348.087.099-.348c.459-1.701 2.011-2.892 3.773-2.892s3.314 1.192 3.773 2.892l.099.348.348-.087a2.368 2.368 0 0 1 2.917 2.296 2.371 2.371 0 0 1-2.371 2.371H2.755z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.522 7.908a3.534 3.534 0 0 1 3.413 2.619l.186.695.708-.161c.149-.037.31-.05.459-.05 1.105 0 1.998.894 1.998 1.986a1.997 1.997 0 0 1-1.998 1.998H2.755a1.997 1.997 0 0 1-1.998-1.998c0-1.092.894-1.986 1.998-1.986.149 0 .31.012.459.05l.708.161.186-.695a3.535 3.535 0 0 1 3.414-2.619m0-.744a4.289 4.289 0 0 0-4.133 3.165 2.742 2.742 0 0 0-3.376 2.657 2.744 2.744 0 0 0 2.743 2.743h9.533a2.744 2.744 0 0 0 2.743-2.743 2.734 2.734 0 0 0-2.743-2.731 2.64 2.64 0 0 0-.633.074 4.292 4.292 0 0 0-4.134-3.165z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.605 11.558a.307.307 0 0 1-.118-.024l-1.204-.497a.311.311 0 0 1 .236-.574l1.204.497a.311.311 0 0 1-.118.598z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.482 12.834c-1.117 0-2.036-.918-2.036-2.036s.919-2.036 2.036-2.036a2 2 0 0 1 .472.05l.298.074.074-.285a3.36 3.36 0 0 1 3.24-2.483 3.35 3.35 0 0 1 3.24 2.483l.074.285.298-.074c.149-.037.31-.05.472-.05 1.117 0 2.036.919 2.036 2.036s-.919 2.036-2.036 2.036h-8.168z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.566 6.417c1.378 0 2.582.931 2.942 2.259l.161.583.583-.136c.136-.025.261-.05.397-.05.956 0 1.725.77 1.725 1.725s-.77 1.725-1.725 1.725h-8.167c-.956 0-1.725-.769-1.725-1.725s.77-1.725 1.725-1.725c.137 0 .273.012.397.05l.583.136.161-.583a3.057 3.057 0 0 1 2.943-2.259m0-.62a3.653 3.653 0 0 0-3.538 2.718 2.352 2.352 0 0 0-2.892 2.284 2.352 2.352 0 0 0 2.346 2.346h8.167a2.352 2.352 0 0 0 2.346-2.346 2.352 2.352 0 0 0-2.892-2.284 3.652 3.652 0 0 0-3.537-2.718z"
			/>
			<path fill="#4F4E4E" d="M23.22 9.62a.32.32 0 0 1-.117-.023L21.886 9.1a.31.31 0 1 1 .235-.575l1.216.497a.31.31 0 0 1-.117.598z" />
			<path
				fill="#C9C8C8"
				d="M9.116 18.846a3.536 3.536 0 0 1-3.531-3.531 3.536 3.536 0 0 1 3.531-3.531c.274 0 .547.037.821.099l.472.112.124-.46a5.801 5.801 0 1 1 11.214-.025l.124.46.472-.112c.274-.062.547-.099.821-.099a3.536 3.536 0 0 1 3.531 3.531 3.536 3.536 0 0 1-3.531 3.531H9.116v.025z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.14 7.706a5.324 5.324 0 0 1 5.135 3.929l.249.932.932-.224c.236-.05.46-.087.696-.087a3.039 3.039 0 0 1 3.034 3.034 3.039 3.039 0 0 1-3.034 3.034H9.103a3.039 3.039 0 0 1-3.034-3.034 3.039 3.039 0 0 1 3.034-3.034c.236 0 .472.025.696.087l.932.224.249-.932a5.377 5.377 0 0 1 5.16-3.929m0-.994a6.303 6.303 0 0 0-6.092 4.662 4.027 4.027 0 1 0-.932 7.944h14.049a4.027 4.027 0 1 0-.932-7.944 6.304 6.304 0 0 0-6.093-4.662z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.801 13.102a.44.44 0 0 1-.173-.036l-1.604-.696a.435.435 0 0 1 .346-.798l1.604.696a.435.435 0 0 1-.173.834z"
			/>
			<path
				fill="#C9C8C8"
				d="M17.832 20.176c.457-.732.457-1.92.457-2.926.092-1.281.092-2.285.092-2.285s-.549.732-1.647 1.371c-.823.549-2.194 1.006-2.652 1.738-.548 1.006-.274 2.285.732 2.924 1.098.549 2.47.182 3.018-.822z"
			/>
			<g>
				<path
					fill="#0971B8"
					d="M17.824 20.205c.421-.713.421-1.943.486-2.947.065-1.295.097-2.3.097-2.3s-.551.713-1.619 1.393c-.842.551-2.202.972-2.623 1.717a2.135 2.135 0 0 0 .777 2.915c1.004.583 2.299.226 2.882-.778z"
				/>
			</g>
			<g>
				<path
					fill="#0971B8"
					d="M23.588 25.14c.421-.713.421-1.943.486-2.947.065-1.295.097-2.3.097-2.3s-.551.713-1.619 1.393c-.842.551-2.202.972-2.623 1.717a2.135 2.135 0 0 0 .777 2.915c1.003.583 2.299.226 2.882-.778z"
				/>
			</g>
		</SVGIcon>
	);
}
