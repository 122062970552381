import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeregenschauerNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.444 13.554c-1.155 1.508-3.118 2.499-5.342 2.499-3.578 0-6.473-2.565-6.473-5.735s2.895-5.735 6.473-5.735c.373 0 .745.033 1.106.088-1.355.902-2.237 2.344-2.237 3.973 0 2.741 2.51 4.976 5.616 4.976.298-.011.583-.033.857-.066zM18.096 5.1l-.037 2.069 2.274-.484-1.516 1.563 2.087.925-2.311.33.932 1.893-2-1.056-.659 1.981-.77-1.948-1.95 1.145.832-1.938-2.323-.231 2.037-1.013-1.603-1.497 2.286.385-.149-2.058 1.479 1.596z"
			/>
			<path
				fill="#0971B8"
				d="M8.685 24.744l-.183-.648-1.28.322.275-1.132-.64-.164-.366 1.135-1.006-.809-.457.485.914.811-1.279.323.182.649 1.372-.324-.366 1.135.732.161.274-1.217 1.006.892.458-.484-.915-.812zm9.967.648l-.183-.569-1.281.325.276-1.135-.641-.161-.365 1.134-1.007-.809-.457.405 1.006.889-1.371.326.183.566 1.371-.325-.366 1.216.733.163.273-1.216 1.006.811.457-.405-.914-.891zM4.206 21.746c.273-.406.273-1.054.273-1.54 0-.648.092-1.215.092-1.215s-.365.404-1.006.73c-.549.324-1.371.566-1.554.89-.367.567-.183 1.215.457 1.541.639.323 1.461.161 1.738-.406zm8.777 3.16c.275-.405.275-1.053.275-1.54 0-.648.091-1.214.091-1.214s-.366.404-1.005.73c-.549.322-1.372.567-1.555.89-.365.567-.183 1.215.456 1.541.64.321 1.463.079 1.738-.407z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.75 17.901c-1.303 0-2.371-.935-2.371-2.101 0-1.155 1.055-2.09 2.371-2.09.186 0 .36.022.546.055l.348.077.099-.308c.459-1.507 2.011-2.562 3.774-2.562s3.315 1.056 3.774 2.562l.099.308.348-.077c.174-.033.36-.055.546-.055 1.303 0 2.371.935 2.371 2.09s-1.055 2.101-2.371 2.101H2.75z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.517 11.303c1.601 0 3.004.957 3.414 2.321l.186.616.708-.143c.149-.033.31-.044.459-.044 1.105 0 1.999.792 1.999 1.76 0 .979-.894 1.771-1.999 1.771H2.75c-1.105 0-1.999-.792-1.999-1.771 0-.968.894-1.76 1.999-1.76.149 0 .31.011.459.044l.708.143.186-.616c.41-1.364 1.813-2.321 3.414-2.321m0-.66c-1.974 0-3.637 1.188-4.134 2.804a2.926 2.926 0 0 0-.633-.066c-1.515 0-2.744 1.089-2.744 2.42 0 1.342 1.229 2.43 2.744 2.43h9.534c1.515 0 2.744-1.089 2.744-2.43 0-1.342-1.229-2.42-2.744-2.42-.223 0-.434.022-.633.066-.496-1.616-2.16-2.804-4.134-2.804z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.6 14.536a.35.35 0 0 1-.118-.021l-1.204-.44c-.158-.058-.234-.219-.169-.359.065-.14.247-.207.405-.149l1.204.44c.158.058.234.219.169.359a.313.313 0 0 1-.287.17z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.478 15.668c-1.117 0-2.036-.814-2.036-1.804s.919-1.804 2.036-1.804c.161 0 .31.011.472.044l.298.066.074-.253c.397-1.298 1.726-2.2 3.24-2.2 1.515 0 2.855.902 3.24 2.2l.074.253.298-.066c.149-.033.31-.044.472-.044 1.117 0 2.036.814 2.036 1.804s-.919 1.804-2.036 1.804h-8.168z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.563 9.982c1.378 0 2.582.825 2.942 2.002l.161.517.583-.121c.137-.022.261-.044.397-.044.956 0 1.726.682 1.726 1.529s-.77 1.529-1.726 1.529h-8.168c-.956 0-1.726-.682-1.726-1.529s.77-1.529 1.726-1.529c.137 0 .273.011.397.044l.583.121.161-.517c.362-1.177 1.566-2.002 2.944-2.002m0-.55c-1.701 0-3.128 1.023-3.538 2.408a3.063 3.063 0 0 0-.546-.055c-1.291 0-2.346.935-2.346 2.079 0 1.144 1.055 2.079 2.346 2.079h8.168c1.291 0 2.346-.935 2.346-2.079 0-1.144-1.055-2.079-2.346-2.079-.186 0-.372.022-.546.055-.41-1.385-1.838-2.408-3.538-2.408z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.216 12.819a.364.364 0 0 1-.117-.02l-1.217-.44c-.159-.057-.235-.218-.17-.358.065-.141.246-.208.405-.151l1.217.44c.159.057.235.218.17.358a.312.312 0 0 1-.288.171z"
			/>
			<g>
				<path
					fill="#FFF"
					d="M9.112 20.995c-1.952 0-3.531-1.41-3.531-3.129 0-1.73 1.592-3.129 3.531-3.129.274 0 .547.033.821.088l.472.099.124-.408c.671-2.27 2.984-3.834 5.608-3.834s4.936 1.565 5.608 3.812l.124.408.472-.099c.274-.055.547-.088.821-.088 1.952 0 3.531 1.41 3.531 3.129 0 1.73-1.592 3.129-3.531 3.129H9.112v.022z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.137 11.123c2.4 0 4.514 1.432 5.135 3.482l.249.826.933-.198c.236-.044.46-.077.696-.077 1.679 0 3.034 1.212 3.034 2.688 0 1.487-1.368 2.688-3.034 2.688H9.099c-1.679 0-3.034-1.212-3.034-2.688 0-1.487 1.368-2.688 3.034-2.688.236 0 .473.022.696.077l.933.198.249-.826c.646-2.049 2.76-3.482 5.16-3.482m0-.881c-2.922 0-5.372 1.752-6.093 4.132a4.303 4.303 0 0 0-.933-.099c-2.226 0-4.029 1.598-4.029 3.57 0 1.972 1.803 3.57 4.029 3.57h14.051c2.226 0 4.029-1.598 4.029-3.57 0-1.972-1.803-3.57-4.029-3.57-.323 0-.634.033-.933.099-.721-2.38-3.17-4.132-6.092-4.132z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.797 15.905a.473.473 0 0 1-.173-.032l-1.604-.617c-.22-.085-.322-.312-.226-.507s.352-.285.572-.2l1.604.617c.22.085.322.312.226.507a.44.44 0 0 1-.399.232z"
				/>
			</g>
		</SVGIcon>
	);
}
