import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import PlayerHeaderDropdownElement from '../PlayerHeaderDropdownElement/PlayerHeaderDropdownElement';
import trackEvent from '../../commons/tracking/trackers';
import ClickOutComponent from 'react-onclickout';

import styles from './PlayerHeaderDropdown.scss';

export default class PlayerHeaderDropdown extends ClickOutComponent {
	state = {
		dropdownVisible: false,
	};

	static propTypes = {
		playlist: PropTypes.array.isRequired,
		className: PropTypes.string,
	};

	static defaultProps = {
		playlist: [],
		className: '',
	};

	onToggleDropdown = () => {
		trackEvent('PlayerHeaderDropdown', this.state.dropdownVisible ? 'close' : 'open');
		this.setState({
			dropdownVisible: !this.state.dropdownVisible,
		});
	};

	onClickOut() {
		this.setState({
			dropdownVisible: false,
		});
	}

	render = () => {
		const dropdownStyle = {};
		dropdownStyle[styles.visible] = this.state.dropdownVisible;

		const items = [];
		if (Array.isArray(this.props.playlist)) {
			this.props.playlist.slice(1).forEach(item => {
				if (items.length < 5 && item.type === 'music') {
					items.push(<PlayerHeaderDropdownElement key={item.id} item={item} />);
				}
			});
		}

		return (
			<div className={classNames(styles.playerHeaderDropdown, this.props.className)}>
				<div onClick={this.onToggleDropdown}>{this.props.children}</div>
				<div className={classNames(styles.dropdown, dropdownStyle)}>
					{items}
					<div className={classNames(styles.loadMore)}>
						<span className={classNames(styles.loadMoreText)}>mehr</span>
					</div>
					<div className={classNames(styles.songfinder)}>
						<Link to="/playlist">
							<span>Song-Finder - Was lief wann?</span>
						</Link>
					</div>
				</div>
			</div>
		);
	};
}
