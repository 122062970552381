import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PlayerPlayWhiteIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="16" cy="15.999" r="16" />
			<path
				fill="#3C3C3B"
				d="M21.775 15.55l-8.634-5.593a.534.534 0 0 0-.827.45v11.186a.54.54 0 0 0 .28.473.537.537 0 0 0 .547-.022l8.634-5.594a.535.535 0 0 0 0-.9z"
			/>
		</SVGIcon>
	);
}
