import SVGIcon from '../SVGIcon';
import React from 'react';

export default function DunstigIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#F59E18">
				<path d="M20.091 14.688c0 2.221-1.812 4.033-4.033 4.033s-4.033-1.812-4.033-4.033 1.812-4.033 4.033-4.033 4.033 1.811 4.033 4.033zm-3.276-5.151h-1.49V4.201h1.49zm-1.49 10.301h1.49v5.336h-1.49zm-1.191-9.99l-1.291.744-2.668-4.616 1.291-.745zm3.86 9.667l1.29-.745 2.668 4.617-1.29.744zm-6.019-8.066l-.745 1.29-4.616-2.668.744-1.291zm8.178 6.44l.744-1.29 4.617 2.668-.745 1.29zM10.907 13.918v1.489H5.571v-1.489zm10.301 1.489v-1.489h5.336v1.489zM11.218 16.599l.744 1.29-4.616 2.668-.745-1.29zm9.667-3.86l-.745-1.29 4.617-2.669.745 1.291zM12.819 18.758l1.29.745-2.668 4.616-1.291-.744zm6.44-8.178l-1.29-.745 2.668-4.616 1.291.744z" />
			</g>
			<path
				fill="#969696"
				d="M32 17.197c-8.045 0-12.068-.365-16-.641-3.932-.273-7.955-.639-16-.639V14.91c8.045 0 12.068.365 16 .641 3.932.273 7.955.641 16 .641v1.005zm0 3.565c-8.045 0-12.068-.365-16-.639-3.932-.275-7.955-.641-16-.641v-1.006c8.045 0 12.068.365 16 .641 3.932.273 7.955.641 16 .641v1.004zm0 3.566c-8.045 0-12.068-.365-16-.639-3.932-.275-7.955-.643-16-.643v-1.004c8.045 0 12.068.365 16 .639 3.932.275 7.955.641 16 .641v1.006zm0 3.475c-8.045 0-12.068-.365-16-.641-3.932-.273-7.955-.641-16-.641v-1.004c8.045 0 12.068.363 16 .639 3.932.275 7.955.639 16 .639v1.008z"
			/>
		</SVGIcon>
	);
}
