import Immutable from 'immutable';
import * as config from '../../config.webapp.ts';
import { resolveAllPromisedRelationships } from '../../helpers/utils.js';
import { createEmptyTeaserBoard } from './_initial_state.js';

const REARRANGE = 'br3/board/REARRANGE';

const LOAD = 'br3/board/LOAD';
const LOAD_SUCCESS = 'br3/board/LOAD_SUCCESS';
const LOAD_MORE_ARTICLES_SUCCESS = 'br3/board/LOAD_MORE_ARTICLES_SUCCESS';
const LOAD_FAIL = 'br3/board/LOAD_FAIL';

const PATCH = 'br3/board/PATCH';
const PATCH_SUCCESS = 'br3/board/PATCH_SUCCESS';
const PATCH_FAIL = 'br3/board/PATCH_FAIL';

export default function teaserBoardReducer(state = createEmptyTeaserBoard(), action = {}) {
	let stateData = state.data;
	switch (action.type) {
		case REARRANGE: {
			let allMatchingArticles = new Immutable.Set(state.data.relationships.pinned_articles.data).union(
				state.data.relationships.matching_articles.data
			);
			let newRestArticles = allMatchingArticles.subtract(action.pinnedArticles).toJS();

			let stateDataAttributes = state.data.attributes;
			let stateDataAttributesMetadata = state.data.attributes.metadata;

			return {
				...state,
				saved: false,
				data: {
					...stateData,
					attributes: {
						...stateDataAttributes,
						metadata: {
							...stateDataAttributesMetadata,
							board_type: action.layoutType,
						},
					},
					relationships: {
						pinned_articles: { data: action.pinnedArticles },
						matching_articles: { data: newRestArticles },
					},
				},
			};
		}

		case LOAD: {
			return {
				...state,
				loading: true,
			};
		}

		case LOAD_SUCCESS: {
			return {
				...state,
				loading: false,
				loaded: true,
				savedw: true,
				data: action.result.data,
				matchingArticlesPagination: action.result.data.relationships.matching_articles.links.next,
				included: action.result.included,
				errors: null,
			};
		}

		case LOAD_MORE_ARTICLES_SUCCESS: {
			let stateDataRelationships = state.data.relationships;
			let stateDataRelationshipsMatchingArticles = state.data.relationships.matching_articles;

			return {
				...state,
				loading: false,
				matchingArticlesPagination: action.result.links.next,
				data: {
					...stateData,
					relationships: {
						...stateDataRelationships,
						matching_articles: {
							...stateDataRelationshipsMatchingArticles,
							data: state.data.relationships.matching_articles.data.concat(action.result.data),
						},
					},
				},
			};
		}

		case LOAD_FAIL: {
			console.log(action.error);
			return {
				...state,
				loading: false,
				loaded: false,
				errors: (state.errors || []).concat([action.error]),
			};
		}

		case PATCH: {
			return {
				...state,
				saving: true,
			};
		}

		case PATCH_SUCCESS: {
			return {
				...state,
				saving: false,
				saved: true,
			};
		}

		case PATCH_FAIL: {
			return {
				...state,
				saving: false,
				errors: (state.errors || []).concat([action.error]),
			};
		}

		default: {
			return state;
		}
	}
}

export function isLoaded(globalState) {
	return globalState.teaserBoard && globalState.teaserBoard.loaded;
}

export function rearrange(layoutType, pinnedArticles) {
	return {
		type: REARRANGE,
		layoutType,
		pinnedArticles,
	};
}

const articleFields =
	'attributes.content.title,' +
	'attributes.content.headline,' +
	'attributes.content.teaser_image,' +
	'attributes.content.teaser_element,' +
	'attributes.metadata.doc.status,' +
	'attributes.metadata.redirect,' +
	'attributes.metadata.tags';

export function load(params) {
	const url = `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.teaserBoard}`;

	if (params && typeof params.id !== 'undefined') {
		// load requested teaserBoard
		let teaserBoardUrl = `${url}/${params.id}`;
		teaserBoardUrl += '?include=pinned_articles,matching_articles';
		teaserBoardUrl += '&fields[article]=' + articleFields;
		teaserBoardUrl += '&limit[matching_articles]=9';
		return {
			types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
			promise: client => resolveAllPromisedRelationships(client.get(teaserBoardUrl, { headers: { Accept: 'application/json' } })),
		};
	} else {
		if (!IS_CMS) {
			// move back to dashboard	in app
			// return to teaserboard or emit 404
			console.warn('no handler set for app and empty article id'); //eslint-disable-line
		} else {
			// resolve with a blank teaserboard for creating a new one
			return {
				types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
				promise: () => Promise.resolve(createEmptyTeaserBoard()),
			};
		}
	}
}

export function loadMoreArticles(paginationNextLink) {
	paginationNextLink = paginationNextLink.replace(/(limit\[matching_articles\]=)\d+/, 'limit[matching_articles]=20');
	return {
		types: [null, LOAD_MORE_ARTICLES_SUCCESS, null],
		promise: client => client.get(paginationNextLink + '&fields[article]=' + articleFields, { headers: { Accept: 'application/json' } }),
	};
}

export function update(patch, id) {
	const url = `${config.ORIGIN_COFFEEMACHINE}${config.API_ENDPOINT.teaserBoard}/${id}`;

	return {
		types: [PATCH, PATCH_SUCCESS, PATCH_FAIL],
		promise: client => client.patch(`${url}`, { data: patch }),
	};
}
