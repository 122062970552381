import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import styles from './SeekControl.scss';

export default class SeekControl extends React.Component {
	state = {
		dragging: false,
	};

	seekPosition = e => {
		// eslint-disable-next-line react/no-find-dom-node,react/no-string-refs
		let rail = ReactDOM.findDOMNode(this.refs.rail);
		let position = (e.clientX - rail.getBoundingClientRect().left) / rail.offsetWidth;
		position = Math.min(position, 1);
		position = Math.max(position, 0);
		this.props.onChange(position);
	};

	onMouseDown = e => {
		this.setState({
			dragging: true,
		});
		this.seekPosition(e);
	};

	onMouseUp = () => {
		this.setState({
			dragging: false,
		});
	};

	onMouseMove = e => {
		if (this.state.dragging) {
			this.seekPosition(e);
		}
	};

	onMouseLeave = () => {
		this.setState({
			dragging: false,
		});
	};

	render = () => {
		return (
			<div
				// eslint-disable-next-line react/no-string-refs
				ref="rail"
				className={classNames(styles.rail)}
				onMouseDown={this.onMouseDown}
				onMouseMove={this.onMouseMove}
				onMouseUp={this.onMouseUp}
				onMouseLeave={this.onMouseLeave}
			>
				<div className={classNames(styles.filled)} style={{ width: this.props.position * 100 + '%' }} />
			</div>
		);
	};
}
