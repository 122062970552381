import SVGIcon from '../SVGIcon';
import React from 'react';

export default function LocationIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#3C3C3B">
				<circle cx="16" cy="16" r="2.734" />
				<path d="M24.436 15.5h-2.062c-.009 0-.017.004-.025.005a6.376 6.376 0 0 0-5.854-5.854c0-.008.005-.016.005-.024V7.563a.5.5 0 0 0-1 0v2.063c0 .009.005.017.005.024a6.377 6.377 0 0 0-5.854 5.854c-.009 0-.016-.004-.025-.004H7.563a.5.5 0 0 0 0 1h2.062c.009 0 .017-.004.025-.005a6.376 6.376 0 0 0 5.854 5.854c0 .009-.005.017-.005.025v2.062a.5.5 0 0 0 1 0v-2.062c0-.009-.005-.017-.005-.025a6.376 6.376 0 0 0 5.854-5.854c.009.001.017.005.025.005h2.062a.5.5 0 0 0 .001-1zM16 21.374c-2.956 0-5.374-2.418-5.374-5.374 0-2.955 2.418-5.374 5.374-5.374 2.955 0 5.374 2.419 5.374 5.374 0 2.956-2.419 5.374-5.374 5.374z" />
			</g>
		</SVGIcon>
	);
}
