import * as config from '../../config.webapp.ts';
import { createReducer } from './_defaults';

const LOAD = 'br3/traffic/LOAD';
const LOAD_SUCCESS = 'br3/traffic/LOAD_SUCCESS';
const LOAD_FAIL = 'br3/traffic/LOAD_FAIL';

const initialState = {
	loaded: false,
};

const serviceUrl = config.SERVICE.traffic;

export default createReducer(initialState, LOAD, LOAD_SUCCESS, LOAD_FAIL);

export function isLoaded(globalState) {
	return globalState.traffic && globalState.traffic.loaded;
}

export function load() {
	return {
		types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
		promise: client => client.get(`${serviceUrl}`, { headers: { Accept: 'application/json' } }),
	};
}
