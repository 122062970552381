import React, { Component } from 'react';

import styles from './BusyIndicator.scss';

let singleton = null;

export class BusyIndicator extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		if (singleton) {
			console.warn("You shouldn't have more than one instace of BusyIndicator mounted!");
		} else {
			singleton = this;
		}
	}

	render() {
		return <div className={styles.busyIndicator}>hello this is my indicator</div>;
	}
}

export class BusyIndicatorManager {}
