import React, { useState } from 'react';
import { PlayerHeader } from '../../br3-components';
import ErrorBoundary from '../../br3-components/components/ErrorBoundary/ErrorBoundary';
import { IPlaylist, usePlaylist } from '../../helpers/teapot';

interface IProps {
	playlist: IPlaylist;
	socketData: IPlaylist & { connected: boolean };
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	navigation: any;
	loadPlaylist: () => Promise<IPlaylist>;
}

const HeaderConnector: React.SFC<IProps> = ({ playlist, navigation, loadPlaylist }: IProps) => {
	const [currentPlaylist, setCurrentPlaylist] = useState(playlist);

	function updateState(arg: React.SetStateAction<IPlaylist>) {
		setCurrentPlaylist(arg);
	}

	usePlaylist({
		asyncFallback: loadPlaylist,
		update: updateState,
	});

	return (
		<ErrorBoundary>
			<PlayerHeader navigation={navigation} playlist={currentPlaylist} />
		</ErrorBoundary>
	);
};

export default HeaderConnector;
