import React from 'react';
import classNames from 'classnames';
import styles from './TeaserBox.scss';
import moment from 'moment';
import trackEvent from '../../commons/tracking/trackers';

import SvgFacebook from './icon_b3_facebook.svg';
import SvgTwitter from './icon_b3_twitter.svg';
import SvgEmail from './icon_b3_email.svg';
import SvgWhatsapp from './icon_b3_whatsapp.svg';

export default class TeaserBoxAMP extends React.Component {
	static defaultProps = {
		legal: '',
		date: new Date(),
		text: null,
		htmlText: null,
		articleId: '',
		headline: '',
		title: '',
	};

	state = {
		isComponentMounted: false,
	};

	trackEvent(service) {
		trackEvent('TeaserBox', service, document.title);
	}

	render() {
		let baseURL = 'https://bayern3.de/';
		let shareURL = baseURL + this.props.articleId;

		let shareText = this.props.headline + ' | ' + this.props.title;

		let text = this.props.htmlText || (this.props.text && `<p>${this.props.text}</p>`) || '';
		text = text.replace(/style/gim, 'data-style');

		let textElement = <div data-class="teasertext" className={classNames(styles.teasertext)} dangerouslySetInnerHTML={{ __html: text }} />;

		return (
			<div data-class="teaserboxContent" className={classNames(styles.teaserboxContent)}>
				<div data-class="metabox" className={classNames(styles.metabox)}>
					<div data-class="metaPrimary" className={styles.metaPrimary}>
						<a
							onClick={this.trackEvent.bind(this, 'facebook')}
							href={'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareURL)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgFacebook className={classNames(styles.shareButton)} />
						</a>
						<a
							onClick={this.trackEvent.bind(this, 'twitter')}
							href={'https://twitter.com/intent/tweet?text=' + encodeURIComponent(shareText) + '&url=' + encodeURIComponent(shareURL)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgTwitter className={classNames(styles.shareButton)} />
						</a>
						<a
							onClick={this.trackEvent.bind(this, 'mail')}
							href={
								'mailto:?subject=' +
								encodeURIComponent(shareText) +
								'&body=' +
								encodeURIComponent(shareText) +
								' ' +
								encodeURIComponent(shareURL)
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgEmail className={classNames(styles.shareButton)} />
						</a>
						<a
							onClick={this.trackEvent.bind(this, 'whatsapp')}
							href={'whatsapp://send?text=' + encodeURIComponent(shareText) + '%20' + encodeURIComponent(shareURL)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgWhatsapp className={classNames(styles.shareButton, styles.shareWhatsApp)} />
						</a>
					</div>

					<div data-class="metaSecondary" className={styles.metaSecondary}>
						<p data-class="legal" className={classNames(styles.legal)}>
							{this.props.legal}
						</p>
						<time data-class="time" className={classNames(styles.time)}>
							{moment(this.props.date).format('DD.MM.YYYY')}
						</time>
					</div>
				</div>
				{textElement}
			</div>
		);
	}
}
