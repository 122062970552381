import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SVGIcon extends Component {
	static propTypes = {
		// color     : React.PropTypes.string,
		// hoverColor: React.PropTypes.string,
		viewBox: PropTypes.string,
	};

	static defaultProps = {
		viewBox: '0 0 32 32',
	};

	render() {
		const {
			children,
			style,
			viewBox,
			// color,
			// hoverColor,
			...other
		} = this.props;

		return (
			<svg {...other} style={style} viewBox={viewBox}>
				{children}
			</svg>
		);
	}
}
