import * as config from '../../config.webapp.ts';

const LOAD = 'br3/playlist/LOAD';
const LOAD_SUCCESS = 'br3/playlist/LOAD_SUCCESS';
const LOAD_FAIL = 'br3/playlist/LOAD_FAIL';

const initialState = {
	loaded: false,
	music: [],
	program: [],
};

const serviceUrl = config.SERVICE.playlist;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case LOAD:
			return {
				...state,
				loading: true,
			};

		case LOAD_SUCCESS:
			return {
				...state,
				loaded: true,
				loading: false,
				music: action.result.music || [],
				program: action.result.program || [],
			};
		case LOAD_FAIL:
			return {
				...state,
				loaded: state.loaded,
				loading: false,
				error: action.error,
			};
		default:
			return state;
	}
}

export function isLoaded(globalState) {
	return globalState.playlist && globalState.playlist.loaded;
}

export function load() {
	return {
		types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
		promise: client => client.get(`${serviceUrl}`, { headers: { Accept: 'application/json' } }),
	};
}
