import SVGIcon from '../SVGIcon';
import React from 'react';

export default function OrkanNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#A03416">
				<path d="M29.333 11.248c6.495 6.981-7.273 7.184-17.541 7.272l.635-1.514-12.429 3.471 8.133 6.764.944-2.248c13.945-1.52 29.201-6.247 20.258-13.745z" />
				<path d="M13.168 15.043C23.58 13.902 35.012 10.357 28.42 4.76c4.894 5.219-5.495 5.416-13.244 5.463l.449-1.078-9.321 2.561 6.123 5.117.741-1.78z" />
			</g>
		</SVGIcon>
	);
}
