/* global YT */
import React from 'react';
import classNames from 'classnames';
import styles from './YouTubePlayer.scss';
import { isMobile } from '../../commons/detectMobile';

export default class YouTubePlayer extends React.Component {
	state = {
		youTubePlayer: null,
		buffering: false,
	};

	componentDidMount() {
		// on mobile devices the javascript sdk isn't needed since only iframes are used
		if (!isMobile()) {
			if (!document.querySelector('#youtubeAPI')) {
				let tag = document.createElement('script');
				tag.id = 'youtubeAPI';
				tag.src = 'https://www.youtube.com/iframe_api';
				let insertNode = document.getElementsByTagName('script')[0];
				insertNode.parentNode.insertBefore(tag, insertNode);
				window.onYouTubeIframeAPIReady = this.createYouTubePlayer.bind(this);
			} else {
				this.createYouTubePlayer();
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			// significant position change
			if (
				this.state.youTubePlayer &&
				this.props.position &&
				Math.abs(this.props.position * this.state.youTubePlayer.getDuration() - this.state.youTubePlayer.getCurrentTime()) > 1.0
			) {
				this.state.youTubePlayer.seekTo(this.props.position * this.state.youTubePlayer.getDuration());
			}

			// toggle play/pause
			if (
				this.state.youTubePlayer &&
				this.state.youTubePlayer.getPlayerState &&
				this.props.playing !== (this.state.youTubePlayer.getPlayerState() === 1)
			) {
				this.props.playing ? this.state.youTubePlayer.playVideo() : this.state.youTubePlayer.pauseVideo();
			}
		}
	}

	componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = null;
		}
		if (this.state.youTubePlayer) {
			this.state.youTubePlayer.destroy();
		}
	}

	setVolume(volume) {
		this.state.youTubePlayer.setVolume(volume * 100);
	}

	createYouTubePlayer() {
		let player = new YT.Player(this.props.videoId, {
			playerVars: {
				autoplay: 1,
				controls: 0,
				rel: 0,
				showinfo: 0,
				disablekb: 1,
				iv_load_policy: 3,
				start: this.props.startTime || 0,
			},
			controls: false,
			videoId: this.props.videoId,
			events: {
				onReady: this.onReady.bind(this),
				onStateChange: this.onPlayerStateChange.bind(this),
				onError: this.props.onError.bind(this),
			},
		});

		this.setState({
			youTubePlayer: player,
		});
	}

	onPlayerStateChange(e) {
		if (e.data === 1) {
			if (this.state.buffering) {
				this.props.onBufferingChange(false);
				this.setState({
					buffering: false,
				});
			}

			//playing
			this.timer = setInterval(this.onPositionChange.bind(this), 500);
			return;
		} else if (e.data === 0) {
			this.props.onEnded();
		} else if (e.data === 3) {
			this.props.onBufferingChange(true);
			this.setState({
				buffering: true,
			});
		}

		clearInterval(this.timer);
		this.timer = null;
	}

	onReady() {
		this.props.onDurationChange(this.state.youTubePlayer.getDuration());
	}

	onPositionChange() {
		this.props.onPositionChange(this.state.youTubePlayer.getCurrentTime() / this.state.youTubePlayer.getDuration());
	}

	fullscreen() {
		const player = document.getElementById(this.props.videoId);
		if (player) {
			let requestFullScreen =
				player.requestFullscreen || player.mozRequestFullScreen || player.webkitRequestFullScreen || player.msRequestFullscreen;
			if (requestFullScreen) {
				requestFullScreen.bind(player)();
			}
		}
	}

	render() {
		if (isMobile()) {
			return (
				<iframe
					id={this.props.videoId}
					className={classNames(styles.youTubePlayer)}
					width="100%"
					src={`https://www.youtube-nocookie.com/embed/${this.props.videoId}?rel=0&amp;showinfo=0`}
					frameBorder="0"
					allowFullScreen
				/>
			);
		} else {
			return (
				<div>
					<div className={classNames(styles.playeroverlay)} />
					<div id={this.props.videoId} className={classNames(styles.youTubePlayer)} />
				</div>
			);
		}
	}
}
