import React from 'react';
import ReactDOM from 'react-dom';

const WIDTH = 85;
const HEIGHT = 48;

export default class SpectrumAnalyzer extends React.Component {
	peaks = [0, 0, 0, 0, 0, 0, 0, 0];
	update = [0, 0, 0, 0, 0, 0, 0, 0];
	frame = 0;

	paint = fd => {
		// eslint-disable-next-line react/no-find-dom-node
		let context = ReactDOM.findDOMNode(this).getContext('2d');
		context.clearRect(0, 0, WIDTH, HEIGHT);
		context.fillStyle = '#A1BF36';
		context.strokeStyle = '#A1BF36';

		if (!fd) {
			fd = [0, 0, 0, 0, 0, 0, 0, 0];
		}

		for (let i = 0; i < fd.length; i++) {
			let amplitude = fd[i] / (255 / (HEIGHT - 4));
			let height = HEIGHT - 2 - amplitude;
			context.fillRect(12 * i + 5, height, 2, amplitude);

			if (fd[i] >= this.peaks[i]) {
				this.peaks[i] = fd[i];
				this.update[i] = this.frame;
			} else {
				if (this.update[i] < this.frame - 60) {
					this.peaks[i] *= 0.95;
				}
			}
			amplitude = this.peaks[i] / (255 / (HEIGHT - 4));
			height = HEIGHT - 2 - amplitude;
			context.beginPath();
			context.arc(12 * i + 6, height, 2, 0, 2 * Math.PI);
			context.fill();
			context.closePath();
		}

		this.frame++;
	};

	render = () => {
		return <canvas width={WIDTH} height={HEIGHT} />;
	};
}
