import logger from 'loglevel';
import fetch from 'cross-fetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let authClient: any;
if (IS_CMS) {
	authClient = require('../br3-components/components/Login2FA/authClient').authClient;
}

const log = logger.getLogger('ApiClient');

/**
 * Enum with possible http request methods.
 */
enum RequestMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	PATCH = 'PATCH',
	DEL = 'DELETE',
}

/**
 * Interface for ApiClient request options.
 */
export interface IRequestOptions {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	params?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	headers?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data?: any;
}

/**
 * Client to send http requests.
 */
export default class ApiClient {
	get(path: string, options?: IRequestOptions): Promise<string | object> {
		return this.request(RequestMethod.GET, path, options);
	}

	post(path: string, options?: IRequestOptions): Promise<string | object> {
		return this.request(RequestMethod.POST, path, options);
	}

	put(path: string, options?: IRequestOptions): Promise<string | object> {
		return this.request(RequestMethod.PUT, path, options);
	}

	patch(path: string, options?: IRequestOptions): Promise<string | object> {
		return this.request(RequestMethod.PATCH, path, options);
	}

	del(path: string, options?: IRequestOptions): Promise<string | object> {
		return this.request(RequestMethod.DEL, path, options);
	}

	/**
	 * Sends a http request and returns the response as Promise.
	 * @param method HTTP method
	 * @param path url to call
	 * @param options request options like headers, payload and search params
	 */
	protected request(method: RequestMethod, path: string, options?: IRequestOptions): Promise<string | object> {
		log.debug(`Requesting ${method} ${path}`, options);

		return new Promise((resolve, reject) => {
			const url = new URL(path);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const reqInit: any = {
				method,
			};

			if (options) {
				if (options.params) {
					Object.keys(options.params).forEach(key => url.searchParams.set(key, options.params[key]));
				}
				if (options.headers) {
					reqInit.headers = options.headers;
				}
				if (options.data) {
					let body = options.data;
					if (typeof body === 'object') {
						// content is a json object
						const contentHeader = { 'Content-Type': 'application/json' };
						reqInit.headers = reqInit.headers ? { ...reqInit.headers, ...contentHeader } : contentHeader;
						body = JSON.stringify(body);
					}
					reqInit.body = body;
				}
			}

			if (IS_CMS) {
				// set status to published as default
				if (!url.searchParams.has('status')) {
					url.searchParams.set('status', 'published');
				}

				const authHeader = { Authorization: `Bearer ${authClient.getUser().credentials.accessToken}` };
				reqInit.headers = reqInit.headers ? { ...reqInit.headers, ...authHeader } : authHeader;
				reqInit.credentials = 'include';
			}

			let requestOk = true;
			fetch(url.toString(), reqInit)
				.then(res => {
					requestOk = res.ok;
					if (res.headers.has('content-type') && res.headers.get('content-type')!.indexOf('json') !== -1) {
						return res.json();
					}
					return res.text();
				})
				.then(data => (requestOk ? resolve(data) : reject(data)))
				.catch(error => {
					log.debug(`Error requesting ${path}`, error.message || error);
					reject(error);
				});
		});
	}
}
