import React from 'react';
import styles from './VolumeControl.scss';
import ReactSlider from 'react-slider';
import SvgVolume from './volume_volume.svg';

export default class VolumeControl extends React.Component {
	state = {
		visible: false,
		volume: 0,
	};

	toggleControl = () => {
		this.setState({
			visible: !this.state.visible,
		});
	};

	onChange = value => {
		this.props.onChange((100 - value) / 100);
		this.setState({
			volume: value,
		});
	};

	onMouseLeave = () => {
		this.setState({
			visible: false,
		});
	};

	render() {
		return (
			<button className={styles.volumeButton} onMouseLeave={this.onMouseLeave}>
				<SvgVolume className={styles.button} onClick={this.toggleControl} />
				{this.state.visible && (
					<div className={styles.volumeControl}>
						<ReactSlider
							min={0}
							max={100}
							defaultValue={this.state.volume}
							onChange={this.onChange}
							orientation="vertical"
							snapDragDisabled
						/>
					</div>
				)}
			</button>
		);
	}
}
