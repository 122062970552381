import PropTypes from 'prop-types';
import React from 'react';
// @ts-ignore
import NestedStatus from 'react-nested-status';
import TeaserBoardTile from './TeaserBoardTile.js';
import TeaserBoardDropTargetTile from './TeaserBoardDropTargetTile.js';
import classNames from 'classnames';
import styles from './TeaserBoard.scss';

export const definedLayoutTypes = ['A', 'B', 'C'];
export const nTeasersForLayoutType = { A: 5, B: 5, C: 6, grid: null };

export default class TeaserBoard extends React.Component {
	static propTypes = {
		layoutType: PropTypes.oneOf(['A', 'B', 'C', 'grid']).isRequired,
		teasers: PropTypes.array.isRequired,
	};

	handleTileChange = (index, newArticle) => this.props.onChange(index, newArticle);

	render() {
		let inflatedTeasers = [];
		for (let i = 0; i < (nTeasersForLayoutType[this.props.layoutType] || this.props.teasers.length); i++) {
			inflatedTeasers[i] = this.props.teasers[i] || null;
		}

		// assumes that we have the correct number of teasers for this layout type
		return (
			<NestedStatus code={200}>
				<div className={classNames(styles.board, styles[this.props.layoutType], styles[this.props.device])} style={this.props.style}>
					{inflatedTeasers.map((teaser, i) => {
						let tileProperties = {
							title: teaser && teaser.attributes.content.title,
							headline: teaser && teaser.attributes.content.headline,
							image: teaser && teaser.attributes.content.teaser_image.content,
							link: teaser && (teaser.attributes.metadata.redirect ? teaser.attributes.metadata.redirect : '/' + teaser.id),
							isPlayer: teaser && teaser.attributes.content.teaser_element.element_type === 'media',
							key: 'tb_tile_' + i,
						};
						return this.props.onChange ? (
							<TeaserBoardDropTargetTile
								key={i}
								index={i}
								className={styles.tileContainer}
								onChange={this.handleTileChange.bind(null, i)}
								teaser={teaser}
							/>
						) : (
							<TeaserBoardTile key={i} container={true} {...tileProperties} />
						);
					})}
					{this.props.children}
				</div>
			</NestedStatus>
		);
	}
}
