import React from 'react';
import classNames from 'classnames';
import styles from './StaticPages.scss';

/**
 * Displays the imprint.
 */
export default class Imprint extends React.Component {
	render() {
		return (
			<div className={classNames(styles.imprint, 'container')}>
				<main>
					<div className={classNames(styles.headline)}>
						<h1>Impressum</h1>
					</div>

					<p>
						<strong>Herausgeber</strong>
						<br />
						Bayerischer Rundfunk
						<br />
						Anstalt des öffentlichen Rechts
						<br />
						Rundfunkplatz 1<br />
						80335 München
						<br />
						Telefon: 089 / 59 00-01
					</p>

					<div className={classNames(styles.content)}>
						<div className="row">
							<label>Gesetzlicher Vertreter:</label>
							<p>Intendantin Dr. Katja Wildermuth</p>
						</div>
						<div className="row">
							<label>Programmbereichsleiter:</label>
							<p>Walter Schmich</p>
						</div>
						<div className="row">
							<label>Kontakt:</label>
							<p>
								<a href="mailto:studio@bayern3.de">studio@bayern3.de</a>
							</p>
						</div>
						<div className="row">
							<label>Umsatzsteueridentifikationsnummer:</label>
							<p>DE 129 523 494</p>
						</div>
					</div>
				</main>
			</div>
		);
	}
}
