import PropTypes from 'prop-types';
import React from 'react';
import idx from 'idx';

export default class ContentElementImageAMP extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		ratio: PropTypes.number,
		headline: PropTypes.string,
		isTeaser: PropTypes.bool,
		data: PropTypes.object,
	};

	static defaultProps = {
		data: {
			element_type: 'gallery',
			content: {
				images: [],
			},
		},
		isTeaser: false,
	};

	state = {
		currentImage: idx(this.props, _ => _.data.content.images.length) > 0 ? 0 : -1,
		isComponentMounted: false,
	};

	render = () => {
		if (this.props.data.content.images.length == 1) {
			if (!this.props.isTeaser) {
				return (
					<amp-carousel controls layout="responsive" type="slides" width="1920" height="1440">
						<div className="slide">
							<amp-img src={this.props.data.content.images[0].content.source + '&q=70'} width="1920" height="960" layout="responsive" />
							<amp-fit-text layout="responsive" width="1920" height="480" max-font-size="16">
								<p>
									<small>
										<i>{this.props.data.content.images[0].metadata.copyright}</i>
									</small>
									<br />
									{this.props.data.content.images[0].metadata.title}
								</p>
							</amp-fit-text>
						</div>
					</amp-carousel>
				);
			} else {
				return (
					<div>
						<amp-img src={this.props.data.content.images[0].content.source + '&q=70'} width="1920" height="960" layout="responsive" />
						<div data-class="titleBox" className="titleBox">
							<h2 dangerouslySetInnerHTML={{ __html: this.props.headline }} />
							<h1 dangerouslySetInnerHTML={{ __html: this.props.title }} />
						</div>
					</div>
				);
			}
		} else {
			if (this.props.isTeaser) {
				return (
					<amp-carousel controls layout="responsive" type="slides" width="1920" height="960">
						{this.props.data.content.images.map((image, index) => (
							<div className="slide" key={index}>
								<amp-img src={image.content.source + '&q=70'} width="1920" height="960" layout="fill" />
								<div className="caption">
									{image.metadata.copyright}
									<br />
									<p>{image.metadata.title}</p>
								</div>
							</div>
						))}
					</amp-carousel>
				);
			} else {
				return (
					<amp-carousel controls layout="responsive" type="slides" width="1920" height="1920">
						{this.props.data.content.images.map((image, index) => (
							<div className="slide" key={index}>
								<amp-img src={image.content.source + '&q=70'} width="1920" height="960" layout="responsive" />
								<amp-fit-text layout="responsive" width="1920" height="960" max-font-size="16">
									<p>
										<small>
											<i> {image.metadata.copyright}</i>
										</small>
										<br />
										{image.metadata.title}
									</p>
								</amp-fit-text>
							</div>
						))}
					</amp-carousel>
				);
			}
		}
	};
}
