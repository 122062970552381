import React from 'react';
import { Route, Switch } from 'react-router';
import ArticleConnector from './views/ArticleConnector';
import TrafficViewConnector from './views/TrafficViewConnector';
import WeatherViewConnector from './views/WeatherViewConnector';
import NewsViewConnector from './views/NewsViewConnector';
import TeaserBoardConnector from './views/TeaserBoardConnector';
import { Webcam, Error404, Imprint } from '../br3-components';
import { withPrefetch } from '../helpers/utils';
import PrivacyPolicyViewConnector from './views/PrivacyPolicyViewConnector';

export default (
	<Switch>
		<Route exact path="/" component={withPrefetch(TeaserBoardConnector)} />
		<Route path="/verkehr/android" component={withPrefetch(TrafficViewConnector)} />
		<Route path="/verkehr" component={withPrefetch(TrafficViewConnector)} />
		<Route path="/webcam" component={withPrefetch(Webcam)} />
		<Route path="/wetter/android" component={withPrefetch(WeatherViewConnector)} />
		<Route path="/wetter" component={withPrefetch(WeatherViewConnector)} />
		<Route path="/impressum" component={withPrefetch(Imprint)} />
		<Route path="/datenschutz" component={withPrefetch(PrivacyPolicyViewConnector)} />
		<Route path="/nachrichten/android" component={withPrefetch(NewsViewConnector)} />
		<Route path="/nachrichten" component={withPrefetch(NewsViewConnector)} />
		<Route path="/r/:id" component={withPrefetch(TeaserBoardConnector)} />
		<Route path="/:id/amp" component={withPrefetch(ArticleConnector)} />
		<Route path="/:id/android" component={withPrefetch(ArticleConnector)} />
		<Route path="/:id/ia" component={withPrefetch(ArticleConnector)} />
		<Route path="/:id" component={withPrefetch(ArticleConnector)} />
		<Route path="*" component={withPrefetch(Error404)} />
	</Switch>
);
