import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PlayerPlayTransparentIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle opacity=".8" fill="#FFF" cx="16" cy="15.998" r="16" />
			<path
				fill="#3C3C3B"
				d="M21.775 15.549l-8.634-5.592a.534.534 0 0 0-.827.45v11.186c0 .197.109.379.28.473a.537.537 0 0 0 .547-.022l8.634-5.594a.536.536 0 0 0 0-.901z"
			/>
		</SVGIcon>
	);
}
