import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterRegenSonnigIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.105 11.392c1.134 1.932.484 4.432-1.448 5.567s-4.432.484-5.567-1.448a4.066 4.066 0 1 1 7.015-4.119zM8.626 8.586l-1.295.761-2.724-4.641 1.295-.76zm-2.172 1.639l-.742 1.306-4.684-2.663.742-1.306zM5.394 12.719l.011 1.503-5.377.037-.011-1.503zm10.4 1.425l-.012-1.502 5.377-.037.012 1.502zM5.727 15.412l.76 1.295-4.64 2.725-.761-1.295zm9.717-3.965l-.761-1.295 4.641-2.724.76 1.295zM13.801 9.292l-1.307-.742 2.652-4.678 1.307.743zm-2.516-1.048l-1.503.012-.037-5.377 1.503-.011z"
			/>
			<path
				fill="#0971B8"
				d="M3.85 23.742c.275-.458.275-1.187.275-1.827 0-.729.091-1.37.091-1.37s-.366.457-1.005.823c-.548.364-1.371.638-1.553 1.004-.365.64-.183 1.37.457 1.735.547.458 1.369.184 1.735-.365zm2.376 4.295c.274-.457.274-1.187.274-1.828 0-.731.091-1.37.091-1.37s-.366.455-1.005.821c-.549.366-1.371.64-1.553 1.006-.366.64-.183 1.37.457 1.736.547.458 1.37.184 1.736-.365zm5.207-1.462c.275-.457.275-1.189.275-1.827 0-.731.091-1.37.091-1.37s-.366.456-1.006.821c-.547.366-1.37.64-1.551 1.004-.366.64-.184 1.372.456 1.738.639.457 1.37.272 1.735-.366z"
			/>
			<path
				fill="#FFF"
				d="M21.393 15.842c-1.125 0-2.051-.925-2.051-2.05s.925-2.051 2.051-2.051c.163 0 .313.013.475.05l.3.075.075-.288a3.385 3.385 0 0 1 3.263-2.501 3.372 3.372 0 0 1 3.263 2.501l.075.288.3-.075c.15-.037.313-.05.475-.05 1.125 0 2.051.925 2.051 2.051s-.925 2.05-2.051 2.05h-8.226z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.506 9.378c1.388 0 2.601.938 2.963 2.275l.163.588.588-.137c.137-.025.263-.05.4-.05.963 0 1.738.775 1.738 1.738s-.775 1.738-1.738 1.738h-8.227a1.734 1.734 0 0 1-1.738-1.738 1.734 1.734 0 0 1 2.138-1.688l.588.137.163-.588a3.076 3.076 0 0 1 2.962-2.275m0-.625a3.68 3.68 0 0 0-3.563 2.738 2.794 2.794 0 0 0-.55-.063 2.37 2.37 0 0 0-2.363 2.363 2.37 2.37 0 0 0 2.363 2.363h8.227a2.37 2.37 0 0 0 2.363-2.363 2.37 2.37 0 0 0-2.913-2.3 3.681 3.681 0 0 0-3.564-2.738z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.143 12.604a.33.33 0 0 1-.118-.023l-1.225-.5a.311.311 0 0 1-.171-.407.311.311 0 0 1 .407-.171l1.225.5a.311.311 0 0 1-.118.601z"
			/>
			<path
				fill="#C9C8C8"
				d="M8.937 21.896a3.563 3.563 0 0 1-3.557-3.557 3.563 3.563 0 0 1 3.557-3.557c.276 0 .551.038.827.1l.476.113.125-.463a5.844 5.844 0 1 1 11.296-.025l.125.463.476-.113c.276-.063.551-.1.827-.1a3.563 3.563 0 0 1 3.557 3.557 3.562 3.562 0 0 1-3.557 3.556H8.937v.026z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.012 10.676a5.362 5.362 0 0 1 5.172 3.957l.25.939.939-.225c.238-.05.463-.088.701-.088a3.06 3.06 0 0 1 3.056 3.056 3.06 3.06 0 0 1-3.056 3.056H8.924a3.06 3.06 0 0 1-3.056-3.056 3.06 3.06 0 0 1 3.056-3.056c.238 0 .476.025.701.088l.939.225.25-.939a5.416 5.416 0 0 1 5.198-3.957m0-1.002a6.348 6.348 0 0 0-6.136 4.696 3.87 3.87 0 0 0-.939-.113 4.056 4.056 0 0 0-4.057 4.058 4.056 4.056 0 0 0 4.057 4.057h14.151a4.056 4.056 0 0 0 4.057-4.057 4.057 4.057 0 0 0-4.057-4.058c-.326 0-.639.038-.939.113a6.35 6.35 0 0 0-6.137-4.696z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.641 16.111a.448.448 0 0 1-.174-.036l-1.615-.701a.44.44 0 0 1 .349-.805l1.615.701a.44.44 0 0 1-.175.841z"
			/>
			<g>
				<path
					fill="#C9C8C8"
					d="M19.93 19.813c-.091-.091-.182-.091-.274-.091l-3.929 1.006 2.649-5.118v-.273a.196.196 0 0 0-.183-.183l-2.102-.731c-.183-.092-.365 0-.365.183l-2.194 6.03-.638 1.736-.183.548c0 .092 0 .181.091.273.092.091.183.091.274.091l3.472-.912h.092l-2.376 6.122v.275c0 .091.092.091.183.183l.548.181h.092c.092 0 .183-.092.274-.181l3.471-6.488.915-1.735.365-.64c-.091-.001-.091-.184-.182-.276z"
				/>
			</g>
			<g>
				<path
					fill="#A03416"
					d="M19.93 19.813c-.091-.091-.182-.091-.274-.091l-3.929 1.006 2.649-5.118v-.273a.196.196 0 0 0-.183-.183l-2.102-.731c-.183-.092-.365 0-.365.183l-2.194 6.03-.638 1.736-.183.548c0 .092 0 .181.091.273.092.091.183.091.274.091l3.472-.912h.092l-2.376 6.122v.275c0 .091.092.091.183.183l.548.181h.092c.092 0 .183-.092.274-.181l3.471-6.488.915-1.735.365-.64c-.091-.001-.091-.184-.182-.276z"
				/>
			</g>
		</SVGIcon>
	);
}
