import SVGIcon from '../SVGIcon';
import React from 'react';

export default function FastWolkenlosNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.142 19.486a7.698 7.698 0 0 1-6.381 3.369c-4.274 0-7.732-3.458-7.732-7.732s3.458-7.732 7.732-7.732c.445 0 .89.044 1.321.119a6.688 6.688 0 0 0-2.671 5.357 6.71 6.71 0 0 0 6.708 6.708 10.82 10.82 0 0 0 1.023-.089zm4.363-11.397l-.044 2.79 2.716-.653-1.811 2.107 2.493 1.247-2.76.445 1.113 2.552-2.389-1.424-.787 2.671-.92-2.627-2.33 1.544.994-2.612-2.775-.312 2.434-1.365-1.914-2.018 2.73.519-.178-2.775 1.766 2.152z"
			/>
			<path
				fill="#FFF"
				d="M19.419 24.239a2.441 2.441 0 0 1-2.43-2.43 2.442 2.442 0 0 1 2.43-2.431c.193 0 .371.015.563.059l.356.089.089-.341c.474-1.749 2.06-2.964 3.868-2.964s3.409 1.215 3.868 2.964l.089.341.356-.089a2.37 2.37 0 0 1 .563-.059 2.441 2.441 0 0 1 2.43 2.431 2.441 2.441 0 0 1-2.43 2.43h-9.752z"
			/>
			<path
				fill="#4F4E4E"
				d="M24.295 16.577a3.645 3.645 0 0 1 3.512 2.697l.193.697.697-.163c.163-.03.311-.059.474-.059 1.141 0 2.06.919 2.06 2.06s-.919 2.06-2.06 2.06h-9.752a2.056 2.056 0 0 1-2.06-2.06c0-1.141.919-2.06 2.06-2.06.163 0 .326.015.474.059l.697.163.193-.697a3.646 3.646 0 0 1 3.512-2.697m0-.741a4.362 4.362 0 0 0-4.224 3.245 3.291 3.291 0 0 0-.652-.074 2.809 2.809 0 0 0-2.801 2.801 2.809 2.809 0 0 0 2.801 2.801h9.752a2.809 2.809 0 0 0 2.801-2.801 2.809 2.809 0 0 0-3.453-2.727 4.362 4.362 0 0 0-4.224-3.245z"
			/>
			<path
				fill="#4F4E4E"
				d="M21.494 20.401a.364.364 0 0 1-.14-.028l-1.452-.593a.371.371 0 0 1 .28-.686l1.452.593a.371.371 0 0 1-.14.714z"
			/>
		</SVGIcon>
	);
}
