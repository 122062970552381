import SVGIcon from '../SVGIcon';
import React from 'react';

export default function BurgerWhiteIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="16" cy="16" r="15.999" />
			<path
				fill="#3C3C3B"
				d="M10.777 11.169h10.446a.51.51 0 1 0 0-1.019H10.777a.509.509 0 1 0 0 1.019zm10.447 4.162H10.777a.51.51 0 1 0 0 1.021h10.446a.51.51 0 0 0 .001-1.021zm0 5.499H10.777a.509.509 0 0 0-.509.51c0 .282.227.51.509.51h10.446a.508.508 0 0 0 .509-.51.508.508 0 0 0-.508-.51z"
			/>
		</SVGIcon>
	);
}
