import PropTypes from 'prop-types';
import React from 'react';
import idx from 'idx';

export default class ContentElementImageIA extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		ratio: PropTypes.number,
		headline: PropTypes.string,
		isTeaser: PropTypes.bool,
	};

	static defaultProps = {
		data: {
			element_type: 'gallery',
			content: {
				images: [],
			},
		},
		isTeaser: false,
	};

	state = {
		currentImage: idx(this.props, _ => _.data.content.images.length) > 0 ? 0 : -1,
		isComponentMounted: false,
	};

	render = () => {
		if (this.props.data.content.images.length == 1) {
			if (!this.props.isTeaser) {
				return (
					<figure data-feedback="fb:likes, fb:comments">
						<img src={this.props.data.content.images[0].content.source} data-feedback="fb:likes, fb:comments" />
						<figcaption className="op-vertical-below">
							<h1>{this.props.data.content.images[0].metadata.title}</h1>
							<cite>{this.props.data.content.images[0].metadata.copyright}</cite>
						</figcaption>
					</figure>
				);
			} else {
				return (
					<figure data-feedback="fb:likes, fb:comments">
						<img src={this.props.data.content.images[0].content.source} />
					</figure>
				);
			}
		} else {
			let imageArray = this.props.data.content.images.map(function(image) {
				return (
					<figure key={image.metadata.source} data-feedback="fb:likes, fb:comments">
						<img src={image.content.source} />
						<figcaption className="op-vertical-below">
							<h1>{image.metadata.title}</h1>
							<cite>{image.metadata.copyright}</cite>
						</figcaption>
					</figure>
				);
			});

			return <figure className="op-slideshow">{imageArray}</figure>;
		}
	};
}
