/*eslint no-console:0*/

import React from 'react';
import classNames from 'classnames';
import styles from './Recommendations.scss';
import TeaserBoardTile from '../TeaserBoard/TeaserBoardTile';

export default class Recommendations extends React.Component {
	static defaultProps = {
		data: [],
	};

	render() {
		if (!this.props.data || this.props.data.length == 0) return null;

		return (
			<div className={classNames(styles.recommendationsContainer)}>
				{this.props.data.map(box => {
					return (
						<TeaserBoardTile
							className={classNames(styles.tile)}
							container={true}
							headline={box.attributes.content.headline}
							title={box.attributes.content.title}
							image={box.attributes.content.teaser_image.content}
							link={box.attributes.metadata.redirect || '/' + box.id}
							key={box.id}
						/>
					);
				})}
			</div>
		);
	}
}
