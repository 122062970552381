/**
 * Class to get data for a spectrum analyzer either from a real audio stream
 * or from generating fake data.
 */
export default class SpectrumDataFetcher {
	static prevFakeData = [0, 0, 0, 0, 0, 0, 0, 0];
	static biasFakeData = [0.6, 0.75, 0.9, 1.0, 1.0, 0.9, 0.75, 0.6];

	private analyser?: AnalyserNode;

	constructor(analyser?: AnalyserNode) {
		this.analyser = analyser;
	}

	/**
	 * Returns either the current real data or new faked data.
	 */
	fetch(isPlaying: boolean) {
		return this.analyser ? this.fetchRealData() : this.fetchFakeData(isPlaying);
	}

	/**
	 * Returns the current data from the analyser.
	 */
	fetchRealData() {
		const data = [];

		let rawData = new Uint8Array(16);
		if (this.analyser) {
			this.analyser.getByteFrequencyData(rawData);
		}
		for (let i = 0; i < rawData.length / 2; i++) {
			data.push(rawData[i]);
		}

		return data;
	}

	/**
	 * Generates fake data.
	 */
	fetchFakeData(isPlaying: boolean) {
		const data = [];
		for (let i = 0; i < 8; i++) {
			let factor = Math.random() * 0.2;
			let val = (1.0 - factor) * SpectrumDataFetcher.prevFakeData[i];
			if (isPlaying) {
				val += factor * Math.abs(Math.sin(1337 + new Date().getTime() / 200 + i) * Math.cos(1.23 + new Date().getTime() / 120 + i)) * 250;
				val += factor * Math.random() * 55;
				val *= SpectrumDataFetcher.biasFakeData[i];
			}
			val = val > 255 ? 255 : val;
			SpectrumDataFetcher.prevFakeData[i] = val;
			data.push(val);
		}
		return data;
	}
}
