export const initialState = {
	loaded: false,
};

export function createReducer(initialState, LOAD_EVENT, SUCCESS_EVENT, FAIL_EVENT, loadHandler, successHandler, failHandler) {
	return function reducer(state = initialState, action = {}) {
		switch (action.type) {
			case LOAD_EVENT:
				return (
					(loadHandler && loadHandler(state, action)) || {
						...state,
						loading: true,
					}
				);

			case SUCCESS_EVENT:
				return (
					(successHandler && successHandler(state, action)) || {
						...state,
						loaded: true,
						loading: false,
						data: action.result,
					}
				);
			case FAIL_EVENT:
				return (
					(failHandler && failHandler(state, action)) || {
						...state,
						loaded: state.loaded,
						loading: false,
						error: action.error,
					}
				);
			default:
				return state;
		}
	};
}
