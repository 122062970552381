import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenschauerHeiterNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M17.694 12.827a8.16 8.16 0 0 1-6.766 3.572c-4.532 0-8.198-3.666-8.198-8.198S6.396.003 10.928.003c.472 0 .944.047 1.4.126A7.093 7.093 0 0 0 9.496 5.81a7.116 7.116 0 0 0 7.113 7.113 11.8 11.8 0 0 0 1.085-.096zM22.32.742l-.047 2.959 2.879-.693-1.919 2.235 2.643 1.321-2.927.473 1.181 2.706-2.534-1.51-.834 2.832-.975-2.785-2.471 1.636 1.054-2.769-2.942-.331 2.58-1.447-2.029-2.14 2.895.55-.189-2.942 1.873 2.281z"
			/>
			<path
				fill="#0971B8"
				d="M4.769 25.799c.345-.577.345-1.498.345-2.307 0-.923.118-1.729.118-1.729s-.462.576-1.269 1.037c-.691.461-1.73.807-1.959 1.268-.462.807-.231 1.73.576 2.19.691.578 1.727.347 2.189-.459zm2.881 5.417c.347-.577.347-1.499.347-2.307 0-.92.114-1.728.114-1.728s-.46.576-1.267 1.037c-.692.46-1.73.808-1.961 1.268-.458.806-.229 1.729.576 2.189.809.578 1.731.348 2.191-.459zm6.688-1.845c.343-.576.343-1.497.343-2.304 0-.923.116-1.731.116-1.731s-.458.578-1.267 1.037c-.691.462-1.728.808-1.959 1.268-.462.807-.231 1.729.576 2.191.69.577 1.729.348 2.191-.461z"
			/>
			<path
				fill="#0971B8"
				d="M4.769 25.799c.345-.577.345-1.498.345-2.307 0-.923.118-1.729.118-1.729s-.462.576-1.269 1.037c-.691.461-1.73.807-1.959 1.268-.462.807-.231 1.73.576 2.19.691.578 1.727.347 2.189-.459zm2.881 5.417c.347-.577.347-1.499.347-2.307 0-.92.114-1.728.114-1.728s-.46.576-1.267 1.037c-.692.46-1.73.808-1.961 1.268-.458.806-.229 1.729.576 2.189.809.578 1.731.348 2.191-.459zm6.688-1.845c.343-.576.343-1.497.343-2.304 0-.923.116-1.731.116-1.731s-.458.578-1.267 1.037c-.691.462-1.728.808-1.959 1.268-.462.807-.231 1.729.576 2.191.69.577 1.729.348 2.191-.461z"
			/>
			<path
				fill="#FFF"
				d="M11.188 23.702a3.678 3.678 0 0 1-3.678-3.678 3.662 3.662 0 0 1 3.678-3.659c.289 0 .558.038.847.096l.539.135.154-.539c.712-2.638 3.119-4.487 5.854-4.487s5.141 1.849 5.854 4.487l.154.539.539-.135c.27-.058.558-.096.847-.096 2.022 0 3.678 1.637 3.678 3.659s-1.637 3.678-3.678 3.678H11.188z"
			/>
			<path
				fill="#4F4E4E"
				d="M18.583 12.149a5.483 5.483 0 0 1 5.295 4.063l.289 1.078 1.098-.25c.231-.058.481-.077.712-.077 1.714 0 3.1 1.386 3.1 3.081 0 1.714-1.386 3.1-3.1 3.1H11.188a3.098 3.098 0 0 1-3.1-3.1 3.094 3.094 0 0 1 3.1-3.081c.231 0 .481.019.712.077l1.098.25.289-1.078a5.485 5.485 0 0 1 5.296-4.063m0-1.156c-3.062 0-5.642 2.08-6.412 4.91a4.041 4.041 0 0 0-.982-.115c-2.349 0-4.256 1.906-4.256 4.236a4.257 4.257 0 0 0 4.256 4.256h14.789a4.257 4.257 0 0 0 4.256-4.256 4.24 4.24 0 0 0-4.256-4.236c-.347 0-.674.038-.982.115-.771-2.83-3.352-4.91-6.413-4.91z"
			/>
			<path
				fill="#4F4E4E"
				d="M14.057 17.81a.493.493 0 0 1-.183-.036l-1.868-.77a.482.482 0 0 1 .367-.891l1.868.77a.482.482 0 0 1-.184.927z"
			/>
		</SVGIcon>
	);
}
