import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import styles from './NewsArticle.scss';
import SvgBildergalerie from './icon_b3_news_bildergalerie.svg';
import SvgNewsAudio from './icon_b3_news_audio.svg';
import SvgNewsVideo from './icon_b3_news_video.svg';
import SvgNewsLocation from './icon_b3_news_location.svg';

export default class NewsArticle extends Component {
	static propTypes = {
		headline: PropTypes.string,
		title: PropTypes.string,
		teaserText: PropTypes.string,
		publicationDate: PropTypes.string,
		audio: PropTypes.array,
		images: PropTypes.array,
		video: PropTypes.array,
		location: PropTypes.oneOfType([
			PropTypes.array, //if there is no location data, location is an empty array
			PropTypes.object,
		]),
		link: PropTypes.string,
	};

	static defaultProps = {
		headline: '',
		title: '',
		teaserText: '',
		publicationDate: '',
		audio: [],
		images: [],
		video: [],
		location: [],
		link: 'javascript:;',
	};

	render() {
		let headlineImage =
			this.props.images.length !== 0 ? (
				// ? <img src={this.props.images[0].url} name={this.props.images[0].author} content={this.props.images[0].description}/>
				<div className={classNames(styles.image)} style={{ backgroundImage: `url(${this.props.images[0].url})` }} />
			) : null;
		let audio = this.props.audio.length !== 0 ? <SvgNewsAudio className={classNames(styles.icons)} /> : null;
		let images = this.props.images.length !== 0 ? <SvgBildergalerie className={classNames(styles.icons)} /> : null;
		let video = this.props.video.length !== 0 ? <SvgNewsVideo className={classNames(styles.icons)} /> : null;
		let location = this.props.location.length !== 0 ? <SvgNewsLocation className={classNames(styles.icons)} /> : null;
		let time =
			audio === null && images === null && video === null && location === null ? (
				<span className={styles.timeLabel}>{moment(this.props.publicationDate).fromNow()}</span>
			) : (
				<span className={classNames(styles.vrLine, styles.timeLabel)}>{moment(this.props.publicationDate).fromNow()}</span>
			);
		return (
			<article>
				{headlineImage && (
					<div className={classNames(styles.imageContainer)}>
						<a href={this.props.link} target="_blank" rel="noopener noreferrer">
							{headlineImage}
						</a>
					</div>
				)}
				<h2>{this.props.headline.replace(/[„“]/gi, '"')}</h2>
				<a href={this.props.link} target="_blank" rel="noopener noreferrer">
					<h1>{this.props.title.replace(/[„“]/gi, '"')}</h1>
				</a>
				<div className={classNames(styles.iconContainer)}>
					{audio}
					{images}
					{video}
					{location}
					{time}
				</div>
				<p>
					{this.props.teaserText}...
					<a href={this.props.link} target="_blank" rel="noopener noreferrer">
						mehr
					</a>
				</p>
			</article>
		);
	}
}
