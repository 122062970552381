import React from 'react';
import styles from './TeaserBoard.scss';
import classNames from 'classnames';
import AutoAspectImage from '../AutoAspectImage/AutoAspectImage.js';
import { Link } from 'react-router-dom';
import SvgPlayerPlay from './icon_b3_player_play.svg';

export default class TeaserBoardTile extends React.Component {
	state = {};

	// prevent continous rerendering while dragging
	//shouldComponentUpdate(nextProps, nextState) {
	//    return this.props !== nextProps || nextState !== this.state;
	//}

	componentDidMount() {
		if ('ontouchstart' in window) this.setState({ onMobile: true });
	}

	render() {
		const isExternalLink = this.props.link ? this.props.link.indexOf('http') === 0 : false;
		let ElementType = this.props.link ? (isExternalLink ? 'a' : Link) : 'div';
		return (
			<ElementType
				className={classNames(
					styles.tile,
					this.props.className,
					this.props.container ? styles.tileContainer : '',
					this.props.dropping ? styles.dropping : '',
					this.props.dragged ? styles.dragged : '',
					this.state.onMobile ? styles.onTouchDevice : ''
				)}
				to={this.props.link}
				href={isExternalLink ? this.props.link : null}
				target={isExternalLink ? '_blank' : null}
			>
				{this.props.image ? <AutoAspectImage {...this.props.image} title={this.props.title} /> : null}
				{this.props.title ? (
					<div className={styles.interiour}>
						<div className={classNames(styles.header)}>
							<div className={classNames(styles.headerText)}>
								<h3 dangerouslySetInnerHTML={{ __html: this.props.headline }} />
								<h2 dangerouslySetInnerHTML={{ __html: this.props.title }} />
							</div>
							{this.props.isPlayer && <SvgPlayerPlay className={classNames(styles.playbutton)} />}
						</div>
					</div>
				) : null}
			</ElementType>
		);
	}
}
