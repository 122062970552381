import PropTypes from 'prop-types';
import React from 'react';

export default class ContentElementMediaPlayerAMP extends React.Component {
	static propTypes = {
		poster: PropTypes.string,
		title: PropTypes.string,
		ratio: PropTypes.number,
		headline: PropTypes.string,
	};

	static defaultProps = {
		data: {
			element_type: 'media',
			element_subtype: 'video/youtube',
			metadata: {
				tags: [],
				title: '',
				copyright: '',
			},
		},
	};

	state = {
		playerLoaded: false,
		playing: false,
		position: 0,
		duration: 0,
		error: false,
		title: '',
		src: [],
		isComponentMounted: false,
		controlsHidden: false,
	};

	render() {
		let mediaURL = this.props.data.content.source.standard;
		let mediaType = this.props.data.element_subtype;

		if (mediaType == 'video/mp4') {
			let videoHTTPS = mediaURL.replace('http://', 'https://');
			return (
				<amp-video width="1920" height="960" layout="responsive" poster={this.props.data.content.poster_image.content.source} controls>
					<div fallback>
						<p>Ihr Browser unterstützt leider kein HTML5-Video</p>
					</div>
					<source type="video/mp4" src={videoHTTPS} />
				</amp-video>
			);
		} else if (mediaType == 'video/youtube') {
			let youtubeID = this.props.data.content.source.standard.slice(-11);
			return (
				<amp-youtube layout="responsive" width="1920" height="960" data-param-controls="1" data-param-fs="1" data-videoid={youtubeID} />
			);
		} else if (mediaType == 'audio/mp3') {
			let audioHTTPS = mediaURL.replace('http://', 'https://');
			return (
				<div>
					<amp-img width="1920" height="960" layout="responsive" src={this.props.data.content.poster_image.content.source} />
					<amp-audio height="40" width="auto" src={audioHTTPS}>
						<div fallback>
							<p>Ihr Browser unterstützt leider kein HTML5-Video</p>
						</div>
						<source type="audio/mpeg" src={audioHTTPS} />
					</amp-audio>
				</div>
			);
		}
		return null;
	}
}
