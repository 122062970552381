import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterRegenStarkNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M6.076 25.642c.256-.431.256-1.176.295-1.783.039-.784.059-1.392.059-1.392s-.334.431-.98.843c-.51.332-1.332.588-1.588 1.039a1.293 1.293 0 0 0 .471 1.764 1.281 1.281 0 0 0 1.743-.471z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.768 15.265a2.368 2.368 0 0 1-2.367-2.368 2.357 2.357 0 0 1 2.367-2.357c.185 0 .359.025.545.063l.348.086.1-.347a3.917 3.917 0 0 1 3.772-2.89c1.76 0 3.311 1.19 3.767 2.89l.1.347.348-.086a2.365 2.365 0 0 1 2.914 2.294 2.368 2.368 0 0 1-2.369 2.368H2.768z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.531 7.824c1.598 0 3 1.078 3.408 2.616l.188.694.707-.16c.146-.038.309-.05.457-.05 1.106 0 1.998.893 1.998 1.983a1.996 1.996 0 0 1-1.998 1.997H2.768a1.995 1.995 0 0 1-1.996-1.997c0-1.091.893-1.983 1.996-1.983.15 0 .31.012.459.05l.707.16.186-.694a3.53 3.53 0 0 1 3.411-2.616m0-.744A4.286 4.286 0 0 0 3.4 10.243a2.739 2.739 0 0 0-3.373 2.654 2.742 2.742 0 0 0 2.74 2.741h9.523a2.742 2.742 0 0 0 2.742-2.741 2.732 2.732 0 0 0-3.373-2.654A4.283 4.283 0 0 0 7.531 7.08z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.475 12.745a2.04 2.04 0 0 1-2.031-2.032 2.04 2.04 0 0 1 2.031-2.032c.162 0 .311.012.471.05l.299.074.074-.286a3.352 3.352 0 0 1 3.234-2.478 3.344 3.344 0 0 1 3.234 2.478l.074.286.297-.074c.148-.038.311-.05.471-.05 1.115 0 2.033.916 2.033 2.032a2.042 2.042 0 0 1-2.033 2.032h-8.154z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.553 6.338c1.375 0 2.576.93 2.938 2.255l.16.583.582-.137c.137-.024.262-.049.396-.049.955 0 1.723.768 1.723 1.723a1.72 1.72 0 0 1-1.723 1.723h-8.154a1.72 1.72 0 0 1-1.723-1.723 1.718 1.718 0 0 1 2.119-1.674l.582.137.162-.583a3.052 3.052 0 0 1 2.938-2.255m0-.62a3.649 3.649 0 0 0-3.533 2.715 2.349 2.349 0 0 0-2.887 2.281 2.348 2.348 0 0 0 2.342 2.342h8.154a2.35 2.35 0 0 0 2.344-2.342 2.35 2.35 0 0 0-2.889-2.281 3.646 3.646 0 0 0-3.531-2.715z"
			/>
			<path
				fill="#C9C8C8"
				d="M9.121 18.752a3.533 3.533 0 0 1-3.527-3.527c0-1.95 1.59-3.527 3.527-3.527.273 0 .547.037.818.099l.473.113.125-.461a5.796 5.796 0 1 1 11.204-.025l.125.461.473-.112c.271-.062.545-.1.818-.1a3.534 3.534 0 0 1 3.527 3.528c0 1.95-1.59 3.527-3.527 3.527H9.121v.024z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.139 7.624a5.316 5.316 0 0 1 5.129 3.924l.25.932.932-.223a3.29 3.29 0 0 1 .695-.088 3.035 3.035 0 0 1 3.031 3.031 3.035 3.035 0 0 1-3.031 3.03H9.107a3.034 3.034 0 0 1-3.029-3.03 3.034 3.034 0 0 1 3.029-3.031c.238 0 .473.025.697.088l.932.223.248-.932a5.37 5.37 0 0 1 5.155-3.924m0-.994a6.299 6.299 0 0 0-6.086 4.657 4.024 4.024 0 1 0-.932 7.937h14.035a4.023 4.023 0 1 0-.932-7.937 6.294 6.294 0 0 0-6.085-4.657z"
			/>
			<path
				fill="#0971B8"
				d="M10.754 23.591c.322-.546.322-1.49.373-2.259.049-.995.072-1.764.072-1.764s-.42.545-1.24 1.066c-.647.423-1.689.746-2.012 1.317a1.636 1.636 0 0 0 .596 2.235 1.622 1.622 0 0 0 2.211-.595z"
			/>
			<path
				fill="#A03416"
				d="M20.842 15.604c-.09-.09-.273-.182-.365-.09l-3.836 1.004 2.467-4.657c.092-.092.092-.183 0-.274 0-.092-.092-.182-.184-.182l-2.83-.914c-.184-.091-.365 0-.457.183l-3.379 9.041c-.09.091 0 .273.092.365.092.092.273.183.365.092l3.471-.914-2.101 5.662v.274c0 .091.092.183.184.183l.547.365h.092c.092 0 .273-.092.365-.183l5.57-9.681c.091 0 .091-.183-.001-.274z"
			/>
			<path
				fill="#0971B8"
				d="M4.072 21.943c.42-.711.42-1.94.484-2.943.064-1.294.098-2.297.098-2.297s-.551.712-1.617 1.392c-.842.55-2.201.97-2.621 1.714a2.132 2.132 0 0 0 .775 2.911c1.004.583 2.297.226 2.881-.777z"
			/>
		</SVGIcon>
	);
}
