import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SearchGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="16" r="15.999" />
			<path
				fill="#FFF"
				d="M24.014 22.987l-4.271-4.268a6.661 6.661 0 0 0 1.445-4.138 6.706 6.706 0 0 0-6.697-6.697 6.704 6.704 0 0 0-6.697 6.697 6.703 6.703 0 0 0 6.697 6.696 6.662 6.662 0 0 0 4.337-1.604l4.25 4.248a.664.664 0 0 0 .936 0 .666.666 0 0 0 0-.934zm-9.523-3.029a5.383 5.383 0 0 1-5.377-5.376 5.383 5.383 0 0 1 5.377-5.377 5.382 5.382 0 0 1 5.374 5.377 5.383 5.383 0 0 1-5.374 5.376zm-.32-9.093a3.568 3.568 0 0 0-3.562 3.564.661.661 0 0 0 1.322 0 2.244 2.244 0 0 1 2.24-2.244.66.66 0 1 0 0-1.32z"
			/>
		</SVGIcon>
	);
}
