import React from 'react';

export default class ContentElementHtmlTextIA extends React.Component {
	static defaultProps = {
		data: {
			content: {
				text: '',
			},
			element_type: 'text',
			element_subtype: 'html',
		},
	};

	render() {
		let rawText = this.props.data.content.text;
		let processedText = rawText.replace(/<h3>|<h4>/gim, '<h1>').replace(/<\/h3>|<\/h4>/gim, '</h1>');
		// .replace(/<[^\/>][^>]*><\/[^>]+>/gim, ""); // empty tags ?

		return <div dangerouslySetInnerHTML={{ __html: processedText }} />;
	}
}
