import React, { Component } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'resize-observer-polyfill';
import URI from 'urijs';
import styles from './AutoAspectImage.scss';

const IMAGE_WIDTH_INCREMENTS = 50; // for better CDN cache reuse

const noop = () => {};

export default class AutoAspectImage extends Component {
	state = {
		currentImage: null,
		currentRatio: 2,
		imageLoaded: false,
	};

	containerRef = React.createRef();

	resizeObserver;

	determineCorrectImage() {
		let { width, height } =
			this.props.container && this.props.container.getBoundingClientRect
				? this.props.container.getBoundingClientRect()
				: window.getComputedStyle(this.containerRef.current);

		let ratio = parseInt(width, 10) / parseInt(height, 10);
		let tolerance = 0.05;

		let variant = this.props.source;

		if (Math.abs(ratio - 1 / 1) < tolerance) variant = this.props.variants.one_by_one;
		else if (Math.abs(ratio - 3 / 2) < tolerance) variant = this.props.variants.three_by_two;
		else if (Math.abs(ratio - 3 / 1) < tolerance || ratio > 2.4) variant = this.props.variants.three_by_one;

		let widthToLoad = Math.ceil(parseInt(width, 10) / IMAGE_WIDTH_INCREMENTS) * IMAGE_WIDTH_INCREMENTS;
		widthToLoad *= window.devicePixelRatio || 1;

		let paramDivider = variant.includes('?') ? '&' : '?';
		const maxWidth = URI(variant).search(true)._naturalWidth || 5000;
		const url = `${variant}${paramDivider}w=${Math.min(maxWidth, Math.floor(widthToLoad))}&q=${widthToLoad > 799 ? '80' : '65'}`;

		if (this.preloadImage) {
			this.preloadImage.onload = noop;
		}
		this.preloadImage = new Image();
		this.preloadImage.onload = () => {
			this.setState({
				imageLoaded: true,
				currentImage: url,
				currentRatio: ratio,
			});
		};
		this.preloadImage.src = url;
	}

	componentDidMount() {
		this.determineCorrectImage();

		this.resizeObserver = new ResizeObserver(entries => {
			entries.forEach(() => this.determineCorrectImage());
		});

		this.resizeObserver.observe(this.containerRef.current);
	}

	componentDidUpdate() {
		this.determineCorrectImage();
	}

	componentWillUnmount() {
		this.resizeObserver.disconnect();
		if (this.preloadImage) {
			this.preloadImage.onload = noop;
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps !== this.props || nextState.currentImage !== this.state.currentImage || nextState.imageLoaded !== this.state.imageLoaded
		);
	}

	render() {
		let lowResUrl;
		if (this.props.source) {
			lowResUrl = this.props.source.split('?')[0] + '?w=32';
		}

		return (
			<div className={styles.imageContainer}>
				<div
					style={{
						backgroundImage: lowResUrl ? `url(${lowResUrl})` : 'none',
					}}
					className={styles.blurLoader}
				/>
				<div
					ref={this.containerRef}
					className={classNames(styles.autoAspectImage, {
						[styles.imageLoaded]: this.state.imageLoaded,
					})}
					role="img"
					aria-label={this.props.title}
					title={this.props.title}
					style={Object.assign(
						this.state.currentImage &&
							this.state.currentImage.length > 0 &&
							this.state.currentImage !== 'undefined' &&
							this.state.currentImage !== '/undefined'
							? { backgroundImage: `url(${this.state.currentImage})` }
							: {},
						this.props.styles
					)}
				/>
			</div>
		);
	}
}
