import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
// @ts-ignore
import NestedStatus from 'react-nested-status';
import * as config from '../../../config.webapp';
import ContentElementsIA from '../../commons/contentElementsIA';

/**
 * Component to display an Instant Article.
 */
export default class ArticleIA extends React.Component {
	static propTypes = {
		article: PropTypes.object,
	};

	render() {
		if (!this.props.article) {
			return null;
		}

		let teaser = React.createElement(ContentElementsIA[this.props.article.attributes.content.teaser_element.element_type], {
			headline: this.props.article.attributes.content.headline,
			title: this.props.article.attributes.content.title,
			isTeaser: true,
			data: this.props.article.attributes.content.teaser_element,
		});

		let youtubeTeaser;
		if (this.props.article.attributes.content.teaser_element.element_subtype == 'video/youtube') {
			teaser = '';
			youtubeTeaser = React.createElement(ContentElementsIA['media'], { data: this.props.article.attributes.content.teaser_element });
		}

		const timestampCreated = this.props.article.createdAt || new Date();
		const timestampModified = this.props.article.updatedAt || new Date();

		const timestampCreatedString = moment(timestampCreated).format('LLLL');
		const timestampModifiedString = moment(timestampModified).format('LLLL');

		const recommendations = [];
		const { relationships } = this.props.article;

		if (relationships && relationships.recommended && relationships.recommended.data) {
			if (this.props.article.relationships.recommended.data.length > 0 && this.props.article.relationships.recommended.data.length <= 3) {
				for (let i = 0; i < this.props.article.relationships.recommended.data.length; i++) {
					recommendations.push(`${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.article.relationships.recommended.data[i].id}`);
				}
			} else if (this.props.article.relationships.recommended.data.length > 3) {
				for (let i = 0; i < 3; i++) {
					recommendations.push(`${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.article.relationships.recommended.data[i].id}`);
				}
			}
		}

		let summaryHeadline;
		if (this.props.article.attributes.content.summary != '') {
			summaryHeadline = 'Kurzzusammenfassung';
		}

		return (
			<NestedStatus code={200}>
				<article>
					<header>
						{teaser}

						<h3 className="op-kicker">{this.props.article.attributes.content.headline}</h3>
						<h1>{this.props.article.attributes.content.title}</h1>

						<time className="op-published" dateTime={timestampCreated}>
							{timestampCreatedString}
						</time>
						<time className="op-modified" dateTime={timestampModified}>
							{timestampModifiedString}
						</time>

						<address>
							<a href={config.PUBLIC_FACING_ORIGIN_WEBAPP}>Bayern 3</a>
						</address>
					</header>

					{youtubeTeaser}

					<div dangerouslySetInnerHTML={{ __html: this.props.article.attributes.content.teaser_text }} />

					{this.props.article.attributes.content.elements.map((e, i) => {
						const element = React.createElement(ContentElementsIA[e.element_type], {
							key: i,
							data: e,
						});
						return element;
					})}

					<h2 dangerouslySetInnerHTML={{ __html: summaryHeadline }} />
					<div dangerouslySetInnerHTML={{ __html: this.props.article.attributes.content.summary }} />

					{this.props.children}
				</article>
			</NestedStatus>
		);
	}
}
