import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeschauerHeiterNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M18.066 12.49a7.925 7.925 0 0 1-6.57 3.468c-4.401 0-7.961-3.56-7.961-7.961S7.095.036 11.496.036c.458 0 .917.046 1.36.122a6.887 6.887 0 0 0-2.75 5.516 6.91 6.91 0 0 0 6.907 6.907c.366-.014.717-.045 1.053-.091zM22.559.755l-.046 2.873 2.796-.672-1.864 2.169 2.567 1.284-2.842.458 1.146 2.628-2.46-1.466-.81 2.75-.948-2.705-2.398 1.59 1.023-2.69-2.857-.321 2.506-1.405-1.971-2.078 2.811.534-.183-2.857 1.818 2.216z"
			/>
			<path
				fill="#0971B8"
				d="M6.374 23.461l-.223-.895-1.677.561.447-1.678-.896-.224-.336 1.677-1.229-1.23-.56.672 1.119 1.117-1.565.449.223.895 1.566-.448-.336 1.565.896.224.335-1.566 1.23 1.119.559-.558-1.118-1.231zm5.705 5.705l-.224-.783-1.566.447.336-1.678-.782-.222-.448 1.677-1.231-1.119-.559.561 1.231 1.228-1.678.448.224.785 1.677-.45-.448 1.678.896.225.336-1.678 1.229 1.117.672-.558-1.231-1.231zm7.829-2.461l-.223-.783-1.566.447.336-1.678-.783-.222-.448 1.677-1.23-1.117-.56.559 1.231 1.23-1.678.448.335.783 1.567-.449-.448 1.677.895.114.447-1.567 1.119 1.119.671-.56-1.231-1.231z"
			/>
			<path
				fill="#FFF"
				d="M12.061 23.077a3.57 3.57 0 0 1-3.57-3.57 3.554 3.554 0 0 1 3.57-3.551c.28 0 .542.037.822.093l.523.131.15-.523a5.9 5.9 0 0 1 5.682-4.355 5.9 5.9 0 0 1 5.682 4.355l.15.523.523-.131c.262-.056.542-.093.822-.093 1.962 0 3.57 1.589 3.57 3.551s-1.589 3.57-3.57 3.57H12.061z"
			/>
			<path
				fill="#4F4E4E"
				d="M19.238 11.863a5.323 5.323 0 0 1 5.14 3.944l.28 1.047 1.065-.243c.224-.056.467-.075.691-.075 1.663 0 3.009 1.346 3.009 2.99a3.007 3.007 0 0 1-3.009 3.009H12.061a3.007 3.007 0 0 1-3.009-3.009 3.003 3.003 0 0 1 3.009-2.99c.224 0 .467.019.691.075l1.065.243.28-1.047a5.325 5.325 0 0 1 5.141-3.944m0-1.122c-2.972 0-5.476 2.018-6.224 4.766a3.908 3.908 0 0 0-.953-.112c-2.28 0-4.131 1.85-4.131 4.112 0 2.28 1.85 4.13 4.131 4.13h14.354c2.28 0 4.131-1.85 4.131-4.13s-1.85-4.112-4.131-4.112c-.336 0-.654.037-.953.112-.748-2.747-3.252-4.766-6.224-4.766z"
			/>
			<path
				fill="#4F4E4E"
				d="M14.846 17.358a.466.466 0 0 1-.178-.035l-1.813-.748a.467.467 0 0 1 .356-.864l1.813.748a.467.467 0 0 1-.178.899z"
			/>
		</SVGIcon>
	);
}
