import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NebelBodennebelIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#4F4E4E"
				d="M21.486 17.755c.184.368.366.734.274 1.192 0 .456-.182.822-.64 1.186-.458.366-1.188.734-2.286 1.1-.822.18-1.372.456-1.828.638-.458.184-.734.458-.916.642-.182.274-.182.548-.182.822.09.366.182.732.364 1.28.642 1.738.734 1.92 1.098 2.743h-4.023c0-.182-.182-1.83-.274-2.012-.092-.548-.092-1.372-.092-1.92 0-.456.184-.824.458-1.28.274-.366.732-.734 1.464-1.006.64-.274 1.462-.55 2.652-.824.73-.184 1.188-.366 1.554-.64.366-.274.548-.458.64-.822.092-.274.092-.55 0-.914-.092-.276-.182-.552-.274-.824h1.37c.367.183.457.367.641.639z"
			/>
			<path
				fill="#4F4E4E"
				d="M15.909 17.117h1.37c.184.272.276.548.366.73 0 .184 0 .366-.182.55-.092.184-.366.364-.732.55-.366.182-1.006.364-1.828.546-1.006.184-1.83.368-2.469.64-.64.276-1.188.458-1.554.824-.366.366-.732.732-.914 1.19-.274.548-.55 1.096-.824 2.01-.64 2.014-.548 2.561-.732 3.201H4.113c.274-.458.55-.824.732-1.188.274-.366.458-.732.64-1.008l.55-.822c.548-.822.914-1.554 1.37-2.194.458-.548.914-1.006 1.464-1.464a7.492 7.492 0 0 1 2.102-1.006c.824-.274 1.83-.548 3.109-.73a9.824 9.824 0 0 0 1.098-.276c.274-.09.456-.182.548-.274.092-.092.184-.274.184-.366.089-.365-.001-.913-.001-.913z"
			/>
			<path
				fill="#969696"
				d="M32 6.914c-8.019 0-12.097-.325-16.04-.64-3.924-.313-7.98-.637-15.96-.637v-.994c8.019 0 12.097.327 16.04.642 3.924.312 7.98.637 15.96.637v.992zm0 3.752c-8.019 0-12.097-.327-16.04-.642-3.924-.313-7.98-.638-15.96-.638v-.994c8.019 0 12.097.325 16.04.642 3.924.313 7.98.638 15.96.638v.994zm0 3.657c-8.019 0-12.097-.327-16.04-.642-3.923-.315-7.98-.64-15.96-.64v-.992c8.019 0 12.097.325 16.04.642 3.923.315 7.98.638 15.96.638v.994zm0 3.747c-8.019 0-12.097-.325-16.04-.642-3.924-.313-7.98-.637-15.96-.637v-.994c8.019 0 12.097.327 16.04.642 3.923.315 7.98.64 15.96.64v.991z"
			/>
		</SVGIcon>
	);
}
