import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './News.scss';
import NewsArticle from '../NewsArticle/NewsArticle';
import TrafficWidget from '../TrafficWidget/TrafficWidget';
import WeatherWidget from '../WeatherWidget/WeatherWidget';
import ContentElementImage from '../ContentElementImage/ContentElementImage';
import moment from 'moment';

export default class News extends Component {
	static propTypes = {
		news: PropTypes.array,
		traffic: PropTypes.object,
		weather: PropTypes.object,
	};

	static defaultProps = {
		news: [],
	};

	render() {
		let articles =
			this.props.news &&
			this.props.news.map(data => {
				return (
					<div key={data.id} className={classNames(styles.articles)}>
						<NewsArticle
							key={data.id}
							headline={data.headline}
							title={data.title}
							teaserText={data.teaserText}
							publicationDate={data.publicationDate}
							audio={data.audio}
							images={data.images}
							video={data.video}
							location={data.location}
							link={data.shareLink}
						/>
						<hr />
					</div>
				);
			});
		return (
			<div className={styles.news}>
				<ContentElementImage
					imageStyles={{ backgroundPosition: '0 50%' }}
					data={{
						element_type: 'gallery',
						content: {
							images: [
								{
									element_type: 'image',
									content: {
										source: require('./img_news.jpg'),
										variants: {
											one_by_one: require('./img_news.jpg'),
											three_by_two: require('./img_news.jpg'),
											three_by_one: require('./img_news.jpg'),
										},
									},
									metadata: {
										title: '',
										tags: [],
										copyright: '',
									},
								},
							],
						},
					}}
					isTeaser={true}
				/>
				<div className="container">
					<main>
						<div className={classNames(styles.headline)}>
							<time>{moment().format('DD.MM.YYYY')}</time>
							<h2>BR24 Nachrichten</h2>
							<h1>Die aktuellsten Meldungen</h1>
						</div>
						{articles}
					</main>
					<aside id="sidebar" style={{ paddingTop: '4em' }}>
						<WeatherWidget weather={this.props.weather} />
						<TrafficWidget traffic={this.props.traffic} />
					</aside>
				</div>
			</div>
		);
	}
}
