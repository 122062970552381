import React from 'react';

interface IProps {
	// @ts-ignore
	data: any;
}

/**
 * Component for displaying facebook videos on instant articles.
 */
export default class ContentElementEmbedFacebookVideoIA extends React.Component<IProps> {
	static defaultProps = {
		data: {
			content: {
				html: '<div class="fb-video" data-href="" data-allowfullscreen="true"></div>',
			},
		},
	};

	render() {
		const FBroot = `<div id="fb-root"></div><script>(function(d, s, id) {  var js, fjs = d.getElementsByTagName(s)[0];  if (d.getElementById(id)) return;  js = d.createElement(s); js.id = id;  js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.3";  fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'facebook-jssdk'));</script>`;

		return (
			<figure className="op-interactive">
				<div dangerouslySetInnerHTML={{ __html: FBroot + this.props.data.content.html }} />
			</figure>
		);
	}
}
