import React from 'react';
import classNames from 'classnames';
import ToggleButton from '../ToggleButton/ToggleButton';
import styles from './DataPrivacyBanner.scss';
import { Services, toggleService, servicesMetadata, registerListener, removeListener, isServiceActive } from '../../commons/dataPrivacy';
import SvgX from './X.svg';
import SvgExtern from '../../assets/img/icon_extern.svg';

interface IProps {
	title?: string;
	isVideoPlayer?: boolean;
	service: Services | null;
	onClose?: () => void;
	onToggle?: () => void;
}

interface IState {
	serviceState: boolean;
}

/**
 * Banner to inform the user about embedded content and asks him to accept it.
 */
export default class DataPrivacyBanner extends React.Component<IProps, IState> {
	state = { serviceState: false };

	handleServiceStateChange = (value: boolean) => {
		this.setState({ serviceState: value });
	};

	handleOnToggle = () => {
		if (this.props.onToggle) {
			this.props.onToggle();
		}
		if (this.props.service) {
			toggleService(this.props.service);
		} else {
			this.setState({ serviceState: !this.state.serviceState });
		}
	};

	componentDidMount() {
		if (this.props.service) {
			this.setState({ serviceState: isServiceActive(this.props.service) });
			registerListener(this.props.service, this.handleServiceStateChange);
		}
	}

	componentWillUnmount() {
		if (this.props.service) {
			removeListener(this.props.service, this.handleServiceStateChange);
		}
	}

	render() {
		const metadata = this.props.service ? servicesMetadata.get(this.props.service) : null;

		return (
			<div className={classNames(this.props.isVideoPlayer && styles.videoPlayer)}>
				<div className={styles.dialog}>
					{this.props.onClose && (
						<div className={styles.dialogClose}>
							<button onClick={this.props.onClose}>
								<SvgX />
							</button>
						</div>
					)}
					<div className={styles.dialogContent}>
						<div className={styles.flex}>
							<div className={styles.icon}>{metadata && metadata.icon ? <metadata.icon /> : <SvgExtern />}</div>
							<div>
								{this.props.title && <div className={styles.dialogTitle}>{this.props.title}</div>}
								<p>
									Ich möchte eingebundene {metadata ? metadata.content : 'Inhalte'} auf Bayern3.de sehen. Hierbei werden personenbezogene
									Daten (IP-Adresse o.ä.){metadata ? ` an ${metadata.provider}` : ''} übertragen. Diese Einstellung kann jederzeit mit
									Wirkung für die Zukunft auf der Seite Datenschutz durch Anklicken des Opt-Out-Buttons geändert werden.
								</p>
								<div className={styles.flexCenter}>
									<span className={styles.bold}>Anzeige erlauben</span>
									<ToggleButton value={this.state.serviceState} onToggle={this.handleOnToggle} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
