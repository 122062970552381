import SVGIcon from '../SVGIcon';
import React from 'react';

export default function TwitterGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="15.999" cy="16.001" r="15.999" />
			<path
				fill="#FFF"
				d="M23.268 9.977a6.303 6.303 0 0 1-2.006.767 3.159 3.159 0 0 0-5.379 2.881 8.963 8.963 0 0 1-6.507-3.3 3.153 3.153 0 0 0-.427 1.588c0 1.095.558 2.061 1.404 2.626a3.14 3.14 0 0 1-1.429-.394c-.002.013-.002.026-.002.039a3.162 3.162 0 0 0 2.533 3.097 3.175 3.175 0 0 1-1.427.052 3.162 3.162 0 0 0 2.949 2.193 6.328 6.328 0 0 1-3.919 1.35c-.256 0-.507-.014-.753-.042a8.933 8.933 0 0 0 4.838 1.417c5.806 0 8.982-4.809 8.982-8.98 0-.139-.004-.275-.008-.409a6.378 6.378 0 0 0 1.572-1.634 6.32 6.32 0 0 1-1.814.497 3.164 3.164 0 0 0 1.393-1.748z"
			/>
		</SVGIcon>
	);
}
