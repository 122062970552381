import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenschauerStarkNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.285 13.177a6.443 6.443 0 0 1-5.342 2.82c-3.578 0-6.472-2.895-6.472-6.472s2.895-6.472 6.472-6.472c.373 0 .745.037 1.106.099a5.599 5.599 0 0 0-2.236 4.484 5.617 5.617 0 0 0 5.615 5.615c.298-.012.584-.037.857-.074zm3.653-9.541L17.9 5.972l2.274-.547-1.516 1.764 2.087 1.043-2.31.373.931 2.137-2-1.193-.658 2.237-.77-2.199-1.951 1.292.832-2.187-2.323-.261 2.038-1.143-1.603-1.689 2.286.435-.149-2.323 1.478 1.801z"
			/>
			<path
				fill="#0971B8"
				d="M7.575 28.307c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.04a1.294 1.294 0 0 0 .471 1.767 1.284 1.284 0 0 0 1.747-.471zm4.53-2.503c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.04a1.293 1.293 0 0 0 .471 1.766 1.28 1.28 0 0 0 1.747-.47zm4.227 2.503c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.04a1.293 1.293 0 0 0 .471 1.766 1.283 1.283 0 0 0 1.747-.47zM3.953 24.605c.421-.713.421-1.943.486-2.947.065-1.296.097-2.3.097-2.3s-.55.712-1.619 1.392c-.842.551-2.202.972-2.624 1.717a2.134 2.134 0 0 0 .777 2.915c1.005.583 2.3.227 2.883-.777z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.759 18.1a2.371 2.371 0 0 1-2.371-2.371 2.36 2.36 0 0 1 2.371-2.358c.186 0 .36.025.546.062l.348.087.099-.348c.459-1.701 2.011-2.892 3.774-2.892s3.314 1.192 3.774 2.892l.099.348.348-.087a2.368 2.368 0 0 1 2.917 2.296 2.372 2.372 0 0 1-2.372 2.371H2.759z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.525 10.652a3.536 3.536 0 0 1 3.414 2.619l.186.695.708-.161c.149-.037.31-.05.459-.05 1.105 0 1.999.894 1.999 1.986a1.997 1.997 0 0 1-1.999 1.998H2.759A1.998 1.998 0 0 1 .76 15.741c0-1.092.894-1.986 1.999-1.986.149 0 .31.012.459.05l.708.161.186-.695a3.534 3.534 0 0 1 3.413-2.619m0-.744a4.29 4.29 0 0 0-4.134 3.165A2.742 2.742 0 0 0 .015 15.73a2.744 2.744 0 0 0 2.743 2.743h9.533a2.744 2.744 0 0 0 2.743-2.743 2.734 2.734 0 0 0-2.743-2.731 2.64 2.64 0 0 0-.633.074 4.288 4.288 0 0 0-4.133-3.165z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.608 14.302a.307.307 0 0 1-.118-.024l-1.204-.497a.311.311 0 0 1 .236-.574l1.204.497a.311.311 0 0 1-.118.598z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.489 15.575c-1.116 0-2.034-.918-2.034-2.034s.918-2.034 2.034-2.034c.161 0 .31.012.471.05l.298.074.074-.285a3.357 3.357 0 0 1 3.237-2.481 3.346 3.346 0 0 1 3.237 2.481l.074.285.298-.074c.149-.037.31-.05.471-.05 1.116 0 2.034.918 2.034 2.034s-.918 2.034-2.034 2.034h-8.16z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.57 9.163c1.377 0 2.58.93 2.94 2.257l.161.583.583-.136c.136-.025.26-.05.397-.05.955 0 1.724.769 1.724 1.724s-.769 1.724-1.724 1.724H21.49c-.955 0-1.724-.769-1.724-1.724s.769-1.724 1.724-1.724c.136 0 .273.012.397.05l.583.136.161-.583a3.05 3.05 0 0 1 2.939-2.257m0-.62a3.65 3.65 0 0 0-3.535 2.716 2.35 2.35 0 0 0-2.89 2.282 2.35 2.35 0 0 0 2.344 2.344h8.161a2.35 2.35 0 0 0 2.344-2.344 2.35 2.35 0 0 0-2.89-2.282 3.648 3.648 0 0 0-3.534-2.716z"
			/>
			<path fill="#4F4E4E" d="M23.226 12.363a.32.32 0 0 1-.117-.023l-1.215-.496a.31.31 0 1 1 .234-.574l1.215.496a.31.31 0 0 1-.117.597z" />
			<path
				fill="#FFF"
				d="M9.15 21.429a3.534 3.534 0 0 1-3.528-3.528c0-1.95 1.59-3.528 3.528-3.528.273 0 .547.037.82.099l.472.112.124-.46a5.805 5.805 0 0 1 5.603-4.311 5.826 5.826 0 0 1 5.615 4.286l.124.46.472-.112c.273-.062.547-.099.82-.099 1.95 0 3.528 1.59 3.528 3.528 0 1.95-1.59 3.528-3.528 3.528H9.15v.025z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.169 10.311a5.32 5.32 0 0 1 5.131 3.926l.248.932.932-.224c.236-.05.46-.087.696-.087a3.035 3.035 0 0 1 3.031 3.031 3.035 3.035 0 0 1-3.031 3.031H9.138a3.035 3.035 0 0 1-3.031-3.031 3.035 3.035 0 0 1 3.031-3.031c.236 0 .472.025.696.087l.932.224.248-.932a5.356 5.356 0 0 1 5.155-3.926m0-.994a6.297 6.297 0 0 0-6.087 4.659 4.024 4.024 0 0 0-4.957 3.913 4.024 4.024 0 0 0 4.025 4.025h14.038a4.024 4.024 0 0 0 4.025-4.025c.012-2.224-1.801-4.025-4.025-4.025-.323 0-.634.037-.932.112a6.297 6.297 0 0 0-6.087-4.659z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.833 15.69a.443.443 0 0 1-.173-.036l-1.603-.696a.436.436 0 0 1 .346-.798l1.603.696a.436.436 0 0 1-.173.834z"
			/>
			<g>
				<path
					fill="#0971B8"
					d="M17.831 22.948c.421-.713.421-1.943.486-2.947.065-1.296.097-2.3.097-2.3s-.551.713-1.619 1.393c-.842.551-2.202.972-2.624 1.717a2.134 2.134 0 0 0 .777 2.915c1.004.583 2.299.226 2.883-.778z"
				/>
			</g>
			<g>
				<path
					fill="#0971B8"
					d="M23.591 27.885c.421-.713.421-1.943.486-2.947.065-1.296.097-2.3.097-2.3s-.551.713-1.619 1.393c-.842.551-2.202.972-2.624 1.717a2.134 2.134 0 0 0 .777 2.915c1.005.582 2.3.226 2.883-.778z"
				/>
			</g>
		</SVGIcon>
	);
}
