import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeschauerHeiterIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M6.649 23.426l-.222-.785-1.572.449.337-1.684-.786-.225-.448 1.684-1.234-1.123-.563.562 1.236 1.234-1.684.449.337.785 1.572-.447-.45 1.683.898.112.336-1.573 1.234 1.122.673-.56-1.234-1.234zm5.725 5.723l-.224-.785-1.572.448.338-1.682-.786-.111-.449 1.57-1.122-1.122-.674.559 1.234 1.236-1.571.448.225.786 1.571-.45-.45 1.684.9.225.448-1.684 1.122 1.124.673-.562-1.234-1.235zm7.856-2.469l-.224-.784-1.571.449.335-1.573-.785-.225-.448 1.573-1.124-1.124-.672.563 1.235 1.234-1.683.448.336.785 1.571-.448-.449 1.684.897.225.45-1.684 1.122 1.122.674-.561-1.234-1.234z"
			/>
			<path
				fill="#F59E18"
				d="M18.347 10.462c1.389 2.361.596 5.42-1.765 6.809s-5.42.596-6.809-1.765c-1.389-2.361-.596-5.42 1.765-6.809s5.412-.61 6.809 1.765zm-6.702-3.426l-1.583.932-3.337-5.672 1.583-.931zM8.99 9.042l-.906 1.599-5.731-3.252.906-1.598zM7.696 12.094l.015 1.837-6.575.051-.015-1.837zm12.719 1.732l-.015-1.838 6.575-.05.015 1.837zM8.106 15.386l.931 1.583-5.672 3.337-.931-1.583zm11.879-4.858l-.932-1.582 5.672-3.337.931 1.583zM17.973 7.894l-1.598-.906 3.238-5.723 1.598.907zm-3.078-1.278l-1.837.015-.051-6.575 1.837-.015z"
			/>
			<path
				fill="#FFF"
				d="M12.372 23.064a3.573 3.573 0 0 1-3.574-3.574 3.557 3.557 0 0 1 3.574-3.555c.281 0 .543.037.823.094l.524.131.15-.524c.692-2.563 3.031-4.359 5.688-4.359s4.996 1.796 5.688 4.359l.15.524.524-.131c.262-.056.543-.094.823-.094 1.965 0 3.574 1.59 3.574 3.555s-1.59 3.574-3.574 3.574h-14.37z"
			/>
			<path
				fill="#4F4E4E"
				d="M19.557 11.838a5.328 5.328 0 0 1 5.145 3.948l.281 1.048 1.067-.243c.224-.056.468-.075.692-.075a3.006 3.006 0 0 1 3.012 2.994 3.01 3.01 0 0 1-3.012 3.012h-14.37A3.01 3.01 0 0 1 9.36 19.51a3.007 3.007 0 0 1 3.012-2.994c.224 0 .468.019.692.075l1.067.243.281-1.048a5.326 5.326 0 0 1 5.145-3.948m0-1.123c-2.975 0-5.482 2.021-6.23 4.771a3.91 3.91 0 0 0-.954-.112c-2.283 0-4.135 1.852-4.135 4.116a4.137 4.137 0 0 0 4.135 4.135h14.369a4.136 4.136 0 0 0 4.135-4.135 4.12 4.12 0 0 0-4.135-4.116 3.91 3.91 0 0 0-.954.112c-.749-2.75-3.257-4.771-6.231-4.771z"
			/>
			<path
				fill="#4F4E4E"
				d="M15.16 17.339a.486.486 0 0 1-.178-.035l-1.815-.748a.467.467 0 1 1 .357-.865l1.815.748a.467.467 0 0 1-.179.9z"
			/>
		</SVGIcon>
	);
}
