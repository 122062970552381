import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeschauerStarkIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zm9.986-1.27l-.757-1.286 4.609-2.709.757 1.285zm-1.633-2.14l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.513 15.649a2.034 2.034 0 0 1-2.027-2.025 2.037 2.037 0 0 1 2.497-1.978l.296.073.075-.283c.395-1.459 1.717-2.473 3.226-2.473s2.844 1.014 3.227 2.473l.074.283.297-.073a2.037 2.037 0 0 1 2.496 1.978 2.034 2.034 0 0 1-2.026 2.025h-8.135z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.579 9.258c1.372 0 2.571.928 2.93 2.251l.161.58.58-.136c.137-.024.26-.049.396-.049.951 0 1.719.766 1.719 1.719 0 .951-.767 1.718-1.719 1.718h-8.134a1.715 1.715 0 0 1-1.719-1.718 1.715 1.715 0 0 1 2.115-1.67l.58.136.161-.58a3.044 3.044 0 0 1 2.93-2.251m0-.617a3.635 3.635 0 0 0-3.521 2.707 2.343 2.343 0 0 0-2.881 2.275 2.343 2.343 0 0 0 2.336 2.336h8.134a2.343 2.343 0 0 0 2.336-2.336 2.343 2.343 0 0 0-2.881-2.275 3.638 3.638 0 0 0-3.523-2.707z"
			/>
			<path
				fill="#CAC9C8"
				d="M2.85 18.193c-1.279 0-2.376-1.098-2.376-2.377 0-1.278 1.097-2.375 2.376-2.375.183 0 .364 0 .548.091l.365.092.092-.364c.457-1.737 2.01-2.925 3.745-2.925 1.736 0 3.289 1.188 3.746 2.925l.092.364.364-.092c.184 0 .366-.091.548-.091 1.279 0 2.376 1.097 2.376 2.375 0 1.279-1.097 2.377-2.376 2.377h-9.5z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.6 10.7c1.553 0 3.015 1.097 3.381 2.649l.184.733.73-.184c.183 0 .274-.092.456-.092 1.098 0 2.01.914 2.01 2.01a2.027 2.027 0 0 1-2.01 2.011h-9.5A2.028 2.028 0 0 1 .84 15.816c0-1.096.914-2.01 2.011-2.01.183 0 .273 0 .457.092l.73.184.182-.732c.365-1.553 1.827-2.65 3.38-2.65m0-.729a4.223 4.223 0 0 0-4.111 3.198c-.182-.094-.456-.094-.639-.094-1.553 0-2.741 1.189-2.741 2.741 0 1.555 1.188 2.74 2.741 2.74h9.5c1.555 0 2.741-1.186 2.741-2.74 0-1.462-1.187-2.741-2.741-2.741-.182 0-.456 0-.639.094A4.224 4.224 0 0 0 7.6 9.971z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.676 14.392a.322.322 0 0 1-.13-.029l-1.187-.55a.307.307 0 0 1-.151-.41.308.308 0 0 1 .41-.151l1.188.55a.308.308 0 0 1-.13.59z"
			/>
			<path
				fill="#FFF"
				d="M9.152 21.572c-1.918 0-3.562-1.553-3.562-3.563 0-2.009 1.553-3.563 3.562-3.563.275 0 .549 0 .822.092l.458.091.09-.456c.642-2.559 3.015-4.296 5.573-4.296 2.649 0 4.934 1.737 5.573 4.296l.091.456.457-.091c.273-.092.547-.092.822-.092 1.919 0 3.563 1.554 3.563 3.563 0 2.011-1.553 3.563-3.563 3.563H9.152z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.187 10.519c2.375 0 4.476 1.644 5.116 3.928l.274.913.912-.182c.274-.092.458-.092.732-.092 1.644 0 3.015 1.371 3.015 3.015 0 1.645-1.37 3.015-3.015 3.015H9.152c-1.643 0-3.014-1.37-3.014-3.015 0-1.643 1.37-3.015 3.014-3.015.275 0 .457 0 .731.092l.914.182.274-.913c.64-2.284 2.741-3.928 5.116-3.928m0-1.004c-2.924 0-5.391 2.008-6.029 4.658-.275-.091-.64-.091-.914-.091-2.192 0-4.018 1.828-4.018 4.018 0 2.192 1.826 4.02 4.018 4.02h13.978c2.192 0 4.019-1.827 4.019-4.02 0-2.19-1.827-4.018-4.019-4.018-.274 0-.639 0-.912.091-.825-2.65-3.2-4.658-6.123-4.658z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.894 15.887a.445.445 0 0 1-.156-.03l-1.646-.642a.432.432 0 1 1 .315-.807l1.644.642a.431.431 0 0 1 .246.56.436.436 0 0 1-.403.277z"
			/>
			<g>
				<path
					fill="#0971B8"
					d="M12.898 25.409l-.182-.73-1.279.365.274-1.279-.64-.183-.365 1.37-.913-1.005-.549.548 1.006.914-1.28.365.182.732 1.281-.365-.367 1.278.731.183.274-1.28 1.005.913.548-.547-1.005-.914z"
				/>
			</g>
			<g>
				<path
					fill="#0971B8"
					d="M23.769 26.688l-.274-.638-1.278.364.366-1.37-.731-.092-.274 1.28-1.005-.914-.457.456.913 1.005-1.279.366.183.642 1.279-.368-.275 1.28.641.182.364-1.279 1.006.914.456-.457-1.005-1.004z"
				/>
			</g>
			<g>
				<path
					fill="#0971B8"
					d="M7.185 24.888l-.358-1.224-2.299.655.581-2.323-1.223-.31-.582 2.325-1.73-1.657-.878.915 1.717 1.656-2.298.655.358 1.224 2.299-.655-.581 2.323 1.237.31.568-2.324 1.731 1.656.877-.915-1.718-1.655z"
				/>
			</g>
			<g>
				<path
					fill="#0971B8"
					d="M19.823 21.207l-.357-1.224-2.299.656.581-2.324-1.225-.31-.58 2.324-1.73-1.656-.878.914 1.718 1.657-2.3.655.359 1.223 2.3-.653-.582 2.322 1.236.31.57-2.324 1.729 1.657.878-.915-1.718-1.658z"
				/>
			</g>
		</SVGIcon>
	);
}
