/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactSlick, { Settings } from 'react-slick';
// @ts-ignore
import { stylesForQueryString } from '../../commons/utils/index';
// @ts-ignore
import AutoAspectImage from '../AutoAspectImage/AutoAspectImage';
import SvgB3Next from './icon_b3_next.svg';
import SvgB3Previous from './icon_b3_previous.svg';
import styles from './Slider.scss';

// @ts-ignore
import objectFitImages from 'object-fit-images';

import 'slick-carousel/slick/slick.css';

interface IImage {
	content: {
		source: string;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		variants: any;
	};
	metadata: {
		title: string;
	};
}

interface IProps {
	images: [IImage];
	isTeaser: boolean;
	// onChange: () => void;
	onImageChange: () => void;
	containerOffsetWidth: number;
}

interface IState {
	isContainerAvailable: boolean;
}

const NextArrowComponent = (props: any) => {
	const { className, style, onClick } = props;
	return <SvgB3Next className={className} style={style} onClick={onClick} />;
};

const PrevArrowComponent = (props: any) => {
	const { className, style, onClick } = props;
	return <SvgB3Previous className={className} style={style} onClick={onClick} />;
};

export default class Slider extends React.Component<IProps, IState> {
	public state: IState = {
		isContainerAvailable: false,
	};

	private slickContainerDOMNode: Element | Text | null = null;

	public componentDidMount() {
		this.setState({ isContainerAvailable: true });
		objectFitImages('img.object-fit-image');
	}

	public shouldComponentUpdate(nextProps: IProps, nextState: IState) {
		let shouldUpdateBecauseOfImagesChanged = false;

		if (this.props.images.length !== nextProps.images.length) {
			shouldUpdateBecauseOfImagesChanged = true;
		}
		const newImages = nextProps.images.filter((image, index) => {
			return index >= this.props.images.length || image.content.source !== this.props.images[index].content.source;
		});

		shouldUpdateBecauseOfImagesChanged = newImages.length > 0;

		const shouldUpdate = shouldUpdateBecauseOfImagesChanged || this.state.isContainerAvailable !== nextState.isContainerAvailable;

		return shouldUpdate;
	}

	public render() {
		const slides = this.state.isContainerAvailable
			? this.renderSlides({
					images: this.props.images,
					isTeaser: this.props.isTeaser,
					// onChange: this.props.onChange,
			  })
			: [];

		const settings: Settings = {
			dots: false,
			infinite: true,
			nextArrow: <NextArrowComponent />,
			prevArrow: <PrevArrowComponent />,
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 500,
			vertical: false,
		};

		return (
			<div className={cx(styles.sliderWrapper)}>
				<ReactSlick
					ref={el => {
						if (el) {
							// eslint-disable-next-line react/no-find-dom-node
							this.slickContainerDOMNode = ReactDOM.findDOMNode(el);
						}
					}}
					{...settings}
					afterChange={this.props.onImageChange}
				>
					{slides}
				</ReactSlick>
			</div>
		);
	}

	private renderSlides({ images, isTeaser = false /*, onChange */ }: { images: [IImage]; isTeaser: boolean /* onChange: () => void */ }) {
		return images.map((image, i) => {
			if (image.content.source.indexOf('blob:') === 0) {
				const imageURL = isTeaser ? image.content.variants.three_by_one : image.content.source;
				const backgroundCropStyles = stylesForQueryString(imageURL, this.props.containerOffsetWidth, isTeaser ? 3 / 2.49 : 1);

				// @ts-ignore
				return <div className={cx(styles.slide)} style={backgroundCropStyles} key={i} />;
			} else {
				return (
					<div className={cx('content-slides', styles.slide)} key={i}>
						{/* following could be used as enhancement for autoaspect image when we use new image server */}
						{/* <img className={cx('object-fit-image', styles.slideImage)} src={image.content.source} alt="" /> */}
						<AutoAspectImage {...image.content} container={this.slickContainerDOMNode} title={image.metadata.title} />
					</div>
				);
			}
		});
	}
}
