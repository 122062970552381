import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterGraupelSonnigIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zM5.677 15.422l.755 1.286-4.609 2.71-.756-1.287zm9.652-3.946l-.757-1.286 4.609-2.709.757 1.285zM13.696 9.336l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<circle fill="#FFF" cx="1.919" cy="20.998" r="1.098" />
			<path
				fill="#4F4E4E"
				d="M1.919 22.284a1.287 1.287 0 0 1 0-2.571 1.286 1.286 0 0 1 0 2.571zm0-2.197a.912.912 0 0 0 0 1.823.913.913 0 0 0 0-1.823z"
			/>
			<circle fill="#FFF" cx="3.565" cy="26.485" r="1.097" />
			<path
				fill="#4F4E4E"
				d="M3.565 27.77a1.284 1.284 0 1 1 .004-2.568 1.284 1.284 0 0 1-.004 2.568zm0-2.195a.912.912 0 0 0 0 1.821.91.91 0 1 0 0-1.821z"
			/>
			<circle fill="#FFF" cx="7.774" cy="24.199" r="1.097" />
			<path
				fill="#4F4E4E"
				d="M7.774 25.484A1.286 1.286 0 0 1 6.49 24.2c0-.708.576-1.285 1.284-1.285a1.286 1.286 0 0 1 0 2.569zm0-2.195a.912.912 0 0 0 0 1.823.912.912 0 0 0 0-1.823z"
			/>
			<g>
				<circle fill="#FFF" cx="12.254" cy="26.485" r="1.097" />
				<path
					fill="#4F4E4E"
					d="M12.254 27.77a1.284 1.284 0 1 1 .004-2.568 1.284 1.284 0 0 1-.004 2.568zm0-2.195a.91.91 0 1 0 0 1.821.912.912 0 0 0 .913-.909.914.914 0 0 0-.913-.912z"
				/>
			</g>
			<g>
				<circle fill="#FFF" cx="20.761" cy="24.841" r="1.098" />
				<path
					fill="#4F4E4E"
					d="M20.761 26.123a1.284 1.284 0 1 1 .002-2.568 1.284 1.284 0 0 1-.002 2.568zm0-2.193a.91.91 0 1 0 0 1.82.91.91 0 0 0 0-1.82z"
				/>
			</g>
			<g>
				<path
					fill="#FFF"
					d="M21.486 15.842a2.043 2.043 0 0 1-2.035-2.036c0-1.118.918-2.035 2.035-2.035.161 0 .31.012.472.051l.298.074.074-.287a3.36 3.36 0 0 1 3.239-2.481 3.348 3.348 0 0 1 3.24 2.481l.075.287.298-.074c.148-.039.309-.051.471-.051 1.118 0 2.037.918 2.037 2.035 0 1.119-.92 2.036-2.037 2.036h-8.167z"
				/>
				<path
					fill="#4F4E4E"
					d="M25.569 9.427c1.378 0 2.583.931 2.943 2.258l.162.584.582-.136c.137-.023.26-.049.396-.049.956 0 1.726.767 1.726 1.723s-.77 1.726-1.726 1.726h-8.166c-.956 0-1.725-.77-1.725-1.726a1.72 1.72 0 0 1 2.123-1.674l.583.136.161-.584a3.053 3.053 0 0 1 2.941-2.258m0-.622a3.651 3.651 0 0 0-3.535 2.72 2.351 2.351 0 0 0-2.895 2.282 2.352 2.352 0 0 0 2.347 2.347h8.166a2.352 2.352 0 0 0 2.347-2.347 2.351 2.351 0 0 0-2.893-2.282 3.651 3.651 0 0 0-3.537-2.72z"
				/>
				<path
					fill="#C9C8C8"
					d="M9.112 21.859a3.54 3.54 0 0 1-3.533-3.533 3.539 3.539 0 0 1 3.533-3.534c.273 0 .549.037.821.1l.473.113.124-.461a5.8 5.8 0 0 1 5.61-4.328 5.803 5.803 0 0 1 5.61 4.303l.124.461.473-.113c.274-.063.548-.1.821-.1a3.537 3.537 0 0 1 3.532 3.534 3.537 3.537 0 0 1-3.532 3.531H9.112v.027z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.14 10.713a5.328 5.328 0 0 1 5.138 3.93l.248.933.935-.224a3.45 3.45 0 0 1 .697-.085 3.04 3.04 0 0 1 3.035 3.035 3.04 3.04 0 0 1-3.035 3.035H9.099a3.038 3.038 0 0 1-3.034-3.035 3.039 3.039 0 0 1 3.034-3.035c.238 0 .474.023.697.085l.933.224.25-.933a5.375 5.375 0 0 1 5.161-3.93m0-.996a6.308 6.308 0 0 0-6.095 4.664 4.03 4.03 0 1 0-.934 7.949h14.057a4.029 4.029 0 0 0 4.03-4.03 4.029 4.029 0 0 0-4.962-3.919 6.309 6.309 0 0 0-6.096-4.664z"
				/>
				<path
					fill="#C9C8C8"
					d="M20.03 19.81c-.092-.093-.183-.093-.275-.093l-3.932 1.007 2.652-5.031v-.273a.196.196 0 0 0-.183-.183l-2.104-.732c-.182-.092-.274 0-.365.183l-2.196 5.944-.64 1.739-.183.548c0 .091 0 .183.092.274.091.092.184.092.273.092l3.385-.914h.092l-2.378 6.036v.275c0 .091.09.091.184.183l.547.181h.092c.091 0 .183-.09.274-.181l3.476-6.494.914-1.739.367-.639c0 .092 0-.093-.092-.183z"
				/>
				<path
					fill="#A03416"
					d="M20.03 19.81c-.092-.093-.183-.093-.275-.093l-3.932 1.007 2.652-5.031v-.273a.196.196 0 0 0-.183-.183l-2.104-.732c-.182-.092-.274 0-.365.183l-2.196 5.944-.64 1.739-.183.548c0 .091 0 .183.092.274.091.092.184.092.273.092l3.385-.914h.092l-2.378 6.036v.275c0 .091.09.091.184.183l.547.181h.092c.091 0 .183-.09.274-.181l3.476-6.494.914-1.739.367-.639c0 .092 0-.093-.092-.183z"
				/>
			</g>
		</SVGIcon>
	);
}
