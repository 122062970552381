import SVGIcon from '../SVGIcon';
import React from 'react';

export default function BurgerGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#94C01C"
				d="M31.985 15.356c.355 8.829-6.514 16.274-15.342 16.629C7.812 32.341.368 25.471.013 16.644-.342 7.813 6.527.369 15.356.015c8.829-.356 16.274 6.513 16.629 15.341z"
			/>
			<path
				fill="#FFF"
				d="M10.776 11.169h10.447a.508.508 0 0 0 .508-.51.508.508 0 0 0-.508-.509H10.776a.51.51 0 0 0 0 1.019zm10.448 4.162H10.776a.51.51 0 0 0 0 1.02h10.447a.509.509 0 0 0 .508-.51.508.508 0 0 0-.507-.51zm0 5.499H10.776a.51.51 0 0 0 0 1.02h10.447a.508.508 0 0 0 .508-.51.508.508 0 0 0-.507-.51z"
			/>
		</SVGIcon>
	);
}
