import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeregenBedecktIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M8.312 22.896l-.183-.733-1.282.366.367-1.282-.734-.182-.366 1.373L5.2 21.43l-.55.55 1.007.916-1.282.365.184.733 1.281-.366-.366 1.281.733.183.366-1.281.915.916.549-.55-1.006-.916zm9.98 1.006l-.184-.641-1.282.367.366-1.282-.731-.183-.366 1.281-.916-.915-.55.458 1.008 1.007-1.282.366.182.641 1.281-.366-.273 1.373.641.183.367-1.373.915.916.549-.459-1.007-1.006zm-14.1-4.028c.273-.458.273-1.189.273-1.739 0-.733.093-1.373.093-1.373s-.366.457-1.007.823c-.55.366-1.374.642-1.557 1.008-.367.641-.184 1.373.457 1.739.642.366 1.375.183 1.741-.458zm8.514 3.48c.275-.458.275-1.191.275-1.74 0-.731.091-1.373.091-1.373s-.366.458-1.007.824c-.549.365-1.373.641-1.557 1.007-.366.642-.183 1.373.458 1.739.641.366 1.375.091 1.74-.457z"
			/>
			<path
				fill="#FFF"
				d="M2.736 15.378a2.374 2.374 0 0 1-2.374-2.374 2.364 2.364 0 0 1 2.374-2.362c.186 0 .36.025.547.062l.348.087.099-.348c.46-1.703 2.014-2.896 3.779-2.896s3.319 1.193 3.779 2.896l.099.348.348-.087a2.372 2.372 0 0 1 2.921 2.3 2.374 2.374 0 0 1-2.374 2.374H2.736z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.509 7.92a3.54 3.54 0 0 1 3.418 2.623l.186.696.709-.162c.149-.037.311-.05.46-.05 1.106 0 2.001.895 2.001 1.989a2 2 0 0 1-2.001 2.001H2.736a2 2 0 0 1-2.001-2.001c0-1.094.895-1.989 2.001-1.989.149 0 .311.012.46.05l.709.162.186-.696A3.54 3.54 0 0 1 7.509 7.92m0-.745a4.295 4.295 0 0 0-4.139 3.17 2.745 2.745 0 0 0-3.381 2.66 2.748 2.748 0 0 0 2.747 2.747h9.546a2.748 2.748 0 0 0 2.747-2.747 2.738 2.738 0 0 0-3.381-2.66 4.295 4.295 0 0 0-4.139-3.17z"
			/>
			<path fill="#4F4E4E" d="M4.588 11.575a.307.307 0 0 1-.118-.024l-1.206-.497a.31.31 0 0 1 .236-.574l1.206.497a.31.31 0 0 1-.118.598z" />
			<path
				fill="#C9C8C8"
				d="M21.49 12.852c-1.118 0-2.037-.919-2.037-2.037s.919-2.037 2.037-2.037c.161 0 .311.012.472.05l.298.074.075-.286c.397-1.466 1.726-2.484 3.242-2.484s2.857 1.018 3.242 2.484l.075.286.298-.074c.149-.037.31-.05.472-.05 1.118 0 2.037.919 2.037 2.037s-.919 2.037-2.037 2.037H21.49z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.576 6.431c1.379 0 2.583.931 2.944 2.26l.161.584.584-.137c.137-.025.261-.05.397-.05.956 0 1.726.77 1.726 1.726s-.77 1.726-1.726 1.726H21.49c-.956 0-1.726-.77-1.726-1.726s.77-1.726 1.726-1.726c.137 0 .273.012.397.05l.584.137.161-.584a3.058 3.058 0 0 1 2.944-2.26m0-.621a3.655 3.655 0 0 0-3.54 2.72 2.353 2.353 0 0 0-2.893 2.285 2.353 2.353 0 0 0 2.347 2.347h8.172a2.353 2.353 0 0 0 2.347-2.347 2.353 2.353 0 0 0-2.893-2.285 3.655 3.655 0 0 0-3.54-2.72z"
			/>
			<path fill="#4F4E4E" d="M23.229 9.636a.32.32 0 0 1-.117-.023l-1.217-.497a.31.31 0 1 1 .235-.575l1.217.497a.31.31 0 0 1-.118.598z" />
			<g>
				<path
					fill="#C9C8C8"
					d="M9.103 18.874a3.542 3.542 0 0 1-3.536-3.536 3.542 3.542 0 0 1 3.536-3.536c.274 0 .548.037.822.1l.473.112.124-.461a5.81 5.81 0 1 1 11.23-.025l.124.461.473-.112c.274-.062.548-.1.822-.1a3.542 3.542 0 0 1 3.536 3.536 3.542 3.542 0 0 1-3.536 3.536H9.103v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.138 7.719a5.331 5.331 0 0 1 5.142 3.934l.249.934.934-.224c.237-.05.461-.087.697-.087a3.042 3.042 0 0 1 3.038 3.038 3.042 3.042 0 0 1-3.038 3.038H9.091a3.042 3.042 0 0 1-3.038-3.038 3.042 3.042 0 0 1 3.038-3.038c.237 0 .473.025.697.087l.934.224.249-.934a5.383 5.383 0 0 1 5.167-3.934m0-.996a6.312 6.312 0 0 0-6.101 4.669 4.033 4.033 0 0 0-4.968 3.922 4.033 4.033 0 0 0 4.034 4.034h14.069a4.033 4.033 0 0 0 4.034-4.034 4.033 4.033 0 0 0-4.968-3.922 6.312 6.312 0 0 0-6.1-4.669z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.792 13.122a.44.44 0 0 1-.173-.036l-1.606-.697a.435.435 0 1 1 .347-.799l1.606.697a.435.435 0 0 1-.174.835z"
				/>
			</g>
		</SVGIcon>
	);
}
