import * as config from '../../config.webapp.ts';

const LOAD = 'br3/weather/LOAD';
const LOAD_SUCCESS = 'br3/weather/LOAD_SUCCESS';
const LOAD_FAIL = 'br3/weather/LOAD_FAIL';

const DEFAULT_ZIPCODE = 80331;

const initialState = {
	loaded: false,
};

const serviceUrl = config.SERVICE.weather;

export default function reducer(state = initialState, action = {}) {
	switch (action.type) {
		case LOAD:
			return {
				...state,
				loading: true,
			};

		case LOAD_SUCCESS:
			return {
				...state,
				loaded: true,
				loading: false,
				data: action.result,
			};

		case LOAD_FAIL:
			return {
				...state,
				loaded: state.loaded,
				loading: false,
				error: action.error,
			};

		default:
			return state;
	}
}

export function isLoaded(globalState) {
	return globalState.weather && globalState.weather.loaded;
}

export function load(zipcode) {
	if (typeof zipcode !== 'number') zipcode = DEFAULT_ZIPCODE;

	let url = zipcode ? `${serviceUrl}/zipcode/${zipcode}` : serviceUrl;
	return {
		types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
		promise: client => client.get(`${url}`, { headers: { Accept: 'application/json' } }),
	};
}
