import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SturmRegenIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#3F3F3F"
				d="M29.341 5.351c6.484 7.124-7.219 7.346-17.485 7.436l.843-2.016-12.711 4.021 8.139 6.917.961-2.299c13.949-1.557 29.199-6.394 20.253-14.059z"
			/>
			<path
				fill="#0971B8"
				d="M13.405 23.344c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.284 1.284 0 0 0 1.747-.472zm6.622 0c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.283 1.283 0 0 0 1.747-.472zm6.62 0c.255-.432.255-1.178.294-1.786.04-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.283 1.283 0 0 0 1.747-.472zm-10.652 2.663c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.283 1.283 0 0 0 1.747-.472zm6.497 0c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.283 1.283 0 0 0 1.747-.472z"
			/>
		</SVGIcon>
	);
}
