import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import Immutable from 'immutable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './WeatherWidget.scss';
import { IconMap } from '../BRIcon/index';

export default class WeatherWidget extends Component {
	static propTypes = {
		weather: PropTypes.object,
	};

	getCleanedDays() {
		const { days } = this.props.weather;
		return Object.keys(days).map(dayKey => {
			const day = Immutable.fromJS(days[dayKey]).toJS();

			day.dateString = moment(day.date).format('dddd, D., MMMM, YYYY');
			day.forecast = Object.keys(day.sections).map(sectionKey => {
				const section = day.sections[sectionKey];
				return section;
			});
			delete day.sections;
			return day;
		});
	}

	render() {
		var weatherForecast = [];
		if (this.props.weather) {
			const days = this.getCleanedDays();
			const today = days[0];
			const tomorrow = days[1];

			weatherForecast = today.forecast.splice(0, 2);
			if (weatherForecast.length <= 1) {
				weatherForecast.push(tomorrow.forecast.pop());
			}
		}

		let weatherItems = weatherForecast.map((forecast, index) => {
			let Icon = IconMap.get(forecast.weathersituation.style);
			return (
				<div key={index} className={classNames(styles.weatherContainer)}>
					<div>
						<span className={classNames(styles.day)}>{forecast.formattedhour}</span>
					</div>
					<div className={classNames(styles.weatherContainerIcon)}>
						<Icon />
					</div>
					<div>
						<span className={classNames(styles.degree)}>
							{forecast.temperature.value}
							{forecast.temperature.Unit}
						</span>
					</div>
				</div>
			);
		});

		return (
			<div className={styles.weatherWidget}>
				<h1 className={classNames(styles.headline)}>Wetter</h1>
				<div className={classNames(styles.container)}>{weatherItems}</div>
				<div className={classNames(styles.linkContainer)}>
					<Link to="/wetter" className={classNames(styles.link)}>
						mehr Wetter
					</Link>
				</div>
			</div>
		);
	}
}
