import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoaded as isServiceLoaded, load as loadService } from '../../redux/modules/privacyPolicy';
import { bindActionCreators } from 'redux';
import DocumentMeta from 'react-document-meta';
import PrivacyPolicy from '../../br3-components/components/StaticPages/PrivacyPolicy';
interface IProps {
	data: { html: string };
	error: any;
	loading: boolean;
	loadService: () => void;
}

/**
 * Loads the privacy policy and mounts the displaying component.
 */
class PrivacyPolicyViewConnector extends Component<IProps> {
	static fetchData(getState: any, dispatch: any) {
		if (!isServiceLoaded(getState())) {
			return dispatch(loadService());
		}
	}

	render() {
		const metaSection = 'Datenschutzerklärung';
		const metaTitle = `Bayern 3 - ${metaSection}`;
		const meta: any = {
			title: metaTitle,
			meta: {
				property: {
					'og:title': metaTitle,
					'twitter:site': '@bayern3',
					'twitter:creator': '@bayern3',
					'twitter:title': metaTitle,
					'ati:section': metaSection,
					'ati:doctype': metaSection,
				},
			},
		};
		return (
			!this.props.loading && (
				<>
					<PrivacyPolicy data={this.props.data} />
					<DocumentMeta {...meta} extend />
				</>
			)
		);
	}
}

export default connect(
	(state: any) => ({
		data: state.privacyPolicy.data,
		error: state.privacyPolicy.error,
		loading: state.privacyPolicy.loading,
	}),
	dispatch => bindActionCreators({ loadService }, dispatch)
)(PrivacyPolicyViewConnector);
