import { startTransition, stopTransition } from '../redux/modules/transition';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';

export const resolveRelationship = (relationshipCollection, fullCollection) =>
	relationshipCollection.map(relationshipItem =>
		fullCollection.find(fullItem => relationshipItem.id === fullItem.id && relationshipItem.type === fullItem.type)
	);

export function resolveAllRelationships(response) {
	let included = response.included;
	let filledResponse = JSON.parse(JSON.stringify(response));

	for (let relationship of Object.values(filledResponse.data.relationships)) {
		relationship.data = resolveRelationship(relationship.data, included);
	}

	return filledResponse;
}

export const resolveAllPromisedRelationships = promise =>
	new Promise((resolve, reject) => promise.then(response => resolve(resolveAllRelationships(response)), reject));

/**
 * Wraps a component to achieve data prefetching.
 */
export const withPrefetch = Comp => {
	const fetchData = async props => {
		if (props) {
			try {
				props.reduxDispatch(startTransition());
				if (Comp && Comp.fetchData) {
					await Comp.fetchData(props.getGlobalState, props.reduxDispatch, props.location, props.match.params);
				}
			} catch (e) {
				console.error('failed to fetchData', JSON.stringify(e));
			} finally {
				props.reduxDispatch(stopTransition());
			}
		}
	};
	return connect(
		state => ({
			getGlobalState: () => state,
		}),
		dispatch => ({
			reduxDispatch: dispatch,
		})
	)(
		frontloadConnect(fetchData, {
			onMount: true,
			onUpdate: true,
			_experimental_updateFunc: (prevProps, props) => !prevProps || prevProps.location !== props.location,
		})(Comp)
	);
};

export const compose = (...fns) => fns.reduceRight((prevFn, nextFn) => (...args) => nextFn(prevFn(...args)), value => value);
