export const isClient = typeof window !== 'undefined';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const accessor = isClient ? (window as Window & { env: any }) : (process as { env: any });

export const IS_CMS = accessor.env.IS_CMS;
export const CACHE_DEFAULT_MAX_AGE = accessor.env.CACHE_DEFAULT_MAX_AGE;
export const IS_DEVELOPMENT = accessor.env.NODE_ENV !== 'production';
export const LOG_LEVEL = accessor.env.LOG_LEVEL!;

export const PUBLIC_FACING_ORIGIN_WEBAPP = accessor.env.PUBLIC_FACING_ORIGIN_WEBAPP!;
export const PUBLIC_FACING_ORIGIN_WEBCMS = accessor.env.PUBLIC_FACING_ORIGIN_WEBCMS!;
export const ORIGIN_COFFEEMACHINE = isClient
	? accessor.env.PUBLIC_FACING_ORIGIN_COFFEEMACHINE!
	: accessor.env.INTERNAL_FACING_ORIGIN_COFFEEMACHINE!;
export const ORIGIN_TEAPOT = isClient ? accessor.env.PUBLIC_FACING_ORIGIN_TEAPOT! : accessor.env.INTERNAL_FACING_ORIGIN_TEAPOT!;
export const PUBLIC_FACING_ORIGIN_FILESERVERDOWNLOAD = accessor.env.PUBLIC_FACING_ORIGIN_FILESERVERDOWNLOAD!;

export const TRACKING_ATINTERNET_SCRIPT_URL = accessor.env.TRACKING_ATINTERNET_SCRIPT_URL!;
export const TRACKING_IVW_SCRIPT_URL = accessor.env.TRACKING_IVW_SCRIPT_URL!;
export const TRACKING_GTM_ID = accessor.env.TRACKING_GTM_ID!;
export const TRACKING_GTM_SRC = accessor.env.TRACKING_GTM_SRC!;

export const FACEBOOK_APP_ID = accessor.env.FACEBOOK_APP_ID!;

export const LOCALSTORAGE_KEY_AUTHTOKEN = 'AUTH_TOKEN';
export const LOCALSTORAGE_KEY_USER = 'USER';
export const USE_SOCKETS_FOR_PLAYLIST = true;

export const API_ENDPOINT = {
	article: '/api/v1/articles',
	teaserBoard: '/api/v1/boards',
	navigation: '/api/v1/navigations',
	banner: '/api/v1/banners',
	signin: '/api/v1/auth/signin',
	search: '/api/v1/search',
	imageupload: '/api/v1/imageupload',
};

export const SERVICE = {
	traffic: `${ORIGIN_TEAPOT}/api/v1/traffic`,
	weather: `${ORIGIN_TEAPOT}/api/v1/weather`,
	news: `${ORIGIN_TEAPOT}/api/v1/news?limit=15`,
	privacyPolicy: `${ORIGIN_TEAPOT}/api/v1/privacy-policy`,
	playlist: `${ORIGIN_TEAPOT}/api/v1/playlist`,
	cover: `${ORIGIN_TEAPOT}/api/v1/cover`,
	piwik: `${ORIGIN_TEAPOT}/api/v1/piwik`,
	location: `${ORIGIN_TEAPOT}/api/v1/location`,
	soundcloud: `${ORIGIN_TEAPOT}/api/v1/soundcloud`,
	facebook: `${ORIGIN_TEAPOT}/api/v1/facebook`,
	youtube: `${ORIGIN_TEAPOT}/api/v1/youtube/thumbnail`,
	webcam: 'https://b7cams.br.de/webcams/b7',
	musicquery: 'https://music-query.brnext.de',
	radiostream: 'https://dispatcher.rndfnk.com/br/br3/live/mp3/mid?aggregator=web',
};

export const IS_SPECTRUM_ANALYZER_DATA_FAKED = true;

export const POPUP_PLAYER = {
	popupUrl: 'https://www.br.de/radio/live/#/mediathek/audio/bayern3/bayern3-audio-livestream-100~radioplayer.json',
	actionStatusUpdate: 'BR_RADIO/updatePlayerStatus',
	actionPlay: 'BR_RADIO/play',
	actionPause: 'BR_RADIO/pause',
	popupOptions: 'height=800px,width=475px,resizable=no,scrollbars=no,status=no,toolbar=no,menubar=no,location=no',
	allowedDomains: ['www.br.de', 'sophora-qs-www.br.de'],
};
