import React from 'react';
import classNames from 'classnames';
import trackEvent from '../../commons/tracking/trackers';
import * as config from '../../../config.webapp';

import SvgPlayerPlay from './icon_b3_player_play.svg';
import SvgPlayerPause from './icon_b3_player_pause.svg';
import SvgPlayerBusy from './icon_b3_player_busy.svg';
import styles from './RadioPlayer.scss';

enum PlayerStatus {
	STOPPED = 'stopped',
	STARTING = 'starting',
	PLAYING = 'playing',
}

interface IState {
	playerStatus: PlayerStatus;
	playerAlreadyUsed: boolean;
}

export default class PopupPlayer extends React.Component<IState> {
	state = {
		playerStatus: PlayerStatus.STOPPED,
		playerAlreadyUsed: false,
	};
	popup: Window | null = null;

	launchPopup = () => {
		this.popup = window.open(config.POPUP_PLAYER.popupUrl, 'br_popup_player', config.POPUP_PLAYER.popupOptions);
	};

	handlePopupPlayer = () => {
		this.setState({ playerStatus: PlayerStatus.STARTING });

		if (this.popup === null || this.popup.closed) {
			this.launchPopup();
		} else {
			switch (this.state.playerStatus) {
				case PlayerStatus.STOPPED:
					this.popup.postMessage({ action: config.POPUP_PLAYER.actionPlay }, '*');
					trackEvent('RadioPlayer', this.state.playerAlreadyUsed ? 'play' : 'firstPlay');
					break;
				case PlayerStatus.PLAYING:
					this.popup.postMessage({ action: config.POPUP_PLAYER.actionPause }, '*');
					trackEvent('RadioPlayer', 'pause');
					break;
			}
		}

		this.setState({
			playerAlreadyUsed: true,
		});
	};

	receiveMessageFromPopup = (event: MessageEvent) => {
		if (
			config.POPUP_PLAYER.allowedDomains.indexOf(event.origin.split('/')[2]) === -1 ||
			config.POPUP_PLAYER.actionStatusUpdate !== event.data.action
		)
			return;

		if (event.data.isPlaying) {
			if (this.popup === null || this.popup.closed) {
				this.launchPopup();
			}
			this.setState({ playerStatus: PlayerStatus.PLAYING });
		} else {
			this.setState({ playerStatus: PlayerStatus.STOPPED });
		}
	};

	componentDidMount() {
		window.addEventListener('message', this.receiveMessageFromPopup);
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.receiveMessageFromPopup);
	}

	render = () => {
		let ActionButton = SvgPlayerPlay;
		let buttonClass = classNames(styles.button);

		if (this.state.playerStatus === PlayerStatus.STARTING) {
			buttonClass = classNames(styles.rotobutton);
			ActionButton = SvgPlayerBusy;
		} else if (this.state.playerStatus === PlayerStatus.PLAYING) {
			ActionButton = SvgPlayerPause;
		}

		return (
			<button onClick={this.handlePopupPlayer} className={buttonClass}>
				<ActionButton className={classNames(styles.currentColorButton)} />
			</button>
		);
	};
}
