import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Webcam.scss';
import ContentElementImage from '../ContentElementImage/ContentElementImage.js';
import TeaserBox from '../TeaserBox/TeaserBox.js';
import DocumentMeta from 'react-document-meta';
import * as config from '../../../config.webapp';

const WEBCAM_IMAGES = [
	`${config.SERVICE.webcam}/bayern3_cam1_16_9.jpg?0`,
	`${config.SERVICE.webcam}/bayern3_cam2_16_9.jpg?0`,
	`${config.SERVICE.webcam}/bayern3_cam3_16_9.jpg?0`,
];
const RELOAD_TIME = 30 * 1000; // 30s

/**
 * Component to display the webcam page.
 */
export default class Webcam extends Component {
	state = {
		webcams: WEBCAM_IMAGES,
	};

	componentDidMount = () => {
		this.timer = setInterval(this.reloadCams, RELOAD_TIME);
	};

	componentWillUnmount = () => {
		clearInterval(this.timer);
	};

	/**
	 * Resets the state to reload the webcam images.
	 */
	reloadCams = () => {
		this.setState({
			webcams: this.state.webcams.map(cam => {
				const camParts = cam.split('?');
				camParts[1] = Number(camParts[1]) + 1;
				return camParts.join('?');
			}),
		});
	};

	render() {
		return (
			<div className={classNames(styles.webcam)}>
				<ContentElementImage
					data={{
						element_type: 'gallery',
						content: {
							images: [
								{
									element_type: 'image',
									content: {
										source: require('./webcam.jpg'),
										variants: {
											three_by_one: require('./webcam.jpg'),
											three_by_two: require('./webcam.jpg'),
											one_by_one: require('./webcam.jpg'),
										},
									},
									metadata: {
										title: '',
										tags: [],
										copyright: '',
									},
								},
							],
						},
					}}
					headline="Webcam"
					title="Unsere Moderatoren live im Studio"
					isTeaser={true}
				/>

				<div className="container">
					<main className={styles.webcamContainer}>
						<TeaserBox text="Radio hören ist das eine - Radio sehen das andere. Und hier hast Du die Chance, den Menschen hinterm Mikro bei der Arbeit zuzusehen. Zumindest alle 30 Sekunden, denn dann gibt's neue Webcambilder." />
						{this.state.webcams.map((cam, i) => {
							return (
								<div key={'webcam' + i}>
									<img className={styles.webcamImage} src={cam} />

									<div className={styles.loading} />
									<h2>Studio Webcam 0{i + 1}</h2>
								</div>
							);
						})}
					</main>
				</div>
				<DocumentMeta
					meta={{
						title: 'Bayern 3 Webcam',
						property: {
							'ati:section': 'Webcam',
							'ati:doctype': 'Webcam',
							'ati:headline': 'Webcam',
							'ati:title': 'Unsere Moderatoren live im Studio',
						},
					}}
					extend
				/>
			</div>
		);
	}
}
