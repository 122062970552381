import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenschauerNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.446 13.479a6.447 6.447 0 0 1-5.345 2.822c-3.58 0-6.476-2.896-6.476-6.476s2.896-6.476 6.476-6.476c.373 0 .746.037 1.106.099a5.62 5.62 0 0 0 3.381 10.105 9.11 9.11 0 0 0 .858-.074zm3.655-9.547l-.038 2.337 2.275-.547-1.516 1.765 2.088 1.045-2.312.372.932 2.138-2.001-1.193-.659 2.238-.771-2.201-1.951 1.293.833-2.188-2.325-.261 2.039-1.143-1.604-1.691 2.288.436-.15-2.325 1.48 1.802z"
			/>
			<path
				fill="#0971B8"
				d="M4.205 23.709c.273-.457.273-1.188.273-1.737 0-.731.092-1.372.092-1.372s-.365.457-1.006.822c-.549.366-1.373.55-1.555 1.008-.367.64-.184 1.371.457 1.737.639.366 1.372.184 1.739-.458zm2.377 4.301c.275-.458.275-1.19.275-1.739 0-.732.092-1.372.092-1.372s-.367.458-1.008.824c-.547.365-1.371.548-1.555 1.006-.365.64-.182 1.371.457 1.738.551.365 1.374.182 1.739-.457zm5.215-1.464c.273-.458.273-1.189.273-1.738 0-.733.092-1.371.092-1.371s-.365.456-1.006.822c-.549.365-1.373.64-1.555 1.007-.367.64-.184 1.372.457 1.737s1.374.184 1.739-.457z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.748 18.537a2.372 2.372 0 0 1-2.372-2.372 2.362 2.362 0 0 1 2.372-2.36c.186 0 .36.025.546.062l.348.087.099-.348c.46-1.702 2.012-2.894 3.776-2.894s3.316 1.192 3.776 2.894l.099.348.348-.087a2.37 2.37 0 0 1 2.918 2.298 2.372 2.372 0 0 1-2.372 2.372H2.748z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.518 11.085a3.538 3.538 0 0 1 3.416 2.621l.186.696.708-.161c.149-.037.311-.05.46-.05 1.105 0 2 .894 2 1.987 0 1.105-.894 2-2 2h-9.54c-1.105 0-2-.894-2-2 0-1.093.894-1.987 2-1.987.149 0 .31.012.46.05l.708.161.186-.696a3.537 3.537 0 0 1 3.416-2.621m0-.745a4.292 4.292 0 0 0-4.136 3.167 2.744 2.744 0 0 0-3.378 2.658 2.746 2.746 0 0 0 2.745 2.745h9.539a2.746 2.746 0 0 0 2.745-2.745 2.735 2.735 0 0 0-2.745-2.732c-.224 0-.435.025-.633.074a4.295 4.295 0 0 0-4.137-3.167z"
			/>
			<path fill="#4F4E4E" d="M4.599 14.737a.307.307 0 0 1-.118-.024l-1.205-.497a.31.31 0 1 1 .236-.574l1.205.497a.31.31 0 0 1-.118.598z" />
			<path
				fill="#C9C8C8"
				d="M21.486 16.013c-1.117 0-2.035-.918-2.035-2.035s.918-2.035 2.035-2.035a2 2 0 0 1 .472.05l.298.074.074-.285A3.36 3.36 0 0 1 25.569 9.3a3.349 3.349 0 0 1 3.239 2.482l.074.285.298-.074c.149-.037.31-.05.472-.05 1.117 0 2.035.918 2.035 2.035s-.918 2.035-2.035 2.035h-8.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.568 9.597c1.378 0 2.581.931 2.941 2.259l.161.583.583-.136c.136-.025.261-.05.397-.05.956 0 1.725.769 1.725 1.725s-.769 1.725-1.725 1.725h-8.166c-.956 0-1.725-.769-1.725-1.725s.769-1.725 1.725-1.725c.137 0 .273.012.397.05l.583.136.161-.583a3.057 3.057 0 0 1 2.943-2.259m0-.621a3.653 3.653 0 0 0-3.537 2.718 2.351 2.351 0 0 0-2.891 2.284 2.35 2.35 0 0 0 2.345 2.345h8.166a2.35 2.35 0 0 0 2.345-2.345 2.352 2.352 0 0 0-2.891-2.284 3.651 3.651 0 0 0-3.537-2.718z"
			/>
			<path fill="#4F4E4E" d="M23.223 12.799a.32.32 0 0 1-.117-.023l-1.216-.496a.31.31 0 1 1 .234-.574l1.216.496a.31.31 0 0 1-.117.597z" />
			<g>
				<path
					fill="#FFF"
					d="M9.112 22.03a3.538 3.538 0 0 1-3.533-3.533 3.538 3.538 0 0 1 3.533-3.533c.274 0 .547.037.821.099l.473.112.124-.46a5.8 5.8 0 0 1 5.61-4.329 5.806 5.806 0 0 1 5.61 4.304l.124.46.473-.112c.274-.062.547-.099.821-.099a3.538 3.538 0 0 1 3.533 3.533 3.538 3.538 0 0 1-3.533 3.533H9.112v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.141 10.884a5.327 5.327 0 0 1 5.138 3.931l.249.933.933-.224c.236-.05.46-.087.697-.087a3.04 3.04 0 0 1 3.035 3.035 3.04 3.04 0 0 1-3.035 3.035H9.1a3.04 3.04 0 0 1-3.035-3.035A3.04 3.04 0 0 1 9.1 15.437c.236 0 .473.025.697.087l.933.224.249-.933a5.376 5.376 0 0 1 5.162-3.931m0-.996a6.308 6.308 0 0 0-6.096 4.665 4.03 4.03 0 1 0-.933 7.949h14.057a4.03 4.03 0 1 0-.933-7.949 6.306 6.306 0 0 0-6.095-4.665z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.799 16.283a.44.44 0 0 1-.173-.036l-1.605-.697a.435.435 0 1 1 .347-.799l1.605.697a.436.436 0 0 1-.174.835z"
				/>
			</g>
		</SVGIcon>
	);
}
