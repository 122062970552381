import { default as BedecktNachtIcon } from './generated/BedecktNachtIcon';
import { default as BedecktIcon } from './generated/BedecktIcon';
import { default as BurgerGreenIcon } from './generated/BurgerGreenIcon';
import { default as BurgerWhiteIcon } from './generated/BurgerWhiteIcon';
import { default as CloseGreenIcon } from './generated/CloseGreenIcon';
import { default as CloseTransparentIcon } from './generated/CloseTransparentIcon';
import { default as CloseWhiteIcon } from './generated/CloseWhiteIcon';
import { default as DunstigNachtIcon } from './generated/DunstigNachtIcon';
import { default as DunstigIcon } from './generated/DunstigIcon';
import { default as EmailGreenIcon } from './generated/EmailGreenIcon';
import { default as FacebookGreenIcon } from './generated/FacebookGreenIcon';
import { default as FastWolkenlosNachtIcon } from './generated/FastWolkenlosNachtIcon';
import { default as FastWolkenlosIcon } from './generated/FastWolkenlosIcon';
import { default as GewitterGraupelSonnigNachtIcon } from './generated/GewitterGraupelSonnigNachtIcon';
import { default as GewitterGraupelSonnigIcon } from './generated/GewitterGraupelSonnigIcon';
import { default as GewitterHagelBedecktNachtIcon } from './generated/GewitterHagelBedecktNachtIcon';
import { default as GewitterHagelBedecktIcon } from './generated/GewitterHagelBedecktIcon';
import { default as GewitterRegenLeichtNachtIcon } from './generated/GewitterRegenLeichtNachtIcon';
import { default as GewitterRegenLeichtIcon } from './generated/GewitterRegenLeichtIcon';
import { default as GewitterRegenSonnigNachtIcon } from './generated/GewitterRegenSonnigNachtIcon';
import { default as GewitterRegenSonnigIcon } from './generated/GewitterRegenSonnigIcon';
import { default as GewitterRegenStarkNachtIcon } from './generated/GewitterRegenStarkNachtIcon';
import { default as GewitterRegenStarkIcon } from './generated/GewitterRegenStarkIcon';
import { default as GewitterTrockenBedecktNachtIcon } from './generated/GewitterTrockenBedecktNachtIcon';
import { default as GewitterTrockenBedecktIcon } from './generated/GewitterTrockenBedecktIcon';
import { default as GewitterTrockenSonnigNachtIcon } from './generated/GewitterTrockenSonnigNachtIcon';
import { default as GewitterTrockenSonnigIcon } from './generated/GewitterTrockenSonnigIcon';
import { default as GraupelBedecktNachtIcon } from './generated/GraupelBedecktNachtIcon';
import { default as GraupelBedecktIcon } from './generated/GraupelBedecktIcon';
import { default as GraupelschauerNachtIcon } from './generated/GraupelschauerNachtIcon';
import { default as GraupelschauerHeiterNachtIcon } from './generated/GraupelschauerHeiterNachtIcon';
import { default as GraupelschauerHeiterIcon } from './generated/GraupelschauerHeiterIcon';
import { default as GraupelschauerIcon } from './generated/GraupelschauerIcon';
import { default as HagelBedecktNachtIcon } from './generated/HagelBedecktNachtIcon';
import { default as HagelBedecktIcon } from './generated/HagelBedecktIcon';
import { default as HagelschauerNachtIcon } from './generated/HagelschauerNachtIcon';
import { default as HagelschauerIcon } from './generated/HagelschauerIcon';
import { default as ImageGreenIcon } from './generated/ImageGreenIcon';
import { default as LeichtBewoelktNachtIcon } from './generated/LeichtBewoelktNachtIcon';
import { default as LeichtBewoelktIcon } from './generated/LeichtBewoelktIcon';
import { default as LinkExternalIcon } from './generated/LinkExternalIcon';
import { default as LocationIcon } from './generated/LocationIcon';
import { default as NebelNachtIcon } from './generated/NebelNachtIcon';
import { default as NebelBodennebelNachtIcon } from './generated/NebelBodennebelNachtIcon';
import { default as NebelBodennebelIcon } from './generated/NebelBodennebelIcon';
import { default as NebelGefrierendNachtIcon } from './generated/NebelGefrierendNachtIcon';
import { default as NebelGefrierendIcon } from './generated/NebelGefrierendIcon';
import { default as NebelIcon } from './generated/NebelIcon';
import { default as NewsAudioIcon } from './generated/NewsAudioIcon';
import { default as NewsBildergalerieIcon } from './generated/NewsBildergalerieIcon';
import { default as NewsLocationIcon } from './generated/NewsLocationIcon';
import { default as NewsVideoIcon } from './generated/NewsVideoIcon';
import { default as NextTransparentIcon } from './generated/NextTransparentIcon';
import { default as OrkanNachtIcon } from './generated/OrkanNachtIcon';
import { default as OrkanIcon } from './generated/OrkanIcon';
import { default as PlayerFullscreenDisableGreenIcon } from './generated/PlayerFullscreenDisableGreenIcon';
import { default as PlayerFullscreenEnableGreenIcon } from './generated/PlayerFullscreenEnableGreenIcon';
import { default as PlayerPauseTransparentIcon } from './generated/PlayerPauseTransparentIcon';
import { default as PlayerPlayGreenIcon } from './generated/PlayerPlayGreenIcon';
import { default as PlayerPlayTransparentIcon } from './generated/PlayerPlayTransparentIcon';
import { default as PlayerPlayWhiteIcon } from './generated/PlayerPlayWhiteIcon';
import { default as PreviousTransparentIcon } from './generated/PreviousTransparentIcon';
import { default as RegenBedecktNachtIcon } from './generated/RegenBedecktNachtIcon';
import { default as RegenBedecktIcon } from './generated/RegenBedecktIcon';
import { default as RegenGefrierendNachtIcon } from './generated/RegenGefrierendNachtIcon';
import { default as RegenGefrierendIcon } from './generated/RegenGefrierendIcon';
import { default as RegenLeichtBedecktNachtIcon } from './generated/RegenLeichtBedecktNachtIcon';
import { default as RegenLeichtBedecktIcon } from './generated/RegenLeichtBedecktIcon';
import { default as RegenStarkBedecktNachtIcon } from './generated/RegenStarkBedecktNachtIcon';
import { default as RegenStarkBedecktIcon } from './generated/RegenStarkBedecktIcon';
import { default as RegenschauerNachtIcon } from './generated/RegenschauerNachtIcon';
import { default as RegenschauerHeiterNachtIcon } from './generated/RegenschauerHeiterNachtIcon';
import { default as RegenschauerHeiterIcon } from './generated/RegenschauerHeiterIcon';
import { default as RegenschauerStarkNachtIcon } from './generated/RegenschauerStarkNachtIcon';
import { default as RegenschauerStarkIcon } from './generated/RegenschauerStarkIcon';
import { default as RegenschauerIcon } from './generated/RegenschauerIcon';
import { default as SchneeBedecktNachtIcon } from './generated/SchneeBedecktNachtIcon';
import { default as SchneeBedecktIcon } from './generated/SchneeBedecktIcon';
import { default as SchneeLeichtBedecktNachtIcon } from './generated/SchneeLeichtBedecktNachtIcon';
import { default as SchneeLeichtBedecktIcon } from './generated/SchneeLeichtBedecktIcon';
import { default as SchneeStarkBedecktNachtIcon } from './generated/SchneeStarkBedecktNachtIcon';
import { default as SchneeStarkBedecktIcon } from './generated/SchneeStarkBedecktIcon';
import { default as SchneeregenBedecktNachtIcon } from './generated/SchneeregenBedecktNachtIcon';
import { default as SchneeregenBedecktIcon } from './generated/SchneeregenBedecktIcon';
import { default as SchneeregenschauerNachtIcon } from './generated/SchneeregenschauerNachtIcon';
import { default as SchneeregenschauerIcon } from './generated/SchneeregenschauerIcon';
import { default as SchneeschauerNachtIcon } from './generated/SchneeschauerNachtIcon';
import { default as SchneeschauerHeiterNachtIcon } from './generated/SchneeschauerHeiterNachtIcon';
import { default as SchneeschauerHeiterIcon } from './generated/SchneeschauerHeiterIcon';
import { default as SchneeschauerStarkNachtIcon } from './generated/SchneeschauerStarkNachtIcon';
import { default as SchneeschauerStarkIcon } from './generated/SchneeschauerStarkIcon';
import { default as SchneeschauerIcon } from './generated/SchneeschauerIcon';
import { default as SearchGreenIcon } from './generated/SearchGreenIcon';
import { default as SearchIcon } from './generated/SearchIcon';
import { default as SettingsGreenIcon } from './generated/SettingsGreenIcon';
import { default as ShareGreenIcon } from './generated/ShareGreenIcon';
import { default as ShareWhiteIcon } from './generated/ShareWhiteIcon';
import { default as StarkBewoelktNachtIcon } from './generated/StarkBewoelktNachtIcon';
import { default as StarkBewoelktIcon } from './generated/StarkBewoelktIcon';
import { default as SturmNachtIcon } from './generated/SturmNachtIcon';
import { default as SturmRegenNachtIcon } from './generated/SturmRegenNachtIcon';
import { default as SturmRegenIcon } from './generated/SturmRegenIcon';
import { default as SturmIcon } from './generated/SturmIcon';
import { default as TwitterGreenIcon } from './generated/TwitterGreenIcon';
import { default as WebcamGreenIcon } from './generated/WebcamGreenIcon';
import { default as WebcamWhteIcon } from './generated/WebcamWhteIcon';
import { default as WhatsappGreenIcon } from './generated/WhatsappGreenIcon';
import { default as WolkenlosNachtIcon } from './generated/WolkenlosNachtIcon';
import { default as WolkenlosIcon } from './generated/WolkenlosIcon';
import { default as WolkigNachtIcon } from './generated/WolkigNachtIcon';
import { default as WolkigIcon } from './generated/WolkigIcon';
export {
	BedecktNachtIcon,
	BedecktIcon,
	BurgerGreenIcon,
	BurgerWhiteIcon,
	CloseGreenIcon,
	CloseTransparentIcon,
	CloseWhiteIcon,
	DunstigNachtIcon,
	DunstigIcon,
	EmailGreenIcon,
	FacebookGreenIcon,
	FastWolkenlosNachtIcon,
	FastWolkenlosIcon,
	GewitterGraupelSonnigNachtIcon,
	GewitterGraupelSonnigIcon,
	GewitterHagelBedecktNachtIcon,
	GewitterHagelBedecktIcon,
	GewitterRegenLeichtNachtIcon,
	GewitterRegenLeichtIcon,
	GewitterRegenSonnigNachtIcon,
	GewitterRegenSonnigIcon,
	GewitterRegenStarkNachtIcon,
	GewitterRegenStarkIcon,
	GewitterTrockenBedecktNachtIcon,
	GewitterTrockenBedecktIcon,
	GewitterTrockenSonnigNachtIcon,
	GewitterTrockenSonnigIcon,
	GraupelBedecktNachtIcon,
	GraupelBedecktIcon,
	GraupelschauerNachtIcon,
	GraupelschauerHeiterNachtIcon,
	GraupelschauerHeiterIcon,
	GraupelschauerIcon,
	HagelBedecktNachtIcon,
	HagelBedecktIcon,
	HagelschauerNachtIcon,
	HagelschauerIcon,
	ImageGreenIcon,
	LeichtBewoelktNachtIcon,
	LeichtBewoelktIcon,
	LinkExternalIcon,
	LocationIcon,
	NebelNachtIcon,
	NebelBodennebelNachtIcon,
	NebelBodennebelIcon,
	NebelGefrierendNachtIcon,
	NebelGefrierendIcon,
	NebelIcon,
	NewsAudioIcon,
	NewsBildergalerieIcon,
	NewsLocationIcon,
	NewsVideoIcon,
	NextTransparentIcon,
	OrkanNachtIcon,
	OrkanIcon,
	PlayerFullscreenDisableGreenIcon,
	PlayerFullscreenEnableGreenIcon,
	PlayerPauseTransparentIcon,
	PlayerPlayGreenIcon,
	PlayerPlayTransparentIcon,
	PlayerPlayWhiteIcon,
	PreviousTransparentIcon,
	RegenBedecktNachtIcon,
	RegenBedecktIcon,
	RegenGefrierendNachtIcon,
	RegenGefrierendIcon,
	RegenLeichtBedecktNachtIcon,
	RegenLeichtBedecktIcon,
	RegenStarkBedecktNachtIcon,
	RegenStarkBedecktIcon,
	RegenschauerNachtIcon,
	RegenschauerHeiterNachtIcon,
	RegenschauerHeiterIcon,
	RegenschauerStarkNachtIcon,
	RegenschauerStarkIcon,
	RegenschauerIcon,
	SchneeBedecktNachtIcon,
	SchneeBedecktIcon,
	SchneeLeichtBedecktNachtIcon,
	SchneeLeichtBedecktIcon,
	SchneeStarkBedecktNachtIcon,
	SchneeStarkBedecktIcon,
	SchneeregenBedecktNachtIcon,
	SchneeregenBedecktIcon,
	SchneeregenschauerNachtIcon,
	SchneeregenschauerIcon,
	SchneeschauerNachtIcon,
	SchneeschauerHeiterNachtIcon,
	SchneeschauerHeiterIcon,
	SchneeschauerStarkNachtIcon,
	SchneeschauerStarkIcon,
	SchneeschauerIcon,
	SearchGreenIcon,
	SearchIcon,
	SettingsGreenIcon,
	ShareGreenIcon,
	ShareWhiteIcon,
	StarkBewoelktNachtIcon,
	StarkBewoelktIcon,
	SturmNachtIcon,
	SturmRegenNachtIcon,
	SturmRegenIcon,
	SturmIcon,
	TwitterGreenIcon,
	WebcamGreenIcon,
	WebcamWhteIcon,
	WhatsappGreenIcon,
	WolkenlosNachtIcon,
	WolkenlosIcon,
	WolkigNachtIcon,
	WolkigIcon,
};

export const IconMap = new Map([
	['bedeckt nacht', BedecktNachtIcon],
	['bedeckt', BedecktIcon],
	['burger_green', BurgerGreenIcon],
	['burger_white', BurgerWhiteIcon],
	['close_green', CloseGreenIcon],
	['close_transparent', CloseTransparentIcon],
	['close_white', CloseWhiteIcon],
	['dunstig nacht', DunstigNachtIcon],
	['dunstig', DunstigIcon],
	['email_green', EmailGreenIcon],
	['facebook_green', FacebookGreenIcon],
	['fast-wolkenlos nacht', FastWolkenlosNachtIcon],
	['fast-wolkenlos', FastWolkenlosIcon],
	['gewitter-graupel-sonnig nacht', GewitterGraupelSonnigNachtIcon],
	['gewitter-graupel-sonnig', GewitterGraupelSonnigIcon],
	['gewitter-hagel-bedeckt nacht', GewitterHagelBedecktNachtIcon],
	['gewitter-hagel-bedeckt', GewitterHagelBedecktIcon],
	['gewitter-regen-leicht nacht', GewitterRegenLeichtNachtIcon],
	['gewitter-regen-leicht', GewitterRegenLeichtIcon],
	['gewitter-regen-sonnig nacht', GewitterRegenSonnigNachtIcon],
	['gewitter-regen-sonnig', GewitterRegenSonnigIcon],
	['gewitter-regen-stark nacht', GewitterRegenStarkNachtIcon],
	['gewitter-regen-stark', GewitterRegenStarkIcon],
	['gewitter-trocken-bedeckt nacht', GewitterTrockenBedecktNachtIcon],
	['gewitter-trocken-bedeckt', GewitterTrockenBedecktIcon],
	['gewitter-trocken-sonnig nacht', GewitterTrockenSonnigNachtIcon],
	['gewitter-trocken-sonnig', GewitterTrockenSonnigIcon],
	['graupel-bedeckt nacht', GraupelBedecktNachtIcon],
	['graupel-bedeckt', GraupelBedecktIcon],
	['graupelschauer nacht', GraupelschauerNachtIcon],
	['graupelschauer-heiter nacht', GraupelschauerHeiterNachtIcon],
	['graupelschauer-heiter', GraupelschauerHeiterIcon],
	['graupelschauer', GraupelschauerIcon],
	['hagel-bedeckt nacht', HagelBedecktNachtIcon],
	['hagel-bedeckt', HagelBedecktIcon],
	['hagelschauer nacht', HagelschauerNachtIcon],
	['hagelschauer', HagelschauerIcon],
	['image_green', ImageGreenIcon],
	['leicht-bewoelkt nacht', LeichtBewoelktNachtIcon],
	['leicht-bewoelkt', LeichtBewoelktIcon],
	['link_external', LinkExternalIcon],
	['location', LocationIcon],
	['nebel nacht', NebelNachtIcon],
	['nebel-bodennebel nacht', NebelBodennebelNachtIcon],
	['nebel-bodennebel', NebelBodennebelIcon],
	['nebel-gefrierend nacht', NebelGefrierendNachtIcon],
	['nebel-gefrierend', NebelGefrierendIcon],
	['nebel', NebelIcon],
	['news_audio', NewsAudioIcon],
	['news_bildergalerie', NewsBildergalerieIcon],
	['news_location', NewsLocationIcon],
	['news_video', NewsVideoIcon],
	['next_transparent', NextTransparentIcon],
	['orkan nacht', OrkanNachtIcon],
	['orkan', OrkanIcon],
	['player_fullscreen_disable_green', PlayerFullscreenDisableGreenIcon],
	['player_fullscreen_enable_green', PlayerFullscreenEnableGreenIcon],
	['player_pause_transparent', PlayerPauseTransparentIcon],
	['player_play_green', PlayerPlayGreenIcon],
	['player_play_transparent', PlayerPlayTransparentIcon],
	['player_play_white', PlayerPlayWhiteIcon],
	['previous_transparent', PreviousTransparentIcon],
	['regen-bedeckt nacht', RegenBedecktNachtIcon],
	['regen-bedeckt', RegenBedecktIcon],
	['regen-gefrierend nacht', RegenGefrierendNachtIcon],
	['regen-gefrierend', RegenGefrierendIcon],
	['regen-leicht-bedeckt nacht', RegenLeichtBedecktNachtIcon],
	['regen-leicht-bedeckt', RegenLeichtBedecktIcon],
	['regen-stark-bedeckt nacht', RegenStarkBedecktNachtIcon],
	['regen-stark-bedeckt', RegenStarkBedecktIcon],
	['regenschauer nacht', RegenschauerNachtIcon],
	['regenschauer-heiter nacht', RegenschauerHeiterNachtIcon],
	['regenschauer-heiter', RegenschauerHeiterIcon],
	['regenschauer-stark nacht', RegenschauerStarkNachtIcon],
	['regenschauer-stark', RegenschauerStarkIcon],
	['regenschauer', RegenschauerIcon],
	['schnee-bedeckt nacht', SchneeBedecktNachtIcon],
	['schnee-bedeckt', SchneeBedecktIcon],
	['schnee-leicht-bedeckt nacht', SchneeLeichtBedecktNachtIcon],
	['schnee-leicht-bedeckt', SchneeLeichtBedecktIcon],
	['schnee-stark-bedeckt nacht', SchneeStarkBedecktNachtIcon],
	['schnee-stark-bedeckt', SchneeStarkBedecktIcon],
	['schneeregen-bedeckt nacht', SchneeregenBedecktNachtIcon],
	['schneeregen-bedeckt', SchneeregenBedecktIcon],
	['schneeregenschauer nacht', SchneeregenschauerNachtIcon],
	['schneeregenschauer', SchneeregenschauerIcon],
	['schneeschauer nacht', SchneeschauerNachtIcon],
	['schneeschauer-heiter nacht', SchneeschauerHeiterNachtIcon],
	['schneeschauer-heiter', SchneeschauerHeiterIcon],
	['schneeschauer-stark nacht', SchneeschauerStarkNachtIcon],
	['schneeschauer-stark', SchneeschauerStarkIcon],
	['schneeschauer', SchneeschauerIcon],
	['search_green', SearchGreenIcon],
	['search', SearchIcon],
	['settings_green', SettingsGreenIcon],
	['share_green', ShareGreenIcon],
	['share_white', ShareWhiteIcon],
	['stark-bewoelkt nacht', StarkBewoelktNachtIcon],
	['stark-bewoelkt', StarkBewoelktIcon],
	['sturm nacht', SturmNachtIcon],
	['sturm-regen nacht', SturmRegenNachtIcon],
	['sturm-regen', SturmRegenIcon],
	['sturm', SturmIcon],
	['twitter_green', TwitterGreenIcon],
	['webcam_green', WebcamGreenIcon],
	['webcam_whte', WebcamWhteIcon],
	['whatsapp_green', WhatsappGreenIcon],
	['wolkenlos nacht', WolkenlosNachtIcon],
	['wolkenlos', WolkenlosIcon],
	['wolkig nacht', WolkigNachtIcon],
	['wolkig', WolkigIcon],
]);
