import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterRegenLeichtNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M3.994 20.734c.276-.457.276-1.191.276-1.742 0-.734.091-1.377.091-1.377s-.367.459-1.008.826c-.55.367-1.375.645-1.559 1.01-.366.643-.183 1.375.459 1.74.641.368 1.374.184 1.741-.457zm2.383 4.309c.274-.459.274-1.191.274-1.742 0-.732.092-1.377.092-1.377s-.367.461-1.008.828c-.549.363-1.375.641-1.558 1.008-.366.641-.184 1.373.459 1.742.551.367 1.375.09 1.741-.459zm5.227-1.469c.274-.459.274-1.19.274-1.74 0-.732.092-1.375.092-1.375s-.366.457-1.008.824c-.551.367-1.376.641-1.559 1.01-.367.641-.184 1.373.458 1.74s1.375.184 1.743-.459z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.718 15.441a2.378 2.378 0 0 1-2.379-2.377 2.368 2.368 0 0 1 2.926-2.302l.349.086.1-.348a3.927 3.927 0 0 1 3.783-2.9 3.927 3.927 0 0 1 3.785 2.9l.1.348.349-.086a2.376 2.376 0 0 1 2.925 2.302 2.378 2.378 0 0 1-2.379 2.377H2.718z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.497 7.973a3.544 3.544 0 0 1 3.424 2.625l.188.697.71-.162c.147-.037.31-.047.458-.047 1.109 0 2.005.894 2.005 1.99a2.004 2.004 0 0 1-2.005 2.006H2.718a2.004 2.004 0 0 1-2.005-2.006 2 2 0 0 1 2.005-1.99c.148 0 .31.01.46.047l.708.162.187-.697a3.547 3.547 0 0 1 3.424-2.625m0-.748a4.307 4.307 0 0 0-4.146 3.174 2.75 2.75 0 0 0-3.385 2.666 2.751 2.751 0 0 0 2.752 2.75h9.559a2.752 2.752 0 0 0 2.752-2.75c0-1.52-1.233-2.74-2.752-2.74-.224 0-.435.025-.634.074a4.305 4.305 0 0 0-4.146-3.174z"
			/>
			<path
				fill="#FFF"
				d="M21.498 12.912a2.05 2.05 0 0 1-2.041-2.041 2.05 2.05 0 0 1 2.041-2.041c.162 0 .311.014.475.051l.295.074.076-.285a3.364 3.364 0 0 1 6.494 0l.074.285.299-.074a2.05 2.05 0 0 1 2.512 1.99 2.05 2.05 0 0 1-2.039 2.041h-8.186z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.59 6.479c1.381 0 2.588.935 2.949 2.266l.16.584.586-.137c.137-.025.26-.049.398-.049a1.728 1.728 0 1 1 0 3.458h-8.186a1.725 1.725 0 0 1-1.728-1.729c0-.959.771-1.729 1.728-1.729.137 0 .274.01.397.049l.586.137.162-.584a3.065 3.065 0 0 1 2.948-2.266m0-.621a3.663 3.663 0 0 0-3.545 2.725 2.355 2.355 0 0 0-2.897 2.29 2.354 2.354 0 0 0 2.35 2.35h8.186a2.356 2.356 0 0 0 2.35-2.35 2.358 2.358 0 0 0-2.899-2.29 3.663 3.663 0 0 0-3.545-2.725z"
			/>
			<path
				fill="#C9C8C8"
				d="M9.096 18.94a3.546 3.546 0 0 1-3.54-3.541 3.544 3.544 0 0 1 3.54-3.539c.275 0 .548.037.823.098l.474.115.124-.463a5.818 5.818 0 1 1 11.244-.026l.125.461.473-.111c.273-.062.551-.1.824-.1a3.547 3.547 0 0 1 3.541 3.541 3.547 3.547 0 0 1-3.541 3.539H9.096v.026z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.141 7.77a5.341 5.341 0 0 1 5.149 3.939l.25.936.934-.227a3.39 3.39 0 0 1 .697-.086 3.046 3.046 0 0 1 3.043 3.043 3.045 3.045 0 0 1-3.043 3.041H9.084a3.045 3.045 0 0 1-3.042-3.041 3.047 3.047 0 0 1 3.042-3.043c.237 0 .474.023.698.086l.935.227.25-.936a5.39 5.39 0 0 1 5.174-3.939m0-.998a6.322 6.322 0 0 0-6.109 4.676 3.887 3.887 0 0 0-.935-.113 4.04 4.04 0 0 0-4.04 4.041 4.037 4.037 0 0 0 4.04 4.037h14.087a4.038 4.038 0 0 0 4.039-4.037 4.04 4.04 0 0 0-4.039-4.041c-.324 0-.637.039-.936.113a6.32 6.32 0 0 0-6.107-4.676z"
			/>
			<path
				fill="#C9C8C8"
				d="M20.035 16.883c-.092-.092-.182-.092-.274-.092l-3.942 1.01 2.659-5.041v-.275a.198.198 0 0 0-.184-.184l-2.107-.734c-.185-.092-.277 0-.368.186l-2.2 5.957-.641 1.742-.185.551c0 .092 0 .182.092.275.093.088.183.088.274.088l3.392-.914h.092l-2.383 6.051v.273c0 .094.09.094.183.184l.55.184h.092c.091 0 .184-.092.274-.184l3.483-6.508.918-1.742.365-.641c.002.089.002-.092-.09-.186z"
			/>
			<g>
				<path
					fill="#A03416"
					d="M20.035 16.883c-.092-.092-.182-.092-.274-.092l-3.942 1.01 2.659-5.041v-.275a.198.198 0 0 0-.184-.184l-2.107-.734c-.185-.092-.277 0-.368.186l-2.2 5.957-.641 1.742-.185.551c0 .092 0 .182.092.275.093.088.183.088.274.088l3.392-.914h.092l-2.383 6.051v.273c0 .094.09.094.183.184l.55.184h.092c.091 0 .184-.092.274-.184l3.483-6.508.918-1.742.365-.641c.002.089.002-.092-.09-.186z"
				/>
			</g>
		</SVGIcon>
	);
}
