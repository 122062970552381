import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NewsAudioIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#3D3D3C"
				d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0zm0 31.201C7.619 31.201.799 24.381.799 16S7.619.799 16 .799 31.201 7.619 31.201 16 24.381 31.201 16 31.201zm.213-21.9a.4.4 0 0 0-.418.036l-5.904 4.377H7.587a.4.4 0 0 0-.4.4v3.523a.4.4 0 0 0 .4.4h2.295l5.901 4.71a.4.4 0 0 0 .649-.313V9.658a.397.397 0 0 0-.219-.357zm-6.589 7.936H7.987v-2.723h1.636v2.723zm6.01 4.366l-5.211-4.159v-3.128l5.211-3.863v11.15zm5.926-5.631a3.209 3.209 0 0 0-3.205-3.206v.799a2.41 2.41 0 0 1 2.406 2.406 2.409 2.409 0 0 1-2.406 2.406v.799a3.207 3.207 0 0 0 3.205-3.204zM18.355 10.9v.8c2.359 0 4.277 1.919 4.277 4.278s-1.918 4.278-4.277 4.278v.799c2.799 0 5.076-2.277 5.076-5.077S21.154 10.9 18.355 10.9z"
			/>
		</SVGIcon>
	);
}
