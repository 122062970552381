import SVGIcon from '../SVGIcon';
import React from 'react';

export default function CloseWhiteIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="16" cy="15.999" r="16" />
			<path
				fill="#3C3C3B"
				d="M16.691 16l4.974-4.974a.513.513 0 0 0-.723-.725l-4.943 4.943-4.943-4.941a.512.512 0 0 0-.724.723l4.976 4.975-4.976 4.974a.516.516 0 0 0 0 .723.509.509 0 0 0 .723 0l4.943-4.942 4.943 4.941a.509.509 0 0 0 .722 0 .515.515 0 0 0 0-.724L16.691 16z"
			/>
		</SVGIcon>
	);
}
