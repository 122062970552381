import * as config from '../../config.webapp.ts';

const LOAD = 'br3/navigation/LOAD';
const LOAD_SUCCESS = 'br3/navigation/LOAD_SUCCESS';
const LOAD_FAIL = 'br3/navigation/LOAD_FAIL';

const initialState = {
	loaded: false,
};

export default function app(state = initialState, action = {}) {
	switch (action.type) {
		case LOAD:
			return {
				...state,
				loading: true,
			};
		case LOAD_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				mainNavigation: action.result.main,
				footerNavigation: action.result.footer,
			};
		case LOAD_FAIL:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};

		default:
			return state;
	}
}

export function isLoaded(globalState) {
	return globalState.navigation && globalState.navigation.loaded;
}

export function load() {
	const headers = { Accept: 'application/json' };
	const baseUrl = config.ORIGIN_COFFEEMACHINE;
	const navigation = config.API_ENDPOINT.navigation;

	return {
		types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
		promise: client => {
			return Promise.all([
				client.get(`${baseUrl}${navigation}/main`, { headers }),
				client.get(`${baseUrl}${navigation}/footer`, { headers }),
			]).then((result, error) => {
				if (error) {
					throw error;
				} else {
					return { main: result[0], footer: result[1] };
				}
			});
		},
	};
}
