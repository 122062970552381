import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NewsLocationIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#3D3D3C">
				<path d="M16.004.799C24.383.799 31.2 7.615 31.2 15.995c0 8.379-6.817 15.196-15.196 15.196S.808 24.375.808 15.996C.808 7.616 7.625.799 16.004.799m0-.799C7.17 0 .009 7.161.009 15.996c0 8.834 7.161 15.996 15.996 15.996C24.839 31.991 32 24.83 32 15.996 32 7.161 24.839 0 16.004 0z" />
				<path d="M16.004 7.997a5.332 5.332 0 0 0-5.332 5.332c0 2.945 5.332 10.664 5.332 10.664s5.332-7.719 5.332-10.664a5.332 5.332 0 0 0-5.332-5.332zm-4.532 5.332c0-2.499 2.033-4.533 4.533-4.533s4.533 2.033 4.533 4.533c0 1.926-2.846 6.662-4.533 9.231-1.687-2.569-4.533-7.304-4.533-9.231z" />
				<circle cx="16.004" cy="14.038" r="1.958" />
			</g>
		</SVGIcon>
	);
}
