import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GraupelBedecktIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="3.288" cy="18.787" r="1.097" />
			<path
				fill="#4F4E4E"
				d="M3.288 20.07a1.285 1.285 0 0 1-1.283-1.283c0-.708.576-1.284 1.283-1.284a1.285 1.285 0 0 1 0 2.567zm0-2.194a.912.912 0 1 0 .003 1.823.912.912 0 0 0-.003-1.823z"
			/>
			<circle fill="#FFF" cx="5.025" cy="24.456" r="1.096" />
			<path
				fill="#4F4E4E"
				d="M5.025 25.738a1.285 1.285 0 0 1-1.283-1.283 1.284 1.284 0 0 1 2.567 0 1.285 1.285 0 0 1-1.284 1.283zm0-2.193a.91.91 0 1 0 .001 1.821.91.91 0 0 0-.001-1.821z"
			/>
			<circle fill="#FFF" cx="10.328" cy="22.535" r="1.097" />
			<path
				fill="#4F4E4E"
				d="M10.328 23.819a1.285 1.285 0 0 1 0-2.568 1.284 1.284 0 0 1 0 2.568zm0-2.194a.912.912 0 0 0-.911.91.912.912 0 0 0 1.823 0c0-.502-.41-.91-.912-.91z"
			/>
			<circle fill="#FFF" cx="15.631" cy="24.456" r="1.096" />
			<path
				fill="#4F4E4E"
				d="M15.631 25.738a1.284 1.284 0 1 1 .003-2.567 1.284 1.284 0 0 1-.003 2.567zm0-2.193a.91.91 0 1 0 .001 1.821.91.91 0 0 0-.001-1.821z"
			/>
			<circle fill="#FFF" cx="20.202" cy="21.987" r="1.098" />
			<path
				fill="#4F4E4E"
				d="M20.202 23.271a1.284 1.284 0 0 1 0-2.567 1.285 1.285 0 0 1 0 2.567zm0-2.196a.911.911 0 1 0 .002 1.822.911.911 0 0 0-.002-1.822z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.747 15.796a2.37 2.37 0 0 1-2.371-2.37 2.36 2.36 0 0 1 2.371-2.358c.185 0 .36.024.547.061l.347.088.099-.348a3.918 3.918 0 0 1 3.774-2.893 3.917 3.917 0 0 1 3.774 2.893l.101.348.347-.088a2.64 2.64 0 0 1 .546-.061c1.304 0 2.371 1.055 2.371 2.358s-1.055 2.37-2.371 2.37H2.747z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.514 8.347a3.537 3.537 0 0 1 3.415 2.62l.186.695.707-.16c.148-.039.311-.051.459-.051 1.104 0 1.998.895 1.998 1.987a1.997 1.997 0 0 1-1.998 1.998H2.747a1.998 1.998 0 0 1-1.999-1.998c0-1.093.895-1.987 1.999-1.987.148 0 .31.012.459.051l.707.16.187-.695a3.536 3.536 0 0 1 3.414-2.62m0-.743a4.29 4.29 0 0 0-4.134 3.164 2.741 2.741 0 0 0-3.376 2.658 2.742 2.742 0 0 0 2.743 2.743h9.534a2.744 2.744 0 0 0 2.744-2.743 2.734 2.734 0 0 0-2.744-2.731c-.224 0-.434.024-.633.073a4.29 4.29 0 0 0-4.134-3.164z"
			/>
			<path
				fill="#FFF"
				d="M21.482 13.302a2.03 2.03 0 0 1-2.012-2.013 2.03 2.03 0 0 1 2.012-2.011c.183 0 .274 0 .456.092l.275.091.092-.274c.364-1.464 1.735-2.468 3.291-2.468 1.554 0 2.834 1.004 3.291 2.468l.093.274.273-.091c.183 0 .272-.092.457-.092a2.03 2.03 0 0 1 2.011 2.011 2.03 2.03 0 0 1-2.011 2.013h-8.228z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.597 6.809c1.372 0 2.56.914 2.926 2.287l.182.548.55-.183c.091 0 .272-.091.365-.091.914 0 1.736.73 1.736 1.737 0 .915-.731 1.737-1.736 1.737h-8.137a1.736 1.736 0 1 1 0-3.474c.092 0 .274 0 .365.091l.549.183.183-.548c.364-1.281 1.646-2.287 3.017-2.287m0-.547c-1.738 0-3.108 1.188-3.565 2.742-.184 0-.366-.091-.549-.091-1.28 0-2.377 1.096-2.377 2.376 0 1.281 1.097 2.378 2.377 2.378h8.137c1.279 0 2.377-1.097 2.377-2.378 0-1.28-1.098-2.376-2.377-2.376-.183 0-.365 0-.549.091-.367-1.645-1.829-2.742-3.474-2.742z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.22 10.047a.293.293 0 0 1-.112-.021l-1.188-.457a.314.314 0 0 1-.178-.402.312.312 0 0 1 .401-.178l1.189.457a.311.311 0 1 1-.112.601z"
			/>
			<path
				fill="#CAC9C8"
				d="M9.141 19.243c-1.92 0-3.565-1.554-3.565-3.564 0-2.012 1.553-3.566 3.565-3.566.273 0 .548 0 .822.093l.457.09.092-.457c.639-2.559 3.017-4.297 5.576-4.297 2.56 0 4.938 1.737 5.577 4.297l.092.457.457-.09c.274-.093.549-.093.823-.093 1.919 0 3.565 1.555 3.565 3.566s-1.556 3.564-3.565 3.564H9.141z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.088 8.091c2.377 0 4.479 1.645 5.119 3.932l.275.914.914-.184c.273-.092.457-.092.731-.092 1.646 0 3.018 1.37 3.018 3.018 0 1.645-1.372 3.016-3.018 3.016H9.048c-1.646 0-3.016-1.371-3.016-3.016 0-1.647 1.37-3.018 3.016-3.018.274 0 .458 0 .731.092l.914.184.274-.914c.641-2.287 2.744-3.932 5.121-3.932m0-.914c-2.925 0-5.394 2.01-6.125 4.661-.274-.092-.641-.092-.915-.092-2.193 0-4.023 1.828-4.023 4.023 0 2.195 1.829 4.024 4.023 4.024h14.08c2.194 0 4.023-1.829 4.023-4.024 0-2.194-1.828-4.023-4.023-4.023-.365 0-.64 0-.914.092-.732-2.742-3.2-4.661-6.126-4.661z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.79 13.553a.466.466 0 0 1-.176-.036l-1.646-.732a.434.434 0 0 1-.22-.573c.099-.221.354-.319.574-.221l1.644.729a.437.437 0 0 1 .223.575.437.437 0 0 1-.399.258z"
			/>
		</SVGIcon>
	);
}
