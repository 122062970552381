import SVGIcon from '../SVGIcon';
import React from 'react';

export default function EmailGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="16" r="16" />
			<path
				fill="#FFF"
				d="M23.462 10.352H8.539a.514.514 0 0 0-.515.516v10.266c0 .284.23.515.515.515h14.923c.284 0 .515-.23.515-.515V10.867a.514.514 0 0 0-.515-.515zm-7.455 6.38l-6.1-5.35h12.188l-6.088 5.35zm-2.398-.731l-4.556 3.996v-7.993l4.556 3.997zm.783.686l1.274 1.119a.512.512 0 0 0 .681-.002l1.273-1.117 4.476 3.933H9.907l4.485-3.933zm4.008-.686l4.546-3.997v7.991L18.4 16.001z"
			/>
		</SVGIcon>
	);
}
