import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './FacebookComments.scss';

export default class FacebookComments extends React.Component {
	static propTypes = {
		link: PropTypes.string.isRequired,
	};

	render() {
		return (
			<div>
				<div className={styles.info}>Jetzt auf Facebook kommentieren!</div>
				<a target="_blank" rel="noopener noreferrer" href={this.props.link} className={classNames(styles.comments)}>
					<div>
						{this.props.data.likes || 0}
						<label>Reaktionen</label>
					</div>
					<div className={styles.middle}>
						{this.props.data.comments || 0}
						<label>Kommentare</label>
					</div>
					<div style={{ display: 'none' }}>
						{this.props.data.shares || 0}
						<label>mal geteilt</label>
					</div>
				</a>
			</div>
		);
	}
}
