import SVGIcon from '../SVGIcon';
import React from 'react';

export default function CloseGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="15.999" r="16" />
			<path
				fill="#FFF"
				d="M16.689 16l4.975-4.974a.513.513 0 0 0-.724-.725l-4.943 4.943-4.942-4.941a.512.512 0 0 0-.724.723l4.975 4.975-4.975 4.974a.514.514 0 0 0 .361.874.506.506 0 0 0 .362-.151l4.942-4.942 4.943 4.941a.512.512 0 0 0 .723-.724L16.689 16z"
			/>
		</SVGIcon>
	);
}
