import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styles from './TrafficWidget.scss';

export default class TrafficWidget extends Component {
	static propTypes = {
		traffic: PropTypes.object,
	};

	render() {
		let trafficInfos = '';
		if (this.props.traffic) {
			var gefahren = this.props.traffic.bmtKategorien.find(function(kategorie) {
				return (kategorie.kategorieId = 'gefahren');
			});
			trafficInfos = gefahren.meldungen.map((meldung, index) => {
				const { absatz, meldungsZeit, headline } = meldung;
				return (
					<div key={index}>
						<h2 className={classNames(styles.title)}>
							<span>{meldungsZeit}</span>
							<span>{headline}</span>
						</h2>
						<p>{absatz}</p>
						<hr />
					</div>
				);
			});
		}

		let trafficWidget = null;
		if (trafficInfos !== '') {
			trafficWidget = (
				<div className={styles.trafficWidget}>
					<hr className={classNames(styles.widgetSpacing)} />
					<h1 className={classNames(styles.headline)}>Gefahrenmeldungen</h1>
					<div>{trafficInfos}</div>
					<div className={classNames(styles.linkContainer)}>
						<Link to="/verkehr" className={classNames(styles.link)}>
							mehr Verkehr
						</Link>
					</div>
				</div>
			);
		}
		return trafficWidget;
	}
}
