import ATInternetTracker from './ATInternetTracker';
import GoogleTagManager from './GoogleTagManagerTracker';
import * as config from '../../../config.webapp';

const STORAGE_KEY = 'allowTracking';

const defaultTrackersSetting = true;

const tracker = [new ATInternetTracker(), new GoogleTagManager(config.TRACKING_GTM_ID, config.TRACKING_GTM_SRC)];

/**
 * Gets the current tracking state.
 */
export const isTrackingActive = (): boolean => {
	let result;
	if (typeof localStorage !== 'undefined') {
		result = localStorage.getItem(STORAGE_KEY);
	}
	return result ? result === 'true' : defaultTrackersSetting;
};

/**
 * Toggles the current tracking state.
 */
export const toggleTracking = () => {
	if (typeof localStorage !== 'undefined') {
		localStorage.setItem(STORAGE_KEY, String(!isTrackingActive()));
	}
};

/**
 * Tracks the given event by calling the trackers from the tracker-array.
 * @param component
 * @param event
 * @param id
 * @param info
 */
export default function trackEvent(component: string, event: string, id = 'null', info = '') {
	// Ignore tracking on CMS and if deactivated
	if (!IS_CMS && isTrackingActive()) {
		// Ignore tracking server side, even page loads are handled by client
		if (typeof window !== 'undefined' && window.document && window.document.createElement) {
			// Tracking depends on external services and should fail gracefully
			try {
				tracker.forEach(t => {
					t.trackEvent(component, event, id, info);
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error('Error happened tracking event.', e);
			}
		}
	}
}
