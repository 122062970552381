import React from 'react';
import classNames from 'classnames';
import styles from './Weather.scss';
import moment, { MomentInput, DurationInputArg1 } from 'moment';
import Immutable from 'immutable';
import WeatherOverview from './WeatherOverview';
import WeatherSlider from './WeatherSlider';
import Mockdata from './Mockdata';
import ContentElementImage from '../ContentElementImage/ContentElementImage';
import { WeatherProps } from './IWeather';

/**
 * Component to display the weather page.
 */
export default class Weather extends React.Component<WeatherProps> {
	static defaultProps = {
		data: Mockdata,
	};

	getComparableDayFromDate(date: MomentInput) {
		return moment(date).format('DD.MM.YYYY');
	}

	getCleanedDays() {
		const { days } = this.props.data;

		return Object.keys(days).map(dayKey => {
			const day = Immutable.fromJS(days[dayKey]).toJS();

			day.forecast = Object.keys(day.sections).map(sectionKey => {
				const section = day.sections[sectionKey];
				return section;
			});
			delete day.sections;
			return day;
		});
	}

	_formatDateString(daysToAdd: DurationInputArg1) {
		const m = moment().add(daysToAdd, 'day');
		return `${m.format('dddd')}`; //, den ${m.format('DD.MM.YYYY')}
	}

	render() {
		const days = this.getCleanedDays();

		// BRRADIO-1904: Sometimes the weather api doesn't contain forecast data. This must not break rendering.
		const maybeForecast = days.length !== 0 ? true : false;
		const today = maybeForecast ? days[0] : {};
		const { report } = this.props.data;

		let weatherAndroidImage;
		if (this.props.view == 'android') {
			weatherAndroidImage = (
				<ContentElementImage
					imageStyles={{ backgroundPosition: '0 50%' }}
					data={{
						element_type: 'gallery',
						content: {
							images: [
								{
									element_type: 'image',
									content: {
										source: require('./weather.jpg'),
										variants: {
											one_by_one: require('./weather.jpg'),
											three_by_two: require('./weather.jpg'),
											three_by_one: require('./weather.jpg'),
										},
									},
									metadata: {
										title: '',
										tags: [],
										copyright: '',
									},
								},
							],
						},
					}}
					isTeaser={true}
				/>
			);
		}

		return (
			<div className={classNames(styles.weather)}>
				{weatherAndroidImage}
				<div className={classNames(styles.header)} data-class={this.props.view == 'android' ? 'android-weather' : ''}>
					<WeatherOverview
						day={today}
						location={this.props.loading ? 'Lädt...' : this.props.data.locationname}
						updateWeatherByZipcode={this.props.updateWeatherByZipcode}
					/>
					{maybeForecast && <WeatherSlider days={days} />}
				</div>
				<div className="container">
					<main>
						<div className={classNames(styles.headline)}>
							<h1>{report && report.title}</h1>
						</div>
						{report && (
							<div>
								<h3>{report.header}</h3>
								<h4>{report.headline}</h4>
								<p>{report.texttoday}</p>
								<h3>Vorhersage</h3>
								{report.texttomorrow && (
									<div>
										<h4>Aussicht für {this._formatDateString(1)}</h4>
										<p>{report.texttomorrow}</p>
									</div>
								)}
								{report.textthedayaftertomorrow && (
									<div>
										<h4>Aussicht für {this._formatDateString(2)}</h4>
										<p>{report.textthedayaftertomorrow}</p>
									</div>
								)}
							</div>
						)}
					</main>
				</div>
				<div className="container">
					<main>
						<h6 className={styles.weatherlink}>
							<strong>Lust auf mehr Wetter?</strong>{' '}
							<a href="http://www.br.de/wettervorhersage">
								Auf den Seiten des Bayerischen Rundfunks finden Sie weitere Informationen zum aktuellen Wetterzustand, den BR Regenradar und
								das detaillierte Bayernwetter mit Wettertrends.
							</a>
						</h6>
					</main>
				</div>
			</div>
		);
	}
}
