import SVGIcon from '../SVGIcon';
import React from 'react';

export default function FacebookGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="16.001" r="16" />
			<path
				fill="#FFF"
				d="M17.23 11.352c0-.648.428-.798.732-.798h1.852V7.709l-2.554-.01c-2.836 0-3.479 2.123-3.479 3.48v1.896h-1.64v2.932h1.64v8.294h3.448v-8.294h2.326l.302-2.932H17.23v-1.723z"
			/>
		</SVGIcon>
	);
}
