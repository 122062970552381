import React from 'react';
import classNames from 'classnames';
import styles from './StaticPages.scss';

/**
 * Displays the credits page.
 */
export default class Credits extends React.Component {
	render() {
		return (
			<div className={classNames(styles.imprint, 'container')}>
				<main>
					<div className={classNames(styles.headline)}>
						<h1>Das Team hinter der BAYERN 3 Webseite</h1>
					</div>

					<div className={classNames(styles.content)}>
						<div className="row">
							<label>Leiter BAYERN 1, BAYERN 3 & PULS:</label>
							<p>Walter Schmich</p>
						</div>
						<div className="row">
							<label>Leiterin BAYERN 3 Programmredaktion:</label>
							<p>Katja Voigt-Kreutzer (kommissarisch)</p>
						</div>
						<div className="row">
							<label>Leiter BAYERN 1 und BAYERN 3 Digital:</label>
							<p>Frizz Lauterbach</p>
						</div>
						<div className="row">
							<label>Team BAYERN 3 Homepage:</label>
							<p>
								Isabella Reismüller, Kerstin Heinz, Christian Ribbers, Uli Dambeck, Marion Uschold, Markus Putz, Anna Feininger, Verena
								Hampl, Maida Besirevic, Michael Pustal, Christian Brecht.
							</p>
						</div>
					</div>

					<p>
						Die BAYERN 3 Webseite ist ein Produkt der Abteilung Softwareentwicklung und Plattformen (SEP), PTD des Bayerischen Rundfunks.
					</p>

					<h1>Credits</h1>

					<div className={classNames(styles.content)}>
						<div className="row">
							<label>Konzeption:</label>
							<p>Christian Bulmahn, Philipp Dressel, Bastian Kistner, Frizz Lauterbach, Marcus Schuler</p>
						</div>
						<div className="row">
							<label>Frontend:</label>
							<p>Daniel Büchele, Philipp Dressel, Anselm Eickhoff, Michael Gabler, Frank Hermann, Bastian Kistner</p>
						</div>
						<div className="row">
							<label>Backend-System:</label>
							<p>Philipp Dressel, Michael Gabler, Bastian Kistner, Thomas Krammer</p>
						</div>
						<div className="row">
							<label>Design und UX:</label>
							<p>Elisabeth Adelsberger, Christian Bulmahn, Luisa Kopp, Alexander Stahl</p>
						</div>
					</div>
				</main>
			</div>
		);
	}
}
