export const createEmptyArticle = () => {
	return {
		data: {
			id: '',
			type: 'article',
			attributes: {
				content: {
					headline: '',
					title: '',
					teaser_text: '',
					teaser_image: {
						element_type: 'image',
						metadata: {
							title: '',
							tags: [],
							copyright: '',
						},
						content: {
							source: '',
							variants: {
								three_by_one: '',
								three_by_two: '',
								one_by_one: '',
							},
						},
					},
					elements: [],
					summary: '',
					teaser_element: {
						element_type: 'gallery',
						content: {
							images: [],
						},
					},
				},
				metadata: {
					facebook_link: '',
					facebook_share: false,
					facebook_comments_active: true,
					facebook_ia_active: true,
					twitter_link: '',
					twitter_share: false,
					recommendations_active: true,
					banner_active: true,
					tags: [],
					author: {
						first_name: '',
						last_name: '',
						image: '',
						link: '',
					},
					doc: {
						status: 'draft',
					},
				},
			},
			relationships: {
				boards: {
					data: [
						{
							id: 'homepage',
							type: 'board',
						},
						{
							id: 'mobile',
							type: 'board',
						},
					],
				},
			},
		},
	};
};

export const createEmptyTeaserBoard = () => {
	return {
		data: {
			id: '',
			type: 'board',
			attributes: {
				content: {
					title: '',
				},
				metadata: {
					board_type: 1,
					doc: {
						status: 'draft',
					},
				},
			},
			relationships: {
				pinned_articles: { data: [] },
				matching_articles: { data: [] },
			},
		},
	};
};
