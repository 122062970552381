import React from 'react';

interface IProps {
	// @ts-ignore
	data: any;
}

/**
 * Component for displaying facebook videos on amp.
 */
export default class ContentElementEmbedFacebookVideoAMP extends React.Component<IProps> {
	static defaultProps = {
		data: {
			content: {
				html: '<div class="fb-video" data-href="" data-allowfullscreen="true"></div>',
			},
		},
	};

	render() {
		const str = this.props.data.content.html;
		const videoURL = 'https://facebook.com/bayern3/videos/' + str.match(/videos\/(.*?)\//).pop() + '/';
		// @ts-ignore
		return <amp-facebook layout="responsive" width="1920" height="1000" data-embed-as="video" data-href={videoURL} />;
	}
}
