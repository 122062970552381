import SVGIcon from '../SVGIcon';
import React from 'react';

export default function HagelschauerIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zm9.986-1.27l-.757-1.286 4.609-2.709.757 1.285zm-1.633-2.14l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<path
				fill="#FFF"
				d="M21.485 15.737c-1.097 0-2.011-.912-2.011-2.01s.914-2.011 2.011-2.011c.183 0 .275 0 .458.092l.273.092.092-.275c.366-1.462 1.737-2.468 3.29-2.468 1.555 0 2.834 1.006 3.292 2.468l.092.275.273-.092c.183 0 .275-.092.458-.092 1.097 0 2.012.914 2.012 2.011s-.914 2.01-2.012 2.01h-8.228z"
			/>
			<path
				fill="#4F4E4E"
				d="M29.712 15.986h-8.227c-1.224 0-2.259-1.035-2.259-2.259s1.035-2.259 2.259-2.259c.191-.004.326 0 .552.109l.023.008.012-.039c.386-1.546 1.838-2.637 3.526-2.637 1.643 0 3.027 1.037 3.53 2.641l.01.035.078-.025h.039c.051 0 .09-.014.15-.033a.887.887 0 0 1 .308-.058c1.224 0 2.259 1.035 2.259 2.259s-1.036 2.258-2.26 2.258zm-8.227-4.023c-.955 0-1.762.809-1.762 1.764s.807 1.762 1.762 1.762h8.227c.955 0 1.762-.807 1.762-1.762s-.807-1.764-1.762-1.764a.422.422 0 0 0-.15.035.917.917 0 0 1-.263.056l-.476.158-.17-.511c-.437-1.398-1.635-2.298-3.056-2.298-1.46 0-2.713.937-3.048 2.28l-.175.528-.544-.181c-.129-.067-.176-.067-.345-.067z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.597 9.338c1.372 0 2.559.916 2.926 2.286l.183.55.548-.183c.092 0 .275-.092.366-.092.914 0 1.737.731 1.737 1.737 0 .914-.731 1.737-1.737 1.737h-8.136a1.736 1.736 0 0 1-1.737-1.737c0-.914.731-1.737 1.737-1.737.092 0 .275 0 .364.092l.55.183.183-.55c.368-1.37 1.646-2.286 3.016-2.286m0-.637c-1.737 0-3.109 1.187-3.565 2.742-.183 0-.364-.092-.548-.092-1.281 0-2.376 1.095-2.376 2.376s1.004 2.378 2.376 2.378h8.136c1.279 0 2.376-1.097 2.376-2.378s-1.097-2.376-2.376-2.376c-.183 0-.366 0-.548.092-.366-1.555-1.828-2.742-3.475-2.742z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.221 12.538h-.091l-1.187-.458c-.183-.09-.275-.273-.183-.364.09-.183.273-.273.364-.183l1.189.458c.181.09.273.275.181.364-.089.092-.181.183-.273.183z"
			/>
			<g>
				<path
					fill="#FFF"
					d="M4.758 21.771a1.736 1.736 0 0 1-1.737 1.737c-.916 0-1.739-.823-1.739-1.737 0-1.006.822-1.737 1.739-1.737a1.737 1.737 0 0 1 1.737 1.737z"
				/>
				<path
					fill="#4F4E4E"
					d="M3.021 23.755c-1.058 0-1.986-.928-1.986-1.984 0-1.095.891-1.986 1.986-1.986a1.986 1.986 0 0 1 0 3.97zm0-3.471c-.823 0-1.489.667-1.489 1.487 0 .778.709 1.487 1.489 1.487a1.488 1.488 0 0 0 0-2.974z"
				/>
				<circle fill="#FFF" cx="6.128" cy="26.982" r="1.738" />
				<path
					fill="#4F4E4E"
					d="M6.128 28.967a1.988 1.988 0 0 1-1.984-1.986c0-1.095.891-1.986 1.984-1.986 1.095 0 1.986.891 1.986 1.986a1.988 1.988 0 0 1-1.986 1.986zm0-3.473a1.49 1.49 0 0 0-1.489 1.487 1.488 1.488 0 1 0 1.489-1.487z"
				/>
				<circle fill="#FFF" cx="11.338" cy="25.153" r="1.737" />
				<path
					fill="#4F4E4E"
					d="M11.338 27.139a1.988 1.988 0 0 1-1.984-1.986c0-1.093.891-1.984 1.984-1.984 1.095 0 1.986.891 1.986 1.984a1.988 1.988 0 0 1-1.986 1.986zm0-3.475a1.49 1.49 0 0 0 0 2.978c.821 0 1.489-.669 1.489-1.489 0-.821-.669-1.489-1.489-1.489z"
				/>
			</g>
			<g>
				<path
					fill="#C9C8C8"
					d="M2.754 18.298a2.371 2.371 0 0 1-2.372-2.37 2.36 2.36 0 0 1 2.372-2.358c.185 0 .361.023.546.06l.347.088.099-.347a3.918 3.918 0 0 1 3.774-2.893 3.918 3.918 0 0 1 3.773 2.893l.099.347.347-.088c.173-.037.361-.06.548-.06a2.367 2.367 0 0 1 2.37 2.358 2.37 2.37 0 0 1-2.37 2.37H2.754z"
				/>
				<path
					fill="#4F4E4E"
					d="M7.519 10.85a3.537 3.537 0 0 1 3.415 2.62l.185.696.708-.162c.15-.037.31-.051.46-.051 1.103 0 1.998.895 1.998 1.986a1.997 1.997 0 0 1-1.998 1.998H2.754a1.996 1.996 0 0 1-1.998-1.998c0-1.092.893-1.986 1.998-1.986.148 0 .31.014.458.051l.708.162.187-.696a3.534 3.534 0 0 1 3.412-2.62m0-.744a4.29 4.29 0 0 0-4.132 3.165A2.742 2.742 0 0 0 .01 15.928a2.744 2.744 0 0 0 2.744 2.742h9.533a2.744 2.744 0 0 0 2.742-2.742 2.733 2.733 0 0 0-2.742-2.731c-.224 0-.435.023-.633.074a4.292 4.292 0 0 0-4.135-3.165z"
				/>
				<path
					fill="#4F4E4E"
					d="M4.604 14.499a.332.332 0 0 1-.119-.021l-1.205-.499a.307.307 0 0 1-.168-.403.312.312 0 0 1 .405-.17l1.203.497a.31.31 0 0 1 .17.405.313.313 0 0 1-.286.191z"
				/>
			</g>
			<g>
				<path
					fill="#CAC9C8"
					d="M9.145 21.771c-1.92 0-3.565-1.553-3.565-3.565 0-1.92 1.553-3.565 3.565-3.565.273 0 .548 0 .823.092l.456.092.092-.458c.639-2.559 3.017-4.296 5.576-4.296s4.935 1.737 5.576 4.296l.092.458.456-.092c.273-.092.548-.092.823-.092 1.92 0 3.565 1.553 3.565 3.565 0 1.92-1.553 3.565-3.565 3.565H9.145z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.092 10.619c2.376 0 4.479 1.647 5.118 3.931l.275.914.914-.183c.275-.092.456-.092.731-.092 1.645 0 3.017 1.372 3.017 3.017 0 1.645-1.372 3.015-3.017 3.015H9.145c-1.647 0-3.017-1.37-3.017-3.015s1.37-3.017 3.017-3.017c.273 0 .456 0 .731.092l.914.183.275-.914c.548-2.284 2.651-3.931 5.027-3.931m0-1.006c-2.926 0-5.393 2.012-6.124 4.662-.275-.09-.639-.09-.914-.09-2.195 0-4.023 1.826-4.023 4.021s1.828 4.021 4.023 4.021H23.13c2.195 0 4.023-1.826 4.023-4.021s-1.828-4.021-4.023-4.021c-.366 0-.641 0-.914.09-.731-2.651-3.199-4.662-6.124-4.662z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.796 16.012h-.183l-1.645-.731c-.183-.092-.366-.366-.183-.548.092-.183.366-.366.548-.183l1.647.731c.181.092.364.365.181.55-.001.089-.182.181-.365.181z"
				/>
			</g>
		</SVGIcon>
	);
}
