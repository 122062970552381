import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeregenschauerIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M13.999 11.421a4.05 4.05 0 0 1-1.436 5.533 4.048 4.048 0 0 1-5.532-1.436 4.051 4.051 0 0 1 1.436-5.533 4.042 4.042 0 0 1 5.532 1.436zM8.553 8.638l-1.287.755-2.71-4.61 1.288-.757zm-2.158 1.628l-.737 1.299-4.655-2.642.738-1.3zm-1.052 2.48l.012 1.494-5.342.039-.012-1.493zm9.986-1.27l-.757-1.286 4.609-2.709.757 1.285zm-1.633-2.14l-1.298-.737 2.631-4.65 1.298.737zm-2.502-1.041l-1.492.014-.04-5.343 1.493-.012z"
			/>
			<path
				fill="#0971B8"
				d="M8.692 26.02l-.182-.735-1.279.365.274-1.285-.642-.185-.365 1.289-1.004-.92-.459.551.915.92-1.28.367.184.736 1.37-.369-.366 1.289.733.182.272-1.379 1.007 1.011.458-.55-.916-.92zM18.657 26.754l-.182-.643-1.281.368.275-1.288-.642-.183-.364 1.289-1.006-.922-.457.461 1.006 1.01-1.371.369.183.642 1.37-.369-.365 1.379.732.188.272-1.381 1.007.92.458-.459-.915-.92zM4.214 22.619c.273-.461.273-1.197.273-1.748 0-.736.092-1.379.092-1.379s-.365.459-1.006.828c-.549.367-1.371.643-1.555 1.01-.364.645-.183 1.379.458 1.748.64.367 1.462.182 1.738-.459zM12.988 26.205c.275-.461.275-1.197.275-1.748 0-.736.092-1.379.092-1.379s-.367.459-1.007.828c-.548.365-1.37.642-1.555 1.01-.365.643-.183 1.381.459 1.748.64.365 1.463.09 1.736-.459z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.76 18.257a2.377 2.377 0 0 1-2.37-2.384 2.366 2.366 0 0 1 2.37-2.371c.186 0 .36.025.546.062l.347.087.099-.349c.459-1.71 2.01-2.908 3.773-2.908s3.313 1.198 3.773 2.908l.099.349.347-.087a2.374 2.374 0 0 1 2.916 2.309 2.377 2.377 0 0 1-2.37 2.384H2.76z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.525 10.769a3.539 3.539 0 0 1 3.413 2.633l.186.699.707-.162c.149-.038.31-.05.459-.05a2 2 0 0 1 1.998 1.997 2.002 2.002 0 0 1-1.998 2.009H2.76a2.002 2.002 0 0 1-1.998-2.009 2 2 0 0 1 1.998-1.997c.149 0 .31.012.459.05l.707.162.186-.699a3.54 3.54 0 0 1 3.413-2.633m0-.748c-1.973 0-3.636 1.348-4.133 3.182a2.749 2.749 0 0 0-3.376 2.671 2.752 2.752 0 0 0 2.743 2.758h9.531a2.752 2.752 0 0 0 2.743-2.758 2.741 2.741 0 0 0-3.376-2.671c-.495-1.835-2.158-3.182-4.132-3.182z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.609 14.439a.311.311 0 0 1-.118-.024l-1.204-.499a.313.313 0 0 1-.169-.407.31.31 0 0 1 .405-.169l1.204.499a.313.313 0 0 1 .169.407.31.31 0 0 1-.287.193z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.488 15.721a2.05 2.05 0 0 1-2.034-2.045 2.05 2.05 0 0 1 2.505-1.995l.298.075.074-.287c.397-1.471 1.724-2.494 3.236-2.494s2.852 1.023 3.236 2.494l.074.287.298-.075a2.05 2.05 0 0 1 2.505 1.995 2.05 2.05 0 0 1-2.034 2.045h-8.158z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.567 9.274c1.376 0 2.579.935 2.939 2.269l.161.586.583-.137c.136-.025.26-.05.397-.05.955 0 1.724.773 1.724 1.733s-.769 1.733-1.724 1.733h-8.159c-.955 0-1.724-.773-1.724-1.733s.769-1.733 1.724-1.733c.136 0 .273.013.397.05l.583.137.161-.586a3.053 3.053 0 0 1 2.938-2.269m0-.623a3.653 3.653 0 0 0-3.534 2.731 2.745 2.745 0 0 0-.546-.062 2.357 2.357 0 0 0-2.344 2.357 2.357 2.357 0 0 0 2.344 2.357h8.159c1.29 0 2.344-1.06 2.344-2.357a2.357 2.357 0 0 0-2.89-2.295 3.65 3.65 0 0 0-3.533-2.731z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.224 12.492a.301.301 0 0 1-.117-.023l-1.215-.499a.311.311 0 0 1 .234-.577l1.215.499a.311.311 0 0 1-.117.6z"
			/>
			<g>
				<path
					fill="#FFF"
					d="M9.12 21.767c-1.952 0-3.53-1.6-3.53-3.55 0-1.963 1.591-3.55 3.53-3.55.273 0 .547.037.82.1l.472.113.124-.463c.671-2.575 2.983-4.35 5.606-4.35s4.935 1.775 5.606 4.325l.124.463.472-.113c.273-.062.547-.1.82-.1 1.952 0 3.53 1.6 3.53 3.55 0 1.963-1.591 3.55-3.53 3.55H9.12v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.143 10.567a5.328 5.328 0 0 1 5.134 3.95l.249.938.932-.225c.236-.05.46-.088.696-.088 1.678 0 3.033 1.375 3.033 3.05a3.045 3.045 0 0 1-3.033 3.05H9.107c-1.678 0-3.033-1.375-3.033-3.05a3.045 3.045 0 0 1 3.033-3.05c.236 0 .472.025.696.088l.932.225.249-.938c.647-2.325 2.76-3.95 5.159-3.95m0-1c-2.921 0-5.37 1.987-6.091 4.688a3.807 3.807 0 0 0-.932-.113c-2.225 0-4.027 1.813-4.027 4.05s1.802 4.05 4.027 4.05h14.046c2.225 0 4.027-1.812 4.027-4.05 0-2.238-1.802-4.05-4.027-4.05-.323 0-.634.037-.932.113-.722-2.701-3.17-4.688-6.091-4.688z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.804 15.992a.44.44 0 0 1-.173-.036l-1.603-.7a.439.439 0 0 1-.226-.576.434.434 0 0 1 .572-.227l1.603.7a.439.439 0 0 1-.173.839z"
				/>
			</g>
		</SVGIcon>
	);
}
