import ContentElementHtmlTextIA from '../components/ContentElementHtmlText/ContentElementHtmlTextIA';
import ContentElementMediaPlayerIA from '../components/ContentElementMediaPlayer/ContentElementMediaPlayerIA';
import ContentElementImageIA from '../components/ContentElementImage/ContentElementImageIA';
import ContentElementEmbedIA from '../components/ContentElementEmbed/ContentElementEmbedIA';

export default {
	text: ContentElementHtmlTextIA,
	media: ContentElementMediaPlayerIA,
	gallery: ContentElementImageIA,
	embed: ContentElementEmbedIA,
};
