import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SearchIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#3C3C3B"
				d="M24.014 22.987l-4.27-4.268a6.66 6.66 0 0 0 1.444-4.138 6.706 6.706 0 0 0-6.697-6.697 6.704 6.704 0 0 0-6.697 6.697 6.703 6.703 0 0 0 6.697 6.696 6.66 6.66 0 0 0 4.337-1.604l4.25 4.248a.662.662 0 0 0 .936 0 .668.668 0 0 0 0-.934zm-9.523-3.029a5.383 5.383 0 0 1-5.377-5.376 5.383 5.383 0 0 1 5.377-5.377 5.382 5.382 0 0 1 5.374 5.377 5.382 5.382 0 0 1-5.374 5.376zm-.32-9.093a3.567 3.567 0 0 0-3.561 3.564.66.66 0 0 0 1.322 0 2.243 2.243 0 0 1 2.239-2.244.662.662 0 0 0 .663-.658.662.662 0 0 0-.663-.662z"
			/>
		</SVGIcon>
	);
}
