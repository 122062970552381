import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isLoaded as isNewsServiceLoaded, load as loadNewsService } from '../../redux/modules/news';
import { isLoaded as isTrafficServiceLoaded, load as loadTrafficService } from '../../redux/modules/traffic';
import { isLoaded as isWeatherServiceLoaded, load as loadWeatherService } from '../../redux/modules/weather';
import { bindActionCreators } from 'redux';
import DocumentMeta from 'react-document-meta';

import { News } from '../../br3-components';
// import NewsView from './NewsView';

class NewsViewConnector extends Component {
	static fetchData(getState, dispatch, location, params) {
		const promises = [];

		if (!isNewsServiceLoaded(getState())) {
			promises.push(dispatch(loadNewsService({ ...params })));
		}
		if (!isTrafficServiceLoaded(getState())) {
			promises.push(dispatch(loadTrafficService({ ...params })));
		}
		if (!isWeatherServiceLoaded(getState())) {
			promises.push(dispatch(loadWeatherService({ ...params })));
		}

		return Promise.all(promises);
	}

	checkLocalZipcode() {
		let zipcode = null;
		if (window && window.localStorage) {
			zipcode = window.localStorage.getItem('location:zipcode');
			if (zipcode) {
				this.props.loadWeatherService(parseInt(zipcode, 10));
			}
		}
	}

	componentDidMount() {
		this.checkLocalZipcode();
	}

	render() {
		let view = this.props.location.pathname.endsWith('/nachrichten/android') && 'android';
		let metaSection = 'Nachrichten';
		let metaTitle = `Bayern 3 - ${metaSection}`;
		let metaDescription = 'Aktuelle Nachrichten aus der Welt.';
		let meta = {
			title: metaTitle,
			description: metaDescription,
			meta: {
				property: {
					'og:title': metaTitle,
					'og:description': metaDescription,
					'twitter:site': '@bayern3',
					'twitter:creator': '@bayern3',
					'twitter:title': metaTitle,
					'twitter:description': metaDescription,
					'ati:section': metaSection,
					'ati:doctype': metaSection,
				},
			},
		};

		const { news, weather, traffic } = this.props;

		return (
			<div>
				<News news={news} weather={weather} traffic={traffic} view={view} />
				<DocumentMeta {...meta} extend />
			</div>
		);
	}
}

export default connect(
	state => ({
		news: state.news.data,
		traffic: state.traffic.data,
		weather: state.weather.data,
		error: state.news.error,
		loading: state.news.loading,
	}),
	dispatch => bindActionCreators({ loadNewsService, loadTrafficService, loadWeatherService }, dispatch)
)(NewsViewConnector);
