import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementHtmlText.scss';

export default class ContentElementHtmlTextAMP extends React.Component {
	static defaultProps = {
		data: {
			content: {
				text: '',
			},
			element_type: 'text',
			element_subtype: 'html',
		},
	};

	render() {
		let rawText = this.props.data.content.text;
		let processedText = rawText.replace(/style=/gim, 'data-style=');

		return <div data-class="contentElementText" className={classNames(styles.text)} dangerouslySetInnerHTML={{ __html: processedText }} />;
	}
}
