import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PlayerPlayGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="15.999" cy="15.999" r="15.999" />
			<path
				fill="#FCFCFC"
				d="M21.775 15.55L13.14 9.957a.534.534 0 0 0-.826.45v11.186a.54.54 0 0 0 .279.473.544.544 0 0 0 .547-.022l8.635-5.594a.535.535 0 0 0 0-.9z"
			/>
		</SVGIcon>
	);
}
