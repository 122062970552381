import SVGIcon from '../SVGIcon';
import React from 'react';

export default function LeichtBewoelktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M15.778 17.626a8.605 8.605 0 0 1-7.134 3.766c-4.778 0-8.643-3.865-8.643-8.643s3.865-8.643 8.643-8.643c.498 0 .995.05 1.476.133a7.479 7.479 0 0 0-2.986 5.989 7.502 7.502 0 0 0 7.499 7.499c.398-.018.78-.052 1.145-.101zm4.877-12.742l-.049 3.119 3.035-.73-2.024 2.356 2.788 1.394-3.086.497 1.244 2.854-2.671-1.593-.879 2.987-1.029-2.937-2.604 1.725 1.111-2.919-3.102-.349 2.721-1.526-2.14-2.256 3.052.58-.199-3.102 1.974 2.406z"
			/>
			<path
				fill="#FFF"
				d="M11.933 27.287a3.875 3.875 0 0 1-3.875-3.875 3.858 3.858 0 0 1 3.875-3.855c.304 0 .588.04.893.101l.568.142.162-.568c.751-2.78 3.287-4.728 6.168-4.728s5.417 1.948 6.168 4.728l.162.568.568-.142c.284-.061.588-.101.893-.101 2.13 0 3.875 1.725 3.875 3.855a3.875 3.875 0 0 1-3.875 3.875H11.933z"
			/>
			<path
				fill="#4F4E4E"
				d="M19.725 15.113a5.78 5.78 0 0 1 5.58 4.281l.304 1.136 1.157-.264c.243-.061.507-.081.751-.081 1.806 0 3.267 1.461 3.267 3.246a3.265 3.265 0 0 1-3.267 3.267H11.933a3.265 3.265 0 0 1-3.267-3.267c0-1.785 1.461-3.246 3.267-3.246.243 0 .507.02.751.081l1.157.264.304-1.136a5.777 5.777 0 0 1 5.58-4.281m0-1.218c-3.226 0-5.945 2.191-6.757 5.174a4.267 4.267 0 0 0-1.035-.122c-2.475 0-4.484 2.009-4.484 4.464a4.486 4.486 0 0 0 4.484 4.484h15.583A4.486 4.486 0 0 0 32 23.411c0-2.475-2.009-4.464-4.484-4.464-.365 0-.71.04-1.035.122-.811-2.982-3.53-5.174-6.756-5.174z"
			/>
			<path
				fill="#4F4E4E"
				d="M14.956 21.078a.506.506 0 0 1-.193-.038l-1.968-.812a.508.508 0 0 1 .386-.938l1.968.812a.508.508 0 0 1-.193.976z"
			/>
		</SVGIcon>
	);
}
