import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import placeholderImage from './cover_fallback2.png';
import styles from './PlayerHeaderDropdownElement.scss';

function formatTimestamp(timestamp) {
	const ts = moment(timestamp);
	const hours = ts.hours();
	const minutes = ts.minutes() > 9 ? ts.minutes() : '0' + ts.minutes();
	return hours + ':' + minutes + ' Uhr';
}

export default class PlayerHeaderDropdown extends Component {
	static propTypes = {
		item: PropTypes.object.isRequired,
	};

	static defaultProps = {
		item: {},
	};

	render = () => {
		const time = formatTimestamp(this.props.item.timestamp);
		const thumbnailBackground = {
			backgroundImage: `url(${placeholderImage})`,
			backgroundSize: '100% auto',
		};
		const thumbnailForeground = Object.assign(
			this.props.item.image_url && this.props.item.image_url.length > 0 && this.props.item.image_url !== 'undefined'
				? { backgroundImage: `url(${this.props.item.image_url})` }
				: {},
			{ backgroundSize: '100% auto' }
		);

		return (
			<div className={classNames(styles.playerHeaderDropdownElement)}>
				<div style={thumbnailBackground} className={classNames(styles.thumbnailBackground)}>
					<div style={thumbnailForeground} className={classNames(styles.thumbnailForeground)} />
				</div>
				<div className={classNames(styles.meta)}>
					<span>{this.props.item.interpret}</span>
					<span className={classNames(styles.title)}>{this.props.item.title}</span>
				</div>
				<div className={classNames(styles.timeShareBox)}>
					<span>{time}</span>
				</div>
			</div>
		);
	};
}
