import PropTypes from 'prop-types';
import React from 'react';

interface IProps {
	// @ts-ignore
	data: any;
}

/**
 * Displays an embedded script for amp and instant articles.
 */
export default class ContentElementEmbedScriptAMPIA extends React.Component<IProps> {
	static propTypes = {
		data: PropTypes.shape({
			content: PropTypes.shape({
				html: PropTypes.string,
			}),
		}),
	};

	static defaultProps = {
		data: {
			content: {
				html: '',
			},
		},
	};

	render() {
		return <div dangerouslySetInnerHTML={{ __html: this.props.data.content.html }} />;
	}
}
