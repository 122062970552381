import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PlayerPauseTransparentIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle opacity=".8" fill="#FFF" cx="16" cy="15.999" r="16" />
			<path
				d="M14.249 9.896H11.23a.536.536 0 0 0-.538.536v11.136c0 .299.24.537.538.537h3.019a.532.532 0 0 0 .533-.537V10.432a.532.532 0 0 0-.533-.536zm6.52 0H17.75a.533.533 0 0 0-.534.536v11.136c0 .299.237.537.534.537h3.019a.535.535 0 0 0 .538-.537V10.432a.536.536 0 0 0-.538-.536z"
				fill="#3C3C3B"
			/>
		</SVGIcon>
	);
}
