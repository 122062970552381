import ContentElementHtmlText from '../components/ContentElementHtmlText/ContentElementHtmlText';
import ContentElementMediaPlayer from '../components/ContentElementMediaPlayer/ContentElementMediaPlayer';
import ContentElementImage from '../components/ContentElementImage/ContentElementImage';
import ContentElementEmbed from '../components/ContentElementEmbed/ContentElementEmbed';

export default {
	text: ContentElementHtmlText,
	media: ContentElementMediaPlayer,
	gallery: ContentElementImage,
	songbattle: ContentElementImage,
	embed: ContentElementEmbed,
};
