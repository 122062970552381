import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GraupelschauerNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.441 13.565a6.446 6.446 0 0 1-5.345 2.823 6.473 6.473 0 0 1-6.477-6.477 6.472 6.472 0 0 1 6.477-6.476c.373 0 .745.036 1.106.1a5.619 5.619 0 0 0 3.382 10.104c.296-.012.584-.036.857-.074zm3.654-9.546l-.037 2.336 2.275-.547-1.516 1.765 2.087 1.045-2.313.372.934 2.139-2.001-1.194-.659 2.238-.771-2.2-1.952 1.293.834-2.188-2.324-.261 2.038-1.144-1.603-1.691 2.285.436-.149-2.324 1.481 1.802z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.742 18.665a2.373 2.373 0 0 1-2.373-2.372 2.363 2.363 0 0 1 2.373-2.36c.186 0 .359.025.547.062l.345.087.1-.348A3.922 3.922 0 0 1 7.51 10.84a3.922 3.922 0 0 1 3.776 2.894l.1.348.347-.087c.175-.037.361-.062.545-.062a2.368 2.368 0 0 1 2.373 2.36 2.372 2.372 0 0 1-2.373 2.372H2.742z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.511 11.213a3.54 3.54 0 0 1 3.417 2.62l.186.696.708-.162c.149-.037.31-.05.457-.05 1.106 0 2.001.895 2.001 1.987 0 1.106-.894 2-2.001 2H2.742a1.997 1.997 0 0 1-1.999-2c0-1.092.893-1.987 1.999-1.987.149 0 .31.013.459.05l.708.162.186-.696a3.538 3.538 0 0 1 3.416-2.62m0-.746a4.29 4.29 0 0 0-4.135 3.167 2.745 2.745 0 0 0-3.38 2.657 2.748 2.748 0 0 0 2.746 2.744h9.537a2.746 2.746 0 0 0 2.746-2.744 2.736 2.736 0 0 0-3.378-2.657 4.293 4.293 0 0 0-4.136-3.167z"
			/>
			<path
				fill="#FFF"
				d="M21.484 16.123a2.03 2.03 0 0 1-2.013-2.013 2.03 2.03 0 0 1 2.013-2.013c.182 0 .275 0 .457.092l.275.091.092-.275c.365-1.464 1.736-2.469 3.291-2.469s2.836 1.004 3.293 2.469l.092.275.273-.091c.184 0 .277-.092.459-.092a2.03 2.03 0 0 1 2.013 2.013 2.03 2.03 0 0 1-2.013 2.013h-8.232z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.6 9.719c1.373 0 2.562.914 2.929 2.287l.182.548.547-.182c.092 0 .277-.091.367-.091.914 0 1.738.731 1.738 1.737 0 1.007-.732 1.738-1.738 1.738h-8.14a1.736 1.736 0 0 1-1.738-1.738c0-1.006.732-1.737 1.738-1.737.092 0 .275 0 .365.091l.549.182.184-.548c.367-1.373 1.646-2.287 3.017-2.287m0-.639c-1.738 0-3.109 1.189-3.566 2.744-.184 0-.367-.091-.549-.091-1.281 0-2.379 1.097-2.379 2.377a2.362 2.362 0 0 0 2.379 2.378h8.14c1.281 0 2.379-1.097 2.379-2.378 0-1.281-1.098-2.377-2.379-2.377-.182 0-.367 0-.549.091-.365-1.556-1.83-2.744-3.476-2.744z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.222 12.958a.31.31 0 0 1-.129-.029l-1.191-.549a.312.312 0 0 1 .263-.564l1.187.549a.308.308 0 0 1 .151.413.307.307 0 0 1-.281.18z"
			/>
			<path
				fill="#CAC9C8"
				d="M9.137 22.158c-1.92 0-3.566-1.554-3.566-3.566s1.554-3.567 3.566-3.567c.275 0 .547 0 .824.092l.457.091.092-.458c.639-2.561 3.017-4.299 5.579-4.299 2.56 0 4.939 1.738 5.579 4.299l.092.458.457-.091c.275-.092.549-.092.824-.092 1.92 0 3.568 1.555 3.568 3.567s-1.557 3.566-3.568 3.566H9.137z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.088 11c2.377 0 4.48 1.646 5.122 3.934l.275.915.914-.184c.275-.091.457-.091.734-.091 1.646 0 3.017 1.373 3.017 3.019 0 1.647-1.371 3.018-3.017 3.018H9.137c-1.646 0-3.019-1.371-3.019-3.018 0-1.646 1.373-3.019 3.019-3.019.275 0 .457 0 .732.091l.914.184.275-.915c.548-2.288 2.651-3.934 5.03-3.934m0-1.005c-2.929 0-5.396 2.012-6.128 4.665-.277-.091-.641-.091-.914-.091-2.195 0-4.025 1.829-4.025 4.024 0 2.195 1.83 4.024 4.025 4.024h14.086c2.195 0 4.023-1.829 4.023-4.024 0-2.195-1.828-4.024-4.023-4.024-.367 0-.641 0-.916.091-.732-2.654-3.201-4.665-6.128-4.665z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.789 16.375a.428.428 0 0 1-.157-.029l-1.648-.64a.436.436 0 0 1 .318-.812l1.646.641a.436.436 0 1 1-.159.84z"
			/>
			<circle fill="#FFF" cx="3.283" cy="21.702" r="1.097" />
			<path
				fill="#4F4E4E"
				d="M3.283 22.986a1.287 1.287 0 0 1-1.285-1.285c0-.707.577-1.283 1.285-1.283.706 0 1.283.576 1.283 1.283 0 .708-.576 1.285-1.283 1.285zm0-2.196a.913.913 0 0 0-.912.911.912.912 0 1 0 .912-.911z"
			/>
			<circle fill="#FFF" cx="5.021" cy="27.281" r="1.098" />
			<path fill="#4F4E4E" d="M5.021 28.566a1.286 1.286 0 0 1-1.285-1.284 1.285 1.285 0 1 1 1.285 1.284zm0-2.196z" />
			<path
				fill="#FFF"
				d="M11.424 25.454c0 .547-.457 1.095-1.099 1.095-.641 0-1.097-.457-1.097-1.095 0-.551.455-1.099 1.097-1.099.642-.001 1.099.456 1.099 1.099z"
			/>
			<path
				fill="#4F4E4E"
				d="M10.325 26.735c-.743 0-1.283-.539-1.283-1.282 0-.622.516-1.286 1.283-1.286.743 0 1.285.54 1.285 1.286 0 .619-.516 1.282-1.285 1.282zm0-2.195c-.545 0-.91.473-.91.914 0 .535.375.909.91.909.545 0 .912-.47.912-.909 0-.538-.376-.914-.912-.914z"
			/>
			<g>
				<circle fill="#FFF" cx="15.631" cy="27.281" r="1.098" />
				<path
					fill="#4F4E4E"
					d="M15.631 28.566a1.286 1.286 0 0 1-1.285-1.284c0-.708.577-1.284 1.285-1.284.706 0 1.283.576 1.283 1.284 0 .708-.577 1.284-1.283 1.284zm0-2.196a.913.913 0 0 0 0 1.823.911.911 0 0 0 0-1.823z"
				/>
			</g>
			<g>
				<circle fill="#FFF" cx="20.204" cy="24.903" r="1.097" />
				<path
					fill="#4F4E4E"
					d="M20.204 26.188a1.288 1.288 0 0 1-1.285-1.286c0-.707.577-1.284 1.285-1.284s1.285.577 1.285 1.284a1.288 1.288 0 0 1-1.285 1.286zm0-2.195a.91.91 0 1 0 0 1.82.91.91 0 0 0 0-1.82z"
				/>
			</g>
		</SVGIcon>
	);
}
