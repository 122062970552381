import React from 'react';

export default class HTML5Player extends React.Component {
	playerRef = React.createRef();

	state = {
		fullscreen: false,
		buffering: false,
	};

	UNSAFE_componentWillReceiveProps = nextProps => {
		let player = this.playerRef.current;

		// significant position change
		if (nextProps.position && Math.abs(nextProps.position * player.duration - player.currentTime) > 1.0) {
			player.currentTime = nextProps.position * player.duration;
		}

		// toggle play/pause
		if (player && nextProps.playing === player.paused) {
			nextProps.playing ? player.play() : player.pause();
		}
	};

	componentDidMount = () => {
		let player = this.playerRef.current;
		if (player) {
			player.ondurationchange = this.onDurationChange;
			player.ontimeupdate = this.onPositionChange;
			player.onplaying = this.onPlaying;
			player.onwaiting = this.onWaiting;
			player.onerror = this.props.onError;
			player.onended = this.props.onEnded;
		}

		document.addEventListener('webkitfullscreenchange', this.fullscreenChanged, false);
		document.addEventListener('mozfullscreenchange', this.fullscreenChanged, false);
		document.addEventListener('fullscreenchange', this.fullscreenChanged, false);
		document.addEventListener('MSFullscreenChange', this.fullscreenChanged, false);
	};

	componentWillUnmount = () => {
		document.removeEventListener('webkitfullscreenchange', this.fullscreenChanged, false);
		document.removeEventListener('mozfullscreenchange', this.fullscreenChanged, false);
		document.removeEventListener('fullscreenchange', this.fullscreenChanged, false);
		document.removeEventListener('MSFullscreenChange', this.fullscreenChanged, false);
	};

	fullscreenChanged = () => {
		this.setState({
			fullscreen: !this.state.fullscreen,
		});
	};

	onPositionChange = () => {
		let player = this.playerRef.current;
		if (player) {
			this.props.onPositionChange(player.currentTime / player.duration);
		}
	};

	onDurationChange = () => {
		let player = this.playerRef.current;
		if (player) {
			this.props.onDurationChange(player.duration);
		}
	};

	onWaiting = e => {
		if (e.target && e.target.readyState === 2) {
			this.props.onBufferingChange(true);
			this.setState({
				buffering: true,
			});
		}
	};

	onPlaying = () => {
		if (this.state.buffering) {
			this.props.onBufferingChange(false);
			this.setState({
				buffering: false,
			});
		}
	};

	setVolume = volume => {
		let player = this.playerRef.current;
		if (player) {
			player.volume = volume;
		}
	};
}
