import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WebcamGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="16" r="16" />
			<path
				fill="#FFF"
				d="M23.841 14.76c0-4.324-3.518-7.84-7.839-7.84-4.324 0-7.842 3.516-7.842 7.84 0 4.131 3.217 7.52 7.277 7.811v1.381h-2.8a.563.563 0 1 0 0 1.128h6.771a.563.563 0 1 0 0-1.128h-2.844V22.57c4.06-.291 7.277-3.679 7.277-7.81zm-7.839 6.711c-3.703 0-6.714-3.012-6.714-6.711 0-3.701 3.011-6.711 6.714-6.711a6.72 6.72 0 0 1 6.711 6.711 6.72 6.72 0 0 1-6.711 6.711zm.021-9.819a3.304 3.304 0 0 0-3.298 3.297 3.304 3.304 0 0 0 3.298 3.299 3.302 3.302 0 0 0 3.298-3.299 3.302 3.302 0 0 0-3.298-3.297zm.895 2.879a.897.897 0 0 1 0-1.792.896.896 0 0 1 0 1.792z"
			/>
		</SVGIcon>
	);
}
