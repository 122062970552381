import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import SvgBr from './BR.svg';
import SvgArd from './ARD.svg';

import styles from './Footer.scss';

/**
 * Displays the footer.
 */
export default class Footer extends React.Component {
	render() {
		return (
			<footer className={classNames(styles.footer)}>
				<div className="container">
					<div className={classNames(styles.container)}>
						<div data-class="col" className={classNames(styles.col)}>
							<h4>Bayerischer Rundfunk</h4>
							<ul>
								<li>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://www.br.de/unternehmen/inhalt/organisation/bayerischer-rundfunk-kontakt-telefon-adressen-v2-100.html"
									>
										<p>Kontakt</p>
									</a>
								</li>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/unternehmen/service/impressum/index.html">
										<p>Impressum</p>
									</a>
								</li>
								<li>
									<Link to="/datenschutz">
										<p>Datenschutz</p>
									</Link>
								</li>
							</ul>
						</div>
						<div data-class="col" className={classNames(styles.col)}>
							<h4>Service</h4>
							<ul>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/service/suche/hilfe/index.html">
										<p>Hilfe</p>
									</a>
								</li>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/service/suche/inhaltsverzeichnis/index.html">
										<p>Inhalt</p>
									</a>
								</li>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/service/abo-downloads/newsletter/newsletter112.html">
										<p>Newsletter</p>
									</a>
								</li>
							</ul>
						</div>
						<div data-class="col" className={classNames(styles.col)}>
							<h4>Medien</h4>
							<ul>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/mediathek/">
										<p>Alle BR Videos</p>
									</a>
								</li>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/radio/index.html">
										<p>Alle BR Audios</p>
									</a>
								</li>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/mediathek/podcast/sender/bayern-3">
										<p>Podcast</p>
									</a>
								</li>
							</ul>
						</div>
						<div data-class="col" className={classNames(styles.col)}>
							<h4>Plattformen</h4>
							<ul>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/service/abo-downloads/mobil100.html">
										<p>Mobil</p>
									</a>
								</li>
								<li>
									<a target="_blank" rel="noopener noreferrer" href="https://www.br.de/extra/webspecials/index.html">
										<p>Webspecials</p>
									</a>
								</li>
							</ul>
						</div>
						<div data-class="col" className={classNames(styles.col, styles.logoCol)}>
							<a href="https://www.br.de">
								<h4 data-class="br-icon" className={classNames([styles.icon, styles.br])}>
									<SvgBr />
								</h4>
							</a>
							<a href="https://www.ard.de">
								<h4 data-class="ard-icon" className={classNames([styles.icon, styles.ard])}>
									<SvgArd />
								</h4>
							</a>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
