import SVGIcon from '../SVGIcon';
import React from 'react';

export default function GewitterRegenSonnigNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M12.288 13.163a6.695 6.695 0 0 1-5.551 2.93c-3.718 0-6.726-3.008-6.726-6.726s3.008-6.726 6.726-6.726c.387 0 .775.039 1.149.103a5.82 5.82 0 0 0-2.324 4.66 5.837 5.837 0 0 0 5.835 5.835c.31-.012.607-.038.891-.076zm3.796-9.915l-.039 2.427 2.362-.568-1.575 1.833 2.169 1.085-2.401.387.968 2.22-2.078-1.239-.685 2.324-.8-2.285-2.027 1.342.865-2.272-2.414-.271 2.117-1.188-1.665-1.755 2.375.452-.155-2.415 1.537 1.872z"
			/>
			<path
				fill="#0971B8"
				d="M2.929 23.631c.287-.479.287-1.242.287-1.814 0-.764.096-1.434.096-1.434s-.383.479-1.051.859c-.572.383-1.432.574-1.623 1.051-.382.668-.191 1.432.477 1.812.668.383 1.528.192 1.814-.474zm2.481 4.486c.288-.479.288-1.242.288-1.815 0-.764.096-1.432.096-1.432s-.384.478-1.051.86c-.573.381-1.432.572-1.623 1.051-.382.666-.191 1.43.478 1.812.572.384 1.431.097 1.812-.476zm5.443-1.529c.287-.477.287-1.24.287-1.812 0-.764.095-1.432.095-1.432s-.382.476-1.051.859c-.572.381-1.432.572-1.621 1.049-.384.668-.191 1.432.478 1.814.57.381 1.431.192 1.812-.478z"
			/>
			<path
				fill="#FFF"
				d="M21.069 15.552c-1.16 0-2.114-.954-2.114-2.114s.954-2.114 2.114-2.114c.168 0 .322.013.49.052l.309.077.077-.296c.413-1.521 1.792-2.578 3.364-2.578s2.965 1.057 3.364 2.578l.077.296.309-.077c.155-.039.322-.052.49-.052 1.16 0 2.114.954 2.114 2.114s-.954 2.114-2.114 2.114h-8.48z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.31 8.888c1.431 0 2.681.967 3.055 2.346l.168.606.606-.142c.142-.026.271-.052.412-.052.993 0 1.792.799 1.792 1.792s-.799 1.792-1.792 1.792h-8.482c-.993 0-1.792-.799-1.792-1.792s.799-1.792 1.792-1.792c.142 0 .284.013.412.052l.606.142.168-.606a3.171 3.171 0 0 1 3.055-2.346m0-.645a3.795 3.795 0 0 0-3.674 2.823 2.442 2.442 0 0 0-3.003 2.372 2.443 2.443 0 0 0 2.436 2.436h8.482a2.442 2.442 0 0 0 2.436-2.436 2.442 2.442 0 0 0-3.003-2.372 3.795 3.795 0 0 0-3.674-2.823z"
			/>
			<path
				fill="#4F4E4E"
				d="M22.873 12.213a.327.327 0 0 1-.122-.024l-1.263-.516a.322.322 0 1 1 .243-.597l1.263.516a.322.322 0 0 1-.121.621z"
			/>
			<path
				fill="#C9C8C8"
				d="M8.217 21.802a3.675 3.675 0 0 1-3.669-3.669 3.675 3.675 0 0 1 3.669-3.669c.284 0 .568.039.853.103l.491.116.129-.478c.698-2.661 3.101-4.496 5.827-4.496s5.129 1.835 5.827 4.47l.129.478.491-.116c.284-.065.568-.103.853-.103a3.675 3.675 0 0 1 3.669 3.669 3.675 3.675 0 0 1-3.669 3.669h-14.6v.026z"
			/>
			<path
				fill="#4F4E4E"
				d="M15.517 10.225c2.494 0 4.69 1.68 5.336 4.083l.258.969.969-.233c.246-.052.478-.09.724-.09a3.156 3.156 0 0 1 3.152 3.153 3.156 3.156 0 0 1-3.152 3.152h-14.6a3.156 3.156 0 0 1-3.152-3.152 3.157 3.157 0 0 1 3.152-3.153c.245 0 .491.026.724.09l.969.233.258-.969a5.586 5.586 0 0 1 5.362-4.083m0-1.033a6.55 6.55 0 0 0-6.331 4.845 4.185 4.185 0 0 0-5.155 4.07 4.185 4.185 0 0 0 4.186 4.186h14.6a4.185 4.185 0 0 0 4.186-4.186 4.185 4.185 0 0 0-5.155-4.07 6.55 6.55 0 0 0-6.331-4.845z"
			/>
			<path fill="#4F4E4E" d="M11.007 15.833a.46.46 0 0 1-.18-.037l-1.667-.723a.453.453 0 0 1 .36-.83l1.667.723a.453.453 0 0 1-.18.867z" />
			<g>
				<path
					fill="#C9C8C8"
					d="M19.54 19.715c-.096-.096-.191-.096-.286-.096l-4.009 1.051 2.769-5.25v-.287c0-.096-.097-.191-.191-.191l-2.196-.668c-.19-.096-.286 0-.381.191l-2.293 6.205-.668 1.814-.191.476c0 .096 0 .191.097.285.095.098.191.098.287.098l3.531-.955h.096l-2.482 6.301v.287c0 .094.096.094.191.189l.572.191h.096c.096 0 .19-.096.286-.191l3.628-6.777.955-1.815.382-.668c-.098.001-.098-.095-.193-.19z"
				/>
			</g>
			<g>
				<path
					fill="#A03416"
					d="M19.54 19.715c-.096-.096-.191-.096-.286-.096l-4.009 1.051 2.769-5.25v-.287c0-.096-.097-.191-.191-.191l-2.196-.668c-.19-.096-.286 0-.381.191l-2.293 6.205-.668 1.814-.191.476c0 .096 0 .191.097.285.095.098.191.098.287.098l3.531-.955h.096l-2.482 6.301v.287c0 .094.096.094.191.189l.572.191h.096c.096 0 .19-.096.286-.191l3.628-6.777.955-1.815.382-.668c-.098.001-.098-.095-.193-.19z"
				/>
			</g>
		</SVGIcon>
	);
}
