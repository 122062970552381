import React, { Component } from 'react';
import classNames from 'classnames';
import { debounce } from 'typescript-debounce-decorator';
import wording from '../../commons/wording';
import * as config from '../../../config.webapp';
import SvgSearch from './search.svg';
import styles from './WeatherOverview.scss';
import { WeatherOverviewProps, IWeatherOverviewState } from './IWeather';

const minimumSearchRequestLength = 2;

enum EventKey {
	ENTER = 'Enter',
	ARROW_UP = 'ArrowUp',
	ARROW_DOWN = 'ArrowDown',
}
/**
 * Component to display an overview of the weather in the selected city.
 * There's also a search bar to change the location.
 */
export default class WeatherOverview extends Component<WeatherOverviewProps> {
	static defaultProps = {};

	state: IWeatherOverviewState = {
		locationSearchResults: [],
		noResults: false,
		lastSearch: '',
	};

    searchFieldRef = React.createRef<HTMLInputElement>();

	@debounce(300)
	searchLocation(zipcodeOrCity: string | number) {
		this.setState({ locationSearchResults: [], lastSearch: zipcodeOrCity, noResults: false });
		const url = config.SERVICE.location;

		fetch(`${url}/${zipcodeOrCity}`)
			.then(res => {
				if (res.ok) {
					res.json().then(responseBody => {
						if (this.state.lastSearch === zipcodeOrCity && responseBody.items && responseBody.items.length > 0)
							this.setState({ locationSearchResults: responseBody.items, noResults: false });
						else this.setState({ locationSearchResults: [], noResults: true });
					});
				} else {
					this.setState({ locationSearchResults: [], noResults: true });
				}
			})
			.catch(() => this.setState({ locationSearchResults: [], noResults: true }));
	}

	resizeSearchField = () => {
		let searchField = this.searchFieldRef.current;
		let wasEmtpy = false;

        if (searchField) {
            if (!searchField.value) {
                wasEmtpy = true;
                searchField.value = searchField.placeholder;
            }

            searchField.style.height = '1px';
            searchField.style.height = searchField.scrollHeight + 'px';

            if (wasEmtpy) {
                searchField.value = '';
            }
        }
	};

	componentDidUpdate(newProps: WeatherOverviewProps) {
		if (newProps.location !== this.props.location) {
			this.setState({ locationSearchResults: [] });
            if (this.searchFieldRef && this.searchFieldRef.current) {
                this.searchFieldRef.current.value = '';
            }
		}
		this.resizeSearchField();
	}

	onLocationSelected = (zipcode: number) => {
		this.props.updateWeatherByZipcode(zipcode);
	};

	onEnterZipcodeOrCity = (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
		e.currentTarget.style.height = '1px';
		e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px';
		const zipcodeOrCity = e.currentTarget && e.currentTarget.value;
		if (!zipcodeOrCity || zipcodeOrCity.length <= minimumSearchRequestLength) {
			this.setState({ locationSearchResults: [], noResults: false });
		} else {
			this.searchLocation(zipcodeOrCity);
		}
	};

	render() {
		function findNextTabStop(el: any, offset: any) {
			var universe = document.querySelectorAll('input[data-search], a[data-result]');
			var list = Array.prototype.filter.call(universe, function(item) {
				return item.tabIndex >= '0';
			});
			var index = list.indexOf(el);
			return list[Math.max(0, Math.min(list.length - 1, index + offset))];
		}

		const currentDay = this.props.day;
		const maybeCurrentDay = !!Object.keys(currentDay).length;

		return (
			<div className={classNames(styles.weatherOverview, 'rows', 'flexcenter')}>
				<div className={classNames('columns')}>
					<span className={classNames(styles.maxtemperature)}>{maybeCurrentDay ? currentDay.maxtemperature.value + '°' : ''}</span>
					<div className={classNames(styles.location)}>
						<input
							type="text"
							data-class="weather-overview"
							data-search
							ref={this.searchFieldRef}
							className={classNames(styles.searchinput)}
							onChange={this.onEnterZipcodeOrCity}
							onKeyDown={e => {
								if (e.key === 'Enter' && this.state.locationSearchResults[0]) {
									this.onLocationSelected(parseInt(this.state.locationSearchResults[0].plz, 10));
									e.preventDefault();
								}
							}}
							onFocus={e => {
								e.target.placeholder = wording.enterLocation;
								this.resizeSearchField();
							}}
							onBlur={e => {
								e.target.placeholder = this.props.location;
								this.resizeSearchField();
							}}
							onKeyUp={e => {
								if (e.key === EventKey.ARROW_DOWN) {
									findNextTabStop(e.target, 1).focus();
								} else {
									this.onEnterZipcodeOrCity(e);
								}
							}}
							placeholder={this.props.location}
						/>
						<button
							className={classNames(styles.searchicon)}
							onClick={() => this.searchFieldRef?.current?.focus()}
						>
							<SvgSearch />
						</button>
						{this.state.locationSearchResults.length > 0 ? (
							<ul className={styles.searchresults}>
								{this.state.locationSearchResults.map((result, index) => {
									let handler = () => this.onLocationSelected(parseInt(result.plz, 10));

									let keyDownHandler = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
										if (event.key === EventKey.ENTER) handler();
										if (event.key === EventKey.ARROW_UP || event.key === EventKey.ARROW_DOWN) event.preventDefault();
									};
									let keyUpHandler = function(e: React.KeyboardEvent<HTMLAnchorElement>) {
										if (e.key === EventKey.ARROW_UP) {
											findNextTabStop(e.target, -1).focus();
										}
										if (e.key === EventKey.ARROW_DOWN) {
											findNextTabStop(e.target, 1).focus();
										}
									};
									return (
										<li key={index} className={styles.searchResultItem}>
											<a data-result tabIndex={0} onClick={handler} onKeyDown={keyDownHandler} onKeyUp={keyUpHandler}>
												{result.label.split('-').map((p, i) => (
													<span key={i}>{p}</span>
												))}
											</a>
										</li>
									);
								})}
							</ul>
						) : this.state.noResults ? (
							<ul className={styles.searchresults}>
								<li className={styles.searchResultItem}>
									<a>{wording.locationNotFound}</a>
								</li>
							</ul>
						) : (
							''
						)}
					</div>
				</div>
				<div className={classNames('columns')}>
					{maybeCurrentDay ? (
						<>
							<span className={classNames(styles.mintemperature)}>{currentDay.mintemperature.value}°</span>

							<div className={classNames(styles.details)}>
								<div className={classNames('rows')}>
									<div>{currentDay.weathersituation.name}</div>
									<div>Wind: {currentDay.windspeed.value} km/h</div>
								</div>
								<div className={classNames('rows')}>
									<div>Regenrisiko: {currentDay.precipitation.precipitationProbability}%</div>
									<div>
										Niederschlag: {currentDay.precipitation.precipitationValue} {currentDay.precipitation.precipitationUnit}
									</div>
								</div>
							</div>
						</>
					) : (
						<h2>Es liegt uns aktuell leider keine Wettervorhersage für {this.props.location} vor.</h2>
					)}
				</div>
			</div>
		);
	}
}
