import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementEmbed.scss';
import update from 'immutability-helper';
import SvgPencil from './pencil.svg';
import { subtypes } from './EmbedSubtypes';
import { identifyService, Services, servicesMetadata } from '../../commons/dataPrivacy';

interface IProps {
	data: any;
	onChange?: any;
}

interface IState {
	isDialogOpen: boolean;
	service: Services | null;
}

/**
 * Component to display embedded content in the webapp like scripts, facebook-videos or typeforms and to edit it in the cms.
 */
class ContentElementEmbed extends React.Component<IProps, IState> {
	static defaultProps = {
		data: {
			element_type: 'embed',
			element_subtype: 'facebook-video',
			content: subtypes['facebook-video'].contentElement.defaultProps.data.content,
		},
	};

	state = {
		isDialogOpen: false,
		service: null,
	};

	openEditorDialog = () => {
		this.setState({ isDialogOpen: true });
	};

	closeEditorDialog = () => {
		this.setState({ isDialogOpen: false });
	};

	onSubtypeChange = (e: any) => {
		const selectedKey = e.target.value;
		this.props.onChange(
			update(this.props.data, {
				element_subtype: { $set: selectedKey },
				content: { html: { $set: subtypes[selectedKey].contentElement.defaultProps.data.content.html } },
			})
		);
	};

	componentDidUpdate() {
		const content = this.props.data.content.html;
		const service = identifyService(content);
		if (service !== this.state.service) {
			this.setState({ service });
		}
	}

	render() {
		let EditComponent = null;
		let DialogComponent = null;

		if (IS_CMS) {
			// eslint-disable-next-line @typescript-eslint/no-var-requires
			const mui = require('@material-ui/core');
			// @ts-ignore
			const metadata = this.state.service ? servicesMetadata.get(this.state.service) : null;
			const isValid = !!this.state.service || !this.props.data.content.html;
			EditComponent = () => (
				<div className={styles.editButton}>
					<mui.Fab color="secondary" onClick={this.openEditorDialog} className={classNames(styles.editIcon)}>
						<SvgPencil />
					</mui.Fab>
				</div>
			);

			DialogComponent = () => (
				<mui.Dialog maxWidth="sm" fullWidth={true} open={this.state.isDialogOpen} onClose={this.closeEditorDialog}>
					<mui.DialogTitle>Exterene Inhalte einbinden</mui.DialogTitle>
					<mui.DialogContent>
						<mui.Select onChange={this.onSubtypeChange} style={{ marginBottom: '2rem' }} value={this.props.data.element_subtype}>
							{Object.keys(subtypes).map(key => (
								<mui.MenuItem key={subtypes[key].title} value={key}>
									{subtypes[key].title}
								</mui.MenuItem>
							))}
						</mui.Select>
						{React.createElement(subtypes[this.props.data.element_subtype].editor, this.props)}
						{metadata && <p>Inhalte von {metadata.provider} werden eingebettet.</p>}
						{!this.state.service && <p>Es konnte kein gültiger Serviceanbieter gefunden werden.</p>}
					</mui.DialogContent>
					<mui.DialogActions>
						<mui.Button color="primary" onClick={this.closeEditorDialog} disabled={!isValid}>
							Fertig
						</mui.Button>
					</mui.DialogActions>
				</mui.Dialog>
			);
		}

		return (
			<div className={classNames(styles.embeddedElement, this.props.onChange && styles.editBox)}>
				{this.props.onChange && EditComponent ? <EditComponent /> : null}

				{React.createElement(subtypes[this.props.data.element_subtype].contentElement, this.props)}

				{this.props.onChange && DialogComponent ? <DialogComponent /> : null}
			</div>
		);
	}
}

let ExportComponent = ContentElementEmbed;

if (IS_CMS) {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const { themeableForCms } = require('../../commons/utils/cms');
	ExportComponent = themeableForCms(ContentElementEmbed);
}

export default ExportComponent;
