import React, { Component } from 'react';
import { Traffic } from '../../br3-components';
import { connect } from 'react-redux';
import { isLoaded as isServiceLoaded, load as loadService } from '../../redux/modules/traffic';
import { bindActionCreators } from 'redux';
import DocumentMeta from 'react-document-meta';

class TrafficViewConnector extends Component {
	static fetchData(getState, dispatch, location, params) {
		if (!isServiceLoaded(getState())) {
			return dispatch(loadService({ ...params }));
		}
	}

	render() {
		let view = this.props.location.pathname.endsWith('/verkehr/android') && 'android';
		let metaSection = 'Verkehr';
		let metaTitle = `Bayern 3 - ${metaSection}`;
		let metaDescription = 'Die aktuelle Verkehrslage in Bayern.';
		let meta = {
			title: metaTitle,
			description: metaDescription,
			meta: {
				property: {
					'og:title': metaTitle,
					'og:description': metaDescription,
					'twitter:site': '@bayern3',
					'twitter:creator': '@bayern3',
					'twitter:title': metaTitle,
					'twitter:description': metaDescription,
					'ati:section': metaSection,
					'ati:doctype': metaSection,
				},
			},
		};
		if (this.props.loading) {
			return null;
		} else {
			return (
				<div>
					<Traffic data={this.props.data} view={view} />
					<DocumentMeta {...meta} extend />
				</div>
			);
		}
	}
}

export default connect(
	state => ({
		data: state.traffic.data,
		error: state.traffic.error,
		loading: state.traffic.loading,
	}),
	dispatch => bindActionCreators({ loadService }, dispatch)
)(TrafficViewConnector);
