import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import trackEvent from '../../commons/tracking/trackers';
import SvgEmail from './icon_b3_email.svg';
import SvgFacebook from './icon_b3_facebook.svg';
import SvgWhatsapp from './icon_b3_whatsapp.svg';
import styles from './TeaserBox.scss';

export default class TeaserBox extends React.Component {
	static defaultProps = {
		legal: '',
		date: new Date(),
		text: null,
		htmlText: null,
	};

	state = {
		isComponentMounted: false,
	};

	componentDidMount = () => {
		this.setState({
			isComponentMounted: true,
		});
	};

	onChange = (key, value) => {
		this.props.onChange(value);
	};

	trackEvent(service) {
		trackEvent('TeaserBox', service, document.title);
	}

	render() {
		let shareText = '';
		if (this.state.isComponentMounted) {
			shareText = document.title;
		}

		const text = this.props.htmlText || (this.props.text && `<p>${this.props.text}</p>`) || '';

		let textElement = <div className={classNames(styles.teasertext)} dangerouslySetInnerHTML={{ __html: text }} />;
		let shareURL = '';
		if (this.state.isComponentMounted) {
			shareURL = location.href;
		}

		if (this.props.onChange) {
			if (IS_CMS) {
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;
				textElement = (
					<ReactMediumEditor
						text={text}
						onChange={this.onChange.bind(this, 'text')}
						options={{
							placeholder: { text: 'Teaser-Text' },
							buttons: ['anchor'],
							anchor: { targetCheckbox: true },
						}}
						className={classNames(styles.teasertext)}
					/>
				);
			}
		}

		const href = `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodeURIComponent(shareText)} ${encodeURIComponent(shareURL)}`;

		return (
			<div className={classNames(styles.teaserboxContent)}>
				<div className={classNames(styles.metabox)}>
					<div className={styles.metaPrimary}>
						<a
							onClick={this.trackEvent.bind(this, 'facebook')}
							href={'https://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareURL)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgFacebook className={classNames(styles.shareButton)} />
						</a>
						<a onClick={this.trackEvent.bind(this, 'mail')} href={href} target="_blank" rel="noopener noreferrer">
							<SvgEmail className={classNames(styles.shareButton)} />
						</a>
						<a
							onClick={this.trackEvent.bind(this, 'whatsapp')}
							href={'whatsapp://send?text=' + encodeURIComponent(shareText) + '%20' + encodeURIComponent(shareURL)}
							target="_blank"
							rel="noopener noreferrer"
						>
							<SvgWhatsapp className={classNames(styles.shareButton, styles.shareWhatsApp)} />
						</a>
					</div>

					<div className={styles.metaSecondary}>
						<p className={classNames(styles.legal)}>{this.props.legal}</p>
						<time className={classNames(styles.time)}>{moment(this.props.date).format('DD.MM.YYYY')}</time>
					</div>
				</div>
				{textElement}
			</div>
		);
	}
}
