import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WolkenlosIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#F59E18">
				<path d="M22.157 16.002c0 3.39-2.765 6.155-6.155 6.155s-6.156-2.765-6.156-6.155 2.765-6.156 6.156-6.156 6.155 2.766 6.155 6.156zm-5-7.86h-2.272V-.002h2.272zm-2.272 15.72h2.272v8.144h-2.272zM13.066 8.615l-1.969 1.137-4.073-7.046 1.97-1.136zm5.891 14.755l1.969-1.137 4.073 7.046-1.97 1.136zM9.771 11.059l-1.137 1.969-7.045-4.072 1.136-1.969zm12.481 9.829l1.137-1.969 7.045 4.072-1.136 1.97zM8.142 14.828V17.1H-.002v-2.272zm15.72 2.272v-2.272h8.144V17.1zM8.615 18.919l1.137 1.969-7.046 4.073-1.136-1.97zm14.755-5.891l-1.137-1.969 7.046-4.072 1.136 1.969zM11.059 22.214l1.969 1.137-4.072 7.045-1.969-1.136zm9.829-12.481l-1.969-1.136 4.072-7.046 1.97 1.136z" />
			</g>
		</SVGIcon>
	);
}
