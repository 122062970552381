import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenBedecktIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M7.813 22.814c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.283 1.283 0 0 0 1.747-.472zm6.623 0c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.284 1.284 0 0 0 1.747-.472zm6.62 0c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.284 1.284 0 0 0 1.747-.472zm-10.652 2.663c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.285 1.285 0 0 0 1.747-.472zm6.497 0c.255-.432.255-1.178.294-1.786.039-.785.059-1.394.059-1.394s-.334.432-.981.844c-.51.334-1.335.589-1.59 1.041a1.294 1.294 0 0 0 .471 1.767 1.285 1.285 0 0 0 1.747-.472z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.486 12.919c-1.117 0-2.035-.918-2.035-2.035s.918-2.036 2.035-2.036a2 2 0 0 1 .472.05l.298.074.074-.285c.397-1.465 1.725-2.482 3.239-2.482s2.855 1.018 3.239 2.482l.074.285.298-.074c.149-.037.31-.05.472-.05 1.117 0 2.035.918 2.035 2.036s-.918 2.035-2.035 2.035h-8.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.57 6.502c1.378 0 2.582.931 2.942 2.259l.161.583.583-.136c.136-.025.261-.05.397-.05.956 0 1.725.77 1.725 1.725s-.769 1.725-1.725 1.725h-8.167c-.956 0-1.725-.769-1.725-1.725s.769-1.725 1.725-1.725c.137 0 .273.012.397.05l.583.136.161-.583a3.057 3.057 0 0 1 2.943-2.259m0-.62A3.652 3.652 0 0 0 22.033 8.6a2.352 2.352 0 0 0-2.892 2.284 2.352 2.352 0 0 0 2.346 2.346h8.167A2.352 2.352 0 0 0 32 10.884 2.352 2.352 0 0 0 29.108 8.6a3.655 3.655 0 0 0-3.538-2.718z"
			/>
			<path fill="#4F4E4E" d="M23.224 9.704a.32.32 0 0 1-.117-.023l-1.217-.496a.31.31 0 1 1 .234-.574l1.216.496a.31.31 0 0 1-.116.597z" />
			<path
				fill="#FFF"
				d="M2.746 15.444a2.372 2.372 0 0 1-2.372-2.372 2.362 2.362 0 0 1 2.372-2.36c.186 0 .36.025.547.062l.348.087.099-.348c.46-1.702 2.012-2.895 3.776-2.895s3.317 1.192 3.776 2.894l.099.348.348-.087a2.65 2.65 0 0 1 .547-.062 2.37 2.37 0 0 1 2.372 2.36 2.372 2.372 0 0 1-2.372 2.372h-9.54z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.516 7.991a3.538 3.538 0 0 1 3.416 2.621l.186.696.708-.161c.149-.037.311-.05.46-.05 1.105 0 2 .894 2 1.987 0 1.106-.894 2-2 2h-9.54c-1.105 0-2-.894-2-2 0-1.093.894-1.987 2-1.987.149 0 .311.012.46.05l.708.161.186-.696a3.538 3.538 0 0 1 3.416-2.621m0-.745a4.292 4.292 0 0 0-4.136 3.167 2.744 2.744 0 0 0-3.379 2.659 2.746 2.746 0 0 0 2.745 2.745h9.54a2.746 2.746 0 0 0 2.745-2.745 2.736 2.736 0 0 0-2.745-2.733c-.224 0-.435.025-.634.074a4.291 4.291 0 0 0-4.136-3.167z"
			/>
			<path fill="#4F4E4E" d="M4.597 11.643a.307.307 0 0 1-.118-.024l-1.205-.497a.31.31 0 1 1 .237-.575l1.205.497a.31.31 0 0 1-.119.599z" />
			<path
				fill="#C9C8C8"
				d="M9.11 18.936a3.538 3.538 0 0 1-3.533-3.533A3.538 3.538 0 0 1 9.11 11.87c.274 0 .547.037.821.1l.473.112.124-.46c.672-2.563 2.986-4.33 5.611-4.33s4.939 1.767 5.611 4.305l.124.46.473-.112c.274-.062.547-.1.821-.1a3.538 3.538 0 0 1 3.533 3.533 3.538 3.538 0 0 1-3.533 3.533H9.11v.025z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.14 7.789a5.327 5.327 0 0 1 5.138 3.931l.249.933.933-.224c.236-.05.46-.087.697-.087a3.04 3.04 0 0 1 3.036 3.036 3.04 3.04 0 0 1-3.036 3.036H9.098a3.04 3.04 0 0 1-3.036-3.036 3.04 3.04 0 0 1 3.036-3.036c.236 0 .473.025.697.087l.933.224.249-.933a5.377 5.377 0 0 1 5.163-3.931m0-.995a6.306 6.306 0 0 0-6.096 4.666 4.03 4.03 0 0 0-4.964 3.919 4.03 4.03 0 0 0 4.031 4.031H23.17a4.03 4.03 0 0 0 4.031-4.031 4.03 4.03 0 0 0-4.964-3.919 6.31 6.31 0 0 0-6.097-4.666z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.797 13.189a.44.44 0 0 1-.173-.036l-1.605-.697a.435.435 0 1 1 .347-.799l1.605.697a.435.435 0 0 1-.174.835z"
			/>
		</SVGIcon>
	);
}
