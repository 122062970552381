import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WhatsappGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="16.001" r="16" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				fill="#FFF"
				d="M21.97 9.997a8.33 8.33 0 0 0-5.934-2.458c-4.622 0-8.386 3.761-8.388 8.385 0 1.477.385 2.92 1.12 4.191l-1.191 4.346 4.446-1.167a8.38 8.38 0 0 0 4.009 1.021h.003c4.623 0 8.386-3.761 8.387-8.386a8.321 8.321 0 0 0-2.452-5.932m-5.934 12.9h-.002a6.952 6.952 0 0 1-3.549-.97l-.255-.153-2.639.693.704-2.571-.165-.265a6.94 6.94 0 0 1-1.066-3.708c0-3.844 3.128-6.97 6.974-6.97a6.93 6.93 0 0 1 4.93 2.044 6.934 6.934 0 0 1 2.039 4.931 6.979 6.979 0 0 1-6.971 6.969m3.824-5.218c-.209-.102-1.239-.61-1.431-.683-.193-.07-.334-.104-.473.106-.141.211-.542.684-.663.822-.123.139-.245.156-.454.053-.209-.106-.885-.326-1.686-1.041a6.345 6.345 0 0 1-1.165-1.451c-.123-.209-.013-.322.091-.428.095-.094.21-.244.315-.366.104-.124.141-.21.21-.351.07-.14.035-.262-.018-.367-.052-.104-.472-1.137-.646-1.555-.171-.408-.343-.354-.473-.359a8.426 8.426 0 0 0-.401-.008.774.774 0 0 0-.559.262c-.193.21-.733.717-.733 1.748 0 1.031.752 2.028.857 2.168.103.141 1.476 2.255 3.579 3.164.501.216.892.343 1.195.44.502.161.959.137 1.319.083.403-.06 1.241-.506 1.415-.996.175-.489.175-.908.124-.996-.053-.089-.194-.139-.403-.245"
			/>
		</SVGIcon>
	);
}
