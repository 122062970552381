import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WebcamWhteIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#FFF" cx="15.999" cy="16.001" r="15.999" />
			<path
				fill="#3C3C3B"
				d="M23.841 14.76c0-4.323-3.519-7.84-7.84-7.84-4.323 0-7.842 3.517-7.842 7.84 0 4.132 3.217 7.52 7.277 7.811v1.38h-2.8a.564.564 0 1 0 0 1.128h6.772a.564.564 0 1 0 0-1.128h-2.844v-1.38c4.06-.292 7.277-3.679 7.277-7.811zm-7.84 6.711c-3.702 0-6.713-3.011-6.713-6.711s3.011-6.711 6.713-6.711c3.699 0 6.711 3.011 6.711 6.711s-3.012 6.711-6.711 6.711zm.021-9.818c-1.816 0-3.297 1.479-3.297 3.297s1.48 3.298 3.297 3.298c1.819 0 3.298-1.48 3.298-3.298s-1.478-3.297-3.298-3.297zm.895 2.878a.898.898 0 0 1-.895-.896c0-.493.402-.896.895-.896a.897.897 0 0 1 0 1.792z"
			/>
		</SVGIcon>
	);
}
