import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Article, ArticleAMP, ArticleIA, Error404 } from '../../br3-components';
import DocumentMeta from 'react-document-meta';
import { load as loadArticle, loadRecommendations, loadBanners, loadFacebook } from '../../redux/modules/article';
import striptags from 'striptags';
import * as config from '../../config.webapp';
import idx from 'idx';

class ArticleConnector extends Component {
	static fetchData(getState, dispatch, location, params) {
		return Promise.all([
			dispatch(loadArticle({ ...params })),
			dispatch(loadBanners({ ...params })),
			dispatch(loadRecommendations({ id: params.id, status: 'published' }, 4)),
		]);
	}

	state = {
		banner: null,
	};

	// static getDerivedStateFromProps(props, state) {}

	async componentDidMount() {
		this._updateBanner();

		if (window && this.props.article.attributes.metadata.redirect) {
			window.location.replace(this.props.article.attributes.metadata.redirect);
		}

		const facebookLink = idx(this.props, _ => _.article.attributes.metadata.facebook_link);
		if (facebookLink) {
			await this._loadFacebookReactions(facebookLink);
		}
	}

	async componentDidUpdate(prevProps) {
		const { article: newArticle } = this.props;
		const { article } = prevProps;

		// select a new banner if this is initial load or article id changes
		if ((newArticle && !article) || (newArticle && article && newArticle.id !== article.id)) {
			this._updateBanner();
		}

		const facebookLink = idx(this.props, _ => _.article.attributes.metadata.facebook_link);
		const oldFacebookLink = idx(prevProps, _ => _.article.attributes.metadata.facebook_link);
		if (facebookLink && facebookLink !== oldFacebookLink) {
			await this._loadFacebookReactions(facebookLink);
		}
	}

	_loadFacebookReactions = async facebookLink => {
		const facebookPostId = facebookLink.match(/\d+/g).reverse()[0];

		try {
			await this.props.loadFacebook(facebookPostId);
		} catch (err) {
			console.error(err);
		}
	};

	_updateBanner() {
		let newBannerIndex, newBanner;

		if (this.props.article && this.props.availableBanners && this.props.availableBanners.length > 0) {
			// filter banners to exclude those that link to the current article and randomly pick one if available
			let availableBannersNotLinkingToSameArticle = this.props.availableBanners.filter(
				banner => !banner.attributes.content.link.includes(this.props.article.id)
			);

			if (availableBannersNotLinkingToSameArticle.length <= 0) {
				this.setState({ banner: null });
			} else {
				// if there is at least one banner that we can display
				newBannerIndex = Math.floor(Math.random() * availableBannersNotLinkingToSameArticle.length);
				newBanner = availableBannersNotLinkingToSameArticle[newBannerIndex];
				this.setState({ banner: newBanner });
			}
		}
	}

	render() {
		let meta = {};
		var canonical;

		let canonicalURL = `${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.location.pathname}`;

		if (this.props.location.pathname.endsWith('/amp')) {
			canonical = canonicalURL.slice(0, -3);
		} else if (this.props.location.pathname.endsWith('/ia')) {
			canonical = canonicalURL.slice(0, -2);
		}

		if (this.props.article) {
			let atiSection = null;
			if (this.props.article.relationships.boards.data.length == 0) {
				atiSection = this.props.article.attributes.content.title;
			}
			let atiDoctype = 'Artikel';

			if (this.props.location.pathname.endsWith('/amp') || this.props.location.pathname.endsWith('/android')) {
				atiDoctype += '-AMP';
			} else if (this.props.location.pathname.endsWith('/ia')) {
				atiDoctype += '-Instant';
			}

			let titleSwitch;

			if (this.props.location.pathname.endsWith('/ia')) {
				titleSwitch = this.props.article.attributes.content.title;
			} else {
				titleSwitch = this.props.article.attributes.content.headline + ' ' + this.props.article.attributes.content.title + ' | BAYERN 3';
			}

			let ogImageUrl = '';
			if (this.props.article.attributes.content.teaser_image.content.source) {
				const imageUrlParts = this.props.article.attributes.content.teaser_image.content.source.split('?');
				if (imageUrlParts.length > 0) {
					ogImageUrl = imageUrlParts[0];
				}
			}

			meta = {
				title: titleSwitch,
				description: striptags(this.props.article.attributes.metadata.description || this.props.article.attributes.content.teaser_text),
				canonical: canonical,
				meta: {
					property: {
						'og:image': ogImageUrl,
						'og:title': this.props.article.attributes.content.title,
						'og:description': striptags(this.props.article.attributes.content.teaser_text),
						'twitter:card': 'summary_large_image',
						'twitter:site': '@bayern3',
						'twitter:creator': '@bayern3',
						'twitter:title': this.props.article.attributes.content.title,
						'twitter:description': striptags(this.props.article.attributes.content.teaser_text),
						'twitter:image': ogImageUrl,
						'ati:doctype': atiDoctype,
						'ati:tags': this.props.article.attributes.metadata.tags.join('|'),
						'ati:published': this.props.article.createdAt,
					},
				},
			};
			if (atiSection) {
				meta.meta.property['ati:section'] = atiSection;
			}
			if (this.props.article.attributes.content.headline) {
				meta.meta.property['ati:headline'] = this.props.article.attributes.content.headline;
			}
			if (this.props.article.attributes.content.title) {
				meta.meta.property['ati:title'] = this.props.article.attributes.content.title;
			}
		}

		if (this.props.error || !this.props.article || !this.props.article.id) {
			return <Error404 />;
		} else if (this.props.article.attributes.metadata.redirect) {
			if (typeof window !== 'undefined') {
				window.location.replace(this.props.article.attributes.metadata.redirect);
			}
			return null;
		} else if (this.props.location.pathname.endsWith('/amp') || this.props.location.pathname.endsWith('/android')) {
			return (
				<ArticleAMP
					article={this.props.article}
					recommendations={this.props.availableRecommendations}
					banner={this.state.banner}
					path={this.props.location.pathname}
					key="article"
				>
					<DocumentMeta {...meta} extend />
				</ArticleAMP>
			);
		} else if (this.props.location.pathname.endsWith('/ia')) {
			return (
				<ArticleIA article={this.props.article} key="article" path={this.props.location.pathname}>
					<DocumentMeta {...meta} extend />
				</ArticleIA>
			);
		} else {
			return (
				<Article
					article={this.props.article}
					recommendations={this.props.availableRecommendations}
					banner={this.state.banner}
					facebook={this.props.facebook}
					key="article"
				>
					<DocumentMeta {...meta} extend />
				</Article>
			);
		}
	}
}

export default connect(
	state => ({
		article: state.article.data,
		facebook: state.article.facebook,
		availableBanners: state.article.banners,
		availableRecommendations: state.article.recommendations,
		error: state.article.error,
		loading: state.article.loading,
		key: 'ArticleConnector',
	}),
	dispatch => bindActionCreators({ loadArticle, loadRecommendations, loadFacebook }, dispatch)
)(ArticleConnector);
