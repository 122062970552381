import SVGIcon from '../SVGIcon';
import React from 'react';

export default function LinkExternalIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#3C3C3B"
				d="M32.034 1.692a1.131 1.131 0 0 0-.325-.846 1.124 1.124 0 0 0-.843-.321c-.014-.001-.025-.008-.039-.008l-6.85.003a1.206 1.206 0 0 0-1.211 1.211 1.205 1.205 0 0 0 1.213 1.213l4.032.001-8.85 8.85.002.002-8.176 8.174a1.141 1.141 0 0 0 0 1.601c.221.223.515.335.802.335.292 0 .58-.112.8-.335l8.105-8.103.001.001 8.918-8.92.002 4.029a1.207 1.207 0 0 0 1.214 1.214 1.205 1.205 0 0 0 1.211-1.211l.003-6.851c-.001-.014-.008-.025-.009-.039zM27.008 16.94c-.706 0-1.279.573-1.278 1.259-.441 6.043-5.529 10.777-11.583 10.777-6.414 0-11.632-5.216-11.632-11.626 0-6.408 5.217-11.624 11.629-11.626l.278-.003v-.037c.56-.137.981-.645.981-1.24 0-.599-.428-1.111-.995-1.242l-.002-.036h-.291C6.308 3.183-.042 9.545-.042 17.349c0 7.396 5.784 13.603 13.188 14.134l2.02-.001c7.04-.505 12.682-6.2 13.122-13.263a1.281 1.281 0 0 0-1.28-1.279z"
			/>
		</SVGIcon>
	);
}
