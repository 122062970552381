import PropTypes from 'prop-types';
import React from 'react';
// @ts-ignore
import NestedStatus from 'react-nested-status';
//@ts-ignore
import ContentElements from '../../commons/contentElements';
//@ts-ignore
import TeaserBox from '../TeaserBox/TeaserBox';
//@ts-ignore
import Summary from '../Summary/Summary';
import idx from 'idx';
//@ts-ignore
import FacebookComments from '../FacebookComments/FacebookComments';
//@ts-ignore
import Banner from '../Banner/Banner';
//@ts-ignore
import Recommendations from '../Recommendations/Recommendations';
import Immutable from 'immutable';
import { IArticle } from '../../../types/Article';

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	facebook: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	banner: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	recommendations: any;
	article: IArticle;
}

/**
 * Component to display a regular article
 */
export default class Article extends React.Component<IProps> {
	static propTypes = {
		article: PropTypes.object, //enhance by using shape
		banner: PropTypes.object,
		facebook: PropTypes.object,
	};

	render() {
		if (!this.props.article) {
			return null;
		}

		const teaser = React.createElement(ContentElements[this.props.article.attributes.content.teaser_element.element_type], {
			headline: this.props.article.attributes.content.headline,
			title: this.props.article.attributes.content.title,
			isTeaser: true,
			data: this.props.article.attributes.content.teaser_element,
		});

		const legal =
			idx(this.props, _ => _.article.attributes.content.teaser_element.content.images[0].metadata.copyright) ||
			idx(this.props, _ => _.article.attributes.content.teaser_element.metadata.copyright);

		const immutableProps = Immutable.fromJS(this.props);
		const bannerContent = immutableProps.getIn(['banner', 'attributes', 'content']);

		const timestamp = this.props.article.updatedAt || this.props.article.createdAt || new Date();

		return (
			<NestedStatus code={200}>
				<div>
					{teaser}
					<div className="container">
						<main className={this.props.article.attributes.metadata.banner_active ? '' : 'fullWidth'}>
							<TeaserBox htmlText={this.props.article.attributes.content.teaser_text} legal={legal} date={timestamp} />
							{this.props.article.attributes.content.elements.map((e, i) => {
								const element = React.createElement(ContentElements[e.element_type], {
									key: i,
									data: e,
								});
								return element;
							})}
							<Summary text={this.props.article.attributes.content.summary} />
							{this.props.article.attributes.metadata.facebook_link && this.props.facebook && (
								<FacebookComments link={this.props.article.attributes.metadata.facebook_link} data={this.props.facebook} />
							)}
						</main>
						<aside id="sidebar">
							{this.props.article.attributes.metadata.banner_active && this.props.banner && (
								<Banner data={bannerContent && bannerContent.toJS()} />
							)}
						</aside>
						<aside id="recommendations">
							{this.props.article.attributes.metadata.recommendations_active && <Recommendations data={this.props.recommendations} />}
						</aside>
					</div>
					{this.props.children}
				</div>
			</NestedStatus>
		);
	}
}
