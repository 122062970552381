import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeschauerStarkNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.237 13.158a6.441 6.441 0 0 1-5.343 2.822A6.471 6.471 0 0 1 2.42 9.506a6.47 6.47 0 0 1 6.474-6.474c.373 0 .745.037 1.106.1a5.602 5.602 0 0 0-2.237 4.486 5.618 5.618 0 0 0 5.616 5.615c.298-.012.584-.036.858-.075zm3.654-9.541l-.039 2.336 2.274-.547-1.516 1.765 2.088 1.043-2.311.373.932 2.137-2.001-1.192-.658 2.236-.771-2.201-1.95 1.293.832-2.187-2.323-.261 2.038-1.143-1.603-1.689 2.285.434-.149-2.322 1.479 1.802z"
			/>
			<path
				fill="#0971B8"
				d="M12.807 25.402l-.183-.73-1.28.457.366-1.371-.732-.186-.365 1.373-.914-1.006-.549.549 1.006.914-1.281.365.183.733 1.28-.367-.273 1.281.639.182.366-1.28.914.914.549-.547-1.005-.916zm10.881 1.281l-.184-.64-1.279.365.366-1.279-.732-.184-.366 1.281-.914-.916-.549.457 1.006 1.006-1.279.367.182.641 1.28-.367-.366 1.371.732.182.365-1.371.915.916.548-.459-1.005-1.004zM7.1 24.89l-.36-1.228-2.308.657.583-2.332-1.228-.311-.583 2.333-1.737-1.663-.881.918 1.725 1.663-2.308.659.36 1.228 2.307-.657-.583 2.332 1.241.31.57-2.334 1.738 1.665.881-.92-1.725-1.663z"
			/>
			<path
				fill="#CAC9C8"
				d="M2.749 18.181c-1.28 0-2.377-1.1-2.377-2.381 0-1.277 1.097-2.375 2.377-2.375.183 0 .366 0 .548.09l.367.092.091-.365c.457-1.738 2.011-2.928 3.748-2.928 1.738 0 3.293 1.19 3.749 2.928l.092.365.366-.092c.182 0 .366-.09.549-.09 1.28 0 2.377 1.098 2.377 2.375 0 1.281-1.097 2.381-2.377 2.381h-9.51z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.503 10.681c1.555 0 3.017 1.098 3.384 2.651l.182.734.732-.184c.182 0 .275-.092.458-.092 1.096 0 2.01.914 2.01 2.01a2.03 2.03 0 0 1-2.01 2.014h-9.51A2.029 2.029 0 0 1 .738 15.8c0-1.096.913-2.01 2.011-2.01.183 0 .274 0 .457.092l.732.184.182-.734c.367-1.552 1.829-2.651 3.383-2.651m0-.731a4.23 4.23 0 0 0-4.115 3.201c-.182-.092-.456-.092-.639-.092-1.555 0-2.743 1.188-2.743 2.742 0 1.557 1.187 2.744 2.743 2.744h9.51c1.554 0 2.742-1.186 2.742-2.744 0-1.553-1.187-2.742-2.742-2.742-.183 0-.458 0-.64.092A4.23 4.23 0 0 0 7.503 9.95z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.578 14.376a.32.32 0 0 1-.131-.029l-1.187-.549a.31.31 0 0 1-.152-.412.309.309 0 0 1 .412-.153l1.189.551a.31.31 0 1 1-.131.592z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.488 15.614a2.044 2.044 0 0 1-2.035-2.036c0-1.116.918-2.034 2.035-2.034.161 0 .31.012.472.049l.297.075.075-.284A3.358 3.358 0 0 1 25.571 8.9a3.348 3.348 0 0 1 3.238 2.483l.075.284.298-.075c.149-.037.31-.049.471-.049 1.117 0 2.036.918 2.036 2.034a2.045 2.045 0 0 1-2.036 2.036h-8.165z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.571 9.199c1.377 0 2.58.93 2.94 2.257l.162.585.582-.137c.137-.024.261-.049.397-.049a1.72 1.72 0 0 1 1.725 1.724c0 .955-.769 1.726-1.725 1.726h-8.163a1.723 1.723 0 0 1-1.726-1.726 1.721 1.721 0 0 1 2.122-1.675l.583.137.162-.585a3.05 3.05 0 0 1 2.941-2.257m0-.62a3.652 3.652 0 0 0-3.537 2.716 2.737 2.737 0 0 0-.545-.063 2.353 2.353 0 0 0-2.346 2.346 2.352 2.352 0 0 0 2.346 2.346h8.163a2.352 2.352 0 0 0 2.346-2.346 2.353 2.353 0 0 0-2.891-2.283 3.652 3.652 0 0 0-3.536-2.716z"
			/>
			<path
				fill="#FFF"
				d="M9.15 21.562c-1.92 0-3.566-1.553-3.566-3.567 0-2.008 1.554-3.565 3.566-3.565.274 0 .549 0 .823.094l.457.09.091-.457c.64-2.561 3.018-4.299 5.578-4.299s4.937 1.738 5.578 4.299l.091.457.457-.09c.276-.094.548-.094.823-.094 1.92 0 3.566 1.557 3.566 3.565 0 2.014-1.555 3.567-3.566 3.567H9.15z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.099 10.499c2.377 0 4.48 1.645 5.12 3.93l.275.916.915-.182c.274-.092.457-.092.731-.092 1.646 0 3.018 1.373 3.018 3.016 0 1.647-1.372 3.018-3.018 3.018H9.15c-1.646 0-3.017-1.371-3.017-3.018 0-1.644 1.371-3.016 3.017-3.016.274 0 .457 0 .732.092l.915.182.274-.916c.548-2.375 2.65-3.93 5.028-3.93m0-1.006c-2.926 0-5.395 2.012-6.127 4.664-.274-.09-.639-.09-.914-.09-2.195 0-4.023 1.828-4.023 4.02 0 2.195 1.829 4.024 4.023 4.024h14.08c2.195 0 4.024-1.83 4.024-4.024 0-2.193-1.83-4.02-4.024-4.02-.274 0-.638 0-.914.09-.731-2.744-3.2-4.664-6.125-4.664z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.801 15.873a.45.45 0 0 1-.177-.039l-1.646-.732a.436.436 0 0 1-.22-.575c.097-.22.354-.318.574-.22l1.645.732a.435.435 0 0 1-.176.834z"
			/>
			<g>
				<path
					fill="#0971B8"
					d="M19.791 21.195l-.36-1.228-2.307.657.582-2.331-1.228-.312-.583 2.334-1.737-1.663-.881.917 1.725 1.663-2.309.659.36 1.228 2.309-.657-.584 2.332 1.241.31.57-2.334 1.738 1.665.881-.92-1.724-1.663z"
				/>
			</g>
		</SVGIcon>
	);
}
