export default {
	locationname: 'München',
	expandedname: '80331 München - Stadt München, Oberbayern',
	zipcode: 80331,
	days: {
		'2015-08-22': {
			date: 1442959200000,
			mintemperature: { value: '9', Unit: '°C' },
			maxtemperature: { value: '13', Unit: '°C' },
			windspeed: { value: '15', direction: 'West', directionshort: 'W', Unit: 'km/h' },
			precipitation: {
				precipitationValue: '12,6',
				precipitationUnit: 'l/m²',
				precipitationProbability: '100',
				precipitationProbabilityUnit: '%',
			},
			weathersituation: { name: 'leichter Regenschauer', style: 'regen-bedeckt', weatherSituationId: 80 },
			sections: {
				'18:00': {
					date: 1442937600000,
					formattedhour: '18:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '17', Unit: '°C' },
				},
				'20:00': {
					date: 1442944800000,
					formattedhour: '20:00',
					weathersituation: { name: 'leichter Regenschauer', style: 'regenschauer nacht', weatherSituationId: 80 },
					temperature: { value: '14', Unit: '°C' },
				},
				'23:00': {
					date: 1442955600000,
					formattedhour: '23:00',
					weathersituation: { name: 'leichter Regenschauer', style: 'regen-bedeckt nacht', weatherSituationId: 80 },
					temperature: { value: '12', Unit: '°C' },
				},
			},
		},
		'2015-08-23': {
			date: 1443045600000,
			mintemperature: { value: '8', Unit: '°C' },
			maxtemperature: { value: '15', Unit: '°C' },
			windspeed: { value: '11', direction: 'Südwest', directionshort: 'SW', Unit: 'km/h' },
			precipitation: {
				precipitationValue: '<0,1',
				precipitationUnit: 'l/m²',
				precipitationProbability: '31',
				precipitationProbabilityUnit: '%',
			},
			weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
			sections: {
				'2:00': {
					date: 1442966400000,
					formattedhour: '2:00',
					weathersituation: { name: 'leichter Regenschauer', style: 'regen-bedeckt nacht', weatherSituationId: 80 },
					temperature: { value: '11', Unit: '°C' },
				},
				'5:00': {
					date: 1442977200000,
					formattedhour: '5:00',
					weathersituation: { name: 'Regen', style: 'regen-bedeckt nacht', weatherSituationId: 63 },
					temperature: { value: '10', Unit: '°C' },
				},
				'8:00': {
					date: 1442988000000,
					formattedhour: '8:00',
					weathersituation: { name: 'Regen', style: 'regen-bedeckt', weatherSituationId: 63 },
					temperature: { value: '9', Unit: '°C' },
				},
				'11:00': {
					date: 1442998800000,
					formattedhour: '11:00',
					weathersituation: { name: 'leichter Regenschauer', style: 'regen-bedeckt', weatherSituationId: 80 },
					temperature: { value: '10', Unit: '°C' },
				},
				'14:00': {
					date: 1443009600000,
					formattedhour: '14:00',
					weathersituation: { name: 'leichter Regenschauer', style: 'regen-bedeckt', weatherSituationId: 80 },
					temperature: { value: '11', Unit: '°C' },
				},
				'17:00': {
					date: 1443020400000,
					formattedhour: '17:00',
					weathersituation: { name: 'bedeckt', style: 'bedeckt', weatherSituationId: 0 },
					temperature: { value: '12', Unit: '°C' },
				},
				'20:00': {
					date: 1443031200000,
					formattedhour: '20:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '10', Unit: '°C' },
				},
				'23:00': {
					date: 1443042000000,
					formattedhour: '23:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '10', Unit: '°C' },
				},
			},
		},
		'2015-08-24': {
			date: 1443132000000,
			mintemperature: { value: '8', Unit: '°C' },
			maxtemperature: { value: '18', Unit: '°C' },
			windspeed: { value: '7', direction: 'Nord', directionshort: 'N', Unit: 'km/h' },
			precipitation: {
				precipitationValue: '0,1',
				precipitationUnit: 'l/m²',
				precipitationProbability: '19',
				precipitationProbabilityUnit: '%',
			},
			weathersituation: { name: 'wolkig', style: 'wolkig', weatherSituationId: 0 },
			sections: {
				'2:00': {
					date: 1443052800000,
					formattedhour: '2:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '10', Unit: '°C' },
				},
				'5:00': {
					date: 1443063600000,
					formattedhour: '5:00',
					weathersituation: { name: 'bedeckt', style: 'bedeckt nacht', weatherSituationId: 0 },
					temperature: { value: '9', Unit: '°C' },
				},
				'8:00': {
					date: 1443074400000,
					formattedhour: '8:00',
					weathersituation: { name: 'bedeckt', style: 'bedeckt', weatherSituationId: 0 },
					temperature: { value: '9', Unit: '°C' },
				},
				'11:00': {
					date: 1443085200000,
					formattedhour: '11:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '12', Unit: '°C' },
				},
				'14:00': {
					date: 1443096000000,
					formattedhour: '14:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '14', Unit: '°C' },
				},
				'17:00': {
					date: 1443106800000,
					formattedhour: '17:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '14', Unit: '°C' },
				},
				'20:00': {
					date: 1443117600000,
					formattedhour: '20:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '13', Unit: '°C' },
				},
				'23:00': {
					date: 1443128400000,
					formattedhour: '23:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '11', Unit: '°C' },
				},
			},
		},
		'2015-08-25': {
			date: 1443218400000,
			mintemperature: { value: '10', Unit: '°C' },
			maxtemperature: { value: '17', Unit: '°C' },
			windspeed: { value: '11', direction: 'Nordost', directionshort: 'NO', Unit: 'km/h' },
			precipitation: {
				precipitationValue: '0,1',
				precipitationUnit: 'l/m²',
				precipitationProbability: '15',
				precipitationProbabilityUnit: '%',
			},
			weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
			sections: {
				'2:00': {
					date: 1443139200000,
					formattedhour: '2:00',
					weathersituation: { name: 'wolkig', style: 'wolkig nacht', weatherSituationId: 0 },
					temperature: { value: '10', Unit: '°C' },
				},
				'5:00': {
					date: 1443150000000,
					formattedhour: '5:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '9', Unit: '°C' },
				},
				'8:00': {
					date: 1443160800000,
					formattedhour: '8:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '10', Unit: '°C' },
				},
				'11:00': {
					date: 1443171600000,
					formattedhour: '11:00',
					weathersituation: { name: 'wolkig', style: 'wolkig', weatherSituationId: 0 },
					temperature: { value: '13', Unit: '°C' },
				},
				'14:00': {
					date: 1443182400000,
					formattedhour: '14:00',
					weathersituation: { name: 'wolkig', style: 'wolkig', weatherSituationId: 0 },
					temperature: { value: '16', Unit: '°C' },
				},
				'17:00': {
					date: 1443193200000,
					formattedhour: '17:00',
					weathersituation: { name: 'wolkig', style: 'wolkig', weatherSituationId: 0 },
					temperature: { value: '17', Unit: '°C' },
				},
				'20:00': {
					date: 1443204000000,
					formattedhour: '20:00',
					weathersituation: { name: 'leicht bewölkt', style: 'leicht-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '15', Unit: '°C' },
				},
				'23:00': {
					date: 1443214800000,
					formattedhour: '23:00',
					weathersituation: { name: 'leicht bewölkt', style: 'leicht-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '13', Unit: '°C' },
				},
			},
		},
		'2015-08-26': {
			date: 1443304800000,
			mintemperature: { value: '8', Unit: '°C' },
			maxtemperature: { value: '15', Unit: '°C' },
			windspeed: { value: '15', direction: 'Nordost', directionshort: 'NO', Unit: 'km/h' },
			precipitation: {
				precipitationValue: '0,0',
				precipitationUnit: 'l/m²',
				precipitationProbability: '8',
				precipitationProbabilityUnit: '%',
			},
			weathersituation: { name: 'leicht bewölkt', style: 'leicht-bewoelkt', weatherSituationId: 0 },
			sections: {
				'2:00': {
					date: 1443225600000,
					formattedhour: '2:00',
					weathersituation: { name: 'wolkig', style: 'wolkig nacht', weatherSituationId: 0 },
					temperature: { value: '12', Unit: '°C' },
				},
				'5:00': {
					date: 1443236400000,
					formattedhour: '5:00',
					weathersituation: { name: 'wolkig', style: 'wolkig nacht', weatherSituationId: 0 },
					temperature: { value: '11', Unit: '°C' },
				},
				'8:00': {
					date: 1443247200000,
					formattedhour: '8:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '11', Unit: '°C' },
				},
				'11:00': {
					date: 1443258000000,
					formattedhour: '11:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '13', Unit: '°C' },
				},
				'14:00': {
					date: 1443268800000,
					formattedhour: '14:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '16', Unit: '°C' },
				},
				'17:00': {
					date: 1443279600000,
					formattedhour: '17:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt', weatherSituationId: 0 },
					temperature: { value: '16', Unit: '°C' },
				},
				'20:00': {
					date: 1443290400000,
					formattedhour: '20:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '14', Unit: '°C' },
				},
				'23:00': {
					date: 1443301200000,
					formattedhour: '23:00',
					weathersituation: { name: 'stark bewölkt', style: 'stark-bewoelkt nacht', weatherSituationId: 0 },
					temperature: { value: '12', Unit: '°C' },
				},
			},
		},
	},
};
