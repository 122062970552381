import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeBedecktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M9.69 23.694l-.182-.732-1.28.367.274-1.281-.642-.183-.364 1.281-1.007-.914-.458.548.916.914-1.28.367.181.732 1.373-.366-.366 1.28.731.184.274-1.281 1.008.914.457-.548-.915-.915zM5.3 19.852l-.183-.64-1.281.366.367-1.372-.733-.183-.365 1.372-.916-1.006-.548.549 1.006.914-1.281.366.183.732 1.28-.365-.274 1.28.641.183.366-1.28.914.914.549-.548-1.006-.916zm13.721 4.209l-.182-.732-1.372.365.367-1.28-.732-.182-.275 1.371-1.007-1.006-.457.549.915.915-1.281.366.183.732 1.281-.366-.274 1.281.732.182.273-1.28 1.006.915.459-.549-.916-.915zm-4.573-2.744l-.274-.642-1.281.367.366-1.281-.731-.183-.275 1.281-1.006-.915-.549.457 1.005 1.006-1.281.366.184.641 1.281-.366-.275 1.372.641.092.365-1.28.916.914.548-.457-1.006-1.007zm9.24 0l-.183-.642-1.281.367.366-1.281-.732-.183-.364 1.281-.917-.915-.548.457 1.007 1.006-1.281.366.182.641 1.281-.366-.367 1.372.733.092.365-1.28.916.914.548-.457-1.005-1.007z"
			/>
			<path
				fill="#FFF"
				d="M2.745 15.304a2.372 2.372 0 0 1-2.372-2.372 2.362 2.362 0 0 1 2.372-2.36c.186 0 .36.025.546.062l.348.087.099-.348c.46-1.702 2.012-2.894 3.776-2.894s3.316 1.192 3.776 2.894l.099.348.348-.087a2.37 2.37 0 0 1 2.918 2.298 2.372 2.372 0 0 1-2.372 2.372H2.745z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.514 7.852a3.538 3.538 0 0 1 3.416 2.621l.186.696.708-.161c.149-.037.311-.05.46-.05 1.105 0 2 .894 2 1.987 0 1.105-.894 2-2 2H2.745c-1.105 0-2-.894-2-2 0-1.093.894-1.987 2-1.987.149 0 .311.012.46.05l.708.161.186-.696a3.536 3.536 0 0 1 3.415-2.621m0-.746a4.292 4.292 0 0 0-4.136 3.167A2.744 2.744 0 0 0 0 12.932a2.746 2.746 0 0 0 2.745 2.745h9.539a2.746 2.746 0 0 0 2.745-2.745 2.735 2.735 0 0 0-2.745-2.732c-.224 0-.435.025-.633.074a4.295 4.295 0 0 0-4.137-3.168z"
			/>
			<path fill="#4F4E4E" d="M4.595 11.503a.307.307 0 0 1-.118-.024l-1.205-.497a.31.31 0 1 1 .236-.574l1.205.497a.31.31 0 0 1-.118.598z" />
			<path
				fill="#C9C8C8"
				d="M21.489 12.78c-1.117 0-2.035-.918-2.035-2.035s.918-2.035 2.035-2.035a2 2 0 0 1 .472.05l.298.074.074-.285c.397-1.464 1.725-2.482 3.239-2.482s2.854 1.018 3.239 2.482l.074.285.298-.074c.149-.037.31-.05.472-.05 1.117 0 2.035.918 2.035 2.035s-.918 2.035-2.035 2.035h-8.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.572 6.364c1.378 0 2.581.931 2.941 2.259l.161.583.583-.136c.136-.025.261-.05.397-.05.956 0 1.725.769 1.725 1.725s-.769 1.725-1.725 1.725h-8.166c-.956 0-1.725-.769-1.725-1.725s.769-1.725 1.725-1.725c.137 0 .273.012.397.05l.583.136.161-.583a3.057 3.057 0 0 1 2.943-2.259m0-.62a3.653 3.653 0 0 0-3.537 2.718 2.352 2.352 0 0 0-2.892 2.284 2.351 2.351 0 0 0 2.346 2.345h8.166a2.352 2.352 0 0 0 2.346-2.345 2.352 2.352 0 0 0-2.892-2.284 3.653 3.653 0 0 0-3.537-2.718z"
			/>
			<path fill="#4F4E4E" d="M23.226 9.566a.32.32 0 0 1-.117-.023l-1.216-.496a.31.31 0 1 1 .234-.574l1.216.496a.31.31 0 0 1-.117.597z" />
			<g>
				<path
					fill="#C9C8C8"
					d="M9.111 18.796a3.538 3.538 0 0 1-3.533-3.533 3.538 3.538 0 0 1 3.533-3.533c.274 0 .547.037.821.099l.473.112.124-.46a5.807 5.807 0 1 1 11.222-.025l.124.46.473-.112c.274-.062.547-.099.821-.099a3.538 3.538 0 0 1 3.533 3.533 3.538 3.538 0 0 1-3.533 3.533H9.111v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.14 7.65a5.327 5.327 0 0 1 5.138 3.931l.249.933.933-.224c.236-.05.46-.087.697-.087a3.04 3.04 0 0 1 3.035 3.035 3.04 3.04 0 0 1-3.035 3.035H9.099a3.04 3.04 0 0 1-3.035-3.035 3.04 3.04 0 0 1 3.035-3.035c.236 0 .473.025.697.087l.933.224.249-.933A5.376 5.376 0 0 1 16.14 7.65m0-.995a6.308 6.308 0 0 0-6.096 4.665 4.03 4.03 0 0 0-4.964 3.919 4.03 4.03 0 0 0 4.031 4.031h14.057a4.03 4.03 0 0 0 4.031-4.031 4.03 4.03 0 0 0-4.964-3.919 6.305 6.305 0 0 0-6.095-4.665z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.798 13.049a.44.44 0 0 1-.173-.036l-1.605-.697a.435.435 0 1 1 .347-.799l1.605.697a.435.435 0 0 1-.174.835z"
				/>
			</g>
		</SVGIcon>
	);
}
