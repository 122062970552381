export function sleep(ms = 0) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export function stylesForQueryString(query: string, containerWidth: number, aspectRatioChangeFactor = 1) {
	const qs = query.split('#');
	if (qs.length > 1) {
		const urlParams = new URLSearchParams(qs[1]);
		const rect = urlParams.has('rect') ? urlParams.get('rect')!.split(',') : undefined;
		const factor = containerWidth / (rect ? Number(rect[2]) : 1);

		return {
			backgroundSize: `auto ${Math.floor(Number(urlParams.get('_naturalHeight')) * factor * aspectRatioChangeFactor)}px`,
			backgroundPosition: rect
				? `${Math.floor(
						Number(rect[0]) * -factor * aspectRatioChangeFactor - 0.5 * (containerWidth * aspectRatioChangeFactor - containerWidth)
				  )}px ${Math.floor(Number(rect[1]) * -factor * aspectRatioChangeFactor)}px`
				: '',
			backgroundImage: `url(${qs[0]})`,
		};
	} else {
		return {
			position: 'absolute',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			backgroundSize: 'cover',
			backgroundPosition: 'left center',
			backgroundImage: `url(${qs})`,
		};
	}
}

/**
 * Parses the url of a youtube video and returns the videoId and the startTime.
 * @param {string} url Video-URL
 */
export function parseYoutubeUrl(url: string) {
	let videoId = null;
	let startTime = null;

	try {
		const parsedUrl = new URL(url);
		if (parsedUrl.hostname.indexOf('youtube') !== -1 || parsedUrl.hostname.indexOf('youtu.be') !== -1) {
			videoId = parsedUrl.searchParams.get('v');
			startTime = parsedUrl.searchParams.get('t') || parsedUrl.searchParams.get('start');
			if (!videoId) {
				const path = parsedUrl.pathname.split('/');
				videoId = path[path.length - 1];
			}
		}
	} catch (err) {
		console.error(err);
	}

	return { videoId, startTime };
}
