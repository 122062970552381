import * as config from '../../config.webapp';
import { createEmptyArticle } from './_initial_state';
import loglevel from 'loglevel';

// eslint-disable-next-line
const logger = loglevel.getLogger('article.js');

const apiBaseUrl = config.ORIGIN_COFFEEMACHINE;
const apiArticle = config.API_ENDPOINT.article;
const apiTeaserBoard = config.API_ENDPOINT.teaserBoard;
const apiBanner = config.API_ENDPOINT.banner;

const LOAD_ARTICLES = 'br3/article/LOAD_ARTICLES';
const LOAD_ARTICLES_SUCCESS = 'br3/article/LOAD_ARTICLES_SUCCESS';
const LOAD_ARTICLES_FAIL = 'br3/article/LOAD_ARTICLES_FAIL';

const SAVE_ARTICLES = 'br3/article/SAVE_ARTICLES';
const SAVE_ARTICLES_SUCCESS = 'br3/article/SAVE_ARTICLES_SUCCESS';
const SAVE_ARTICLES_FAIL = 'br3/article/SAVE_ARTICLES_FAIL';

const UPDATE_ARTICLES = 'br3/article/UPDATE_ARTICLES';
const UPDATE_ARTICLES_SUCCESS = 'br3/article/UPDATE_ARTICLES_SUCCESS';
const UPDATE_ARTICLES_FAIL = 'br3/article/UPDATE_ARTICLES_FAIL';

const LOAD_RECOMMENDATIONS = 'br3/article/LOAD_RECOMMENDATIONS';
const LOAD_RECOMMENDATIONS_SUCCESS = 'br3/article/LOAD_RECOMMENDATIONS_SUCCESS';
const LOAD_RECOMMENDATIONS_FAIL = 'br3/article/LOAD_RECOMMENDATIONS_FAIL';

const LOAD_BOARDS = 'br3/article/LOAD_BOARDS';
const LOAD_BOARDS_SUCCESS = 'br3/article/LOAD_BOARDS_SUCCESS';
const LOAD_BOARDS_FAIL = 'br3/article/LOAD_BOARDS_FAIL';

const LOAD_BANNERS = 'br3/banner/LOAD';
const LOAD_BANNERS_SUCCESS = 'br3/banner/LOAD_SUCCESS';
const LOAD_BANNERS_FAIL = 'br3/banner/LOAD_FAIL';

const LOAD_FACEBOOK = 'br3/facebook/LOAD';
const LOAD_FACEBOOK_SUCCESS = 'br3/facebook/LOAD_SUCCESS';
const LOAD_FACEBOOK_FAIL = 'br3/facebook/LOAD_FAIL';

export default function article(state = { ...createEmptyArticle(), facebook: {} }, action = {}) {
	switch (action.type) {
		case LOAD_FACEBOOK_SUCCESS:
			return {
				...state,
				facebook: action.result,
			};

		// ---------------------------------------------------------------------------------------------------
		// Article load
		case LOAD_ARTICLES:
			return {
				...state,
				loading: true,
			};
		case LOAD_ARTICLES_SUCCESS:
			return {
				...state,
				loading: false,
				loaded: true,
				data: action.result.data,
				error: null,
			};
		case LOAD_ARTICLES_FAIL:
			return {
				...state,
				loading: false,
				loaded: false,
				error: action.error,
			};

		// ---------------------------------------------------------------------------------------------------
		// Recommendations load
		case LOAD_RECOMMENDATIONS_SUCCESS:
			return {
				...state,
				recommendations: action.result.data,
			};

		// ---------------------------------------------------------------------------------------------------
		// Boards load
		case LOAD_BOARDS_SUCCESS:
			return {
				...state,
				boards: action.result.data,
			};

		// ---------------------------------------------------------------------------------------------------
		// Banners load
		case LOAD_BANNERS_SUCCESS:
			return {
				...state,
				banners: action.result.data,
			};

		// ---------------------------------------------------------------------------------------------------
		// Article save

		// ---------------------------------------------------------------------------------------------------
		// Article update (patch)

		case SAVE_ARTICLES:
		case UPDATE_ARTICLES:
			return {
				...state,
				data: action.optimisticResult,
				saving: true,
			};
		case SAVE_ARTICLES_SUCCESS:
		case UPDATE_ARTICLES_SUCCESS:
			return {
				...state,
				saving: false,
				saved: true,
				data: action.result.data,
			};
		case SAVE_ARTICLES_FAIL:
		case UPDATE_ARTICLES_FAIL:
			return {
				...state,
				saving: false,
				saved: false,
				error: action.error,
			};

		default:
			return state;
	}
}

export function isLoaded(globalState) {
	return globalState.article && globalState.article.loaded;
}

export function load(params) {
	const url = `${apiBaseUrl}${apiArticle}`;

	if (params && typeof params.id !== 'undefined' && params.id !== 'undefined') {
		// load requested article
		const status = IS_CMS ? '?status=all' : '';
		return {
			types: [LOAD_ARTICLES, LOAD_ARTICLES_SUCCESS, LOAD_ARTICLES_FAIL],
			promise: client => client.get(`${url}/${params.id}${status}`, { headers: { Accept: 'application/json' } }),
		};
	} else {
		if (!IS_CMS) {
			// move back to dashboard  in app
			return {
				types: [LOAD_ARTICLES, LOAD_ARTICLES_SUCCESS, LOAD_ARTICLES_FAIL],
				promise: () => Promise.reject({ error: 'NOT FOUND' }), //client.get(`${url}/${params.id}${status}`, {headers: {Accept: 'application/json'}})
			};
		} else {
			// resolve with a blank article for creating a new one
			return {
				types: [LOAD_ARTICLES, LOAD_ARTICLES_SUCCESS, LOAD_ARTICLES_FAIL],
				promise: () => Promise.resolve(createEmptyArticle()),
			};
		}
	}
}

export function loadRecommendations(params, limit = 4) {
	const url = `${apiBaseUrl}${apiArticle}`;

	const status = params.status ? '&status=' + params.status : '';

	const finalUrl = `${url}/${params.id}/relationships/recommended?limit[recommended]=${limit}&offset[recommended]=0${status}`;

	return {
		types: [LOAD_RECOMMENDATIONS, LOAD_RECOMMENDATIONS_SUCCESS, LOAD_RECOMMENDATIONS_FAIL],
		promise: client =>
			client.get(finalUrl, {
				headers: { Accept: 'application/json' },
			}),
	};
}

export function loadFacebook(id) {
	return {
		types: [LOAD_FACEBOOK, LOAD_FACEBOOK_SUCCESS, LOAD_FACEBOOK_FAIL],
		promise: client => client.get(`${config.SERVICE.facebook}/${id}`, { headers: { Accept: 'application/json' } }),
	};
}

export function loadBoardList() {
	const url = `${apiBaseUrl}${apiTeaserBoard}`;

	return {
		types: [LOAD_BOARDS, LOAD_BOARDS_SUCCESS, LOAD_BOARDS_FAIL],
		promise: client => client.get(`${url}?fields[board]=id,attributes&limit=0`, { headers: { Accept: 'application/json' } }),
	};
}

export function save(article) {
	const url = `${apiBaseUrl}${apiArticle}`;

	return {
		types: [SAVE_ARTICLES, SAVE_ARTICLES_SUCCESS, SAVE_ARTICLES_FAIL],
		promise: client => client.post(`${url}`, { data: { data: article } }),
		optimisticResult: article,
	};
}

export function update(article, id) {
	const url = `${apiBaseUrl}${apiArticle}/${id}`;

	return {
		types: [UPDATE_ARTICLES, UPDATE_ARTICLES_SUCCESS, UPDATE_ARTICLES_FAIL],
		promise: client => client.put(`${url}`, { data: { data: article } }),
		optimisticResult: article,
	};
}

export function loadBanners() {
	const url = `${apiBaseUrl}${apiBanner}`;

	return {
		types: [LOAD_BANNERS, LOAD_BANNERS_SUCCESS, LOAD_BANNERS_FAIL],
		promise: client => client.get(`${url}`, { headers: { Accept: 'application/json' } }),
	};
}
