import React from 'react';
import FacebookSocialPlugin from '../../commons/decorators/FacebookSocialPlugin';
import * as config from '../../../config.webapp';
import { Services, isServiceActive, registerListener, removeListener } from '../../commons/dataPrivacy';
import DataPrivacyBanner from '../DataPrivacyBanner/DataPrivacyBanner';

declare const FB: any;

interface IProps {
	// @ts-ignore
	data: any;
}

interface IState {
	isComponentMounted: boolean;
	isServiceActive: boolean;
}

/**
 * Component for displaying facebook videos.
 */
// @ts-ignore
@FacebookSocialPlugin({ appId: config.FACEBOOK_APP_ID })
class ContentElementEmbedFacebookVideo extends React.Component<IProps, IState> {
	state = {
		isComponentMounted: false,
		isServiceActive: false,
	};

	static defaultProps = {
		data: {
			content: {
				html: '<div class="fb-video" data-href="" data-allowfullscreen="true"></div>',
			},
		},
	};

	fbVideoRef = React.createRef<HTMLDivElement>();

	handleServiceActiveChange = (value: boolean) => {
		this.setState({ isServiceActive: value });
	};

	/**
	 * Reinitializes the fb sdk to handle new facebook elements on the page.
	 */
	reinitializeFacebookSDK() {
		// eslint-disable-next-line no-undef
		if (typeof FB === 'object' && FB.init) {
			// eslint-disable-next-line no-undef
			FB.init({
				appId: config.FACEBOOK_APP_ID,
				status: true,
				xfbml: true,
				version: 'v2.4',
			});
		}
	}

	componentDidMount = () => {
		this.setState({ isComponentMounted: true, isServiceActive: isServiceActive(Services.FACEBOOK) });
		registerListener(Services.FACEBOOK, this.handleServiceActiveChange);
	};

	componentDidUpdate = (prevProps: IProps) => {
		// eslint-disable-next-line react/no-find-dom-node,react/no-string-refs
		if (
			prevProps.data.content.html !== this.props.data.content.html ||
			!this.fbVideoRef.current ||
			!this.fbVideoRef.current.querySelector('iframe')
		) {
			this.reinitializeFacebookSDK();
		}
	};

	componentWillUnmount = () => {
		removeListener(Services.FACEBOOK, this.handleServiceActiveChange);
	};

	render() {
		// eslint-disable-next-line react/no-string-refs
		return (
			<>
				{!this.state.isServiceActive && <DataPrivacyBanner service={Services.FACEBOOK} />}
				<div ref={this.fbVideoRef} dangerouslySetInnerHTML={{ __html: this.props.data.content.html }} />
			</>
		);
	}
}

export default ContentElementEmbedFacebookVideo;
