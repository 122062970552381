import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenGefrierendNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M12.409 7.144c.301-.509.301-1.388.347-2.105.046-.925.069-1.642.069-1.642s-.393.509-1.157.995c-.601.393-1.573.694-1.874 1.226a1.525 1.525 0 0 0 .557 2.081 1.51 1.51 0 0 0 2.058-.555zm7.8 0c.301-.509.301-1.388.347-2.105.046-.925.069-1.642.069-1.642s-.393.509-1.156.995c-.601.393-1.573.694-1.874 1.226A1.525 1.525 0 0 0 18.15 7.7a1.513 1.513 0 0 0 2.059-.556zm7.801 0c.301-.509.301-1.388.347-2.105.046-.925.069-1.642.069-1.642s-.393.509-1.156.995c-.601.393-1.573.694-1.874 1.226a1.525 1.525 0 0 0 .555 2.082 1.513 1.513 0 0 0 2.059-.556zm-12.549 3.578c.301-.509.301-1.388.347-2.105.046-.925.069-1.642.069-1.642s-.393.509-1.157.995c-.601.393-1.573.694-1.874 1.226a1.525 1.525 0 0 0 .555 2.082 1.514 1.514 0 0 0 2.06-.556zm7.653 0c.301-.509.301-1.388.347-2.105.046-.925.069-1.642.069-1.642s-.393.509-1.156.995c-.601.393-1.573.694-1.874 1.226a1.525 1.525 0 0 0 .555 2.082 1.513 1.513 0 0 0 2.059-.556z"
			/>
			<g fill="#0971B8">
				<path d="M5.574 13.867H4.395V8.842l.589-.414.59.414v4.477z" />
				<path d="M5.439 11.458l-.827-.827L6.153 9.09l.703.124.124.703z" />
				<path d="M4.529 11.458l.828-.827L3.816 9.09l-.703.124-.124.703zm.745 2.916l-.29-.507-.289-.507.475-.279 3.878-2.233.651.3-.072.713-3.877 2.244z" />
				<path d="M7.301 13.061l.299-1.138 2.099.569.249.672-.548.455z" />
				<path d="M6.846 12.264l1.127.311.568-2.099-.465-.559-.672.248zM4.695 14.374l.289-.507.29-.507.476.269 3.877 2.244.072.714-.651.299-3.878-2.233z" />
				<path d="M6.846 15.47l1.127-.31.568 2.099-.465.558-.672-.248z" />
				<path d="M7.301 14.674l.299 1.137 2.099-.569.249-.672-.548-.455zM4.395 13.867h1.179v5.025l-.59.414-.589-.414v-4.477z" />
				<path d="M4.529 16.276l.828.828-1.541 1.54-.703-.124-.124-.703z" />
				<path d="M5.439 16.276l-.827.828 1.541 1.54.703-.124.124-.703z" />
				<g>
					<path d="M4.695 13.36l.289.507.29.507-.476.279-3.877 2.233-.652-.299.073-.714 3.877-2.244z" />
					<path d="M2.668 14.674l-.3 1.137-2.099-.569-.248-.672.548-.455z" />
					<path d="M3.123 15.47l-1.127-.31-.569 2.099.466.558.672-.248z" />
				</g>
				<g>
					<path d="M5.274 13.36l-.29.507-.289.507-.476-.269-3.877-2.244-.073-.713.652-.3 3.877 2.233z" />
					<path d="M3.123 12.264l-1.127.311-.569-2.099.466-.559.672.248z" />
					<path d="M2.668 13.061l-.3-1.138-2.099.569-.248.672.548.455z" />
				</g>
			</g>
			<g fill="#0971B8">
				<path d="M31.515 14.318c.326.545.545 1.199.436 1.744 0 .654-.328 1.199-.873 1.744-.653.654-1.743 1.199-3.489 1.635-1.199.33-2.071.654-2.726.982-.655.328-1.091.654-1.418.983-.218.326-.327.764-.218 1.199.108.547.327 1.09.545 1.854.872 2.617 1.091 2.945 1.636 4.145H19.41c0-.328-.327-2.727-.327-2.943-.109-.873-.109-2.072-.109-2.838 0-.652.218-1.307.763-1.852.436-.547 1.092-1.092 2.181-1.529.982-.436 2.29-.869 3.928-1.307.981-.219 1.853-.545 2.399-.873.545-.324.872-.762.981-1.199a2.653 2.653 0 0 0 0-1.307 5.364 5.364 0 0 0-.437-1.203h1.963c.435.111.652.439.763.765z" />
				<path d="M23.335 13.336h1.964c.218.328.436.873.546 1.092 0 .328 0 .545-.219.873-.219.326-.546.545-1.092.871-.653.328-1.525.545-2.726.766-1.525.324-2.726.652-3.599.979-.982.326-1.744.764-2.289 1.201-.654.434-.981 1.09-1.418 1.744a12.619 12.619 0 0 0-1.2 2.943c-.872 3.055-.872 3.926-1.091 4.799H5.779c.437-.654.762-1.199 1.2-1.744.326-.545.653-1.092.981-1.527.326-.438.545-.873.764-1.307.763-1.311 1.417-2.293 2.072-3.273a10.246 10.246 0 0 1 2.181-2.18c.871-.547 1.852-1.092 3.163-1.527 1.199-.438 2.726-.766 4.578-1.199.654-.111 1.199-.219 1.636-.436.437-.109.655-.33.764-.438.109-.217.218-.328.218-.545l-.001-1.092z" />
			</g>
		</SVGIcon>
	);
}
