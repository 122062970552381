import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PreviousTransparentIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle opacity=".8" fill="#FFF" cx="16" cy="16" r="16" />
			<path
				fill="#3C3C3B"
				d="M18.858 9.729c.142 0 .283.054.389.161a.544.544 0 0 1 0 .775L13.914 16l5.333 5.334a.543.543 0 0 1 0 .774.544.544 0 0 1-.773 0l-5.724-5.722a.548.548 0 0 1 0-.775l5.724-5.722a.542.542 0 0 1 .384-.16z"
			/>
		</SVGIcon>
	);
}
