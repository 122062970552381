import SVGIcon from '../SVGIcon';
import React from 'react';

export default function WolkigNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M12.808 16.647a6.998 6.998 0 0 1-5.801 3.062c-3.885 0-7.028-3.143-7.028-7.028s3.143-7.028 7.028-7.028c.405 0 .809.04 1.201.108a6.081 6.081 0 0 0-2.428 4.87 6.1 6.1 0 0 0 6.098 6.098c.322-.015.633-.042.93-.082zm3.966-10.361l-.041 2.537 2.469-.594-1.646 1.916 2.266 1.133-2.509.404 1.012 2.321-2.172-1.295-.715 2.428-.836-2.388-2.118 1.403.904-2.374-2.523-.283 2.212-1.242-1.74-1.834 2.482.472-.162-2.523 1.606 1.956z"
			/>
			<path
				fill="#FFF"
				d="M20.615 19.306a2.217 2.217 0 0 1-2.207-2.207c0-1.211.996-2.207 2.207-2.207.175 0 .337.014.511.054l.323.081.081-.31c.431-1.588 1.871-2.692 3.513-2.692s3.096 1.104 3.513 2.692l.081.31.323-.081c.161-.04.337-.054.511-.054 1.211 0 2.207.996 2.207 2.207a2.217 2.217 0 0 1-2.207 2.207h-8.856z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.043 12.347c1.494 0 2.8 1.01 3.19 2.45l.175.633.633-.148c.148-.027.283-.054.431-.054 1.036 0 1.871.835 1.871 1.871a1.867 1.867 0 0 1-1.871 1.871h-8.857a1.867 1.867 0 0 1-1.871-1.871c0-1.036.834-1.871 1.871-1.871.148 0 .296.014.431.054l.633.148.175-.633a3.31 3.31 0 0 1 3.189-2.45m0-.673a3.961 3.961 0 0 0-3.836 2.948 2.55 2.55 0 0 0-3.136 2.477 2.55 2.55 0 0 0 2.544 2.544h8.857a2.55 2.55 0 0 0 2.544-2.544 2.55 2.55 0 0 0-3.136-2.477 3.964 3.964 0 0 0-3.837-2.948z"
			/>
			<path
				fill="#4F4E4E"
				d="M22.499 15.82a.338.338 0 0 1-.127-.025l-1.319-.538a.336.336 0 1 1 .255-.623l1.319.538a.336.336 0 0 1-.128.648z"
			/>
			<path
				fill="#FFF"
				d="M7.189 25.834A3.84 3.84 0 0 1 3.355 22a3.84 3.84 0 0 1 3.834-3.834c.297 0 .594.04.891.108l.513.122.135-.5c.729-2.781 3.24-4.698 6.089-4.698s5.36 1.917 6.089 4.671l.135.5.513-.122a4.05 4.05 0 0 1 .891-.108 3.84 3.84 0 0 1 3.834 3.834 3.84 3.84 0 0 1-3.834 3.834H7.189v.027z"
			/>
			<path
				fill="#4F4E4E"
				d="M14.816 13.739a5.78 5.78 0 0 1 5.575 4.266l.27 1.013 1.012-.243c.257-.054.5-.095.756-.095a3.298 3.298 0 0 1 3.294 3.294 3.299 3.299 0 0 1-3.294 3.294H7.175a3.299 3.299 0 0 1-3.294-3.294 3.298 3.298 0 0 1 3.294-3.294c.256 0 .513.027.756.095l1.012.243.27-1.013c.703-2.511 2.998-4.266 5.603-4.266m0-1.08a6.843 6.843 0 0 0-6.615 5.062 4.155 4.155 0 0 0-1.012-.122 4.374 4.374 0 1 0 0 8.748h15.255a4.374 4.374 0 1 0-1.013-8.626 6.843 6.843 0 0 0-6.615-5.062z"
			/>
			<path
				fill="#4F4E4E"
				d="M10.105 19.598a.48.48 0 0 1-.188-.039l-1.741-.756a.473.473 0 0 1 .377-.867l1.742.756a.473.473 0 0 1-.19.906z"
			/>
		</SVGIcon>
	);
}
