import React from 'react';
// @ts-ignore
import NestedStatus from 'react-nested-status';
import { withRouter } from 'react-router';
import { History } from 'history';
import styles from './Error404.scss';

const WAIT_FOR_REDIRECT = 5000;

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	match: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	location: any;
	history: History;
}

/**
 * Component that gets rendered in case of a 404 error and redirects
 * to the homepage.
 */
class Error404 extends React.Component<IProps> {
	timeout = 0;

	componentDidMount() {
		this.timeout = window.setTimeout(() => {
			this.props.history.push('/');
		}, WAIT_FOR_REDIRECT);
	}

	componentWillUnmount() {
		window.clearTimeout(this.timeout);
	}

	render() {
		return (
			<NestedStatus code={404}>
				<div className={styles.error}>
					<p>Du wirst in {WAIT_FOR_REDIRECT / 1000} Sekunden automatisch weitergeleitet...</p>
				</div>
			</NestedStatus>
		);
	}
}

export default withRouter(Error404);
