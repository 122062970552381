import React from 'react';
import { subtypesIA } from './EmbedSubtypes';

interface IProps {
	// @ts-ignore
	data: any;
}

/**
 * Embeddings for instant articles.
 */
export default class ContentElementEmbed extends React.Component<IProps> {
	static defaultProps = {
		data: {
			element_type: 'embed',
			element_subtype: 'facebook-video',
			content: subtypesIA['facebook-video'].contentElement.defaultProps.data.content,
		},
	};

	render() {
		return React.createElement(subtypesIA[this.props.data.element_subtype].contentElement, this.props);
	}
}
