import PropTypes from 'prop-types';
import React from 'react';
import styles from './ContentElementEmbed.scss';
import { identifyService, isServiceActive, registerListener, removeListener, Services } from '../../commons/dataPrivacy';
import DataPrivacyBanner from '../DataPrivacyBanner/DataPrivacyBanner';

interface IProps {
	data: any;
	onChange?: any;
}

interface IState {
	isServiceActive: boolean;
}

/**
 * Displays an embedded script.
 */
export default class ContentElementEmbedScript extends React.Component<IProps, IState> {
	static propTypes = {
		onChange: PropTypes.func,
		data: PropTypes.shape({
			content: PropTypes.shape({
				html: PropTypes.string,
			}),
		}),
	};

	static defaultProps = {
		data: {
			content: {
				html: '',
			},
		},
	};

	state = {
		isServiceActive: false,
	};

	service: Services | null = null;

	handleServiceActiveChange = (value: boolean) => {
		this.setState({ isServiceActive: value });
	};

	handleOnToggle = () => {
		if (!this.service) {
			this.handleServiceActiveChange(!this.state.isServiceActive);
		}
	};

	componentDidMount() {
		this.service = identifyService(this.props.data.content.html);
		if (this.service) {
			this.handleServiceActiveChange(isServiceActive(this.service));
			registerListener(this.service, this.handleServiceActiveChange);
		}
	}

	componentWillUnmount() {
		if (this.service) {
			removeListener(this.service, this.handleServiceActiveChange);
		}
	}

	render() {
		if (this.props.onChange) {
			return <div className={styles.scriptTag}>&lt;script&gt;</div>;
		}

		return this.state.isServiceActive ? (
			<SmartEmbed htmlText={this.props.data.content.html} />
		) : (
			<DataPrivacyBanner service={this.service} onToggle={this.handleOnToggle} />
		);
	}
}

interface ISmartEmbedProps {
	htmlText: string;
}

class SmartEmbed extends React.Component<ISmartEmbedProps> {
	containerRef = React.createRef<HTMLIFrameElement>();
	// @ts-ignore
	intervalReference: any;
	// @ts-ignore
	mountTimeoutId: any;
	observer: MutationObserver | null = null;

	componentDidMount() {
		this.mountTimeoutId = setTimeout(() => this.mountFragment(), 1000); // timeout is important as it won't work in firefox if there is no timeout!
	}

	mountFragment = () => {
		if (document && this.containerRef.current && this.containerRef.current.contentDocument) {
			this.mountTimeoutId = null;
			const doc = this.containerRef.current.contentDocument;
			doc.body.style.overflow = 'hidden';
			doc.body.style.margin = '0 0 0 0';

			const embedText = this.props.htmlText;

			let frag = doc.createRange().createContextualFragment(`<div class="embed-wrapper">${embedText}</div>`);

			this.observer = new MutationObserver(() => {
				if (this.intervalReference) {
					clearInterval(this.intervalReference);
				}
				this.intervalReference = setInterval(() => {
					const children = Array.from(doc.body.children);
					const newHeight = `${children.reduce((acc, child) => acc + child.scrollHeight, 0)}px`;
					// const newHeight = `${doc.body.scrollHeight}px`;
					if (this.containerRef.current && this.containerRef.current.style.height !== newHeight) {
						this.containerRef.current.style.height = newHeight;
					}
				}, 500);
			});

			this.observer.observe(doc.body, { childList: true });

			doc.body.appendChild(frag);
		}
	};

	componentWillUnmount() {
		if (this.mountTimeoutId) {
			clearTimeout(this.mountTimeoutId);
		}
		if (this.intervalReference) {
			clearInterval(this.intervalReference);
		}
		if (this.observer) {
			this.observer.disconnect();
		}
	}

	render() {
		return (
			<iframe
				// eslint-disable-next-line react/no-find-dom-node
				ref={this.containerRef}
				style={{ border: 'none', width: '100%', overflow: 'hidden' }}
				title="embed"
			/>
		);
	}
}
