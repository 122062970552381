import SVGIcon from '../SVGIcon';
import React from 'react';

export default function DunstigNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M19.717 14.465a6.437 6.437 0 0 1-5.336 2.817c-3.574 0-6.466-2.892-6.466-6.466s2.892-6.466 6.466-6.466c.372 0 .745.037 1.104.099a5.593 5.593 0 0 0-2.234 4.48 5.611 5.611 0 0 0 5.609 5.609c.299-.011.584-.036.857-.073zm3.649-9.531l-.037 2.333 2.271-.546-1.514 1.762 2.085 1.043-2.309.372.931 2.135-1.998-1.192-.658 2.234-.769-2.197-1.948 1.291.831-2.184-2.321-.261 2.036-1.141-1.601-1.688 2.283.434-.149-2.32 1.477 1.799z"
			/>
			<path
				fill="#969696"
				d="M32 17.045c-8.045 0-12.068-.365-16-.641-3.93-.273-7.953-.639-16-.639V14.76c8.047 0 12.07.365 16 .639 3.932.275 7.955.639 16 .639v1.007zm0 3.564c-8.045 0-12.068-.363-16-.639-3.93-.275-7.953-.641-16-.641v-1.006c8.047 0 12.07.365 16 .641 3.932.274 7.955.641 16 .641v1.004zm0 3.475c-8.045 0-12.068-.365-16-.639-3.93-.275-7.953-.641-16-.641v-1.006c8.047 0 12.07.367 16 .639 3.932.275 7.955.643 16 .643v1.004zm0 3.566c-8.045 0-12.068-.365-16-.641-3.93-.274-7.953-.641-16-.641v-1.004c8.047 0 12.07.365 16 .639 3.932.275 7.955.641 16 .641v1.006z"
			/>
		</SVGIcon>
	);
}
