import React, { Component } from 'react';
import DocumentMeta from 'react-document-meta';
import { connect } from 'react-redux';
import { isLoaded as isTeaserBoardLoaded, load as loadTeaserBoard, loadMoreArticles } from '../../redux/modules/teaserBoard';
import { bindActionCreators } from 'redux';
import { TeaserBoard, Error404 } from '../../br3-components';
import styles from './TeaserBoardConnector.scss';
import classNames from 'classnames';
import SocialBar from '../../br3-components/components/SocialBar/SocialBar';
import * as config from '../../config.webapp';

const teaserBoardLayoutNames = ['A', 'B', 'C'];

class TeaserBoardConnector extends Component {
	static fetchData(getState, dispatch, location, params) {
		if (!params.id) {
			params.id = 'homepage';
		}

		if (!isTeaserBoardLoaded(getState()) || getState().teaserBoard.id !== params.id) {
			return dispatch(loadTeaserBoard({ ...params }));
		}
	}

	loadMoreArticles = () => {
		this.props.loadMoreArticles(this.props.matchingArticlesPagination);
	};

	render() {
		if (this.props.errors || !this.props.teaserBoard || !this.props.teaserBoard.id) {
			return <Error404 />;
		} else {
			let meta = {};

			const atiDoctype = this.props.match.path ? 'Verteilseite' : 'Startseite';
			const title =
				this.props.teaserBoard.id === 'homepage'
					? 'BAYERN 3 - Bayerns Hitradio Nummer 1 mit allen Hits von heute – und den meisten neuen! Webradio Livestream Playlist Musik Wetter Verkehr'
					: 'BAYERN 3 ' + this.props.teaserBoard.attributes.content.title;
			meta = {
				title: title,
				description:
					'BAYERN 3 - Bayerns Hitradio Nummer 1 mit allen Hits von heute – und den meisten neuen! Webradio Livestream Playlist Musik Wetter Verkehr. Kontakt: 0800/8003800',
				canonical: `${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.teaserBoard.id === 'homepage' ? '' : this.props.teaserBoard.id}`,
				meta: {
					property: {
						'og:title': title,
						'og:description':
							'BAYERN 3 - Bayerns Hitradio Nummer 1 mit allen Hits von heute – und den meisten neuen! Webradio Livestream Playlist Musik Wetter Verkehr. Kontakt: 0800/8003800',
						'twitter:card': 'summary',
						'twitter:site': '@bayern3',
						'twitter:title': title,
						'twitter:description':
							'BAYERN 3 - Bayerns Hitradio Nummer 1 mit allen Hits von heute – und den meisten neuen! Webradio Livestream Playlist Musik Wetter Verkehr. Kontakt: 0800/8003800',
						'ati:section': this.props.teaserBoard.attributes.content.title,
						'ati:doctype': atiDoctype,
					},
				},
			};

			return (
				<div className={classNames(styles.teaserBoardContainer)}>
					{this.props.teaserBoard.id !== 'homepage' && <h1 className={styles.title}>{this.props.teaserBoard.attributes.content.title}</h1>}
					<SocialBar />
					<TeaserBoard
						layoutType={teaserBoardLayoutNames[this.props.teaserBoard.attributes.metadata.board_type]}
						teasers={this.props.pinnedArticles}
					/>
					<TeaserBoard
						layoutType="grid"
						teasers={this.props.matchingArticles.filter(obj => obj && obj.id !== '68rkgdtj70tk4dtr70t38dtk6gvk4')}
					/>
					{this.props.matchingArticlesPagination && (
						<div className={styles.loadMore}>
							<button onClick={this.loadMoreArticles}>mehr anzeigen</button>
						</div>
					)}
					<DocumentMeta {...meta} extend />
				</div>
			);
		}
	}
}

export default connect(
	globalState => ({
		teaserBoard: globalState.teaserBoard.data,
		pinnedArticles: globalState.teaserBoard.data.relationships.pinned_articles.data,
		matchingArticles: globalState.teaserBoard.data.relationships.matching_articles.data,
		matchingArticlesPagination: globalState.teaserBoard.matchingArticlesPagination,
		loading: globalState.teaserBoard.loading,
		errors: globalState.teaserBoard.errors,
	}),
	dispatch => bindActionCreators({ loadTeaserBoard, loadMoreArticles }, dispatch)
)(TeaserBoardConnector);
