import SVGIcon from '../SVGIcon';
import React from 'react';

export default function StarkBewoelktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.285 16.587a6.441 6.441 0 0 1-5.341 2.819c-3.577 0-6.471-2.894-6.471-6.471s2.894-6.471 6.471-6.471c.373 0 .745.037 1.105.099a5.616 5.616 0 0 0 3.378 10.098 9.11 9.11 0 0 0 .858-.074zm3.652-9.54L17.9 9.383l2.273-.547-1.516 1.764 2.087 1.043-2.31.373.931 2.136-1.999-1.192-.659 2.236-.77-2.199-1.95 1.292.832-2.186-2.322-.261 2.037-1.143-1.603-1.689 2.286.435-.149-2.323 1.478 1.801z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.751 21.577a2.372 2.372 0 0 1-2.372-2.372 2.362 2.362 0 0 1 2.372-2.36c.186 0 .36.025.547.062l.348.087.099-.348c.46-1.702 2.012-2.894 3.776-2.894s3.316 1.192 3.776 2.894l.099.348.348-.087a2.65 2.65 0 0 1 .547-.062c1.304 0 2.372 1.056 2.372 2.36s-1.056 2.372-2.372 2.372h-9.54z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.521 14.124a3.538 3.538 0 0 1 3.416 2.621l.186.696.708-.161c.149-.037.311-.05.46-.05 1.105 0 2 .894 2 1.987 0 1.106-.894 2-2 2h-9.54c-1.105 0-2-.894-2-2 0-1.093.894-1.987 2-1.987.149 0 .311.012.46.05l.708.161.186-.696a3.537 3.537 0 0 1 3.416-2.621m0-.745a4.292 4.292 0 0 0-4.136 3.167 2.744 2.744 0 0 0-3.378 2.659 2.746 2.746 0 0 0 2.745 2.745h9.539a2.746 2.746 0 0 0 2.745-2.745 2.736 2.736 0 0 0-2.745-2.733c-.224 0-.435.025-.633.074a4.294 4.294 0 0 0-4.137-3.167z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.602 17.776a.307.307 0 0 1-.118-.024l-1.205-.497a.311.311 0 0 1 .237-.574l1.205.497a.31.31 0 0 1-.119.598z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.482 19.052c-1.117 0-2.035-.918-2.035-2.035s.918-2.035 2.035-2.035a2 2 0 0 1 .472.05l.298.074.074-.285c.397-1.464 1.725-2.482 3.239-2.482s2.855 1.018 3.239 2.482l.074.285.298-.074c.149-.037.31-.05.472-.05 1.117 0 2.035.918 2.035 2.035s-.918 2.035-2.035 2.035h-8.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.565 12.636c1.378 0 2.581.931 2.941 2.259l.161.583.583-.136c.136-.025.261-.05.397-.05.956 0 1.725.77 1.725 1.725s-.769 1.725-1.725 1.725h-8.166c-.956 0-1.725-.769-1.725-1.725s.769-1.725 1.725-1.725c.137 0 .273.012.397.05l.583.136.161-.583a3.058 3.058 0 0 1 2.943-2.259m0-.621a3.652 3.652 0 0 0-3.537 2.718 2.352 2.352 0 0 0-2.892 2.284 2.352 2.352 0 0 0 2.346 2.346h8.166a2.352 2.352 0 0 0 2.346-2.346 2.352 2.352 0 0 0-2.892-2.284 3.652 3.652 0 0 0-3.537-2.718z"
			/>
			<path fill="#4F4E4E" d="M23.219 15.838a.32.32 0 0 1-.117-.023l-1.216-.496a.31.31 0 1 1 .234-.574l1.216.496a.31.31 0 0 1-.117.597z" />
			<g>
				<path
					fill="#FFF"
					d="M9.116 25.064a3.535 3.535 0 0 1-3.53-3.53 3.535 3.535 0 0 1 3.53-3.53c.274 0 .547.037.82.099l.472.112.124-.46a5.8 5.8 0 1 1 11.212-.025l.124.46.472-.112c.274-.062.547-.099.82-.099a3.535 3.535 0 0 1 3.53 3.53 3.535 3.535 0 0 1-3.53 3.53H9.116v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.139 13.926a5.324 5.324 0 0 1 5.134 3.928l.249.932.932-.224c.236-.05.46-.087.696-.087a3.037 3.037 0 0 1 3.033 3.033 3.037 3.037 0 0 1-3.033 3.033H9.103a3.037 3.037 0 0 1-3.033-3.033 3.037 3.037 0 0 1 3.033-3.033c.236 0 .472.025.696.087l.932.224.249-.932a5.375 5.375 0 0 1 5.159-3.928m0-.995a6.302 6.302 0 0 0-6.091 4.662 4.027 4.027 0 1 0-.932 7.944h14.047a4.027 4.027 0 1 0-.932-7.944 6.304 6.304 0 0 0-6.092-4.662z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.801 19.321a.44.44 0 0 1-.173-.036l-1.604-.696a.435.435 0 0 1 .346-.798l1.604.696a.436.436 0 0 1-.173.834z"
				/>
			</g>
		</SVGIcon>
	);
}
