import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeLeichtBedecktNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#FFF"
				d="M2.745 15.56a2.371 2.371 0 0 1-2.371-2.371 2.36 2.36 0 0 1 2.371-2.359c.186 0 .36.025.546.062l.348.087.099-.348c.459-1.701 2.011-2.892 3.774-2.892s3.315 1.192 3.774 2.892l.099.348.348-.087a2.368 2.368 0 0 1 2.917 2.297 2.37 2.37 0 0 1-2.371 2.371H2.745z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.512 8.112a3.536 3.536 0 0 1 3.414 2.619l.186.695.708-.161c.149-.037.31-.05.459-.05 1.105 0 1.999.894 1.999 1.986a1.998 1.998 0 0 1-1.999 1.999H2.745a1.998 1.998 0 0 1-1.999-1.999c0-1.092.894-1.986 1.999-1.986.149 0 .31.012.459.05l.708.161.186-.695a3.535 3.535 0 0 1 3.414-2.619m0-.745a4.29 4.29 0 0 0-4.134 3.166A2.741 2.741 0 0 0 .002 13.19a2.744 2.744 0 0 0 2.743 2.743h9.534a2.744 2.744 0 0 0 2.743-2.743 2.733 2.733 0 0 0-2.743-2.731 2.64 2.64 0 0 0-.633.074 4.29 4.29 0 0 0-4.134-3.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M4.594 11.762a.307.307 0 0 1-.118-.024l-1.204-.497a.311.311 0 0 1 .236-.574l1.204.497a.311.311 0 0 1-.118.598z"
			/>
			<path
				fill="#C9C8C8"
				d="M21.483 13.028a2.028 2.028 0 0 1-2.011-2.009 2.03 2.03 0 0 1 2.011-2.013c.182 0 .274 0 .457.092l.274.092.092-.277c.365-1.462 1.737-2.467 3.292-2.467 1.554 0 2.833 1.006 3.29 2.467l.093.277.275-.092c.182 0 .273-.092.457-.092 1.096 0 2.01.914 2.01 2.013a2.028 2.028 0 0 1-2.01 2.009h-8.23z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.599 6.627c1.369 0 2.559.914 2.924 2.284l.183.552.55-.185h.365a1.74 1.74 0 0 1 1.738 1.741c0 1.004-.732 1.737-1.738 1.737h-8.138a1.736 1.736 0 0 1-1.737-1.737c0-1.008.729-1.741 1.737-1.741h.365l.549.185.184-.552c.365-1.37 1.645-2.284 3.018-2.284m0-.639c-1.739 0-3.11 1.189-3.567 2.742-.184 0-.366-.092-.549-.092-1.281 0-2.379 1.097-2.379 2.38 0 1.279 1.098 2.376 2.379 2.376h8.138c1.282 0 2.377-1.097 2.377-2.376 0-1.283-1.095-2.38-2.377-2.38-.183 0-.365 0-.551.092-.363-1.553-1.826-2.742-3.471-2.742z"
			/>
			<path
				fill="#CAC9C8"
				d="M9.138 19.064a3.569 3.569 0 0 1-3.564-3.567c0-1.922 1.553-3.565 3.564-3.565.274 0 .55 0 .821.09l.458.092.093-.458c.639-2.559 3.016-4.298 5.577-4.298 2.562 0 4.938 1.739 5.578 4.298l.092.458.457-.092c.275-.09.548-.09.825-.09 1.918 0 3.564 1.553 3.564 3.565 0 1.922-1.554 3.567-3.564 3.567H9.138z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.088 7.906c2.378 0 4.481 1.647 5.12 3.933l.276.916.913-.183c.275-.092.457-.092.73-.092 1.648 0 3.019 1.37 3.019 3.017 0 1.645-1.371 3.017-3.019 3.017H9.047c-1.645 0-3.019-1.372-3.019-3.017 0-1.647 1.374-3.017 3.019-3.017.274 0 .456 0 .731.092l.915.183.274-.916c.642-2.286 2.744-3.933 5.121-3.933m0-1.004c-2.925 0-5.395 2.009-6.128 4.664-.272-.092-.639-.092-.913-.092-2.194 0-4.024 1.826-4.024 4.023 0 2.195 1.83 4.023 4.024 4.023h14.08c2.199 0 4.027-1.828 4.027-4.023 0-2.196-1.828-4.023-4.027-4.023-.273 0-.637 0-.912.092-.732-2.655-3.202-4.664-6.127-4.664z"
			/>
			<path
				fill="#0971B8"
				d="M21.848 21.257l-.091-.456-.915.274.184-.916-.458-.091-.182.824-.639-.55-.368.275.641.64-.824.274.094.458.913-.274-.274.914.457.091.275-.914.64.639.365-.272-.64-.642zm-2.835 3.291l-.091-.454-.914.273.274-.824-.547-.181-.184.914-.64-.642-.366.367.639.641-.822.181.09.458.914-.183-.182.823.458.091.182-.822.641.639.367-.366-.641-.64zM16.18 21.257l-.183-.456-.825.274.184-.916-.458-.091-.182.824-.639-.55-.366.275.64.64-.824.274.09.458.916-.274-.275.914.458.091.274-.914.642.639.273-.272-.64-.642zM13.344 24.548l-.182-.454-.823.273.182-.824-.457-.181-.182.914-.641-.642-.364.367.639.641-.823.181.091.458.912-.183-.272.823.458.091.274-.822.64.639.274-.366-.639-.64zM10.418 21.257l-.091-.456-.824.274.185-.916-.46-.091-.273.824-.641-.55-.272.275.639.64-.913.274.181.458.824-.274-.184.914.458.091.181-.914.642.639.368-.272-.643-.642z"
			/>
		</SVGIcon>
	);
}
