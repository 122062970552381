import PropTypes from 'prop-types';
import React from 'react';
// @ts-ignore
import NestedStatus from 'react-nested-status';
import ContentElementsAMP from '../../commons/contentElementsAMP';
import TeaserBoxAMP from '../TeaserBox/TeaserBoxAMP.js';
import Summary from '../Summary/Summary.js';
import moment from 'moment';
import idx from 'idx';
import * as config from '../../../config.webapp';

/**
 * Component to display an AMP-Article
 * @see https://www.ampproject.org/
 */
export default class ArticleAMP extends React.Component {
	static propTypes = {
		article: PropTypes.object, //enhance by using shape
		banner: PropTypes.object,
	};

	render() {
		if (!this.props.article) {
			return null;
		}

		let teaser = React.createElement(ContentElementsAMP[this.props.article.attributes.content.teaser_element.element_type], {
			headline: this.props.article.attributes.content.headline,
			title: this.props.article.attributes.content.title,
			isTeaser: true,
			data: this.props.article.attributes.content.teaser_element,
		});

		let legal =
			idx(this.props, _ => _.article.attributes.content.teaser_element.content.images[0].metadata.copyright) ||
			idx(this.props, _ => _.article.attributes.content.teaser_element.metadata.copyright);

		const timestamp = this.props.article.updatedAt || this.props.article.createdAt || new Date();

		const timestampString = moment(timestamp).format('DD.MM.YY');

		let schemaHeadline = this.props.article.attributes.content.headline + ': ' + this.props.article.attributes.content.title;
		let schemaURL = `${config.PUBLIC_FACING_ORIGIN_WEBAPP}/${this.props.article.id}`;

		const isAndroid = this.props.path.endsWith('/android');

		return (
			<NestedStatus code={200}>
				<div>
					<div
						data-class="container"
						data-timestamp={timestamp}
						data-headline={schemaHeadline}
						data-image={this.props.article.attributes.content.teaser_image.content.source}
						data-url={schemaURL}
						id="metadataStorage"
					>
						{!isAndroid ? teaser : ''}
						<main>
							{isAndroid && <div data-class="android-timestamp">{timestampString}</div>}

							{!isAndroid ? (
								<TeaserBoxAMP
									htmlText={this.props.article.attributes.content.teaser_text}
									legal={legal}
									date={timestamp}
									articleId={this.props.article.id}
									headline={this.props.article.attributes.content.headline}
									title={this.props.article.attributes.content.title}
								/>
							) : (
								<div
									data-class="teaserboxContent"
									dangerouslySetInnerHTML={{
										__html: this.props.article.attributes.content.teaser_text,
									}}
								/>
							)}

							{this.props.article.attributes.content.elements.map((e, i) => {
								const element = React.createElement(ContentElementsAMP[e.element_type], {
									key: i,
									data: e,
								});
								return element;
							})}
						</main>
						{!isAndroid && <Summary text={this.props.article.attributes.content.summary} />}
					</div>
					{this.props.children}
				</div>
			</NestedStatus>
		);
	}
}
