import React, { Component } from 'react';
import { Services, isServiceActive, registerListener, removeListener } from '../dataPrivacy';

/**
 * Decorator for components that require the facebook sdk.
 * @param options
 */
function withFacebookSDK(options = { appId: '' }) {
	// @ts-ignore
	return (ComposedComponent: any) =>
		class FacebookSocialPlugin extends Component {
			state = {
				isComponentMounted: false,
			};

			// exposing the defaultProps of the decorated component
			static defaultProps = ComposedComponent.defaultProps || {};

			/**
			 * Loads the facebook sdk or parses the page if it has already been loaded
			 */
			loadFbSdk = () => {
				if (!document.querySelector('#facebook-jssdk')) {
					// @ts-ignore
					(function(d: any, s: string, id: string) {
						let js,
							fjs = d.getElementsByTagName(s)[0];
						if (d.getElementById(id)) return;
						js = d.createElement(s);
						js.id = id;
						js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5&appId=' + options.appId;
						fjs.parentNode.insertBefore(js, fjs);
					}.bind(this)(document, 'script', 'facebook-jssdk'));
					// @ts-ignore
				} else if (window && window.FB) {
					// @ts-ignore
					window.FB.XFBML.parse();
				}
			};

			/**
			 * Loads the fb sdk when the service gets activated.
			 */
			handleServiceActiveChange = (value: boolean) => {
				if (value) {
					this.loadFbSdk();
				}
			};

			componentDidMount = () => {
				this.handleServiceActiveChange(isServiceActive(Services.FACEBOOK));
				registerListener(Services.FACEBOOK, this.handleServiceActiveChange);
				this.setState({ isComponentMounted: true });
			};

			componentWillUnmount = () => {
				removeListener(Services.FACEBOOK, this.handleServiceActiveChange);
			};

			render() {
				return <ComposedComponent {...this.props} />;
			}
		};
}

export default withFacebookSDK;
