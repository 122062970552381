// Polyfills
import './polyfills';

import React from 'react';
import { hydrate, render } from 'react-dom';
import createStore from './redux/create';
import ApiClient from './helpers/ApiClient';
import * as config from './config.webapp.ts';
import logger from 'loglevel';
import Root from './Root.tsx';

import { createBrowserHistory } from 'history';

logger.setLevel(config.LOG_LEVEL);

const client = new ApiClient();
const routerHistory = createBrowserHistory();
const store = createStore(client, routerHistory, window.__data);

if (__DISABLE_SSR__) {
	render(<Root store={store} history={routerHistory} />, document.getElementById('root'));
} else {
	hydrate(<Root store={store} history={routerHistory} />, document.getElementById('root'));
}

if (module.hot) {
	module.hot.accept();
}
