import SVGIcon from '../SVGIcon';
import React from 'react';

export default function HagelschauerNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.443 13.163a6.449 6.449 0 0 1-5.344 2.82A6.47 6.47 0 0 1 2.626 9.51a6.47 6.47 0 0 1 6.473-6.473c.374 0 .747.037 1.106.099a5.6 5.6 0 0 0-2.237 4.487 5.618 5.618 0 0 0 5.617 5.614c.297-.012.584-.037.858-.074zm3.651-9.542l-.037 2.335 2.275-.546-1.516 1.765 2.086 1.044-2.311.371.932 2.137-1.999-1.192-.66 2.237-.77-2.2-1.95 1.293.833-2.187-2.324-.261 2.037-1.143-1.603-1.69 2.286.434-.148-2.323 1.478 1.801z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.747 18.292a2.37 2.37 0 0 1-2.371-2.37 2.36 2.36 0 0 1 2.371-2.36c.187 0 .36.025.546.062l.348.088.099-.349a3.918 3.918 0 0 1 3.774-2.892 3.917 3.917 0 0 1 3.774 2.892l.1.349.347-.088c.174-.037.361-.062.546-.062a2.368 2.368 0 0 1 2.372 2.36 2.37 2.37 0 0 1-2.372 2.37H2.747z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.515 10.844a3.535 3.535 0 0 1 3.415 2.619l.186.695.708-.162c.149-.037.311-.049.459-.049 1.106 0 1.999.892 1.999 1.986a1.997 1.997 0 0 1-1.999 1.998H2.747a1.997 1.997 0 0 1-1.998-1.998c0-1.094.893-1.986 1.998-1.986.149 0 .31.012.461.049l.707.162.185-.695a3.536 3.536 0 0 1 3.415-2.619m0-.746a4.29 4.29 0 0 0-4.133 3.166 2.743 2.743 0 0 0-3.378 2.658 2.745 2.745 0 0 0 2.744 2.742h9.534a2.744 2.744 0 0 0 2.744-2.742 2.734 2.734 0 0 0-2.744-2.732c-.222 0-.434.025-.632.074a4.293 4.293 0 0 0-4.135-3.166z"
			/>
			<path
				fill="#FFF"
				d="M21.482 15.731a2.029 2.029 0 0 1-2.011-2.01c0-1.098.914-2.012 2.011-2.012.183 0 .275 0 .458.092l.275.092.092-.276c.365-1.462 1.736-2.467 3.291-2.467s2.834 1.005 3.292 2.467l.092.276.274-.092c.182 0 .274-.092.456-.092 1.098 0 2.012.913 2.012 2.012 0 1.096-.914 2.01-2.012 2.01h-8.23z"
			/>
			<path
				fill="#4F4E4E"
				d="M29.71 15.981h-8.228c-1.224 0-2.259-1.036-2.259-2.259 0-1.225 1.035-2.261 2.259-2.261.189 0 .326 0 .552.111l.022.006.014-.039c.385-1.547 1.837-2.639 3.526-2.639 1.642 0 3.026 1.038 3.529 2.643l.012.035.077-.025h.041c.05 0 .089-.014.149-.033.078-.025.175-.058.307-.058 1.226 0 2.26 1.036 2.26 2.261-.001 1.221-1.035 2.258-2.261 2.258zm-8.228-4.024c-.955 0-1.762.808-1.762 1.764 0 .954.807 1.76 1.762 1.76h8.228c.957 0 1.763-.806 1.763-1.76 0-.956-.806-1.764-1.763-1.764-.051 0-.09.014-.149.033-.069.023-.154.053-.264.058l-.475.158-.17-.512c-.436-1.396-1.636-2.296-3.055-2.296-1.46 0-2.716.937-3.051 2.278l-.175.53-.542-.183c-.132-.066-.177-.066-.347-.066z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.596 9.33c1.372 0 2.56.915 2.927 2.286l.182.549.549-.181h.364c.915 0 1.737.73 1.737 1.737 0 1.005-.731 1.737-1.737 1.737h-8.137a1.736 1.736 0 0 1-1.736-1.737c0-1.007.731-1.737 1.736-1.737h.366l.548.181.184-.549c.366-1.37 1.646-2.286 3.017-2.286m0-.638c-1.737 0-3.108 1.188-3.566 2.744-.183 0-.366-.092-.549-.092-1.279 0-2.377 1.096-2.377 2.378 0 1.279 1.097 2.376 2.377 2.376h8.137c1.28 0 2.378-1.096 2.378-2.376a2.361 2.361 0 0 0-2.378-2.378c-.182 0-.364 0-.549.092-.364-1.556-1.827-2.744-3.473-2.744z"
			/>
			<path
				fill="#4F4E4E"
				d="M23.219 12.569a.352.352 0 0 1-.13-.027L21.9 11.99a.309.309 0 0 1-.151-.411.308.308 0 0 1 .412-.152l1.188.547a.31.31 0 0 1 .151.413.304.304 0 0 1-.281.182z"
			/>
			<path
				fill="#CAC9C8"
				d="M9.14 21.766c-1.92 0-3.565-1.554-3.565-3.565 0-1.92 1.554-3.566 3.565-3.566.274 0 .549 0 .823.092l.457.092.093-.458c.64-2.559 3.016-4.296 5.576-4.296 2.652 0 4.938 1.737 5.577 4.296l.092.458.458-.092c.275-.092.548-.092.823-.092 1.919 0 3.566 1.554 3.566 3.566 0 1.92-1.554 3.565-3.566 3.565H9.14z"
			/>
			<path
				fill="#4F4E4E"
				d="M16.088 10.612c2.378 0 4.481 1.647 5.12 3.932l.274.915.914-.185c.275-.09.459-.09.732-.09 1.645 0 3.017 1.371 3.017 3.016s-1.372 3.016-3.017 3.016H9.14c-1.644 0-3.016-1.371-3.016-3.016s1.372-3.016 3.016-3.016c.274 0 .458 0 .731.09l.914.185.276-.915c.548-2.285 2.65-3.932 5.027-3.932m0-1.005c-2.926 0-5.394 2.01-6.125 4.662-.274-.092-.64-.092-.913-.092-2.195 0-4.023 1.829-4.023 4.023 0 2.195 1.828 4.023 4.023 4.023h14.079c2.195 0 4.023-1.829 4.023-4.023 0-2.195-1.829-4.023-4.023-4.023-.366 0-.64 0-.914.092-.733-2.652-3.201-4.662-6.127-4.662z"
			/>
			<path
				fill="#4F4E4E"
				d="M11.791 15.984a.464.464 0 0 1-.158-.029l-1.644-.641a.434.434 0 1 1 .315-.81l1.645.639a.436.436 0 0 1-.158.841z"
			/>
			<g>
				<circle fill="#FFF" cx="3.014" cy="21.766" r="1.737" />
				<path
					fill="#4F4E4E"
					d="M3.014 23.75a1.986 1.986 0 0 1-1.985-1.984 1.986 1.986 0 0 1 3.971 0c0 1.094-.89 1.984-1.986 1.984zm0-3.472a1.49 1.49 0 0 0-1.489 1.488c0 .82.668 1.488 1.489 1.488a1.49 1.49 0 0 0 1.489-1.488 1.49 1.49 0 0 0-1.489-1.488z"
				/>
				<circle fill="#FFF" cx="6.123" cy="26.977" r="1.737" />
				<path
					fill="#4F4E4E"
					d="M6.123 28.963a1.987 1.987 0 0 1 0-3.972 1.986 1.986 0 0 1 0 3.972zm0-3.476a1.49 1.49 0 0 0 0 2.98 1.49 1.49 0 0 0 0-2.98z"
				/>
				<circle fill="#FFF" cx="11.334" cy="25.149" r="1.738" />
				<path
					fill="#4F4E4E"
					d="M11.334 27.135a1.988 1.988 0 0 1-1.986-1.986c0-1.094.891-1.986 1.986-1.986a1.987 1.987 0 0 1 0 3.972zm0-3.476a1.49 1.49 0 0 0 0 2.98c.82 0 1.489-.668 1.489-1.49 0-.82-.669-1.49-1.489-1.49z"
				/>
			</g>
		</SVGIcon>
	);
}
