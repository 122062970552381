import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenLeichtBedecktIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#0971B8"
				d="M6.776 22.342c.162-.274.162-.746.187-1.132.025-.498.037-.884.037-.884s-.212.274-.622.535c-.323.212-.846.373-1.008.659a.813.813 0 1 0 1.406.822zm4.975 0c.162-.274.162-.746.187-1.132.025-.498.037-.883.037-.883s-.212.274-.622.535c-.323.212-.846.373-1.008.659a.82.82 0 0 0 .299 1.12.815.815 0 0 0 1.107-.299zm4.974 0c.162-.274.162-.746.187-1.132.025-.498.037-.883.037-.883s-.212.274-.622.535c-.323.212-.846.373-1.008.659a.82.82 0 0 0 .299 1.12.815.815 0 0 0 1.107-.299zm4.973 0c.162-.274.162-.746.187-1.132.025-.498.037-.883.037-.883s-.212.273-.622.534c-.323.212-.846.373-1.008.659a.814.814 0 1 0 1.406.822zm-8.395 2.718c.162-.274.162-.746.187-1.132.025-.498.037-.883.037-.883s-.212.274-.622.535c-.323.212-.846.373-1.008.659a.82.82 0 0 0 .299 1.12.813.813 0 0 0 1.107-.299zm-4.763 0c.162-.274.162-.746.187-1.132.025-.498.037-.883.037-.883s-.212.274-.622.535c-.323.212-.846.373-1.008.659a.813.813 0 1 0 1.406.821zm9.525 0c.162-.274.162-.746.187-1.132.025-.498.037-.883.037-.883s-.212.274-.622.535c-.323.212-.846.373-1.008.659a.82.82 0 0 0 .299 1.12.813.813 0 0 0 1.107-.299z"
			/>
			<path
				fill="#FFF"
				d="M2.747 16.094a2.372 2.372 0 0 1-2.372-2.372 2.362 2.362 0 0 1 2.372-2.36c.186 0 .36.025.546.062l.348.087.099-.348c.46-1.701 2.013-2.894 3.777-2.894s3.316 1.192 3.776 2.894l.099.348.348-.087a2.37 2.37 0 0 1 2.918 2.298 2.372 2.372 0 0 1-2.372 2.372H2.747z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.517 8.642a3.536 3.536 0 0 1 3.415 2.621l.186.696.708-.161c.149-.037.311-.05.46-.05 1.105 0 2 .894 2 1.987 0 1.105-.894 2-2 2H2.747c-1.105 0-2-.894-2-2 0-1.093.894-1.987 2-1.987.149 0 .311.012.46.05l.708.161.186-.696a3.537 3.537 0 0 1 3.416-2.621m0-.745a4.292 4.292 0 0 0-4.136 3.167 2.744 2.744 0 0 0-3.378 2.658 2.746 2.746 0 0 0 2.745 2.745h9.539a2.746 2.746 0 0 0 2.745-2.745 2.735 2.735 0 0 0-2.745-2.732c-.224 0-.435.025-.633.074a4.295 4.295 0 0 0-4.137-3.167z"
			/>
			<path fill="#4F4E4E" d="M4.598 12.294a.307.307 0 0 1-.118-.024l-1.205-.497a.31.31 0 1 1 .236-.574l1.205.497a.31.31 0 0 1-.118.598z" />
			<path
				fill="#FFF"
				d="M21.487 13.57c-1.117 0-2.035-.918-2.035-2.035S20.37 9.5 21.487 9.5a2 2 0 0 1 .472.05l.298.074.074-.285a3.36 3.36 0 0 1 3.239-2.482 3.349 3.349 0 0 1 3.239 2.482l.074.285.298-.074c.149-.037.31-.05.472-.05 1.117 0 2.035.918 2.035 2.035s-.918 2.035-2.035 2.035h-8.166z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.569 7.154c1.378 0 2.581.931 2.941 2.259l.161.583.583-.136c.136-.025.261-.05.397-.05.956 0 1.725.769 1.725 1.725s-.769 1.725-1.725 1.725h-8.166c-.956 0-1.725-.769-1.725-1.725s.769-1.725 1.725-1.725c.137 0 .273.012.397.05l.583.136.161-.583a3.058 3.058 0 0 1 2.943-2.259m0-.62a3.653 3.653 0 0 0-3.537 2.718 2.351 2.351 0 0 0-2.891 2.284 2.35 2.35 0 0 0 2.345 2.345h8.166a2.35 2.35 0 0 0 2.345-2.345 2.352 2.352 0 0 0-2.891-2.284 3.651 3.651 0 0 0-3.537-2.718z"
			/>
			<path fill="#4F4E4E" d="M23.224 10.356a.32.32 0 0 1-.117-.023l-1.216-.496a.31.31 0 1 1 .234-.574l1.216.496a.31.31 0 0 1-.117.597z" />
			<g>
				<path
					fill="#C9C8C8"
					d="M9.111 19.586a3.538 3.538 0 0 1-3.533-3.533 3.538 3.538 0 0 1 3.533-3.533c.274 0 .547.037.821.099l.473.112.124-.46a5.8 5.8 0 0 1 5.61-4.329 5.806 5.806 0 0 1 5.61 4.304l.124.46.473-.112c.274-.062.547-.099.821-.099a3.538 3.538 0 0 1 3.533 3.533 3.538 3.538 0 0 1-3.533 3.533H9.111v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.14 8.44a5.327 5.327 0 0 1 5.138 3.931l.249.933.933-.224c.236-.05.46-.087.697-.087a3.04 3.04 0 0 1 3.035 3.035 3.04 3.04 0 0 1-3.035 3.035H9.099a3.04 3.04 0 0 1-3.035-3.035 3.04 3.04 0 0 1 3.035-3.035c.236 0 .473.025.697.087l.933.224.249-.933A5.376 5.376 0 0 1 16.14 8.44m0-.995a6.308 6.308 0 0 0-6.096 4.665 4.03 4.03 0 1 0-.933 7.949h14.057a4.03 4.03 0 1 0-.933-7.949 6.306 6.306 0 0 0-6.095-4.665z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.798 13.839a.44.44 0 0 1-.173-.036l-1.605-.697a.435.435 0 1 1 .347-.799l1.605.697a.435.435 0 0 1-.174.835z"
				/>
			</g>
		</SVGIcon>
	);
}
