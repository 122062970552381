import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NewsBildergalerieIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#3D3D3C"
				d="M16 .799c8.381 0 15.201 6.819 15.201 15.2 0 8.383-6.82 15.201-15.201 15.201S.799 24.382.799 16C.799 7.619 7.619.799 16 .799M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16S24.837 0 16 0z"
			/>
			<path
				fill="#3D3D3C"
				d="M22.369 10.001h-8.557c-.846 0-1.533.687-1.533 1.533v2.373h.799v-2.373c0-.404.329-.733.734-.733h8.557c.404 0 .733.329.733.733v5.826a.734.734 0 0 1-.733.733h-2.73V15.04c0-.623-.51-1.133-1.133-1.133H9.95c-.624 0-1.134.51-1.134 1.133v5.826c0 .624.51 1.134 1.134 1.134h8.557c.623 0 1.133-.51 1.133-1.133v-1.974h2.73c.845 0 1.533-.687 1.533-1.533v-5.826a1.537 1.537 0 0 0-1.534-1.533zM18.84 20.867a.337.337 0 0 1-.333.333H9.95a.338.338 0 0 1-.334-.333v-5.826c0-.181.153-.333.334-.333h8.557c.18 0 .333.153.333.333v5.826z"
			/>
		</SVGIcon>
	);
}
