import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './ContentElementImage.scss';
import mediaPlayerStyles from '../ContentElementMediaPlayer/ContentElementMediaPlayer.scss';
import AutoAspectImage from '../AutoAspectImage/AutoAspectImage';
import { stylesForQueryString } from '../../commons/utils/index';
import SvgPencil from './pencil.svg';
import SvgAudioTrack from './ic_audiotrack_black_24px.svg';
import trackEvent from '../../commons/tracking/trackers';
import Slider from './Slider.tsx';
import idx from 'idx';

/**
 * Component for displaying a gallery, slideshow or songbattle in the webapp and for editing the same content in the cms.
 */
class ContentElementImage extends React.Component {
	static propTypes = {
		title: PropTypes.string,
		ratio: PropTypes.number,
		headline: PropTypes.string,
		isTeaser: PropTypes.bool,
	};

	static defaultProps = {
		data: {
			element_type: 'gallery',
			content: {
				images: [],
			},
		},
		isTeaser: false,
	};

	state = {
		currentImage: idx(this.props, _ => _.data.content.images.length) > 0 ? 0 : -1,
		isGalleryEditorDialogOpen: false,
		isSongbattleDialogOpen: false,
	};

	containerRef = React.createRef();
	progressCounterRef = React.createRef();

	UNSAFE_componentWillReceiveProps = nextProps => {
		this.setState({
			currentImage: idx(nextProps, _ => _.data.content.images.length) > 0 ? 0 : -1,
		});
	};

	componentDidMount = () => {
		if (!this.props.onChange && this.props.isTeaser && (idx(this.props, _ => _.data.content.images) || []).length > 1) {
			window.addEventListener('keyup', this.onKeyPress);
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('keyup', this.onKeyPress);
	};

	onKeyPress = e => {
		if (e.which === 37) {
			document.querySelector('.slick-prev').click();
		} else if (e.which === 39) {
			document.querySelector('.slick-next').click();
		}
	};

	onImagesChange = images => {
		this.props.data.content.images = images;
		this.props.onChange(this.props.data);
	};

	onImageChange = i => {
		const teaserTitle = this.props.isTeaser
			? this.props.title.toLowerCase()
			: (idx(this.props, _ => _.datacontent.images[0].metadata.title) || '').toLowerCase();

		if (!this.props.onChange) {
			trackEvent('ContentElementImage', `${('0' + i).substr(-2)}`, `gallery-${teaserTitle}`, {
				id: this.props.id,
				title: this.props.title,
			});
		}

		this.setState({
			currentImage: i,
		});

		if (this.progressCounterRef.current) {
			const element = this.progressCounterRef.current;
			setTimeout(() => element.classList.add(styles.progressCounterVisible), 0.01);
		}
	};

	openGalleryEditorDialog = () => {
		this.setState({ isGalleryEditorDialogOpen: true });
	};

	closeGalleryEditorDialog = () => {
		this.setState({ isGalleryEditorDialogOpen: false });

		// trigger resize to rerender silder
		window.dispatchEvent(new Event('resize'));

		if (this.props.isTeaser) {
			// set teaser_image
			this.props.onTeaserImageChange(idx(this.props, _ => _.data.content.images[0]));
		}
	};

	onTitleChange = title => {
		if (this.props.isTeaser) {
			this.props.onTitleChange(title);
		}

		// update title not only on article, but also on first image
		if ((idx(this.props, _ => _.data.content.images) || []).length > 0) {
			//this.props.onChange(_.set(this.props.data,'content.images[0].metadata.title',title));
		}
	};

	render = () => {
		const titleText = this.props.isTeaser
			? this.props.title
			: idx(this.props, _ => _.data.content.images[this.state.currentImage].metadata.title) || '';
		let title = React.createElement(this.props.isTeaser ? 'h1' : 'h3', {
			className: classNames(mediaPlayerStyles.title),
			dangerouslySetInnerHTML: { __html: titleText },
		});

		let headline;
		if (this.props.isTeaser && this.props.headline) {
			headline = React.createElement(this.props.isTeaser ? 'h2' : 'h4', {
				className: classNames(mediaPlayerStyles.headline),
				dangerouslySetInnerHTML: { __html: this.props.headline },
			});
		}

		let slideshowEditor;
		if (this.props.onChange) {
			if (IS_CMS) {
				// eslint-disable-next-line @typescript-eslint/no-var-requires
				const mui = require('@material-ui/core');
				const ImageGalleryEditor = require('./ImageGalleryEditor').default;
				const SongBattleEditor = require('./SongBattleEditor').default;
				const ReactMediumEditor = require('../ReactMediumEditor/ReactMediumEditor').default;

				slideshowEditor = (
					<div className={classNames(styles.slideshowEditor)}>
						<mui.Dialog maxWidth="sm" fullWidth={true} open={this.state.isGalleryEditorDialogOpen} onClose={this.closeGalleryEditorDialog}>
							<mui.DialogContent>
								<ImageGalleryEditor
									onChange={this.onImagesChange}
									images={idx(this.props, _ => _.data.content.images) || []}
									isTeaser={this.props.isTeaser}
									title={this.props.title}
									onTitleChange={this.onTitleChange}
									ratios={this.props.isTeaser ? ['3:1', '2:1', '1:1', '3:2'] : ['16:9']}
								/>
							</mui.DialogContent>
							<mui.DialogActions>
								<mui.Button color="primary" onClick={this.closeGalleryEditorDialog}>
									Fertig
								</mui.Button>
							</mui.DialogActions>
						</mui.Dialog>
						<mui.Dialog
							maxWidth="md"
							fullWidth={true}
							open={this.state.isSongbattleDialogOpen}
							onClose={() => {
								this.setState({ isSongbattleDialogOpen: false });
							}}
						>
							<mui.DialogContent>
								<SongBattleEditor onChange={this.props.onChange} data={this.props.data} />
							</mui.DialogContent>
							<mui.DialogActions>
								<mui.Button
									color="primary"
									onClick={() => {
										this.setState({ isSongbattleDialogOpen: false });
									}}
								>
									Fertig
								</mui.Button>
							</mui.DialogActions>
						</mui.Dialog>
						<div className={classNames(styles.editButton)}>
							<mui.Fab color="secondary" onClick={this.openGalleryEditorDialog}>
								<SvgPencil />
							</mui.Fab>
							{this.props.data.element_type === 'songbattle' && (
								<mui.Fab
									color="default"
									onClick={() => {
										this.setState({ isSongbattleDialogOpen: true });
									}}
									style={{ marginLeft: 10 }}
								>
									<SvgAudioTrack style={{ fill: '#000' }} />
								</mui.Fab>
							)}
						</div>
					</div>
				);

				title = <h3>{titleText}</h3>;

				if (this.props.isTeaser) {
					headline = (
						<ReactMediumEditor
							text={this.props.headline}
							tag={this.props.isTeaser ? 'h2' : 'h4'}
							className={classNames(mediaPlayerStyles.headline)}
							onChange={this.props.onHeadlineChange}
							options={{
								placeholder: { text: 'Dachzeile' },
								disableReturn: true,
								toolbar: false,
								buttons: ['anchor'],
							}}
						/>
					);

					title = (
						<ReactMediumEditor
							text={titleText}
							tag={this.props.isTeaser ? 'h1' : 'h3'}
							className={classNames(mediaPlayerStyles.title)}
							onChange={this.onTitleChange}
							options={{
								placeholder: { text: 'Titel' },
								disableReturn: true,
								toolbar: false,
								buttons: ['anchor'],
							}}
						/>
					);
				}
			}
		}

		let slider;

		if ((idx(this.props, _ => _.data.content.images) || []).length > 1) {
			const props = {
				images: this.props.data.content.images,
				// onChange: this.props.onChange,
				onImageChange: this.onImageChange,
				isTeaser: this.props.isTeaser,
				containerOffsetWidth: this.containerRef.current ? this.containerRef.current.offsetWidth : 0,
			};

			slider = <Slider {...props} />;
		} else if ((idx(this.props, _ => _.data.content.images) || []).length === 1) {
			if (this.props.onChange && this.props.data.content.images[0].content.source.indexOf('blob:') === 0) {
				let backgroundCropStyles = {};
				backgroundCropStyles = stylesForQueryString(
					this.props.isTeaser
						? idx(this.props.data, _ => _.content.images[0].content.variants.three_by_one)
						: idx(this.props.data, _ => _.content.images[0].content.source),
					this.containerRef.current ? this.containerRef.current.offsetWidth : '',
					this.props.isTeaser ? 3 / 2.49 : 1
				);

				slider = <div className={classNames(styles.poster)} style={backgroundCropStyles} />;
			} else {
				slider = (
					<AutoAspectImage
						styles={this.props.imageStyles}
						{...idx(this.props, _ => _.data.content.images[0].content)}
						title={idx(this.props, _ => _.data.content.images[0].metadata.title)}
					/>
				);
			}
		} else {
			slider = <div className={classNames(styles.poster)} />;
		}

		let legal = (
			<div className={classNames(mediaPlayerStyles.legal)}>
				{this.state.currentImage > -1 ? this.props.data.content.images[this.state.currentImage].metadata.copyright : ''}
			</div>
		);

		let titlebar = (
			<div className={classNames(styles.titlebar)}>
				{!this.props.isTeaser ? legal : null}
				{headline}
				{title}
			</div>
		);

		//change title during slideshow
		if (
			(idx(this.props, _ => _.data.content.images) || []).length > 1 &&
			this.state.currentImage !== 0 &&
			this.props.data.content.images[this.state.currentImage].metadata.title.length > 0
		) {
			titlebar = (
				<div className={classNames(styles.titlebar)}>
					{!this.props.isTeaser && !this.props.onChange && legal}
					{React.createElement(this.props.isTeaser ? 'h1' : 'h3', {
						className: classNames(mediaPlayerStyles.title),
						dangerouslySetInnerHTML: {
							__html: this.props.data.content.images[this.state.currentImage].metadata.title,
						},
					})}
				</div>
			);
		}

		const currentImageIndex = this.state.currentImage + 1;
		const imagesCount = (idx(this.props, _ => _.data.content.images) || []).length;
		const progressWidth = currentImageIndex === 1 ? '0%' : (currentImageIndex / imagesCount) * 100 + '%';

		const progressHint = (
			<div className={classNames(styles.progressIndicator)}>
				<div className={classNames(styles.progressBar)} style={{ width: progressWidth }} />
				<div ref={this.progressCounterRef} className={classNames(['slide' + this.state.currentImage, styles.progressCounter])}>
					{currentImageIndex}/{imagesCount}
				</div>
			</div>
		);

		return (
			<div className={classNames(styles.contentElementImageGallery)} ref={this.containerRef}>
				<div className={classNames(styles.imagePlayer)}>
					<div className={classNames([mediaPlayerStyles.aspectRatio, 'aspectRatio'])} />
					{imagesCount > 1 && progressHint}
					{slider}
					{slideshowEditor}
				</div>
				{(this.props.onChange ||
					(this.props.title || this.props.headline) ||
					(idx(this.props, _ => _.data.content.images[this.state.currentImage].metadata.title) ||
						idx(this.props, _ => _.data.content.images[this.state.currentImage].metadata.copyright))) && (
					<div className="titleBox">{titlebar}</div>
				)}
			</div>
		);
	};
}

let ExportComponent = ContentElementImage;

if (IS_CMS) {
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const { themeableForCms } = require('../../commons/utils/cms');
	ExportComponent = themeableForCms(ContentElementImage);
}

export default ExportComponent;
