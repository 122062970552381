import React from 'react';
import { isServiceActive, Services, registerListener, removeListener } from '../../commons/dataPrivacy';
import DataPrivacyBanner from '../DataPrivacyBanner/DataPrivacyBanner';

interface IProps {
	data: any;
}

interface IState {
	isServiceActive: boolean;
}

/**
 * Component to display an embedded typeform form.
 */
export default class ContentElementEmbedTypeForm extends React.Component<IProps, IState> {
	state = {
		isServiceActive: false,
	};

	static defaultProps = {
		data: {
			content: {
				html: '<iframe id="typeform" src=""></iframe>',
			},
		},
	};

	handleServiceActiveChange = (value: boolean) => {
		this.setState({ isServiceActive: value });
	};

	componentDidMount() {
		this.handleServiceActiveChange(isServiceActive(Services.TYPEFORM));
		registerListener(Services.TYPEFORM, this.handleServiceActiveChange);
	}

	componentWillUnmount() {
		removeListener(Services.TYPEFORM, this.handleServiceActiveChange);
	}

	render() {
		return (
			<div>
				{this.state.isServiceActive ? (
					<div dangerouslySetInnerHTML={{ __html: this.props.data.content.html }} />
				) : (
					<DataPrivacyBanner service={Services.TYPEFORM} />
				)}
			</div>
		);
	}
}
