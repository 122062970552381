import SVGIcon from '../SVGIcon';
import React from 'react';

export default function PlayerFullscreenEnableGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="16" cy="15.999" r="16" />
			<path
				fill="#FFF"
				d="M13.818 8.953H9.382a.52.52 0 0 0-.522.521v4.287a.521.521 0 1 0 1.044 0V9.998h3.915a.522.522 0 0 0-.001-1.045zm8.799-.076h-4.286a.522.522 0 0 0 0 1.046h3.765v3.915a.52.52 0 1 0 1.043 0V9.4a.522.522 0 0 0-.522-.523zm0 8.843a.52.52 0 0 0-.521.521v3.762h-3.915a.523.523 0 0 0 0 1.046h4.436a.523.523 0 0 0 .522-.522V18.24a.52.52 0 0 0-.522-.52zm-8.951 4.358H9.903v-3.914a.522.522 0 0 0-1.044 0V22.6a.52.52 0 0 0 .522.522h4.284a.521.521 0 1 0 .001-1.044z"
			/>
		</SVGIcon>
	);
}
