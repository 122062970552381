import React from 'react';
import classNames from 'classnames';
import styles from './Navigation.scss';
import { Link } from 'react-router-dom';
import trackEvent from '../../commons/tracking/trackers';
import ClickOutHandler from 'react-onclickout';
import SvgClose from './icon_b3_close.svg';
import SvgBurger from './icon_b3_burger.svg';
import logger from 'loglevel';

const log = logger.getLogger('Navigation.js');
log.setLevel('DEBUG');

const LinkList = ({ closeMenu, overlayStyle, children }) => (
	<div className={classNames(styles.overlay, overlayStyle)} onClick={closeMenu}>
		<nav className={classNames(styles.teaserbox)}>
			<ul>{children}</ul>
		</nav>
	</div>
);

function prependSlashIfNotExists(link) {
	// prepend a slash to urls which don't start with "/"
	return link.match(/^\//g) ? link : `/${link}`;
}

const NavItem = ({ title, link }) => {
	// Render external links classified by 'http...'
	const isExternalLink = link.match(/^https|^http/g) ? true : false;

	return (
		<li>
			{isExternalLink ? (
				<a href={link} target="_blank" rel="noopener noreferrer">
					{title}
				</a>
			) : (
				<Link key={'nav_' + title} to={prependSlashIfNotExists(link)}>
					{title}
				</Link>
			)}
		</li>
	);
};

export default class Navigation extends React.Component {
	static defaultProps = {
		data: {
			data: {
				attributes: {
					content: {
						items: [],
					},
				},
			},
		},
	};

	state = {
		menuVisible: false,
	};

	componentDidMount = () => {
		window.addEventListener('keyup', this.onKeyPress);
	};

	componentWillUnmount = () => {
		window.removeEventListener('keyup', this.onKeyPress);
	};

	onToggleMenu = () => {
		trackEvent('Navigation', this.state.menuVisible ? 'close' : 'open');

		this.setState({
			menuVisible: !this.state.menuVisible,
		});
	};

	onClickOut = () => {
		this.closeMenu();
	};

	closeMenu = () => {
		this.setState({ menuVisible: false });
	};

	onKeyPress = e => {
		if (e.which === 27) {
			this.closeMenu();
		}
	};

	render = () => {
		// TODO: fix render issue (uncomment the next line to see that we have a re-render every second)
		// console.log('🚨 render');

		const overlayStyle = {};
		overlayStyle[styles.visible] = this.state.menuVisible;

		const removeNavItems = ['/r/mobile', '/r/homepage', '/r/soundsnack'];
		const links = this.props.data.data.attributes.content.items
			.filter(item => !removeNavItems.includes(item.link))
			.map(item => <NavItem {...item} key={'nav_li_' + item.title} />);

		return (
			<div className={classNames(styles.navigation)}>
				<ClickOutHandler onClickOut={this.onClickOut}>
					<button onClick={this.onToggleMenu} title="Navigation öffnen/schließen">
						{this.state.menuVisible ? (
							<SvgClose className={classNames(styles.button)} />
						) : (
							<SvgBurger className={classNames(styles.button)} />
						)}
					</button>
				</ClickOutHandler>

				<LinkList closeMenu={this.closeMenu} overlayStyle={overlayStyle}>
					{links}
				</LinkList>
			</div>
		);
	};
}
