import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SchneeschauerNachtIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M14.446 13.352a6.453 6.453 0 0 1-5.349 2.824c-3.583 0-6.481-2.898-6.481-6.481s2.898-6.481 6.481-6.481c.373 0 .746.037 1.107.099a5.625 5.625 0 0 0 3.384 10.114c.299-.013.585-.038.858-.075zm3.658-9.554l-.038 2.339 2.277-.548-1.518 1.767 2.09 1.045-2.314.373.933 2.139-2.002-1.194-.66 2.24-.771-2.202-1.953 1.293.833-2.189-2.326-.261 2.04-1.145-1.605-1.692 2.289.436-.149-2.326 1.48 1.803z"
			/>
			<path
				fill="#0971B8"
				d="M4.93 21.922l-.184-.732-1.281.365.275-1.281-.64-.182-.368 1.372-1.005-1.006-.458.549 1.006.915-1.373.366.276.731 1.281-.366-.367 1.282.732.184.276-1.282 1.005.915.549-.548-1.006-.916zm4.666 4.667l-.182-.732-1.281.366.275-1.282-.642-.182-.366 1.372-1.006-1.006-.457.548 1.006.916-1.373.366.274.731 1.281-.365-.365 1.28.732.184.274-1.281 1.007.915.549-.549-1.008-.915zm6.406-2.013l-.184-.732-1.279.457.275-1.373-.732-.182-.275 1.373-1.006-1.008-.459.55.916.915-1.281.365.183.732 1.371-.366-.365 1.282.732.182.274-1.28 1.006.915.459-.549-.916-.916z"
			/>
			<path
				fill="#C9C8C8"
				d="M2.739 18.432a2.374 2.374 0 0 1-2.374-2.374 2.364 2.364 0 0 1 2.374-2.362c.186 0 .36.025.547.062l.348.087.099-.348c.46-1.703 2.014-2.896 3.779-2.896s3.319 1.193 3.779 2.896l.099.348.348-.087a2.372 2.372 0 0 1 2.921 2.3 2.374 2.374 0 0 1-2.374 2.374H2.739z"
			/>
			<path
				fill="#4F4E4E"
				d="M7.512 10.974a3.54 3.54 0 0 1 3.418 2.623l.186.696.709-.162c.149-.037.311-.05.46-.05 1.106 0 2.001.895 2.001 1.989a2 2 0 0 1-2.001 2.001H2.739A2 2 0 0 1 .738 16.07c0-1.094.895-1.989 2.001-1.989.149 0 .311.012.46.05l.709.162.186-.696a3.538 3.538 0 0 1 3.418-2.623m0-.746a4.295 4.295 0 0 0-4.139 3.17 2.745 2.745 0 0 0-3.381 2.66 2.748 2.748 0 0 0 2.747 2.747h9.546a2.748 2.748 0 0 0 2.747-2.747 2.738 2.738 0 0 0-3.381-2.66 4.296 4.296 0 0 0-4.139-3.17z"
			/>
			<path fill="#4F4E4E" d="M4.591 14.629a.307.307 0 0 1-.118-.024l-1.206-.497a.31.31 0 1 1 .237-.575l1.206.497a.31.31 0 0 1-.119.599z" />
			<path
				fill="#C9C8C8"
				d="M21.486 15.908c-1.118 0-2.037-.919-2.037-2.037s.919-2.037 2.037-2.037a2 2 0 0 1 .472.05l.298.074.075-.286c.397-1.466 1.726-2.484 3.242-2.484s2.857 1.018 3.242 2.484l.075.286.298-.074c.149-.037.31-.05.472-.05 1.118 0 2.037.919 2.037 2.037s-.919 2.037-2.037 2.037h-8.174z"
			/>
			<path
				fill="#4F4E4E"
				d="M25.572 9.487c1.379 0 2.583.932 2.944 2.26l.161.584.584-.137c.137-.025.261-.05.397-.05.956 0 1.726.77 1.726 1.726s-.77 1.726-1.726 1.726h-8.172c-.956 0-1.726-.77-1.726-1.726s.77-1.726 1.726-1.726c.137 0 .273.012.397.05l.584.137.161-.584a3.058 3.058 0 0 1 2.944-2.26m0-.621a3.655 3.655 0 0 0-3.54 2.72 2.353 2.353 0 0 0-2.893 2.285 2.353 2.353 0 0 0 2.347 2.347h8.172a2.353 2.353 0 0 0 2.347-2.347 2.353 2.353 0 0 0-2.893-2.285 3.655 3.655 0 0 0-3.54-2.72z"
			/>
			<path fill="#4F4E4E" d="M23.225 12.691a.32.32 0 0 1-.117-.023l-1.217-.497a.31.31 0 1 1 .235-.575l1.217.497a.31.31 0 0 1-.118.598z" />
			<g>
				<path
					fill="#FFF"
					d="M9.11 21.922a3.538 3.538 0 0 1-3.533-3.533 3.538 3.538 0 0 1 3.533-3.533c.274 0 .547.037.821.099l.473.112.124-.46a5.8 5.8 0 0 1 5.61-4.329 5.806 5.806 0 0 1 5.61 4.304l.124.46.473-.112c.274-.062.547-.099.821-.099a3.538 3.538 0 0 1 3.533 3.533 3.538 3.538 0 0 1-3.533 3.533H9.11v.025z"
				/>
				<path
					fill="#4F4E4E"
					d="M16.139 10.776a5.327 5.327 0 0 1 5.138 3.931l.249.933.933-.224c.236-.05.46-.087.697-.087a3.04 3.04 0 0 1 3.035 3.035 3.04 3.04 0 0 1-3.035 3.035H9.098a3.04 3.04 0 0 1-3.035-3.035 3.04 3.04 0 0 1 3.035-3.035c.236 0 .473.025.697.087l.933.224.249-.933a5.376 5.376 0 0 1 5.162-3.931m0-.995a6.308 6.308 0 0 0-6.096 4.665 4.03 4.03 0 1 0-.933 7.949h14.057a4.03 4.03 0 1 0-.933-7.949 6.306 6.306 0 0 0-6.095-4.665z"
				/>
				<path
					fill="#4F4E4E"
					d="M11.797 16.175a.44.44 0 0 1-.173-.036l-1.605-.697a.435.435 0 1 1 .347-.799l1.605.697a.436.436 0 0 1-.174.835z"
				/>
			</g>
		</SVGIcon>
	);
}
