import React from 'react';
import classNames from 'classnames';
import ToggleButton from '../ToggleButton/ToggleButton';
import styles from './StaticPages.scss';
import {
	IPrivacySettings,
	getSettings,
	Services,
	toggleService,
	registerListener,
	ICallbackFunction,
	removeListener,
	servicesMetadata,
	defaultPrivacySettings,
} from '../../commons/dataPrivacy';
import { isTrackingActive, toggleTracking } from '../../commons/tracking/trackers';

interface IProps {
	data: {
		html?: string;
	};
}

interface IState extends IPrivacySettings {
	tracking: boolean;
}

// Services that should always be allowed
const EXCLUDED_SERVICES = [Services.BAYERN3_EVENTS, Services.BAYERN3_RADIO, Services.BR];

/**
 * Displays the privacy policy.
 */
export default class PrivacyPolicy extends React.Component<IProps, IState> {
	state = { ...defaultPrivacySettings, tracking: false };

	callbacks: ICallbackFunction[] = [];

	/**
	 * Rewrite all h3 tags to label tags since br.de uses h3 and bayern3.de uses label...
	 */
	rewriteTags = (text: string) => text.replace(/h3/g, 'label');

	componentDidMount() {
		this.setState({ ...getSettings(), tracking: isTrackingActive() });
		Object.values(Services).forEach(service => {
			const callback = (state: boolean) => this.setState({ ...this.state, [service]: state });
			registerListener(service, callback);
			this.callbacks.push(callback);
		});
	}

	componentWillUnmount() {
		Object.values(Services).forEach((service, i) => {
			removeListener(service, this.callbacks[i]);
		});
	}

	toggleTrackingSwitch = () => {
		toggleTracking();
		this.setState({ tracking: isTrackingActive() });
	};

	render() {
		// const privacyPolicyText = this.rewriteTags(this.props.data ? this.props.data.html || '' : '');

		return (
			<div className={classNames(styles.imprint, 'container')}>
				<main>
					<div className={classNames(styles.headline)}>
						<h1>Datenschutz</h1>
					</div>
					<div className={classNames(styles.content)}>
						<p>Hierfinden Sie die Datenschutzerklärung des Bayerischen Rundfunks:</p>
						<p>
							<a
								href="https://www.br.de/unternehmen/service/datenschutz/datenschutz-br-102.html"
								target="_blank"
								rel="noopener noreferrer"
								className={classNames(styles.buttonLink)}
							>
								Zur Datenschutzerklärung &gt;
							</a>
						</p>
						<label>Einstellungenzur Einbettung von Drittanbietern</label>
						<p>
							Hier können Sie einstellen, welche Drittanbieter Sie auf den Bayern 3-Seiten eingebunden sehen möchten. Dabei werden
							personenbezogene Daten übertragen. Diese Einstellung können Sie jederzeit mit Wirkung für die Zukunft wieder ändern.Weitere
							Infos zur Einbindung von Drittanbietern finden Sie in der{' '}
							<a
								href="https://www.br.de/unternehmen/service/datenschutz/datenschutz-drittplattformen-100.html"
								target="_blank"
								rel="noopener noreferrer"
							>
								Datenschutzerklärung
							</a>
						</p>
						<div className={styles.serviceControl}>
							<ToggleButton value={this.state.tracking} onToggle={this.toggleTrackingSwitch} />
							<p>
								Mein Besuch auf Bayern3.de wird anonymisiert statistisch erfasst. Bei der Messung mit AT-Internet und Google Analytics sind
								technisch keine Rückschlüsse auf mich als individuelle Person möglich.
							</p>
						</div>
						{Object.values(Services)
							.filter(service => !EXCLUDED_SERVICES.includes(service))
							.map(service => {
								const metadata = servicesMetadata.get(service);
								return (
									<div key={service} className={styles.serviceControl}>
										<ToggleButton value={this.state[service]} onToggle={() => toggleService(service)} />
										<p>
											Ich möchte eingebundene {metadata ? metadata.content : 'Inhalte'} auf Bayern3.de sehen. Hierbei werden
											personenbezogene Daten (IP-Adresse o.ä.) an {metadata ? metadata.provider : service} übertragen.
										</p>
									</div>
								);
							})}

						{/* <div dangerouslySetInnerHTML={{ __html: privacyPolicyText }} /> */}
					</div>
				</main>
			</div>
		);
	}
}
