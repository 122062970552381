import { useEffect } from 'react';
import ioClient from 'socket.io-client';
import * as config from '../config.webapp';

const url = `${config.ORIGIN_TEAPOT}`;
const socket = ioClient.connect(url, { transports: ['websocket'] });

enum TEAPOT_EVENTS {
	playlist = 'playlist',
}

export interface IPlaylist {
	music?: IMusic[];
	program?: IProgram[];
}

interface IProps {
	update: React.Dispatch<React.SetStateAction<IPlaylist>>;
}

interface IMusic {
	duration: string;
	id: number;
	timestamp: string;
	title: string;
	title_id: string;
	type: 'cart' | string;
}

interface IProgram {
	title: string;
	program: string;
	timestart: string;
	timestop: string;
	id: number;
}

export async function usePlaylist({ update }: IProps) {
	async function pollData(): Promise<void> {
		const response = await fetch(`${config.SERVICE.playlist}`, { headers: { Accept: 'application/json' } });
		const { music, program } = await response.json();
		update({ music, program });
	}

	useEffect(() => {
		let intervalId: number | undefined;

		if (!config.USE_SOCKETS_FOR_PLAYLIST) {
			pollData();
			intervalId = window.setInterval(pollData, 5000);
		} else {
			socket.on(TEAPOT_EVENTS.playlist, ({ music, program }: IPlaylist) => {
				update({ music, program });
			});
		}

		return function cleanup() {
			if (intervalId) {
				clearInterval(intervalId);
			}
			if (socket) {
				socket.removeListener(TEAPOT_EVENTS.playlist);
			}
		};
	}, []);
}
