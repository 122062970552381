import SVGIcon from '../SVGIcon';
import React from 'react';

export default function SturmIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#3F3F3F"
				d="M29.341 7.821c6.484 7.124-7.219 7.346-17.485 7.435l.843-2.016-12.711 4.02 8.139 6.917.961-2.299c13.948-1.555 29.198-6.392 20.253-14.057z"
			/>
		</SVGIcon>
	);
}
