import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './Banner.scss';
import { Link } from 'react-router-dom';
import SvgAirBallon from './airballoon.svg';
import * as config from '../../../config.webapp.ts';

export default class Banner extends React.Component {
	constructor(props) {
		super(props);
	}

	static propTypes = {
		data: PropTypes.shape({
			image_source: PropTypes.string,
			title: PropTypes.string,
			link: PropTypes.string,
		}).isRequired,
	};

	static defaultProps = {
		data: {},
	};

	render() {
		const { data } = this.props;

		let component = null;

		let anchor;
		let isSameOrigin = true;

		if (window && window.document) {
			anchor = window.document.createElement('a');
			anchor.href = data.link;
			if (new URL(config.PUBLIC_FACING_ORIGIN_WEBAPP).hostname.includes(anchor.hostname)) {
				isSameOrigin = true;
			} else {
				isSameOrigin = window.document.location.origin === anchor.origin;
			}
		}

		if (data && data.image_source && data.link) {
			if (isSameOrigin) {
				component = (
					<Link to={anchor.pathname}>
						<img className={classNames(styles.banner)} src={data.image_source} alt={data.title} />
					</Link>
				);
			} else {
				component = (
					<a target="_blank" rel="noopener noreferrer" href={anchor.href}>
						<img className={classNames(styles.banner)} src={data.image_source} alt={data.title} />
					</a>
				);
			}
		} else if (IS_CMS) {
			// if we are in cms show dummy
			component = (
				<div className={classNames(styles.bannerPlaceholder)}>
					<div>
						<SvgAirBallon />
						BANNER
					</div>
				</div>
			);
		}

		return component;
	}
}
