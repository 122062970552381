import SVGIcon from '../SVGIcon';
import React from 'react';

export default function RegenschauerHeiterIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#F59E18"
				d="M18.417 10.75a5.133 5.133 0 0 1-1.82 7.013 5.133 5.133 0 0 1-7.013-1.82c-1.43-2.433-.613-5.583 1.82-7.014s5.574-.625 7.013 1.821zm-6.904-3.53l-1.631.959-3.436-5.844 1.631-.959zM8.777 9.285l-.934 1.647L1.941 7.58l.934-1.646zM7.443 12.429l.016 1.893-6.773.05-.016-1.893zm13.103 1.788l-.016-1.893 6.774-.051.015 1.893zM7.865 15.82l.958 1.631-5.843 3.436-.959-1.631zm12.238-5.001l-.959-1.63 5.844-3.436.959 1.631zM18.032 8.105l-1.646-.934 3.337-5.894 1.647.934zm-3.171-1.318l-1.892.016L12.918.03l1.893-.016z"
			/>
			<path
				fill="#0971B8"
				d="M5.818 25.881c.346-.578.346-1.503.346-2.312 0-.926.117-1.734.117-1.734s-.463.578-1.272 1.041c-.695.462-1.734.809-1.967 1.271-.461.809-.231 1.733.578 2.196.694.58 1.735.233 2.198-.462zm2.891 5.32c.346-.579.346-1.503.346-2.314 0-.924.117-1.734.117-1.734s-.463.578-1.272 1.041c-.695.465-1.736.809-1.967 1.273-.463.81-.23 1.734.578 2.195.811.579 1.735.348 2.198-.461zm6.707-1.734c.346-.579.346-1.503.346-2.314 0-.924.115-1.734.115-1.734s-.461.578-1.272 1.04c-.691.464-1.732.811-1.965 1.272-.463.809-.23 1.735.578 2.197.696.579 1.735.348 2.198-.461z"
			/>
			<path
				fill="#FFF"
				d="M12.262 23.731a3.682 3.682 0 0 1-3.682-3.682 3.666 3.666 0 0 1 3.682-3.663c.289 0 .559.038.848.096l.54.135.154-.54c.713-2.641 3.123-4.492 5.861-4.492s5.148 1.851 5.861 4.492l.154.54.54-.135c.27-.058.559-.096.848-.096 2.024 0 3.682 1.639 3.682 3.663s-1.639 3.682-3.682 3.682H12.262z"
			/>
			<path
				fill="#4F4E4E"
				d="M19.666 12.163a5.49 5.49 0 0 1 5.302 4.068l.289 1.08 1.099-.251c.231-.058.482-.077.713-.077a3.098 3.098 0 0 1 3.104 3.085 3.102 3.102 0 0 1-3.104 3.104H12.262a3.102 3.102 0 0 1-3.104-3.104 3.098 3.098 0 0 1 3.104-3.085c.231 0 .482.019.713.077l1.099.251.289-1.08a5.493 5.493 0 0 1 5.303-4.068m0-1.156c-3.066 0-5.649 2.082-6.42 4.916a4.043 4.043 0 0 0-.983-.116c-2.352 0-4.261 1.909-4.261 4.242a4.263 4.263 0 0 0 4.261 4.261H27.07a4.263 4.263 0 0 0 4.261-4.261 4.246 4.246 0 0 0-4.261-4.242c-.347 0-.675.038-.983.116-.772-2.834-3.356-4.916-6.421-4.916z"
			/>
			<path
				fill="#4F4E4E"
				d="M15.135 17.832a.48.48 0 0 1-.184-.037l-1.87-.771a.482.482 0 0 1 .367-.891l1.87.771a.482.482 0 0 1-.183.928z"
			/>
			<g fill="#F59E18">
				<path d="M18.417 10.75a5.133 5.133 0 0 1-1.82 7.013 5.133 5.133 0 0 1-7.013-1.82c-1.43-2.433-.613-5.583 1.82-7.014s5.574-.625 7.013 1.821zm-6.904-3.53l-1.631.959-3.436-5.844 1.631-.959zM8.777 9.285l-.934 1.647L1.941 7.58l.934-1.646zM7.443 12.429l.016 1.893-6.773.05-.016-1.893zm13.103 1.788l-.016-1.893 6.774-.051.015 1.893zM7.865 15.82l.958 1.631-5.843 3.436-.959-1.631zm12.238-5.001l-.959-1.63 5.844-3.436.959 1.631zM18.032 8.105l-1.646-.934 3.337-5.894 1.647.934zm-3.171-1.318l-1.892.016L12.918.03l1.893-.016z" />
			</g>
			<g>
				<path
					fill="#FFF"
					d="M12.262 23.731a3.682 3.682 0 0 1-3.682-3.682 3.666 3.666 0 0 1 3.682-3.663c.289 0 .559.038.848.096l.54.135.154-.54c.713-2.641 3.123-4.492 5.861-4.492s5.148 1.851 5.861 4.492l.154.54.54-.135c.27-.058.559-.096.848-.096 2.024 0 3.682 1.639 3.682 3.663s-1.639 3.682-3.682 3.682H12.262z"
				/>
				<path
					fill="#4F4E4E"
					d="M19.666 12.163a5.49 5.49 0 0 1 5.302 4.068l.289 1.08 1.099-.251c.231-.058.482-.077.713-.077a3.098 3.098 0 0 1 3.104 3.085 3.102 3.102 0 0 1-3.104 3.104H12.262a3.102 3.102 0 0 1-3.104-3.104 3.098 3.098 0 0 1 3.104-3.085c.231 0 .482.019.713.077l1.099.251.289-1.08a5.493 5.493 0 0 1 5.303-4.068m0-1.156c-3.066 0-5.649 2.082-6.42 4.916a4.043 4.043 0 0 0-.983-.116c-2.352 0-4.261 1.909-4.261 4.242a4.263 4.263 0 0 0 4.261 4.261H27.07a4.263 4.263 0 0 0 4.261-4.261 4.246 4.246 0 0 0-4.261-4.242c-.347 0-.675.038-.983.116-.772-2.834-3.356-4.916-6.421-4.916z"
				/>
				<path
					fill="#4F4E4E"
					d="M15.135 17.832a.48.48 0 0 1-.184-.037l-1.87-.771a.482.482 0 0 1 .367-.891l1.87.771a.482.482 0 0 1-.183.928z"
				/>
			</g>
		</SVGIcon>
	);
}
