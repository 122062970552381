import SVGIcon from '../SVGIcon';
import React from 'react';

export default function NebelIcon(props) {
	return (
		<SVGIcon {...props}>
			<path
				fill="#969696"
				d="M31.999 10.057c-8.045 0-12.068-.365-15.999-.641-3.931-.273-7.954-.639-15.999-.639V7.772c8.045 0 12.068.365 15.999.638 3.931.275 7.954.642 15.999.642v1.005zm0 3.566c-8.045 0-12.068-.365-15.999-.641-3.931-.273-7.954-.641-15.999-.641v-1.004c8.045 0 12.068.363 15.999.639 3.931.275 7.954.639 15.999.639v1.008zm0 3.473c-8.045 0-12.068-.365-15.999-.639-3.931-.275-7.954-.641-15.999-.641V14.81c8.045 0 12.068.365 15.999.641 3.931.273 7.954.641 15.999.641v1.004zm0 3.566c-8.045 0-12.068-.365-15.999-.639-3.931-.275-7.954-.642-15.999-.642v-1.004c8.045 0 12.068.365 15.999.639 3.931.275 7.954.641 15.999.641v1.005zm0 3.567c-8.045 0-12.068-.367-15.999-.643-3.931-.271-7.954-.639-15.999-.639v-1.004c8.045 0 12.068.365 15.999.639 3.931.275 7.954.641 15.999.641v1.006z"
			/>
		</SVGIcon>
	);
}
