import SVGIcon from '../SVGIcon';
import React from 'react';

export default function FastWolkenlosIcon(props) {
	return (
		<SVGIcon {...props}>
			<g fill="#F59E18">
				<path d="M17.021 16c0 2.603-2.123 4.726-4.726 4.726S7.57 18.603 7.57 16s2.123-4.726 4.725-4.726 4.726 2.123 4.726 4.726zm-3.838-6.034h-1.745V3.714h1.745zm-1.745 12.068h1.745v6.252h-1.745zm-1.396-11.705l-1.512.873-3.126-5.409 1.512-.873zm4.522 11.327l1.512-.872 3.126 5.408-1.512.873zm-7.052-9.451l-.872 1.512-5.409-3.126.872-1.512zm9.582 7.546l.872-1.512 5.409 3.126-.872 1.512zM6.262 15.098v1.745H.01v-1.745zm12.068 1.745v-1.745h6.252v1.745zM6.625 18.239l.873 1.512-5.409 3.126-.873-1.512zm11.327-4.522l-.872-1.512 5.408-3.126.873 1.512zM8.501 20.769l1.512.872-3.126 5.409-1.512-.872zm7.546-9.582l-1.512-.872 3.126-5.409 1.512.872z" />
			</g>
			<path
				fill="#FFF"
				d="M19.696 22.839a2.39 2.39 0 0 1-2.38-2.38 2.39 2.39 0 0 1 2.38-2.38c.189 0 .363.015.551.058l.348.087.087-.334a3.928 3.928 0 0 1 3.787-2.902c1.77 0 3.337 1.19 3.787 2.902l.087.334.348-.087a2.27 2.27 0 0 1 .551-.058 2.39 2.39 0 0 1 2.38 2.38 2.39 2.39 0 0 1-2.38 2.38h-9.546z"
			/>
			<path
				fill="#4F4E4E"
				d="M24.47 15.338a3.57 3.57 0 0 1 3.439 2.641l.189.682.682-.16c.16-.029.305-.058.464-.058 1.117 0 2.017.9 2.017 2.017s-.9 2.017-2.017 2.017h-9.548c-1.117 0-2.017-.9-2.017-2.017s.9-2.017 2.017-2.017c.16 0 .319.015.464.058l.682.16.189-.682a3.57 3.57 0 0 1 3.439-2.641m0-.726a4.269 4.269 0 0 0-4.135 3.178 3.16 3.16 0 0 0-.638-.073 2.749 2.749 0 0 0-2.742 2.742 2.749 2.749 0 0 0 2.742 2.742h9.548a2.749 2.749 0 0 0 2.742-2.742 2.749 2.749 0 0 0-3.38-2.669 4.275 4.275 0 0 0-4.137-3.178z"
			/>
			<path
				fill="#4F4E4E"
				d="M21.727 19.081a.367.367 0 0 1-.137-.027l-1.422-.58a.363.363 0 1 1 .274-.672l1.422.58a.364.364 0 0 1-.137.699z"
			/>
		</SVGIcon>
	);
}
