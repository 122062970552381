import PropTypes from 'prop-types';
import React from 'react';

export default class ContentElementMediaPlayerIA extends React.Component {
	static propTypes = {
		poster: PropTypes.string,
		title: PropTypes.string,
		ratio: PropTypes.number,
		headline: PropTypes.string,
	};

	static defaultProps = {
		data: {
			element_type: 'media',
			element_subtype: 'video/youtube',
			metadata: {
				tags: [],
				title: '',
				copyright: '',
			},
		},
	};

	state = {
		playerLoaded: false,
		playing: false,
		position: 0,
		duration: 0,
		error: false,
		title: '',
		src: [],
		isComponentMounted: false,
		controlsHidden: false,
	};

	render() {
		let videoType = this.props.data.element_subtype;
		let videoURL = this.props.data.content.source.standard;

		if (videoType == 'video/mp4') {
			let videoHTTPS = videoURL.replace('http://', 'https://');
			return (
				<figure>
					<video>
						<source type="video/mp4" src={videoHTTPS} />
					</video>
					<figcaption>
						<h1>{this.props.data.metadata.title}</h1>
						<cite>{this.props.data.metadata.copyright}</cite>
					</figcaption>
				</figure>
			);
		} else if (videoType == 'video/youtube') {
			let videoEmbed = videoURL.replace('watch?v=', 'embed/');
			return (
				<figure className="op-interactive">
					<iframe width="560" height="315" src={videoEmbed} frameBorder="0" allowFullScreen />
				</figure>
			);
		}
		return null;
	}
}
