import SVGIcon from '../SVGIcon';
import React from 'react';

export default function ImageGreenIcon(props) {
	return (
		<SVGIcon {...props}>
			<circle fill="#94C01C" cx="15.998" cy="16.003" r="15.998" />
			<path
				fill="#FFF"
				d="M24.204 7.807h-13.53a.507.507 0 0 0-.508.509v2.374H7.793a.508.508 0 0 0-.509.508V23.69c0 .279.227.506.509.506h13.529a.507.507 0 0 0 .509-.506v-2.376h2.373a.508.508 0 0 0 .509-.509V8.315a.507.507 0 0 0-.509-.508zm-3.388 15.374H8.301V11.705h12.516v11.476zm2.88-2.882h-1.865v-9.101a.507.507 0 0 0-.509-.508h-10.14V8.823h12.515v11.476zm-7.013-3.819l-1.392 1.734-2.283-3.428-3.198 4.799h9.593l-2.72-3.105zm-.204-1.949a1.093 1.093 0 1 0 .003-2.185 1.093 1.093 0 0 0-.003 2.185z"
			/>
		</SVGIcon>
	);
}
